import { Box, Stack, Typography } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { listUsers } from 'api/organisations/users';
import { LookupSelectFieldOptions } from 'components/common/LookupInputField/types';
import useUserOrganisations from 'hooks/useUserOrganisations';
import React, { FC, useEffect, useRef, useState } from 'react';
import { User } from 'types/dbSchema/userProfiles';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { ShiftApproval } from '../types';
import { placeholderUnallocatedServiceData } from 'utils/helpers/getDBData';
import { Service } from 'types/dbSchema/services';
import { Participant } from 'types/dbSchema/participants';
import variables from 'styles/variables';
import { format } from 'date-fns';
import ListServiceTypes from 'components/features/ListServiceTypes';
import { ServiceType } from 'types/dbSchema/schedulingSettings';
import { calculateDuration } from 'utils/helpers/fullCalendarTemplates';

const ShiftApprovalList: FC<ShiftApproval> = ({ currentService, setCurrentService }) => {
  const [organisations] = useUserOrganisations();
  const [searchText, setSearchText] = useState('');
  const internalRef = useRef<HTMLDivElement>(null);

  const { data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, status } = useInfiniteQuery({
    queryKey: ['services-infinite', organisations[0]?.organisation.globalId, 100],
    // queryFn: ({ pageParam = 0 }) => listUsers(organisations[0].organisation.globalId, null, pageParam + 1, 100),
    queryFn: ({ pageParam = 0 }) => placeholderUnallocatedServiceData,
    initialPageParam: 0,
    staleTime: cachingInvalidation.userProfiles,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, allPages) => {
      return allPages.length;
    },
  });

  useEffect(() => {
    const handleScroll = (event: any) => {
      if (!event.target) return;
      const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
      if (bottom && hasNextPage) {
        fetchNextPage();
      }
    };

    const selectElement = internalRef.current as HTMLElement | null;
    if (selectElement) {
      selectElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (selectElement) {
        selectElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [fetchNextPage, hasNextPage]);

  return (
    <Stack
      ref={internalRef}
      height='calc(100% - 69px)'
      width='320px'
      sx={{
        overflowY: 'auto',
        borderRight: `1px solid ${variables.colors.lightNeutral.darker}`,
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#D1D5DB',
          borderRadius: '100px',
          cursor: 'grab',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#bec2c7',
        },
      }}
    >
      <Stack>
        {data &&
          data.pages.flatMap((page, pageIndex) =>
            placeholderUnallocatedServiceData.map((service: Service) => (
              <ServiceListItem
                key={`${service.id}-${pageIndex}`}
                option={service}
                currentService={currentService}
                setCurrentService={setCurrentService}
              />
            ))
          )}
      </Stack>
    </Stack >
  );
};

interface ServiceListItemProps {
  option: Service;
  currentService: Service | null;
  setCurrentService: React.Dispatch<React.SetStateAction<Service | null>>;
}

const ServiceListItem: FC<ServiceListItemProps> = ({ option, currentService, setCurrentService }) => {
  if (!option) return null;

  return (
    <Stack
      position='relative'
      flexDirection='row'
      alignItems='center'
      onClick={() => setCurrentService(option)}
      height='102px'
      padding='12px 16px'
      sx={{
        borderBottom: `1px solid ${variables.colors.lightNeutral.darker}`,
        cursor: 'pointer',
      }}
    >
      {currentService?.id === option.id && (
        <Box
          position='absolute'
          right='0'
          top='0'
          width='4px'
          height='100%'
          sx={{ backgroundColor: variables.colors.primary.main }}
        />
      )}
      <img
        src={(option.participant as Participant).profilePicture}
        alt="profile"
        style={{
          width: '42px',
          height: '42px',
          borderRadius: '32px',
          objectFit: 'cover',
          marginRight: '12px',
        }}
      />
      <Stack flex='1' minWidth='0' overflow='hidden'>
        <Typography variant='body2'>
          {(option.participant as Participant).fullName.firstName} {(option.participant as Participant).fullName.lastName}
        </Typography>
        <Stack flexDirection='row' gap='8px' alignItems='center'>
          <Typography variant='body2' sx={{ color: variables.colors.text.secondary }}>
            {format(option.scheduledStart, 'EEE, dd MMM')}
          </Typography>
          <svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4' fill='none'>
            <circle cx='2' cy='2' r='2' fill={variables.colors.text.secondary} />
          </svg>
          <Typography variant='body2' sx={{ color: variables.colors.text.secondary }}>
            {calculateDuration(option.scheduledStart, option.scheduledEnd)}h
          </Typography>
        </Stack>
        <ListServiceTypes serviceTypesDelivered={option.serviceTypes as ServiceType[]} />
      </Stack>
    </Stack>
  );
};

export default ShiftApprovalList;