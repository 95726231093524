import axiosInstance from "api/config";
import { RelationshipType, RelationshipTypeList } from "types/dbSchema/participantSettings";

// Returns a list of relationship types for the given organisation ID
export const listRelationShipTypes = async (organisationId: string, page?: number, pageSize?: number): Promise<RelationshipTypeList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID, page, and page size are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/participants/relationship-types`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing relationship types for the organisation', error);
    throw error;
  }
};

// Returns a relationship type by ID
export const getRelationshipTypeByID = async (organisationId: string, relationshipTypeId: string): Promise<RelationshipType> => {
  try {
    if (!relationshipTypeId || !organisationId) throw new Error('Organisation ID and relationshipType ID are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/participants/relationship-types/${relationshipTypeId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the relationship type', error);
    throw error;
  }
};

// Create a new relationship type
export const createRelationshipType = async (organisationId: string, data: RelationshipType) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/${organisationId}/settings/participants/relationship-types`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the relationship type', error);
  }
};

// Update a relationship type
export const updateRelationshipType = async (organisationId: string, data: RelationshipType) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.put(`/api/${organisationId}/settings/participants/relationship-types/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the relationship type', error);
    throw error;
  }
};

// Delete a relationship type
export const deleteRelationshipType = async (organisationId: string, relationshipTypeId: string) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.delete(`/api/${organisationId}/settings/participants/relationship-types/${relationshipTypeId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the relationship type', error);
    throw error;
  }
};