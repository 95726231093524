import axiosInstance from "api/config";
import { ExitReason, ExitReasonList } from "types/dbSchema/participantSettings";

// Returns a list of exit reasons for the given organisation ID
export const listExitReasons = async (organisationId: string, page?: number, pageSize?: number): Promise<ExitReasonList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID, page, and page size are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/participants/exit-reasons`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing exit reasons for the organisation', error);
    throw error;
  }
};

// Returns a exit reason by ID
export const getExitReasonByID = async (organisationId: string, exitReasonId: string): Promise<ExitReason> => {
  try {
    if (!exitReasonId || !organisationId) throw new Error('Organisation ID and ExitReason ID are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/participants/exit-reasons/${exitReasonId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the exit reason', error);
    throw error;
  }
};

// Create a new exit reason
export const createExitReason = async (organisationId: string, data: ExitReason) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/${organisationId}/settings/participants/exit-reasons`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the exit reason', error);
    throw error;
  }
};

// Update a exit reason
export const updateExitReason = async (organisationId: string, data: ExitReason) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.put(`/api/${organisationId}/settings/participants/exit-reasons/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the exit reason', error);
    throw error;
  }
};

// Delete a exit reason
export const deleteExitReason = async (organisationId: string, exitReasonId: string) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.delete(`/api/${organisationId}/settings/participants/exit-reasons/${exitReasonId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the exit reason', error);
    throw error;
  }
};