import React, { FC } from 'react';
import LinkIcon from '@mui/icons-material/Link';
import { Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

const Actions: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const handleViewContact = () => {
    navigate(`/participant-centre/participants/view/${id}/link-contact`);
  };

  return (
    <Button color='primary' variant='contained' onClick={handleViewContact} startIcon={<LinkIcon />} sx={{ minWidth: 'fit-content', height: '100%' }}>
      Link Contact
    </Button>
  );
};

export default Actions;