import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import DataTableControls from './Controls';
import DataTable from 'components/common/DataTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { GridRowId } from '@mui/x-data-grid';
import { Columns } from './Controls/Columns';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { Data, PaginationModel } from 'components/common/DataTable/types';
import { Participant } from 'types/dbSchema/participants';
import { useNavigate, useParams } from 'react-router-dom';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { listParticipantExternalContacts } from 'api/organisations/participants/externalcontacts';
import useInvalidateData from 'hooks/useInvalidateData';

export interface ContactsProps {
  participant: Participant;
}

const ParticipantContactsDataTable: FC<ContactsProps> = ({ participant }) => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({ page: 0, pageSize: 20 });
  const navigate = useNavigate();
  const { id } = useParams();
  const [organisations] = useUserOrganisations();
  useInvalidateData('participant-contacts');

  const handleViewContact = () => {
    navigate(`/participant-centre/participants/view/${id}/link-contact`);
  };

  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: ['participant-contacts', id, paginationModel, selectedFilters],
    queryFn: () => listParticipantExternalContacts(organisations[0].organisation.globalId, id as string, paginationModel.page + 1, paginationModel.pageSize),
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.participantContacts,
  });

  return (
    <Stack height='100%' width='100%' sx={{ backgroundColor: 'white' }}>
      <DataTableControls
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        searchText={searchText}
        setSearchText={setSearchText}
        selectedRowIds={selectedRowIds}
      />
      <DataTable
        data={data as Data}
        columns={Columns}
        setSelectedRowIds={setSelectedRowIds}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isPending={isPending || isFetching}
        isError={isError}
        title='Participant Contacts'
        dataTableName='participant-contacts'
        checkboxSelection={false}
        paginationMode='server'
        onNoData={handleViewContact}
      />
    </Stack>
  );
};

export default ParticipantContactsDataTable;