import React, { FC, useState } from 'react';
import { Box, Button, Stack, Typography, } from '@mui/material';
import variables from 'styles/variables';
import styled from 'styled-components';
import TitleWithClose from 'components/common/TitleWithClose';
import AvailabilityTemplatesLookupField from 'components/common/LookupInputField/AvailabilityTemplatesLookupField';
import { Controller, useForm } from 'react-hook-form';
import { CalendarEvent } from 'types/fullCalendar';
import { clearTemplatePreviewEvents, convertPreviewEventsToCalendarEvents, mapConvertTemplateToCalendarEvents } from 'utils/helpers/fullCalendarTemplates';

export interface SideControlsProps {
  isControlsOpen: boolean;
  setIsControlsOpen: (value: boolean) => void;
  setEvents: (value: any) => void;
}

const SideControls: FC<SideControlsProps> = ({ isControlsOpen, setIsControlsOpen, setEvents }) => {
  const [description, setDescription] = useState('');
  const { control, formState: { errors } } = useForm({
    defaultValues: {
      template: ''
    }
  });

  const handleTemplateSelection = (value: any) => {
    handleClearTemplatePreviews();
    setDescription(value.description);
    setEvents((prevEvents: CalendarEvent[]) => [
      ...mapConvertTemplateToCalendarEvents(value.availabilities, true),
      ...prevEvents,
    ]);
  };

  const handleImport = () => {
    setEvents((prevEvents: CalendarEvent[]) => convertPreviewEventsToCalendarEvents(prevEvents));
    setIsControlsOpen(false);
  };

  const handleClearTemplatePreviews = () => {
    setEvents((prevEvents: CalendarEvent[]) => clearTemplatePreviewEvents(prevEvents));
  };

  const handleCancel = () => {
    handleClearTemplatePreviews();
    setIsControlsOpen(false);
  };

  return (
    <StyledSideControls sx={{ transform: isControlsOpen ? 'translateX(0)' : 'translateX(100%)' }}>
      <Stack gap='32px'>
        <TitleWithClose title='Import template' handleClose={() => setIsControlsOpen(false)} />
        <ImportInfo>
          <Typography variant='body2' color={variables.colors.text.primary}>
            Importing an availability template will create a copy of the details into the staff
            availability. Once applied, you can manually customise the details (this will not change the saved template in settings).
          </Typography>
        </ImportInfo>
        <Controller
          name="template"
          control={control}
          rules={{ required: 'Template is required' }}
          render={({ field }) =>
            <AvailabilityTemplatesLookupField
              {...field}
              id='template'
              label='Availability Template'
              validationError={errors.template ? true : false}
              errorText={errors.template?.message}
              isMandatory
              placeholder='Select template'
              onChange={(value: any) => {
                handleTemplateSelection(value);
                field.onChange(value.name);
              }}
            />
          }
        />
        <Stack gap='4px'>
          <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary}>
            Description
          </Typography>
          <Box minHeight='71px' maxHeight='120px' padding='8px' borderRadius='4px' border='1px solid #E5E7EB' sx={{ backgroundColor: '#F9FAFB', overflowY: 'auto' }}>
            <Typography variant='body2' color={variables.colors.text.primary}>
              {description}
            </Typography>
          </Box>
        </Stack>
      </Stack>
      <Stack gap='16px'>
        <WarningNotice />
        <ButtonStack>
          <Button variant='outlined' onClick={handleCancel}>Cancel</Button>
          <Button variant='contained' color='primary' onClick={handleImport}>Import</Button>
        </ButtonStack>
      </Stack>
    </StyledSideControls>
  );
};

const WarningNotice = () => {
  return (
    <WarningNoticeBox>
      <Typography variant='subtitle2' color={variables.colors.error.darker}>
        Important!
      </Typography>
      <Typography variant='caption' fontWeight={500}>
        Once imported, the availability details will have to be manually deleted.
      </Typography>
    </WarningNoticeBox>
  );
};

const WarningNoticeBox = styled(Box)`
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  border-left: 4px solid ${variables.colors.error.main};
  background:  #FEF6F6;
`;

const StyledSideControls = styled(Stack)`
  box-shadow: 0px 2px 8px 0px rgba(55, 65, 81, 0.05), 0px 4px 20px 0px rgba(55, 65, 81, 0.10);
  background-color: white;
  width: 400px;
  min-width: 400px;
  height: 100%;
  justify-content: space-between;
  padding: 32px;
`;

const ButtonStack = styled(Stack)`
  gap: 16px;
  justify-content: flex-end;
  flex-direction: row;
`;

const ImportInfo = styled(Box)`
  padding: 16px;
  border-radius: 4px;
  background:#F8F7FA;
`;

export default SideControls;