import React, { FC, useEffect, useState } from 'react';
import { Control, Controller, UseFormSetValue, useWatch } from 'react-hook-form';
import { Box, Stack, TextField, Typography } from '@mui/material';
import styled from 'styled-components';
import { User } from 'types/dbSchema/userProfiles';
import CheckboxInput from 'components/common/InputFields/CheckboxInput';
import variables from 'styles/variables';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import RoleTemplateLookupField from 'components/common/LookupInputField/RoleTemplateLookupField';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getRoleTemplateList } from 'api/organisations/users';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { createFilterString } from 'utils/helpers';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';

export interface AccessDetailsProps {
  control: Control<User>;
  isSubmitted: boolean;
  setValue: UseFormSetValue<User>;
  getValues: () => User;
  // The type for the below is Partial<Readonly<User>> but typescript is not able to infer the type correctly
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  touchedFields: any;
}

// This is a unique component that is used to render the access details section of the user profile form
// Validations are cross dependent and nested, I've separated useEffects to check for errors and readability
// Don't judge the code and IF statements too much, it's an abomination but works well
const AccessDetails: FC<AccessDetailsProps> = ({ control, isSubmitted, touchedFields, setValue }) => {
  const values = useWatch({ control }) as { webAppUserStatus: boolean, mobileAppUserStatus: boolean, userRoles: string[] };

  const [userRolesHasError, setUserRolesHasError] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [organisations] = useUserOrganisations();
  const [serviceTeamRoleId, setServiceTeamRoleId] = useState<string>('');
  const [ownerRoleId, setOwnerRoleId] = useState<string>('');
  // This is a unique filter that is used to filter out the user roles that are not required for the web app user type
  const selectedFilters = [{
    field: "Name",
    valuesSelected: [
      "Owner",
      "Service Team"
    ]
  }] as SelectedFilter[];

  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: ['role-templates', createFilterString(selectedFilters)],
    queryFn: () => getRoleTemplateList(organisations[0].organisation.globalId, selectedFilters),
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.roleTemplates,
  });

  useEffect(() => {
    // Find the ID of the "Service Team" and "Owner" roles and set them to the state
    // This is used to filter out the user roles that are not required for the web app user type and to add the "Service Team" role to the mobile app user type
    setServiceTeamRoleId(data?.items.find(role => role.name === 'Service Team')?.id || '');
    setOwnerRoleId(data?.items.find(role => role.name === 'Owner')?.id || '');
  }, [data]);


  // Check if the user has not selected any user role after they have selected the web app user type
  // Filter the user roles array to check if the user has selected any role other than 'Service Team' (as this is limited to only mobile app users)
  // Only show the error message if the form has been submitted or the user has interacted with the fields
  useEffect(() => {
    if (values.webAppUserStatus &&
      (!values.userRoles || (values.userRoles && values.userRoles.filter((role) => role !== serviceTeamRoleId).length === 0)) &&
      (isSubmitted || touchedFields.webAppUserStatus)) {
      setUserRolesHasError(true);
    } else {
      setUserRolesHasError(false);
    }

  }, [values.userRoles, isSubmitted, touchedFields.userRoles]);

  // Check if the user has not selected any user type, only show the error message if the form has been submitted or the user has interacted with the fields
  // This is to stop it from showing the error message when first rendering the form
  useEffect(() => {
    if ((!values.webAppUserStatus && !values.mobileAppUserStatus) && (isSubmitted || (touchedFields.webAppUserStatus || touchedFields.mobileAppUserStatus))) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [values.webAppUserStatus, values.mobileAppUserStatus, isSubmitted, touchedFields.webAppUserStatus, touchedFields.mobileAppUserStatus]);

  // If the user unchecks the web app user type, remove all user roles from the userRoles array except 'Service Team'
  const handleWebAppUserCheck = (isChecked: boolean) => {
    if (!isChecked) {
      setValue('userRoles', values.userRoles.filter((role: string) => role === serviceTeamRoleId));
    }
  };

  // If the user checks the mobile app user type, add the user role 'Service Team' to the userRoles array, otherwise remove the user role from the array
  const handleMobileAppUserCheck = (isChecked: boolean) => {
    if (!isChecked) {
      setValue('userRoles', values.userRoles.filter((role: string) => role !== serviceTeamRoleId));
    } else {
      setValue('userRoles', [...values.userRoles, serviceTeamRoleId]);
    }
  };

  return (
    <AccessDetailsStack>
      <Typography variant='h6' fontWeight='600' color='text.primary'>
        Access Details
      </Typography>
      <Stack>
        <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
          User Type <span style={{ color: 'red' }}>*</span>
        </Typography>
        <Typography variant='body2' fontSize='13px' color={variables.colors.text.secondary}>
          This grants the user access to the system. Select all that apply.
        </Typography>
      </Stack>
      <StyledStack
        sx={{
          border: values.webAppUserStatus ? '2px solid #D4A3E3' : '1px solid #E5E5E5',
          padding: values.webAppUserStatus ? '23px 15px' : '24px 16px'
        }}>
        <Box>
          <Controller
            name="webAppUserStatus"
            control={control}
            rules={{
              validate: () => {
                if (!values.mobileAppUserStatus && !values.webAppUserStatus) {
                  return 'At least one user type must be selected';
                } else if (values.webAppUserStatus &&
                  (!values.userRoles || (values.userRoles && values.userRoles.filter((role: string) => role !== serviceTeamRoleId).length === 0))) {
                  return 'User Role is required';
                }
                return true;
              },
            }}
            render={({ field }) =>
              <CheckboxInput
                {...field}
                id='webAppUserStatus'
                label="Web App User"
                onChange={(e) => {
                  field.onChange(e.target.checked);
                  handleWebAppUserCheck(e.target.checked);
                }}
                error={hasError}
                checked={field.value ? true : false}
              />}
          />
        </Box>
        {values.webAppUserStatus &&
          <Stack flexDirection='row' gap='8px' justifyContent='space-between' alignItems='flex-start'>
            <Typography variant='subtitle2' fontWeight='500' minWidth='fit-content' sx={{ transform: 'translateY(50%)' }}>
              User Role
            </Typography>
            <Controller
              name="userRoles"
              control={control}
              rules={{
                validate: () => {
                  if (!values.userRoles && values.webAppUserStatus) {
                    return 'User Role is required';
                  }
                  return true;
                }
              }}
              render={({ field }) =>
                <RoleTemplateLookupField
                  {...field}
                  id='userRoles'
                  validationError={userRolesHasError}
                  errorText={userRolesHasError ? 'User Role is required' : ''}
                  isMandatory
                  placeholder='Select'
                  filterValues={[serviceTeamRoleId, ownerRoleId]}
                  filterIncludeExclude='exclude'
                  onChange={(value: string) => field.onChange([...(field.value as string[]).filter((role: string) => role === serviceTeamRoleId), value])}
                />
              }
            />
          </Stack>
        }
      </StyledStack>
      <StyledStack
        sx={{
          border: values.mobileAppUserStatus ? '2px solid #D4A3E3' : '1px solid #E5E5E5',
          padding: values.mobileAppUserStatus ? '23px 15px' : '24px 16px'
        }}>
        <Box>
          <Controller
            name="mobileAppUserStatus"
            control={control}
            rules={{
              validate: () => {
                if (!values.mobileAppUserStatus && !values.webAppUserStatus) {
                  return 'At least one user type must be selected';
                }
                return true;
              }
            }}
            render={({ field }) =>
              <CheckboxInput
                {...field}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                  handleMobileAppUserCheck(e.target.checked);
                }}
                id='mobileAppUserStatus'
                label="Mobile App User"
                error={hasError}
                checked={field.value ? true : false}
              />}
          />
        </Box>
        {values.mobileAppUserStatus &&
          <Stack flexDirection='row' gap='8px' justifyContent='space-between' alignItems='center'>
            <Typography variant='subtitle2' fontWeight='500' minWidth='fit-content'>
              User Role
            </Typography>
            <StyledInput
              disabled
              placeholder='Service Team'
              InputProps={{
                endAdornment: <LockOutlinedIcon />
              }}
            />
          </Stack>
        }
      </StyledStack>
      {hasError && (
        <Typography variant='body2' color='error'>
          At least one user type must be selected
        </Typography>
      )}
    </AccessDetailsStack>
  );
};

const AccessDetailsStack = styled(Stack)`
  background-color: white;
  padding: 32px;
  gap: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
`;

const StyledStack = styled(Stack)`
  border-radius: 8px;
  flex-direction: row;
  & > div {
    width: 50%;
  }
`;

const StyledInput = styled(TextField)`
  & * {
    border-color: #F3F4F6 !important;
  }
  & > * {
    font-size: 14px;
  }
  .MuiInputBase-root {
    height: 42px;
  }
  .Mui-disabled {
    -webkit-text-fill-color: #000000 !important;
  }
  .MuiSvgIcon-root {
    color: #E5E7EB;
  }
  width: 100%;
`;

export default AccessDetails;