import { useEffect, useState } from 'react';
import useErrorMessage from './useErrorMessage';
import { useAtom } from 'jotai';
import { triggerRefreshCurrentDataPointAtom } from 'atoms';

export function useFetchDataPoint({ getByIDFunc }: { getByIDFunc: () => Promise<any> }) {
  // Current data point in state - we don't need to persist as we grab the data fresh each time
  // If we need to persist the data, we can add a Jotai atom to store the data
  const [currentDataPoint, setCurrentDataPoint] = useState<any>();
  // This atom is used to trigger a refresh of the data point from anywhere in the app
  // This is useful when we need to refresh the data point while the user is currently viewing it
  const [triggerRefresh] = useAtom(triggerRefreshCurrentDataPointAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const showError = useErrorMessage();

  useEffect(() => {
    let isSubscribed = true; // Control flag to avoid state update on unmount

    // declare the async data fetching function
    const fetchData = async () => {
      try {
        setIsLoading(true); // Start loading when fetch starts

        // get the data from the api
        const response = await getByIDFunc();
        // set state with the result if `isSubscribed` is true
        if (isSubscribed) {
          setCurrentDataPoint(response);
          setIsLoading(false); // Only stop loading once the fetch is complete
        }
      } catch (error) {
        if (isSubscribed) {
          showError((error as Error).message);
          setHasError(true);
          setIsLoading(false); // Stop loading even on error
        }
      }
    };

    // Call the function
    fetchData();

    // Cleanup function to cancel any future `setData`
    return () => {
      isSubscribed = false;
    };
  }, [triggerRefresh]);

  return { isLoading, currentDataPoint, hasError };
}