import { Button, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { userMessageAtom } from 'atoms';
import { closeModalAtom } from 'atoms/modalAtom';
import CheckboxInput from 'components/common/InputFields/CheckboxInput';
import DatePickerInput from 'components/common/InputFields/DatePickerInput';
import SelectInput from 'components/common/InputFields/SelectInput';
import TimePickerInput from 'components/common/InputFields/TimePickerInput';
import { addDays, format, isBefore, parseISO, startOfWeek } from 'date-fns';
import { useAtom } from 'jotai';
import React, { FC, useEffect, useState } from 'react';
import { CalendarEvent, RRule } from 'types/fullCalendar';
import { calculateDuration, daysMap, daysOfWeek, deleteFirstInSeriesEvent, deleteIndexInSeriesEvent, deleteStandAloneEvent, editFirstInSeriesEvent, editIndexInSeriesEvent, editStandAloneEvent, getDayTwoLetters, getEventSeriesPosition, getRepeatFrequency, recurrenceIsEdited, updateRRuleFrequency, updateRRuleUntil } from 'utils/helpers/fullCalendarTemplates';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import variables from 'styles/variables';
import TitleWithClose from 'components/common/TitleWithClose';
import { ConfirmationModalProps, EventEditModalProps, RepeatFrequency } from '../types';

// Note: editedEvent is a copy of the parent event, this will be used when in submission
// The selected event is the event that was clicked on to open the modal.
const EventEditModal: FC<EventEditModalProps> = ({ selectedEvent, events, setEvents }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [, setUserMessage] = useAtom(userMessageAtom);
  const parentEvent = events.find((el) => selectedEvent.id === el.id) || null; // Get the parent event (actual event in the events array)

  // Early return if the parent event is not found
  if (!parentEvent) {
    setUserMessage({
      title: 'Error',
      message: 'We could not find the availability period you are trying to edit. Please refresh and try again.',
      variant: 'error',
      open: true,
      autoHideDuration: 5000,
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    });
    closeModal();
    return null;
  }

  const [editedEvent, setEditedEvent] = useState<CalendarEvent>({
    ...parentEvent,
    start: format(new Date(selectedEvent.start), "yyyy-MM-dd'T'HH:mm:ss"),
    end: format(new Date(selectedEvent.end), "yyyy-MM-dd'T'HH:mm:ss"),
    allDay: selectedEvent.allDay,
    day: selectedEvent.extendedProps.day
  }); // Copies the parent event to be edited
  const [error, setError] = useState<string | null>(null); // Error message string state
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false); // Determines if the confirmation modal should be shown
  const [variant, setVariant] = useState<'delete' | 'edit'>('edit'); // Determines the variant of the confirmation modal
  const [isNeverChecked, setIsNeverChecked] = useState<boolean>(false); // State for 'Never' checkbox is checked


  // Handle the submission of the edited event
  // This function will determine the position of the event in the series and handle the submission accordingly
  const handleEditSubmit = () => {
    const seriesPosition = getEventSeriesPosition(selectedEvent, parentEvent);
    switch (seriesPosition) {
      // Stand alone event (not part of a series) - Update the event as is
      case 'standalone':
        editStandAloneEvent(editedEvent, events, setEvents);
        closeModal();
        break;
      // Handle the update in confirmation modal
      case 'firstInSeries':
        setVariant('edit');
        setShowConfirmation(true);
        break;
      // Handle the update in confirmation modal
      case 'indexInSeries':
        setVariant('edit');
        setShowConfirmation(true);
        break;
      default:
        return;
    }
  };

  // Handle the submission of the deleted event
  // This function will determine the position of the event in the series and handle the deletion accordingly
  const handleDeleteSubmit = () => {
    const seriesPosition = getEventSeriesPosition(selectedEvent, parentEvent);
    switch (seriesPosition) {
      // Stand alone event (not part of a series) - Delete the event as is
      case 'standalone':
        deleteStandAloneEvent(editedEvent, events, setEvents);
        closeModal();
        break;
      // Handle the deletion in confirmation modal
      case 'firstInSeries':
        setVariant('delete');
        setShowConfirmation(true);
        break;
      // Handle the deletion in confirmation modal
      case 'indexInSeries':
        setVariant('delete');
        setShowConfirmation(true);
        break;
      default:
        return;
    }
  };

  // Handle the change of the time input fields
  const handleTimeChange = (e: string | null, field: 'start' | 'end', editedEvent: CalendarEvent, selectedEvent: CalendarEvent) => {
    if (!e) return;
    setError(null);

    // Get the start of the current week (Sunday by default)
    const weekStart = startOfWeek(new Date(selectedEvent.start), { weekStartsOn: 0 });

    // Calculate the new date based on the selected day
    const newDayIndex = daysMap[editedEvent.day];
    const newDate = addDays(weekStart, newDayIndex);

    const newStartTimeValue = `${format(new Date(newDate), "yyyy-MM-dd")}T${e.split('T')[1]}`;
    const newEndTimeValue = `${format(new Date(newDate), "yyyy-MM-dd")}T${e.split('T')[1]}`;

    // Prevent the update if the end time is before the start time
    if (isBefore(parseISO(newEndTimeValue), parseISO(newStartTimeValue))) {
      setError('End time cannot be before start time.');
      return;
    }
    const duration = calculateDuration(newStartTimeValue, newEndTimeValue);

    // Update the form value for the specific field
    setEditedEvent({
      ...editedEvent,
      [field]: field === 'start' ? newStartTimeValue : newEndTimeValue,
      duration,
    });
  };

  // Handle the change of the day input field
  const updateRRuleDays = (selectedDay: string, editedEvent: CalendarEvent, selectedEvent: CalendarEvent) => {
    // Get the start of the current week (Sunday by default)
    const weekStart = startOfWeek(new Date(selectedEvent.start), { weekStartsOn: 0 });

    // Calculate the new date based on the selected day
    const newDayIndex = daysMap[selectedDay];
    const newDate = addDays(weekStart, newDayIndex);

    // Extract the time parts from the current start and end times
    const currentStartTime = editedEvent.start.split('T')[1]; // Time part of the start time
    const currentEndTime = editedEvent.end.split('T')[1];     // Time part of the end time

    // Format the new start and end times with the new date
    const newStartTime = `${format(newDate, 'yyyy-MM-dd')}T${currentStartTime}`;
    const newEndTime = `${format(newDate, 'yyyy-MM-dd')}T${currentEndTime}`;

    // Return the updated event with new day, start, end times, and updated rrule
    setEditedEvent({
      ...editedEvent,
      start: newStartTime,
      end: newEndTime,
      day: selectedDay,
      rrule: editedEvent?.rrule ? {
        ...editedEvent?.rrule,
        byweekday: [getDayTwoLetters(selectedDay)]
      } : undefined
    });
  };

  // Handle the change of the repeat frequency input field
  const handleFrequencyChange = (selectedValue: string) => {
    const updatedEvent = updateRRuleFrequency(selectedValue as RepeatFrequency, editedEvent);
    if (selectedValue !== 'never' && !editedEvent.rrule?.until) {
      setIsNeverChecked(true);
    }
    setEditedEvent(updatedEvent);
  };

  // Handle the change of the until date input field
  const handleUntilDateChange = (newDate: string | null) => {
    if (newDate === null || newDate === '') {
      // Handle the case where the date is cleared or not selected
      setEditedEvent(updateRRuleUntil(undefined, editedEvent, true));
      setIsNeverChecked(true);
    } else {
      // Update the until date as usual
      setEditedEvent(updateRRuleUntil(newDate, editedEvent, false));
      setIsNeverChecked(false);
    }
  };

  // Handle the change of the 'Never' checkbox
  const handleNeverChecked = (isChecked: boolean) => {
    if (isChecked) {
      // If the checkbox is checked (Never), remove the until date
      const updatedEvent = updateRRuleUntil(undefined, editedEvent!, true);
      setEditedEvent(updatedEvent);
      setIsNeverChecked(true);
    } else {
      // If the checkbox is unchecked, set the until date to the current date
      const updatedEvent = updateRRuleUntil(format(new Date(), 'yyyy-MM-dd'), editedEvent!, false);
      setEditedEvent(updatedEvent);
      setIsNeverChecked(false);
    }
  };

  if (showConfirmation) return (
    <ConfirmationModal
      variant={variant}
      setShowConfirmation={setShowConfirmation}
      editedEvent={editedEvent}
      parentEvent={parentEvent}
      selectedEvent={selectedEvent}
      events={events}
      setEvents={setEvents}
    />
  );

  return (
    <Stack height='fit-content' gap='32px' sx={{ position: 'relative', width: '500px', padding: '32px' }}>
      <TitleWithClose title='Availability Period' handleClose={closeModal} />
      <Stack gap='8px'>
        <Stack flexDirection='row' gap='8px' alignItems='center'>
          <Typography variant='body2' sx={{ color: variables.colors.text.secondary, minWidth: '104px' }}>
            Day
          </Typography>
          <SelectInput
            id='day'
            placeholder='Select'
            error={!editedEvent.day ? true : false}
            onChange={(e) => updateRRuleDays(e, editedEvent, selectedEvent)}
            value={editedEvent.day}
            isMandatory
            options={daysOfWeek}
          />
        </Stack>
        <Stack flexDirection='row' gap='8px' alignItems='center'>
          <Typography variant='body2' sx={{ color: variables.colors.text.secondary, minWidth: '104px' }}>
            Start Time
          </Typography>
          <TimePickerInput
            ampm={false}
            id='startTime'
            error={!editedEvent.start ? true : false}
            isMandatory
            onChange={(e) => handleTimeChange(e, 'start', editedEvent, selectedEvent)}
            value={editedEvent.start}
            clearable={false}
          />
        </Stack>
        <Stack flexDirection='row' gap='8px' alignItems='center'>
          <Typography variant='body2' sx={{ color: variables.colors.text.secondary, minWidth: '104px' }}>
            End Time
          </Typography>
          <TimePickerInput
            ampm={false}
            id='endTime'
            error={!editedEvent.end ? true : false}
            isMandatory
            onChange={(e) => handleTimeChange(e, 'end', editedEvent, selectedEvent)}
            value={editedEvent.end}
            clearable={false}
          />
        </Stack>
      </Stack>
      <Stack gap='8px'>
        <Stack flexDirection='row' gap='8px' alignItems='center'>
          <Typography variant='body2' sx={{ color: variables.colors.text.secondary, minWidth: '104px' }}>
            Repeat
          </Typography>
          <SelectInput
            id="repeat"
            placeholder="Select"
            error={false}
            onChange={handleFrequencyChange}
            value={getRepeatFrequency(editedEvent.rrule)}
            isMandatory
            options={[
              { label: 'Never', value: 'never' },
              { label: 'Weekly', value: 'weekly' },
              { label: 'Fortnightly', value: 'fortnightly' },
            ]}
          />
        </Stack>
        {editedEvent.rrule && (
          <Stack flexDirection='row' gap='8px' alignItems='center'>
            <Typography variant='body2' sx={{ color: variables.colors.text.secondary, minWidth: '104px' }}>
              Repeat ends on
            </Typography>
            <DatePickerInput
              id='until'
              error={!editedEvent.rrule?.until ? true : false}
              isMandatory
              onChange={handleUntilDateChange}
              value={editedEvent.rrule?.until}
              clearable={false}
            />
            <CheckboxInput
              checked={isNeverChecked}
              error={false}
              id='neverRepeat'
              label='Never'
              onChange={(e) => handleNeverChecked(e.target.checked)}
            />
          </Stack>
        )}
      </Stack>
      <Stack gap='8px'>
        {error && <Typography variant='body2' sx={{ color: variables.colors.error.main, textAlign: 'right' }}>{error}</Typography>}
        <Stack flexDirection='row' gap='8px' justifyContent='space-between'>
          <Button
            onClick={handleDeleteSubmit}
            variant='outlined'
            startIcon={<DeleteOutlinedIcon />}
            sx={{
              color: variables.colors.icon.standard,
              borderColor: variables.colors.border.main,
              ':hover': {
                borderColor: variables.colors.icon.standard
              }
            }}
          >
            Delete
          </Button>
          <Button
            onClick={handleEditSubmit}
            disabled={!editedEvent.day || !editedEvent.start || !editedEvent.end || error !== null}
            variant='contained'
            sx={{ width: '90px' }}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

const ConfirmationModal: FC<ConfirmationModalProps> = ({ variant, setShowConfirmation, editedEvent, parentEvent, selectedEvent, events, setEvents }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [selectedTarget, setSelectedTarget] = useState<'thisAndFollowing' | 'single'>('thisAndFollowing');
  const [isRecurrenceEdited, setIsRecurrenceEdited] = useState<boolean>();

  useEffect(() => {
    setIsRecurrenceEdited(recurrenceIsEdited(editedEvent, parentEvent));
  }, [editedEvent, parentEvent]);

  const handleEditSubmit = () => {
    const seriesPosition = getEventSeriesPosition(selectedEvent, parentEvent);
    switch (seriesPosition) {
      // They should not end up here as it is handled prior but just in case
      case 'standalone':
        editStandAloneEvent(editedEvent, events, setEvents);
        closeModal();
        break;
      case 'firstInSeries':
        editFirstInSeriesEvent(editedEvent, selectedEvent, setEvents, parentEvent, selectedTarget);
        closeModal();
        break;
      case 'indexInSeries':
        editIndexInSeriesEvent(editedEvent, selectedEvent, setEvents, parentEvent, selectedTarget);
        closeModal();
        break;
      default:
        return;
    }
  };

  const handleDeleteSubmit = () => {
    const seriesPosition = getEventSeriesPosition(selectedEvent, parentEvent);
    switch (seriesPosition) {
      // They should not end up here as it is handled prior but just in case
      case 'standalone':
        deleteStandAloneEvent(editedEvent, events, setEvents);
        closeModal();
        break;
      case 'firstInSeries':
        deleteFirstInSeriesEvent(editedEvent, selectedEvent, setEvents, parentEvent, selectedTarget);
        closeModal();
        break;
      case 'indexInSeries':
        deleteIndexInSeriesEvent(editedEvent, selectedEvent, setEvents, parentEvent, selectedTarget);
        closeModal();
        break;
      default:
        return;
    }
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTarget(e.target.value as 'thisAndFollowing' | 'single'); // Update state with selected value
  };

  return (
    <Stack height='fit-content' sx={{ position: 'relative', width: '500px' }}>
      <Stack padding='32px' gap='32px'>
        <Typography variant='h6'>
          {variant === 'delete' ? 'Delete Recurring Time Periods' : 'Edit Recurring Time Periods'}
        </Typography>
        <FormControl>
          <RadioGroup
            value={selectedTarget}
            row
            onChange={handleRadioChange}
          >
            <FormControlLabel value="thisAndFollowing" control={<Radio />} label="This and following periods" />
            {!isRecurrenceEdited && <FormControlLabel value="single" control={<Radio />} label="This time period only" />}
          </RadioGroup>
        </FormControl>
      </Stack>
      <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' padding='16px 32px' boxSizing='border-box' sx={{ backgroundColor: variables.colors.lightNeutral.subtle, borderRadius: '0 0 12px 12px' }} >
        <Button variant='text' onClick={() => setShowConfirmation(false)}>Cancel</Button>
        <Button variant='contained' onClick={variant === 'delete' ? handleDeleteSubmit : handleEditSubmit}>
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export default EventEditModal;