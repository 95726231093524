import React, { FC, useState } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue, set } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import TextInput from 'components/common/InputFields/TextInput';
import AddressLookupField from 'components/common/InputFields/AddressLookupField';
import CheckboxInput from 'components/common/InputFields/CheckboxInput';
import { Address } from 'types';
import { OrganisationDetails } from 'types/dbSchema/organisation';

export interface ContactDetailsProps {
  control: Control<OrganisationDetails>;
  errors: FieldErrors<OrganisationDetails>;
  setValue: UseFormSetValue<OrganisationDetails>,
}

const ContactDetails: FC<ContactDetailsProps> = ({
  control,
  errors,
  setValue
}) => {
  const [differentPostalAddress, setDifferentPostalAddress] = useState(false);

  const obj = {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      setDifferentPostalAddress(e.target.checked);
      if (!e.target.checked) {
        handleSetPostalAddress({
          streetAddress: '',
          suburb: '',
          state: '',
          postCode: '',
          country: '',
          isPrimary: false,
        });
      }
    }
  };

  const handleSetAddress = (values: Address) => {
    setValue('businessAddress', values);
  };

  const handleSetPostalAddress = (values: Address) => {
    setValue('postalAddress', values);
  };

  return (
    <DetailsStack>
      <Typography variant='h6' fontWeight='600' color='text.primary'>
        Contact Details
      </Typography>
      <Row>
        <Controller
          name="phone"
          control={control}
          rules={{
            required: 'Phone Number is required',
            pattern: { value: /^[0-9]+$/, message: 'Phone Number must only contain numbers' },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='phone'
              label='Phone Number'
              error={errors.phone ? true : false}
              errorText={errors.phone?.message}
              isMandatory
            />}
        />
        <Controller
          name="email"
          control={control}
          rules={{
            pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: 'Email Address is invalid' },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='email'
              label='Email'
              error={errors.email ? true : false}
              errorText={errors.email?.message}
            />}
        />
      </Row>
      <AddressLookupField
        id='businessAddressLookup'
        placeholder='Search for your address'
        setValue={handleSetAddress}
        label='Business Address'
        error={false}
      />
      <CheckboxInput
        error={false}
        checked={differentPostalAddress}
        id='sameAsPostalAddress'
        label='Different Postal Address'
        {...obj}
      />
      {differentPostalAddress && (
        <AddressLookupField
          id='postalAddressLookup'
          placeholder='Search for your address'
          setValue={handleSetPostalAddress}
          label='Postal Address'
          error={false}
        />
      )}
    </DetailsStack>
  );
};

const DetailsStack = styled(Stack)`
  background-color: white;
  padding: 32px;
  gap: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
`;

export interface RowProps {
  numberOfChildren?: number;
}

const Row = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'numberOfChildren'
}) <RowProps>`
  gap: 24px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start; /* Changed to flex-start to accommodate varying numbers of children */
  align-items: flex-start;

  & > * {
    flex: 0 0 calc((100% - 48px) / ${({ numberOfChildren }) => numberOfChildren});
    max-width: calc((100% - 48px) / ${({ numberOfChildren }) => numberOfChildren});
  }
`;

export default ContactDetails;