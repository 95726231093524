import axiosInstance from 'api/config';
import { ServiceProviderNote, ServiceProviderNoteList } from 'types/dbSchema/serviceProviderNotes';

// Returns a list of service provider notes for the given organisation ID and service provider ID
export const listServiceProviderNotes = async (
  organisationId: string,
  serviceProviderId: string,
  page: number,
  pageSize: number
): Promise<ServiceProviderNoteList> => {

  try {
    if (!organisationId || !serviceProviderId) {
      throw new Error('Organisation ID and Participant ID are required');
    }

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/serviceproviders/${serviceProviderId}/notes`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing notes for the service provider', error);
    throw error;
  }
};


// Retrieves a service provider note by ID
export const getServiceProviderNoteById = async (organisationId: string, serviceProviderId: string, ServiceProviderNoteId: string): Promise<ServiceProviderNote> => {
  try {
    if (!serviceProviderId || !organisationId || !ServiceProviderNoteId) throw new Error('Organisation ID, ServiceProviderNoteId, and ParticipantGoal ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/serviceproviders/${serviceProviderId}/notes/${ServiceProviderNoteId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the service provider note', error);
    throw error;
  }
};

// Updates a service provider note by ID
export const updateServiceProviderNoteById = async (organisationId: string, serviceProviderId: string, ServiceProviderNoteId: string, data: Partial<any>) => {
  try {
    if (!serviceProviderId || !organisationId || !ServiceProviderNoteId || !data)
      throw new Error('Organisation ID, Participant ID, Service Provider Note Id, and data are required');

    const response = await axiosInstance.put(`/api/organisations/${organisationId}/serviceproviders/${serviceProviderId}/notes/${ServiceProviderNoteId}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('There was an error updating the service provider note', error);
    throw error;
  }
};

// Create new service provider note
export const createServiceProviderNote = async (organisationId: string, serviceProviderId: string, data: any) => {
  try {
    if (!organisationId || !serviceProviderId || !data) throw new Error('Organisation ID, Participant ID, and data are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/serviceproviders/${serviceProviderId}/notes`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });
    return response.data;
  } catch (error) {
    console.error('There was an error creating a new service provider note', error);
    throw error;
  }
};

// Deletes a service provider note by ID
export const deleteServiceProviderNoteById = async (organisationId: string, serviceProviderId: string, ServiceProviderNoteId: string): Promise<ServiceProviderNote> => {
  try {
    if (!serviceProviderId || !organisationId || !ServiceProviderNoteId) throw new Error('Organisation ID, ServiceProviderNoteId, and ParticipantGoal ID are required');

    const response = await axiosInstance.delete(`/api/organisations/${organisationId}/serviceproviders/${serviceProviderId}/notes/${ServiceProviderNoteId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the service provider note', error);
    throw error;
  }
};