import useCurrentUser from 'hooks/useCurrentUser';
import { useHasPermission } from 'hooks/useHasPermission';
import React from 'react';
import { Navigate } from 'react-router-dom';

interface PermissionWrapperProps {
  requiredPermissions: string[];
  children: React.ReactNode;
  redirect?: string;
}

const PermissionWrapper: React.FC<PermissionWrapperProps> = ({ requiredPermissions, children, redirect }) => {
  // Exempt certain pages from permission checks
  // This is needed because in the .App.tsx file we iterate over all the pages and wrap them in a PermissionWrapper
  const exemptPages = ['/login', '/return', '/organisation-registration'];
  if (exemptPages.includes(window.location.pathname)) return <>{children}</>;

  // Check if the user has all of the required permissions
  const hasPermission = useHasPermission(requiredPermissions);
  const [user] = useCurrentUser();

  // If the user is not fetched yet, do not render the children
  if (!user) return null;

  // If the user does not have the required permissions, redirect them to the specified page if one is provided
  // Otherwise, do not render the children
  if (!hasPermission) {
    if (redirect) return <Navigate to={redirect} />;
    return null;
  }

  // If the user has the required permissions, render the children as normal
  return <>{children}</>;
};

export default PermissionWrapper;