import { Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import Chart from "react-apexcharts";

const ParticipantFunding = () => {
  const [options, setOptions] = useState({
    chart: {
      id: "basic-bar"
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
    }
  });

  const [series, setSeries] = useState([
    {
      name: "series-1",
      data: [30, 40, 45, 50, 49, 60, 70, 91]
    }
  ]);

  return (
    <Stack width='100%' sx={{ backgroundColor: 'white' }}>
      <Typography variant='h6' sx={{ padding: '16px' }}>Participant Funding</Typography>
      <Chart
        options={options}
        series={series}
        type="line"
        height={350}
      // width="500"
      />
    </Stack>
  );
};

export default ParticipantFunding;