import React, { FC, useEffect, useState } from 'react';
import TitleWithClose from 'components/common/TitleWithClose';
import { Button, Stack, Typography } from '@mui/material';
import variables from 'styles/variables';
import styled from 'styled-components';
import { useAtom } from 'jotai';
import { userMessageAtom } from 'atoms';
import { useForm, Controller } from 'react-hook-form';
import { GridRowId } from '@mui/x-data-grid';
import { closeModalAtom } from 'atoms/modalAtom';
import RoleTemplateLookupField from 'components/common/LookupInputField/RoleTemplateLookupField';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { createFilterString } from 'utils/helpers';
import { getRoleTemplateList } from 'api/organisations/users';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';

export interface UpdateWebappUserRoleProps {
  selectedRowIds: GridRowId[];
}

const UpdateWebappUserRole: FC<UpdateWebappUserRoleProps> = ({ selectedRowIds }) => {
  const [, setUserMessage] = useAtom(userMessageAtom);
  const [, closeModal] = useAtom(closeModalAtom);
  const [serviceTeamRoleId, setServiceTeamRoleId] = useState<string>('');
  const [ownerRoleId, setOwnerRoleId] = useState<string>('');
  const [organisations] = useUserOrganisations();
  // This is a unique filter that is used to filter out the user roles that are not required for the web app user type
  const selectedFilters = [{
    field: "Name",
    valuesSelected: [
      "Owner",
      "Service Team"
    ]
  }] as SelectedFilter[];

  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: ['role-templates', createFilterString(selectedFilters)],
    queryFn: () => getRoleTemplateList(organisations[0].organisation.globalId, selectedFilters),
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.roleTemplates,
  });

  useEffect(() => {
    // Find the ID of the "Service Team" and "Owner" roles and set them to the state
    // This is used to filter out the user roles that are not required for the web app user type and to add the "Service Team" role to the mobile app user type
    setServiceTeamRoleId(data?.items.find(role => role.name === 'Service Team')?.id || '');
    setOwnerRoleId(data?.items.find(role => role.name === 'Owner')?.id || '');
  }, [data]);

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      userRole: ''
    }
  });

  const handleUpdateRole = (data: { userRole: string }) => {
    closeModal();
    console.log(`Updating ${selectedRowIds.length} users`); // Handle update api here
    console.log(data);
    setUserMessage({
      title: `${selectedRowIds.length} User${selectedRowIds.length > 1 ? 's' : ''} Updated.`,
      message: `The selected user${selectedRowIds.length > 1 ? 's' : ''} have had their role updated.`,
      variant: 'success',
      open: true,
      autoHideDuration: 6000,
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    });
  };

  if (serviceTeamRoleId === '' || ownerRoleId === '') return null;

  return (
    <form onSubmit={handleSubmit(handleUpdateRole)}>
      <Stack alignItems="center" width='500px'>
        <Stack width='100%' alignItems="flex-start" gap='32px' padding='32px' boxSizing='border-box'>
          <TitleWithClose title="Update Web App User Role" handleClose={closeModal} />
          <Stack gap='8px' width='100%'>
            <Controller
              name="userRole"
              control={control}
              rules={{
                required: 'User Role is required',
              }}
              render={({ field }) => (
                <RoleTemplateLookupField
                  {...field}
                  id='userRole'
                  label='User Role'
                  validationError={errors.userRole ? true : false}
                  errorText={errors.userRole?.message}
                  isMandatory
                  filterValues={[serviceTeamRoleId, ownerRoleId]}
                  filterIncludeExclude='exclude'
                  placeholder='Select'
                />
              )}
            />
            <Typography variant='body2' color={variables.colors.text.primary}>
              You are about to update the User Role of {selectedRowIds.length} user{selectedRowIds.length > 1 ? 's' : ''}.
            </Typography>
          </Stack>
          <ButtonStack>
            <Button variant='outlined' onClick={closeModal}>Cancel</Button>
            <Button type='submit' variant='contained'>Confirm</Button>
          </ButtonStack>
        </Stack>
      </Stack>
    </form>
  );
};

const ButtonStack = styled(Stack)`
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px 0px 12px 12px;
  box-sizing: border-box;
`;

export default UpdateWebappUserRole;