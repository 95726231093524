import React, { FC, useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import { DateSelectArg } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Button, MenuItem, Select, Stack, Typography, } from '@mui/material';
import useUserOrganisations from 'hooks/useUserOrganisations';
import variables from 'styles/variables';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import { useNavigate } from 'react-router-dom';
import { User } from 'types/dbSchema/userProfiles';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styled from 'styled-components';
import { modalStateAtom, userMessageAtom } from 'atoms';
import { useAtom } from 'jotai';
import { addDays, addWeeks, format, startOfWeek } from 'date-fns';
import SideControls from './SideControls';
import LearnMoreModal from './LearnMoreModal';
import rrulePlugin from '@fullcalendar/rrule';
import { checkEventsIsPreview, checkForEventOverlaps, handleDateSelect, handleEventChange, handleNextClick, handlePrevClick, handleTodayClick } from 'utils/helpers/fullCalendarTemplates';
import EventEditModal from './EventEditModal';

export interface ServiceTeamAvailabilityProps {
  serviceTeamMember: User;
}

const ServiceTeamAvailability: FC<ServiceTeamAvailabilityProps> = ({ serviceTeamMember }) => {
  const [events, setEvents] = useState<any[]>([]);
  const [organisations] = useUserOrganisations();
  const calendarRef = useRef<any>(null);
  const [currentDay, setCurrentDay] = useState(0);
  const navigate = useNavigate();
  const [jumpDate, setJumpDate] = useState('');
  const [options, setOptions] = useState<any[]>([]);
  const [isControlsOpen, setIsControlsOpen] = useState(false);
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const [, setUserMessage] = useAtom(userMessageAtom);

  const handleEventClick = (eventClickInfo: any) => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: EventEditModal,
      props: {
        selectedEvent: eventClickInfo.event,
        events,
        setEvents,
      }
    });
  };

  const handleLearnMoreClick = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: LearnMoreModal,
    });
  };

  const handleJumpTo = (event: any) => {
    const calendarApi = calendarRef.current.getApi();
    const date = new Date(event.target.value);
    calendarApi.gotoDate(date);
    setJumpDate(event.target.value);
  };

  // Generate options for the dropdown
  const generateDateOptions = () => {
    const localOptions: any[] = [];
    const now = new Date();
    if (!calendarRef.current) return localOptions;

    for (let i = 0; i <= 13; i++) {
      const weekStart = startOfWeek(addWeeks(now, i), { weekStartsOn: 0 });
      localOptions.push({
        label: `${format(weekStart, 'dd MMM')} - ${format(addDays(weekStart, 6), 'dd MMM yyyy')}`,
        value: weekStart,
      });
      setJumpDate(localOptions[0].value);
    }
    return localOptions;
  };

  useEffect(() => {
    if (calendarRef.current) setOptions(generateDateOptions());
  }, [calendarRef.current]);

  const validateSave = () => {
    const hasOverlaps = checkForEventOverlaps(events);
    const anyIsPreview = checkEventsIsPreview(events);
    if (hasOverlaps) {
      setUserMessage({
        message: 'Overlapping events detected. Please resolve the overlaps before saving.',
        title: "Warning",
        variant: "warning",
        open: true,
        autoHideDuration: 5000,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      });
      return false;
    }
    if (anyIsPreview) {
      setUserMessage({
        message: 'Preview events detected. Please resolve the preview events before saving.',
        title: "Warning",
        variant: "warning",
        open: true,
        autoHideDuration: 5000,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      });
      return false;
    }
    return true;
  };

  const handleSave = () => {
    const isValid = validateSave();
    if (isValid) {
      console.log('Saving events:', events);
      navigate(-1);
    }
  };

  const handleRemoveAllEvents = () => {
    setEvents([]);
  };

  return (
    <Stack>
      <Stack height='72px' padding='16px 24px' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{ borderBottom: '1px solid #E5E7EB' }}>
        <Stack flexDirection='row' alignItems='center' gap='8px'>
          <CloseOutlinedIcon sx={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
          <Typography fontSize='18px' fontWeight='600' style={{ color: variables.colors.text.primary }}>
            {serviceTeamMember.fullName.firstName} {serviceTeamMember.fullName.lastName} Availability
          </Typography>
        </Stack>
        <Stack flexDirection='row' justifyContent='flex-end' alignItems='center' gap='8px'>
          <Button
            variant='outlined'
            sx={{ color: variables.colors.text.primary, borderColor: variables.colors.border.main }}
            startIcon={<DeleteOutlinedIcon />}
            onClick={handleRemoveAllEvents}
          >
            Remove all
          </Button>
          <Button
            variant='contained'
            sx={{ backgroundColor: 'rgba(166, 0, 255, 0.1)', color: variables.colors.primary.darker, ':hover': { backgroundColor: 'rgba(166, 0, 255, 0.4)' } }}
            startIcon={<FileDownloadOutlinedIcon />}
            onClick={() => setIsControlsOpen(true)}
          >
            Import Template
          </Button>
          <Button
            variant='contained'
            onClick={handleSave}
          >
            Save
          </Button>
        </Stack>
      </Stack>
      <Stack height='calc(100vh - 72px)' flexDirection='row' overflow='hidden'>
        <Stack height='100%' width='100%' sx={{ minWidth: isControlsOpen ? '' : '100vw' }}>
          <Stack>
            <Stack padding='16px 24px' flexDirection='row' justifyContent='space-between' alignItems='center'>
              <Stack flexDirection='row' justifyContent='flex-start' alignItems='center' gap='12px'>
                <Button onClick={() => handleTodayClick(calendarRef)} variant='outlined' sx={{ color: variables.colors.text.main, borderColor: variables.colors.border.main }}>Today</Button>
                <Select value={jumpDate} onChange={handleJumpTo} variant='standard' disableUnderline>
                  {options && options.map((option, index) => (
                    <MenuItem key={index} value={option.value.toString()}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <Button onClick={() => handlePrevClick(calendarRef)} sx={{ padding: 0, minWidth: 'unset', color: 'black' }}><KeyboardArrowLeftIcon /></Button>
                <Button onClick={() => handleNextClick(calendarRef)} sx={{ padding: 0, minWidth: 'unset', color: 'black' }}><KeyboardArrowRightIcon /></Button>
              </Stack>
              <Stack flexDirection='row' justifyContent='flex-end' alignItems='center' gap='8px'>
                <InfoOutlinedIcon sx={{ color: '#01579B' }} />
                <Typography fontSize='12px' fontWeight='500' sx={{ color: variables.colors.text.secondary }}>
                  Select the slots in the calendar to create availability periods.
                </Typography>
                <Button
                  variant='text'
                  onClick={handleLearnMoreClick}
                >
                  Learn More
                </Button>
              </Stack>
            </Stack>
          </Stack>
          <StyledDiv>
            <FullCalendar
              ref={calendarRef}
              eventTimeFormat={{ hour: 'numeric', minute: '2-digit', hour12: false }}
              plugins={[timeGridPlugin, interactionPlugin, dayGridPlugin, listPlugin, rrulePlugin]}
              initialView="timeGridWeek"
              selectable={true}
              events={events}
              nowIndicator
              headerToolbar={false}
              select={(selectInfo: DateSelectArg) => handleDateSelect(selectInfo, setEvents, false)}
              slotDuration='1:00'
              slotLabelInterval={{ hours: 1 }}
              slotLabelFormat={{ hour: 'numeric', minute: '2-digit', hour12: false }}
              droppable={true}
              eventResize={(eventResizeInfo) => handleEventChange(eventResizeInfo, setEvents)}
              eventDrop={(eventDropInfo) => handleEventChange(eventDropInfo, setEvents)}
              editable={true}
              allDayText=''
              firstDay={currentDay}
              height='100%'
              slotMinTime='00:00:00'
              eventColor='#388E3C'
              eventClick={handleEventClick}
            />
          </StyledDiv>
        </Stack >
        <SideControls
          isControlsOpen={isControlsOpen}
          setIsControlsOpen={setIsControlsOpen}
          setEvents={setEvents}
        />
      </Stack>
    </Stack>
  );
};

const StyledDiv = styled.div`
  height: 100%;
  
  .fc .fc-timegrid-slot {
    height: calc((100vh - 224px) / 24);
  }

  .fc .fc-daygrid-day {
    height: calc((100vh - 224px) / 24);
  }

  .fc-day-today {
    background-color: transparent !important;
  }
`;

export default ServiceTeamAvailability;