import axiosInstance from "api/config";
import { ServiceProvider, ServiceProviderList } from "types/dbSchema/serviceProviders";
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { createFilterString } from "utils/helpers";

// Returns a list of leave types for the given organisation ID
export const listServiceProviders = async (organisationId: string, filters: SelectedFilter[] | null, page: number, pageSize: number): Promise<ServiceProviderList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID, page, and page size are required');

    // Create a formatted filter string
    let formattedFilters = '';
    if (filters && filters.length > 0) {
      formattedFilters = createFilterString(filters);
    }


    const response = await axiosInstance.get(`/api/organisations/${organisationId}/serviceproviders`, {
      params: {
        filters: formattedFilters,
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing leave types for the organisation', error);
    throw error;
  }
};

// Returns a leave type by ID
export const getServiceProviderByID = async (organisationId: string, serviceProviderId: string): Promise<ServiceProvider> => {
  try {
    if (!serviceProviderId || !organisationId) throw new Error('Organisation ID and ServiceProvider ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/serviceproviders/${serviceProviderId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the leave type', error);
    throw error;
  }
};

// Create a new leave type
export const createServiceProvider = async (organisationId: string, data: ServiceProvider) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/serviceproviders`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the leave type', error);
    throw error;
  }
};

// Update a leave type
export const updateServiceProvider = async (organisationId: string, data: ServiceProvider) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.put(`/api/organisations/${organisationId}/serviceproviders/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the leave type', error);
    throw error;
  }
};

// Delete a leave type
export const deleteServiceProvider = async (organisationId: string, serviceProviderId: string) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.delete(`/api/organisations/${organisationId}/serviceproviders/${serviceProviderId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the leave type', error);
    throw error;
  }
};