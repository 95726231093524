import { GridRowId } from '@mui/x-data-grid';
import { FC } from 'react';

export type Pronouns = 'HeHim' | 'SheHer' | 'TheyThem' | 'Other';

export const pronounOptions: { value: Pronouns; label: string }[] = [
  { value: 'HeHim', label: 'He/Him' },
  { value: 'SheHer', label: 'She/Her' },
  { value: 'TheyThem', label: 'They/Them' },
  { value: 'Other', label: 'Other' },
];

export type AboriginalOrTorresStraitIslander = 'Aboriginal' | 'TorresStraitIslander' | 'Both' | 'Neither' | 'PreferNotToSay';

export const aboriginalOrTorresStraitIslanderOptions: { value: AboriginalOrTorresStraitIslander; label: string }[] = [
  { value: 'Aboriginal', label: 'Aboriginal' },
  { value: 'TorresStraitIslander', label: 'Torres Strait Islander' },
  { value: 'Both', label: 'Both' },
  { value: 'Neither', label: 'Neither' },
  { value: 'PreferNotToSay', label: 'Prefer not to say' },
];

export interface RenderWithRouterOptions {
  route?: string;
  history?: string[];
}

export interface PaginationBase<T> {
  items: T[];
  totalCount: number;
  pageNumber: number;
  pageSize: number;
}

export interface IPages {
  to: string;
  // eslint-disable-next-line
  pageComponent: FC<any> | undefined;
  // eslint-disable-next-line
  props: { [key: string]: any };
  requiredPermissions: string[];
}

export interface ActionsProps {
  selectedRowIds: GridRowId[];
  dataTableName: string;
}

export interface Address {
  id?: string;
  streetAddress: string;
  suburb: string;
  state: string;
  postCode: string;
  country: string;
  latitude?: number;
  longitude?: number;
  isPrimary: boolean;
}

// This is used for local storage while we handle the file upload on the client side
export interface Attachment {
  file: File;
  state: 'uploading' | 'complete' | 'error' | 'submitted';
  message: string;
  fileSize: number;
  fileName: string;
  fileType: string;
}

// This is used for attachments coming from the database
export interface DBAttachment {
  id: string;
  fileName: string;
  blobUrl: string;
  uploadDate: string;  // "2024-07-02T02:52:51.560Z" This format
  contentType: string;
  fileSize: number;
  fileType: string;
}

export type TUnitOfMeasure = 'Annual' | 'Daily' | 'Each' | 'Hourly' | 'Monthly' | 'Per Item' | 'Weekly' | 'Yearly';
export const unitOfMeasureOptions = ['Annual', 'Daily', 'Each', 'Hourly', 'Monthly', 'Per Item', 'Weekly', 'Yearly'];

export type TPriceGuideRegion = 'QLD/NSW/VIC' | 'NT/SA/WA/TAS' | 'Remote' | 'Very Remote';
export const priceGuideRegionOptions = ['QLD/NSW/VIC', 'NT/SA/WA/TAS', 'Remote', 'Very Remote'];

export type TTimezone = 'UTC+10 Brisbane' | 'UTC+11 Sydney' | 'UTC+9:30 Adelaide' | 'UTC+9:30 Darwin' | 'UTC+8 Perth';
export type TTimezoneValue = '+10' | '+11' | '+9:30' | '+9:30' | '+8';

export const timezoneOptions: { value: TTimezoneValue; label: TTimezone }[] = [
  { value: '+10', label: 'UTC+10 Brisbane' },
  { value: '+11', label: 'UTC+11 Sydney' },
  { value: '+9:30', label: 'UTC+9:30 Adelaide' },
  { value: '+9:30', label: 'UTC+9:30 Darwin' },
  { value: '+8', label: 'UTC+8 Perth' },
];

export const globalTimezones = [
  { name: 'Baker Island UTC-12:00', value: '-12:00' },
  { name: 'Niue UTC-11:00', value: '-11:00' },
  { name: 'Hawaii-Aleutian Time UTC-10:00', value: '-10:00' },
  { name: 'Marquesas Islands UTC-09:30', value: '-09:30' },
  { name: 'Alaska Time UTC-09:00', value: '-09:00' },
  { name: 'Pacific Time UTC-08:00', value: '-08:00' },
  { name: 'Mountain Time UTC-07:00', value: '-07:00' },
  { name: 'Central Time UTC-06:00', value: '-06:00' },
  { name: 'Eastern Time UTC-05:00', value: '-05:00' },
  { name: 'Atlantic Time UTC-04:00', value: '-04:00' },
  { name: 'Newfoundland Time UTC-03:30', value: '-03:30' },
  { name: 'Argentina Time UTC-03:00', value: '-03:00' },
  { name: 'South Georgia Time UTC-02:00', value: '-02:00' },
  { name: 'Azores Time UTC-01:00', value: '-01:00' },
  { name: 'Greenwich Mean Time UTC+00:00', value: '00:00' },
  { name: 'Central European Time UTC+01:00', value: '+01:00' },
  { name: 'Eastern European Time UTC+02:00', value: '+02:00' },
  { name: 'Moscow Time UTC+03:00', value: '+03:00' },
  { name: 'Iran Time UTC+03:30', value: '+03:30' },
  { name: 'Gulf Standard Time UTC+04:00', value: '+04:00' },
  { name: 'Afghanistan Time UTC+04:30', value: '+04:30' },
  { name: 'Pakistan Standard Time UTC+05:00', value: '+05:00' },
  { name: 'India Standard Time UTC+05:30', value: '+05:30' },
  { name: 'Nepal Time UTC+05:45', value: '+05:45' },
  { name: 'Bangladesh Time UTC+06:00', value: '+06:00' },
  { name: 'Cocos Islands Time UTC+06:30', value: '+06:30' },
  { name: 'Thailand Time UTC+07:00', value: '+07:00' },
  { name: 'China Standard Time UTC+08:00', value: '+08:00' },
  { name: 'Australian Central Western Time UTC+08:45', value: '+08:45' },
  { name: 'Japan Standard Time UTC+09:00', value: '+09:00' },
  { name: 'Australian Central Time UTC+09:30', value: '+09:30' },
  { name: 'Australian Eastern Time UTC+10:00', value: '+10:00' },
  { name: 'Lord Howe Island Time UTC+10:30', value: '+10:30' },
  { name: 'Solomon Islands Time UTC+11:00', value: '+11:00' },
  { name: 'New Zealand Time UTC+12:00', value: '+12:00' },
  { name: 'Chatham Islands Time UTC+12:45', value: '+12:45' },
  { name: 'Tonga Time UTC+13:00', value: '+13:00' },
  { name: 'Line Islands Time UTC+14:00', value: '+14:00' },
];

export const languages = [
  { value: 'english', label: 'English' },
  { value: 'arabic', label: 'Arabic' },
  { value: 'chinese', label: 'Chinese' },
  { value: 'french', label: 'French' },
  { value: 'german', label: 'German' },
  { value: 'greek', label: 'Greek' },
  { value: 'hindi', label: 'Hindi' },
  { value: 'italian', label: 'Italian' },
  { value: 'japanese', label: 'Japanese' },
  { value: 'korean', label: 'Korean' },
  { value: 'russian', label: 'Russian' },
  { value: 'spanish', label: 'Spanish' },
  { value: 'vietnamese', label: 'Vietnamese' },
  { value: 'other', label: 'Other' },
];