import React, { FC } from 'react';
import ActionsDropdown from 'components/common/ActionsDropdown';
import { Action } from 'components/common/ActionsDropdown/types';
import UpdatePosition from './UpdatePosition';
import SendInvite from './SendInvite';
import UpdateWebappUserRole from './UpdateWebappUserRole';
import { modalStateAtom } from 'atoms';
import { useAtom } from 'jotai';
import { UpdateProps } from './types';
import ButtonWithDropdown from 'components/common/ButtonWithDropdown';

const Updates: FC<UpdateProps> = ({ selectedRowIds }) => {
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const updateActions: Action[] = [
    {
      label: 'Web app User Role',
      function: (props) => setModalState({
        ...modalState,
        status: 'open',
        position: 'center',
        component: UpdateWebappUserRole,
        props: { ...props, dataTableName: 'user-profiles' },
      }),
      icon: null,
    },
    {
      label: 'Position',
      function: (props) => setModalState({
        ...modalState,
        status: 'open',
        position: 'center',
        component: UpdatePosition,
        props: { ...props, dataTableName: 'user-profiles' },
      }),
      icon: null,
    },
    {
      label: 'Send sign up invite',
      function: (props) => setModalState({
        ...modalState,
        status: 'open',
        position: 'center',
        component: SendInvite,
        props: { ...props, dataTableName: 'user-profiles' },
      }),
      icon: null,
      borderTop: true,
    }
  ];

  return (
    <ButtonWithDropdown
      color='secondary'
      label='Update'
      isDisabled={selectedRowIds.length === 0}
      dropdownContents={
        <ActionsDropdown selectedRowIds={selectedRowIds} dataTableName='user-profiles' actions={updateActions} />
      }
    />
  );
};

export default Updates;