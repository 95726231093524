import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import Edit from './Edit';
import { Stack, Typography } from '@mui/material';
import variables from 'styles/variables';
import { format, parseISO } from 'date-fns';

export const Columns: GridColDef[] = [
  {
    field: 'edit',
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <Edit
        schedulingSupport={params.row}
      />
    ),
  },
  {
    field: 'supportItem',
    headerName: 'Support Item',
    flex: 2,
    minWidth: 300,
    renderCell: (params) => (
      <Stack sx={{ overflow: 'hidden' }}>
        <Typography variant='subtitle2' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {params.row.service}
        </Typography>
        <Typography variant='body2' color={variables.colors.text.secondary} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {params.row.productCode}
        </Typography>
      </Stack>
    ),
    sortable: false,
  },
  {
    field: 'serviceTimeslot',
    headerName: 'Deliver During',
    flex: 1,
    minWidth: 150,
    sortable: false,
  },
  {
    field: 'startTime',
    headerName: 'Start Time',
    flex: 1,
    minWidth: 150,
    renderCell: (params) => (
      <Typography variant='body2'>
        {params.row.startTime && format(parseISO(params.row.startTime), 'hh:mm a')}
      </Typography>
    ),
    sortable: false,
  },
  {
    field: 'endTime',
    headerName: 'End Time',
    flex: 1,
    minWidth: 150,
    renderCell: (params) => (
      <Typography variant='body2'>
        {params.row.endTime && format(parseISO(params.row.endTime), 'hh:mm a')}
      </Typography>
    ),
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => (
      <IsActiveDropdown
        isActive={params.row.isActive}
        setIsActive={() => { console.log('setIsActive'); }} // TODO: When APIs are ready, replace this with actual API call
      />
    ),
  },
];