import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { User } from 'types/dbSchema/userProfiles';
import UserProfileCell from 'components/features/UserProfileCell';
import variables from 'styles/variables';
import { useNavigate } from 'react-router-dom';

export interface OwnerDetailsProps {
  owner: User;
}

const OwnerDetails: FC<OwnerDetailsProps> = ({ owner }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/settings/user-management/user-profiles/view/${owner.id}`);
  };
  return (
    <DetailsStack>
      <Typography variant='h6' fontWeight='600' color='text.primary'>
        Owner Details
      </Typography>
      <StyledBox onClick={handleClick}>
        <UserProfileCell userProfile={owner} displayOnly />
      </StyledBox>
    </DetailsStack>
  );
};

const DetailsStack = styled(Stack)`
  background-color: white;
  padding: 32px;
  gap: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
`;

const StyledBox = styled(Box)`
  cursor: pointer;
  border: 2px solid white;
  padding: 10px;
  border-radius: 8px;
  width: fit-content;
  transition: border 120ms;
  &:hover {
    border: 2px solid ${variables.colors.primary.main};
  }
`;

export default OwnerDetails;