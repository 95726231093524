import axiosInstance from "api/config";
import { ServiceType, ServiceTypeList } from "types/dbSchema/schedulingSettings";

// Returns a list of service types for the given organisation ID
export const listServiceTypes = async (organisationId: string, page?: number, pageSize?: number): Promise<ServiceTypeList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID, page, and page size are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/scheduling/service-types`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing service types for the organisation', error);
    throw error;
  }
};

// Returns a service type by ID
export const getServiceTypeByID = async (organisationId: string, serviceTypeID: string): Promise<ServiceType> => {
  try {
    if (!serviceTypeID || !organisationId) throw new Error('Organisation ID and ServiceType ID are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/scheduling/service-types/${serviceTypeID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the service type', error);
    throw error;
  }
};

// Create a new service type
export const createServiceType = async (organisationId: string, data: ServiceType) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/${organisationId}/settings/scheduling/service-types`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the service type', error);
    throw error;
  }
};

// Update a service type
export const updateServiceType = async (organisationId: string, data: ServiceType) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.put(`/api/${organisationId}/settings/scheduling/service-types/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the service type', error);
    throw error;
  }
};

// Delete a service type
export const deleteServiceType = async (organisationId: string, serviceTypeID: string) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.delete(`/api/${organisationId}/settings/scheduling/service-types/${serviceTypeID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the service type', error);
    throw error;
  }
};