import React, { FC, useEffect, useRef } from "react";
import { Button, Stack, Typography } from "@mui/material";
import variables from "styles/variables";
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import { SettingsDropdownProps } from "../types";
import { Controller, useForm } from "react-hook-form";
import SelectInput from "components/common/InputFields/SelectInput";
import { timezoneOptions } from "types";

const SettingsDropdown: FC<SettingsDropdownProps> = ({ isSettingsOpen, setIsSettingsOpen, handleStartTimeChange, currentStartTime }) => {
  const settingsDropdownRef = useRef<HTMLDivElement>(null);
  const settingsButtonRef = useRef<HTMLDivElement>(null);

  const handleSettingsDropdown = (event: any) => {
    event.stopPropagation();
    setIsSettingsOpen(!isSettingsOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      settingsButtonRef.current &&
      !settingsButtonRef.current.contains(event.target as Node) &&
      settingsDropdownRef.current &&
      !settingsDropdownRef.current.contains(event.target as Node)) {
      setIsSettingsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Stack position='relative' ref={settingsButtonRef}>
      <Button
        onClick={handleSettingsDropdown}
        startIcon={<DisplaySettingsOutlinedIcon />}
        sx={{
          color: variables.colors.icon.standard,
          padding: '8px',
          minWidth: 'unset'
        }}
      >
        Settings
      </Button>
      {isSettingsOpen && <SettingsForm
        settingsDropdownRef={settingsDropdownRef}
        handleStartTimeChange={handleStartTimeChange}
        currentStartTime={currentStartTime}
      />}
    </Stack>
  );
};

const SettingsForm = ({ settingsDropdownRef, handleStartTimeChange, currentStartTime }: any) => {
  const { control, formState: { errors } } = useForm({
    defaultValues: {
      startTime: currentStartTime || '06:00:00',
      displayTimezone: 'Local Time',
    },
    mode: 'onChange'
  });

  return (
    <Stack
      ref={settingsDropdownRef}
      sx={{
        position: 'absolute',
        top: '40px',
        right: '0',
        backgroundColor: 'white',
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        zIndex: 999,
        width: '320px',
        padding: '24px',
        gap: '16px'
      }}
    >
      <Typography fontSize='12px' fontWeight='600' color={variables.colors.text.secondary}>Calendar Settings</Typography>
      <Controller
        name="startTime"
        control={control}
        render={({ field }) =>
          <SelectInput
            {...field}
            id='startTime'
            label="Calendar Start Time"
            error={errors.startTime ? true : false}
            placeholder='Select'
            onChange={(e: any) => {
              handleStartTimeChange(e);
              field.onChange(e);
            }}
            options={[
              { value: '06:00:00', label: '6:00 AM' },
              { value: '07:00:00', label: '7:00 AM' },
              { value: '08:00:00', label: '8:00 AM' },
              { value: '09:00:00', label: '9:00 AM' },
              { value: '10:00:00', label: '10:00 AM' },
              { value: '11:00:00', label: '11:00 AM' },
              { value: '12:00:00', label: '12:00 PM' },
              { value: '13:00:00', label: '1:00 PM' },
              { value: '14:00:00', label: '2:00 PM' },
              { value: '15:00:00', label: '3:00 PM' },
              { value: '16:00:00', label: '4:00 PM' },
              { value: '17:00:00', label: '5:00 PM' },
              { value: '18:00:00', label: '6:00 PM' },
              { value: '19:00:00', label: '7:00 PM' },
              { value: '20:00:00', label: '8:00 PM' },
              { value: '21:00:00', label: '9:00 PM' },
              { value: '22:00:00', label: '10:00 PM' },
              { value: '23:00:00', label: '11:00 PM' },
              { value: '00:00:00', label: '12:00 AM' },
              { value: '01:00:00', label: '1:00 AM' },
              { value: '02:00:00', label: '2:00 AM' },
              { value: '03:00:00', label: '3:00 AM' },
              { value: '04:00:00', label: '4:00 AM' },
              { value: '05:00:00', label: '5:00 AM' },
            ]}
          />}
      />
      <Controller
        name="displayTimezone"
        control={control}
        render={({ field }) =>
          <SelectInput
            {...field}
            id='displayTimezone'
            label="Display Timezone"
            error={errors.displayTimezone ? true : false}
            errorText={errors.displayTimezone?.message}
            placeholder='Select'
            options={timezoneOptions}
          />}
      />
    </Stack>
  );
};

export default SettingsDropdown;