import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import variables from 'styles/variables';
import { Typography } from '@mui/material';
import ViewIcon from './ViewIcon';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { formatISODate } from 'utils/helpers';

export const Columns: GridColDef[] = [
  {
    field: 'view',
    headerName: '',
    width: 50,
    sortable: false,
    renderCell: (params) => (
      <ViewIcon serviceContractToView={params.row} />
    ),
  },
  {
    field: 'sosReferenceId',
    headerName: 'Reference ID',
    width: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2' fontWeight='600'>
        {params.row.sosReferenceId}
      </Typography>
    ),
  },
  {
    field: 'startingFunds',
    headerName: 'Starting Funds',
    width: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary}>
        ${params.row.startingFunds.toFixed(2)}
      </Typography>
    ),
  },
  {
    field: 'deliveredAmount',
    headerName: 'Delivered Amount',
    width: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary}>
        ${params.row.deliveredAmount.toFixed(2)}
      </Typography>
    ),
  },
  {
    field: 'remainingFunds',
    headerName: 'Remaining Funds',
    width: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2' fontWeight='600' >
        ${params.row.remainingAmount.toFixed(2)}
      </Typography>
    ),
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    width: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary}>
        {formatISODate(params.row.startDate)}
      </Typography>
    ),
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    minWidth: 200,
    flex: 1,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary}>
        {formatISODate(params.row.endDate)}
      </Typography>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => (
      <IsActiveDropdown
        isActive={params.row.isActive}
        disabled
      />
    ),
  },
];