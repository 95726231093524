import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography, } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import styled from 'styled-components';
import DataTable from 'components/common/DataTable';
import SearchInput from 'components/common/InputFields/SearchInput';
import DownloadAttachmentsIcon from 'components/common/DownloadAttachmentsIcon';

const PaymentHistory = () => {
  const [searchValue, setSearchValue] = useState('');
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    // TODO: Fetch data from API
    setRows([]);
  }, []);

  return (
    <StyledBox>
      <Stack flexDirection='row' justifyContent='space-between' alignItems='center' padding='16px 24px'>
        <Typography variant='subtitle2' fontSize='18px'>Payment History</Typography>
        <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholderText='Search' />
      </Stack>
      {/* <DataTable
        data={{ items: rows, totalCount: rows.length, pageNumber: 1, pageSize: 10 }}
        columns={columns}
        checkboxSelection={false}
        dataTableName='payment-history'
        isPending={true}
        title=''
      /> */}
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  height: 100%;
  width: 100%;
  background-color: white;
`;

// Grid column definitions for DataTable
const columns: GridColDef[] = [
  {
    field: 'download',
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <DownloadAttachmentsIcon attachments={params.row.attachments} />
    ),
    sortable: false,
  },
  {
    field: 'invoiceNumber',
    headerName: 'Invoice Number',
    flex: 1,
  },
  {
    field: 'invoiceDate',
    headerName: 'Invoice Date',
    flex: 1,
  },
  {
    field: 'invoiceTotal',
    headerName: 'Invoice Total',
    flex: 1,
  },
];


export default PaymentHistory;