import React, { createElement, useState, FC, SyntheticEvent } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import styled from 'styled-components';
import variables from 'styles/variables';
import { AccordionBaseProps, AccordionElementProps } from './types';
import { Box } from '@mui/material';

const AccordionBase: FC<AccordionBaseProps> = ({ accordionArray }) => {
  const [expanded, setExpanded] = useState<string | false>('panel0');

  const handleChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Box>
      {accordionArray.map((accordion, index) => {
        if (!accordion.detailsContent) return (
          <AccordionElement sx={{ padding: '24px 40px 24px 54px' }} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} key={index} disableGutters>
            <MuiAccordionSummary sx={{ margin: 0, padding: 0, flexDirection: 'row-reverse', alignItems: 'flex-start' }} data-testid={`panel${index}-summary`}>
              {createElement(accordion.summaryContent)}
            </MuiAccordionSummary>
          </AccordionElement>
        );
        return (
          <AccordionElement sx={{ padding: '24px 40px 24px 0px' }} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} key={index} disableGutters>
            <MuiAccordionSummary sx={{ margin: 0, padding: 0, flexDirection: 'row-reverse', alignItems: 'flex-start' }} expandIcon={<KeyboardArrowDownIcon sx={{ margin: '16px' }} />} data-testid={`panel${index}-summary`}>
              {createElement(accordion.summaryContent)}
            </MuiAccordionSummary>
            <MuiAccordionDetails sx={{ margin: 0, padding: 0 }} data-testid={`panel${index}-details`}>
              {createElement(accordion.detailsContent)}
            </MuiAccordionDetails>
          </AccordionElement>
        );
      })}
    </Box>
  );
};

const AccordionElement = styled(MuiAccordion) <AccordionElementProps>`
  border: ${({ expanded }) => expanded ? `1px solid ${variables.colors.primary.main}` : `1px solid ${variables.colors.lightNeutral.main}`};
  border-bottom: ${({ expanded }) => expanded ? `1px solid ${variables.colors.primary.main}` : '1px solid transparent'};
  &:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &::before {
    display: none;
  }
`;

export default AccordionBase;