import React, { FC, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import Actions from './Actions';
import { ControlsProps } from '../../types';

const Controls: FC<ControlsProps> = ({
  selectedFilters,
  setSelectedFilters,
  searchText,
  setSearchText,
  selectedRowIds
}) => {
  return (
    <StyledStack>
      <Stack justifyContent='space-between' height='100%' gap='16px'>
        <ActionStack>
          <Typography variant='subtitle2' minWidth='fit-content' fontSize='18px'>
            Budget Notes
          </Typography>
          <Actions />
        </ActionStack>
      </Stack>
    </StyledStack>
  );
};

const StyledStack = styled(Stack)`
  height: fit-content;
  padding: 24px;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
`;

const ActionStack = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  @media (max-width: 950px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export default Controls;