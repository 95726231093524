import React from 'react';
import SettingsTabs from 'components/common/SettingsTabs';
import TemplateNotificationsDataTable from '../DataTables/TemplateNotificationsDataTable';
import PushNotificationsDataTable from '../DataTables/PushNotificationsDataTable';
import ParticipantNotificationsDataTable from '../DataTables/ParticipantNotificationsDataTable';
import SchedulingNotificationsDataTable from '../DataTables/SchedulingNotificationsDataTable';

const NotificationSettings = () => {
  return (
    <SettingsTabs
      title='Notification Settings'
      tabs={[
        {
          label: 'Scheduling',
          content: <SchedulingNotificationsDataTable />,
          requiredPermissions: [],
        },
        {
          label: 'Participant',
          content: <ParticipantNotificationsDataTable />,
          requiredPermissions: [],
        },
        {
          label: 'Mobile Push Notifications',
          content: <PushNotificationsDataTable />,
          requiredPermissions: [],
        },
        {
          label: 'Template',
          content: <TemplateNotificationsDataTable />,
          requiredPermissions: [],
        }
      ]}
    />
  );
};

export default NotificationSettings;