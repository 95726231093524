import React, { FC } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import ProfileImageUpload from 'components/common/InputFields/ProfileImageFileUpload';
import { User } from 'types/dbSchema/userProfiles';
import SelectInput from 'components/common/InputFields/SelectInput';
import TextInput from 'components/common/InputFields/TextInput';
import DatePickerInput from 'components/common/InputFields/DatePickerInput';
import { pronounOptions } from 'types';

export interface PersonalDetailsProps {
  control: Control<User>;
  errors: FieldErrors<User>;
}

const PersonalDetails: FC<PersonalDetailsProps> = ({ control, errors }) => {
  return (
    <PersonalDetailsStack>
      <Typography variant='h6' fontWeight='600' color='text.primary'>
        Personal Details
      </Typography>
      <Row numberOfChildren={3}>
        <Controller
          name="profilePicture"
          control={control}
          render={({ field }) => <ProfileImageUpload variant='text' {...field} />}
        />
      </Row>
      <Row numberOfChildren={3}>
        <Controller
          name="salutation"
          control={control}
          render={({ field }) =>
            <SelectInput
              {...field}
              id='salutation'
              label="Salutation"
              error={errors.salutation ? true : false}
              errorText={errors.salutation?.message}
              placeholder='Select a salutation'
              options={[
                { value: 'Mr', label: 'Mr' },
                { value: 'Mrs', label: 'Mrs' },
                { value: 'Ms', label: 'Ms' },
                { value: 'Dr', label: 'Dr' },
                { value: 'Other', label: 'Other' }
              ]}
            />}
        />
      </Row>
      <Row numberOfChildren={3}>
        <Controller
          name="fullName.firstName"
          control={control}
          rules={{
            required: 'First Name is required',
            pattern: { value: /^[a-zA-Z-]+$/, message: 'First Name must only contain letters' },
            minLength: { value: 2, message: 'First Name must be at least 2 characters long' },
            maxLength: { value: 50, message: 'First Name must be at most 50 characters long' }
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='firstName'
              label='First Name'
              error={errors.fullName?.firstName ? true : false}
              errorText={errors.fullName?.firstName?.message}
              isMandatory
            />}
        />
        <Controller
          name="fullName.middleName"
          control={control}
          rules={{
            pattern: { value: /^[a-zA-Z-]+$/, message: 'Middle Names must only contain letters' },
            minLength: { value: 2, message: 'Middle Names must be at least 2 characters long' },
            maxLength: { value: 50, message: 'Middle Names must be at most 50 characters long' }
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='middleName'
              label='Middle Names'
              error={errors.fullName?.middleName ? true : false}
              errorText={errors.fullName?.middleName?.message}
            />}
        />
        <Controller
          name="fullName.lastName"
          control={control}
          rules={{
            required: 'First Name is required',
            pattern: { value: /^[a-zA-Z-]+$/, message: 'Last Name must only contain letters' },
            minLength: { value: 2, message: 'Last Name must be at least 2 characters long' },
            maxLength: { value: 50, message: 'Last Name must be at most 50 characters long' }
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='lastName'
              label='Last Name'
              error={errors.fullName?.lastName ? true : false}
              errorText={errors.fullName?.lastName?.message}
              isMandatory
            />}
        />
      </Row>
      <Row numberOfChildren={3}>
        <Controller
          name="dob"
          control={control}
          rules={{ required: 'Date of Birth is required' }}
          render={({ field }) =>
            <DatePickerInput
              {...field}
              id='dob'
              label='Date of Birth'
              error={errors.dob ? true : false}
              errorText={errors.dob?.message}
              isMandatory
            />}
        />
        <Controller
          name="gender"
          control={control}
          rules={{ required: 'Gender is required' }}
          render={({ field }) =>
            <SelectInput
              {...field}
              id='gender'
              label="Gender"
              error={errors.gender ? true : false}
              errorText={errors.gender?.message}
              placeholder='Select'
              isMandatory
              options={[
                { value: 'Male', label: 'Male' },
                { value: 'Female', label: 'Female' },
                { value: 'Other', label: 'Other' },
              ]}
            />}
        />
        <Controller
          name="pronoun"
          control={control}
          render={({ field }) =>
            <SelectInput
              {...field}
              id='pronoun'
              label="Pronoun"
              error={errors.pronoun ? true : false}
              errorText={errors.pronoun?.message}
              placeholder='Select'
              options={pronounOptions}
            />}
        />
      </Row>
    </PersonalDetailsStack>
  );
};

const PersonalDetailsStack = styled(Stack)`
  background-color: white;
  padding: 32px;
  gap: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
`;

export interface RowProps {
  numberOfChildren?: number;
}

const Row = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'numberOfChildren'
}) <RowProps>`
  gap: 24px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start; /* Changed to flex-start to accommodate varying numbers of children */
  align-items: flex-start;

  & > * {
    flex: 0 0 calc((100% - 48px) / ${({ numberOfChildren }) => numberOfChildren});
    max-width: calc((100% - 48px) / ${({ numberOfChildren }) => numberOfChildren});
  }
`;

export default PersonalDetails;