import React, { FC, useEffect, useRef, useState } from 'react';
import { AvailabilityTemplate } from 'types/dbSchema/availabilitySettings';
import FullCalendar from '@fullcalendar/react';
import { DateSelectArg } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Stack, Typography, } from '@mui/material';
import { CalendarEvent } from 'types/fullCalendar';
import styled from 'styled-components';
import variables from 'styles/variables';
import { format, differenceInMinutes, parseISO } from 'date-fns';
import SideControls from './SideControls';
import { useAtom } from 'jotai';
import { selectedTemplatedIdAtom } from 'atoms';
import { handleDateSelect, handleEventChange, mapConvertTemplateToCalendarEvents } from 'utils/helpers/fullCalendarTemplates';

export interface CreateEditAvailabilityTemplateProps {
  availabilityTemplate?: AvailabilityTemplate;
}

const CreateEditAvailabilityTemplate: FC<CreateEditAvailabilityTemplateProps> = ({ availabilityTemplate }) => {
  const calendarRef = useRef<any>(null);
  const [events, setEvents] = useState<CalendarEvent[]>(
    mapConvertTemplateToCalendarEvents(availabilityTemplate?.availabilities || [], false)
  );
  const [totalHours, setTotalHours] = useState<number>(0);
  const [dailyTotals, setDailyTotals] = useState({});
  const [, setSelectedEventId] = useAtom(selectedTemplatedIdAtom);

  useEffect(() => {
    const totals: { [key: string]: number } = {};
    let grandTotalHours = 0;

    events.forEach((event) => {
      if (event.start && event.end && event.day) {
        const start = parseISO(event.start);
        const end = parseISO(event.end);

        const diffInMinutes = differenceInMinutes(end, start);
        const hours = parseFloat((diffInMinutes / 60).toFixed(2));

        // Add the hours to the corresponding day
        if (!totals[event.day]) {
          totals[event.day] = 0;
        }
        totals[event.day] += hours;
        grandTotalHours += hours;
      }
    });

    setDailyTotals(totals);
    setTotalHours(parseFloat(grandTotalHours.toFixed(2)));
  }, [events, setDailyTotals, setTotalHours]);

  const dateSelectHandler = (selectInfo: DateSelectArg) => {
    const newEvent = handleDateSelect(selectInfo, setEvents, true);
    setSelectedEventId(newEvent.id!);
  };

  const updateEvent = (eventChangeInfo: any) => {
    const { event } = eventChangeInfo;
    handleEventChange(eventChangeInfo, setEvents);
    setSelectedEventId(event.id);
  };

  return (
    <Stack width='100%' height='100%' sx={{ backgroundColor: 'white' }}>
      <Stack height='calc(100vh - 58px)' flexDirection='row' overflow='hidden' width='100%'>
        <Stack flexDirection='column' width='100%'>
          <Stack flexDirection='row' justifyContent='flex-start' alignItems='center' padding='32px' gap='24px'>
            <Typography variant='h6'>{availabilityTemplate ? 'Edit' : 'New'} Availability Template</Typography>
            <Stack flexDirection='row' gap='8px' padding='2px 8px' alignItems='center' borderRadius='100px' sx={{ backgroundColor: '#F5F2FA' }}>
              <Typography variant='body1' sx={{ color: variables.colors.primary.darker }}>Total selected hours</Typography>
              <Typography fontWeight='600' fontSize='18px' sx={{ color: variables.colors.primary.darker }}>{totalHours}</Typography>
            </Stack>
          </Stack>
          <StyledDiv>
            <FullCalendar
              ref={calendarRef}
              eventTimeFormat={{ hour: 'numeric', minute: '2-digit', hour12: false }}
              plugins={[timeGridPlugin, interactionPlugin]}
              initialView="timeGridWeek"
              selectable={true}
              events={events}
              nowIndicator
              headerToolbar={false}
              select={(selectInfo: DateSelectArg) => dateSelectHandler(selectInfo)}
              slotDuration='1:00'
              slotLabelInterval={{ hours: 1 }}
              slotLabelFormat={{ hour: 'numeric', minute: '2-digit', hour12: false }}
              dayHeaderContent={(args) => DayHeader(args, dailyTotals)}
              allDaySlot={false}
              droppable={true}
              eventResize={(eventResizeInfo) => updateEvent(eventResizeInfo)}
              eventDrop={(eventDropInfo) => updateEvent(eventDropInfo)}
              eventClick={(eventClickInfo) => setSelectedEventId(eventClickInfo.event.id)}
              editable={true}
              allDayText=''
              firstDay={0}
              height='100%'
              slotMinTime='00:00:00'
              eventColor='#4CAF50'
            />
          </StyledDiv>
        </Stack>
        <SideControls
          events={events}
          setEvents={setEvents}
          availabilityTemplate={availabilityTemplate}
        />
      </Stack>
    </Stack>
  );
};

const DayHeader = (args: any, dailyTotals: any) => {
  const day = format(args.date, 'EEEE');

  return (
    <Stack alignItems='center' gap='12px' height='50px'>
      <Typography variant='caption'>{args.date.toLocaleDateString('en-US', { weekday: 'short' })}</Typography>
      {(dailyTotals && dailyTotals[day]) && (
        <Stack flexDirection='row' gap='4px' alignItems='center'>
          <Typography variant='caption'>{dailyTotals[day]} hours</Typography>
        </Stack>
      )}
    </Stack>
  );
};

const StyledDiv = styled.div`
  height: 100%;
  transition: width 0.3s ease;
  
  .fc-col-header,
  .fc-daygrid-body,
  .fc-scrollgrid-sync-table,
  .fc-timegrid-body, 
  .fc-timegrid-body table {
    width: 100% !important;
  }
  .fc .fc-timegrid-slot {
    height: calc((100vh - 220px) / 24);
  }

  .fc .fc-daygrid-day {
    height: calc((100vh - 220px) / 24);
  }

  .fc-day-today {
    background-color: transparent !important;
  }
`;

export default CreateEditAvailabilityTemplate;