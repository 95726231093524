import { Address, PaginationBase } from "types";

export type ClaimType = 'STAND' | 'CANC - NSDH' | 'CANC - NSDF' | 'CANC - NSDT' | 'CANC - NSDO' | 'REPW' | 'TRAN' | 'NF2F' | 'THLT' | 'IRSS';
export const claimTypes: ClaimType[] = ['STAND', 'CANC - NSDH', 'CANC - NSDF', 'CANC - NSDT', 'CANC - NSDO', 'REPW', 'TRAN', 'NF2F', 'THLT', 'IRSS'];
export type ShiftLocationList = PaginationBase<ShiftLocation>;
export type ServiceTypeList = PaginationBase<ServiceType>;
export type DeclinedReasonList = PaginationBase<DeclinedReason>;
export type CancellationCodeList = PaginationBase<CancellationCode>;

export interface ShiftLocation {
  id: string;
  name: string;
  address: Address;
  isActive: boolean;
  isEditable: boolean;
}

export interface ServiceType {
  id: string;
  name: string;
  color: string;
  claimType: ClaimType;
  isActive: boolean;
  isEditable: boolean;
}

export interface DeclinedReason {
  id: string;
  declinedReason: string;
  description: string;
  isActive: boolean;
  isEditable: boolean;
}

export interface CancellationCode {
  id: string;
  code: string;
  description: string;
  isActive: boolean;
  isEditable: boolean;
}

export const serviceTypesColorList = [
  { value: '#ffcccc', label: 'Pale red' },
  { value: '#ff9999', label: 'Salmon pink' },
  { value: '#ccccff', label: 'Periwinkle' },
  { value: '#cc99cc', label: 'Magenta' },
  { value: '#cc9966', label: 'Tan' },
  { value: '#99ccff', label: 'Light blue' },
  { value: '#99cccc', label: 'Cyan' },
  { value: '#993333', label: 'Red' },
  { value: '#666699', label: 'Mauve' },
  { value: '#336699', label: 'Blue' },
  { value: '#336666', label: 'Teal' },
];