import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import DataTableControls from './Controls';
import DataTable from 'components/common/DataTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Columns } from './Controls/Columns';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { Data, PaginationModel } from 'components/common/DataTable/types';
import { useNavigate } from 'react-router-dom';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { listServiceProviders } from 'api/organisations/serviceproviders';
import useInvalidateData from 'hooks/useInvalidateData';

const ServiceProvidersDataTable: FC = () => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);
  const [searchText, setSearchText] = useState('');
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({ page: 0, pageSize: 20 });
  const navigate = useNavigate();
  const [organisations] = useUserOrganisations();
  useInvalidateData('service-providers');

  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: ['service-providers', paginationModel, selectedFilters],
    queryFn: () => listServiceProviders(
      organisations[0].organisation.globalId,
      selectedFilters,
      paginationModel.page + 1,
      paginationModel.pageSize
    ),
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.serviceProviders,
  });

  return (
    <Stack height='100%' width='100%' sx={{ backgroundColor: 'white' }}>
      <DataTableControls
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        searchText={searchText}
        setSearchText={setSearchText}
        selectedRowIds={[]}
      />
      <DataTable
        data={data as Data}
        columns={Columns}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isPending={isPending || isFetching}
        isError={isError}
        title='Service Providers'
        dataTableName='service-providers'
        checkboxSelection={false}
        paginationMode='server'
        onNoData={() => navigate('/resources/service-providers/create')}
      />
    </Stack>
  );
};

export default ServiceProvidersDataTable;