import { Stack } from '@mui/material';
import React, { FC } from 'react';
import variables from 'styles/variables';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import GlobalSearchbar from '../GlobalSearchbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { TopbarProps } from '../../types';
import { useAtom } from 'jotai';
import Avatar from 'components/features/Avatar';
import { sidebarAtom } from 'atoms';

const TopBarElements: FC<TopbarProps> = ({ withSearchbar }) => {
  const [sidebarState, setSidebarState] = useAtom(sidebarAtom);
  const location = useLocation();
  const navigate = useNavigate();
  const isSettingsPage = location.pathname.includes('/settings');

  const handleSettingsClick = () => {
    if (sidebarState.type === 'main') {
      setSidebarState({
        ...sidebarState,
        type: 'settings',
        isOpen: true,
      });
    } else {
      setSidebarState({
        ...sidebarState,
        type: 'main',
        isOpen: true,
      });
    }
  };

  const handleNotificationsClick = () => {
    navigate('/scheduling/notifications');
  };

  const handleHelpClick = () => {
    navigate('/help-centre/help-articles');
  };

  return (
    <Stack flexDirection='row' height='100%' gap='16px' data-testid='topbar-elements'>
      {withSearchbar && <GlobalSearchbar />}
      <SettingsOutlinedIcon
        onClick={handleSettingsClick}
        sx={{
          color: variables.colors.primary.darker,
          height: '100%',
          width: 'auto',
          padding: '6px',
          boxSizing: 'border-box',
          cursor: 'pointer',
          backgroundColor: isSettingsPage ? '#ac4fc64c' : 'transparent',
          borderRadius: '10%',
          transition: 'background-color 0.24s'
        }} />
      <NotificationsNoneIcon
        onClick={handleNotificationsClick}
        sx={{
          color: variables.colors.darkNeutral.darker,
          height: '100%',
          width: 'auto',
          padding: '6px',
          boxSizing: 'border-box',
          cursor: 'pointer'
        }} />
      <HelpOutlineIcon
        onClick={handleHelpClick}
        sx={{
          color: variables.colors.darkNeutral.darker,
          height: '100%',
          width: 'auto',
          padding: '6px',
          boxSizing: 'border-box',
          cursor: 'pointer'
        }} />
      <Avatar />
    </Stack>
  );
};

export default TopBarElements;