import { Avatar, Box, LinearProgress, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { sidebarAtom } from 'atoms';
import { useAtom } from 'jotai';
import React, { FC } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { User } from 'types/dbSchema/userProfiles';
import { UserPosition } from 'types/dbSchema/userPositions';
import variables from 'styles/variables';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { getUserById } from 'api/organisations/users';
import useUserOrganisations from 'hooks/useUserOrganisations';

const ServiceTeamMemberDetails = () => {
  const [sidebarState] = useAtom(sidebarAtom);
  const { pathname } = useLocation();
  const lastSlug = pathname.split('/').pop();
  const [organisations] = useUserOrganisations();
  const { id } = useParams();

  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
    getByIDFunc: () => getUserById(organisations[0].organisation.globalId, id as string)
  });

  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  const user = currentDataPoint as User;

  const rosterLinks = [
    {
      id: 'roster',
      title: 'Roster',
      link: `/scheduling/service-team/${user.id}/roster`,
    },
    {
      id: 'leave',
      title: 'Leave',
      link: `/scheduling/service-team/${user.id}/leave`,
    }
  ];

  const profileLinks = [
    {
      id: 'employee-details',
      title: 'Employee Details',
      link: `/scheduling/service-team/${user.id}/employee-details`,
    },
    {
      id: 'skills-qualifications',
      title: 'Skills & Qualifications',
      link: `/scheduling/service-team/${user.id}/skills-qualifications`,
    },
    {
      id: 'employee-notes',
      title: 'Employee Notes',
      link: `/scheduling/service-team/${user.id}/employee-notes`,
    }
  ];

  return (
    <Stack width='100%'>
      <BackToServiceTeamButton />
      {sidebarState.isOpen && (
        <Stack gap='32px' padding='8px'>
          <Stack gap='8px' padding='8px 16px'>
            <Avatar src={currentDataPoint.profilePicture} sx={{ width: 64, height: 64 }} />
            <Stack>
              <Typography variant='h6' color='white'>
                {user.fullName.firstName} {user.fullName.lastName}
              </Typography>
              <Typography variant='body2' color='white'>
                {user.position ? (user.position as UserPosition).name : 'Unknown Position'}
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <Typography color='#FFF' fontSize='12px' fontWeight='600' sx={{ opacity: 0.4 }} padding='8px 16px'>
              SCHEDULING
            </Typography>
            {rosterLinks.map((link, index) => (
              <Link to={link.link} key={index} style={{ textDecoration: 'none' }}>
                <LinkItem isActive={lastSlug === link.id} >
                  {lastSlug === link.id && (
                    <IsActiveIndicator />
                  )}
                  <Typography variant='body2' color='white' >
                    {link.title}
                  </Typography>
                </LinkItem>
              </Link>
            ))}
          </Stack>
          <Stack>
            <Typography color='#FFF' fontSize='12px' fontWeight='600' sx={{ opacity: 0.4 }} padding='8px 16px'>
              PROFILE
            </Typography>
            {profileLinks.map((link, index) => (
              <Link to={link.link} key={index} style={{ textDecoration: 'none' }}>
                <LinkItem isActive={lastSlug === link.id}>
                  {lastSlug === link.id && (
                    <IsActiveIndicator />
                  )}
                  <Typography variant='body2' color='white'>
                    {link.title}
                  </Typography>
                </LinkItem>
              </Link>
            ))}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

const BackToServiceTeamButton: FC = () => {
  const [sidebarState, setSidebarState] = useAtom(sidebarAtom);
  const navigate = useNavigate();

  const handleBackToServiceTeam = () => {
    setSidebarState({
      ...sidebarState,
      content: null,
    });
    navigate('/scheduling/service-team');
  };

  return (
    <StyledList data-testid='BackToMainMenuButton'>
      <StyledTitleItem
        onClick={handleBackToServiceTeam}>
        <StyledListItemIcon>
          <ArrowCircleLeftOutlinedIcon sx={{ color: 'white', width: '100%' }} />
        </StyledListItemIcon>
        {sidebarState.isOpen &&
          <TitleItemText>
            <Typography variant='body2' color='white'>
              Back to Service Team
            </Typography>
          </TitleItemText>
        }
      </StyledTitleItem>
      <BoxLine />
    </StyledList>
  );
};


export type LinkItemProps = {
  isActive: boolean;
};

const LinkItem = styled(ListItem).withConfig({
  shouldForwardProp: (prop) => prop !== 'isActive',
}) <LinkItemProps>`
  padding: 12px 16px;
  cursor: pointer;
  border-radius: 4px;
  user-select: none;
  position: relative;
  background-color: ${({ isActive }) => isActive && variables.colors.primary.main30};
  &:hover {
    background-color: ${({ isActive }) => !isActive && variables.colors.primary.main20};
  }
`;

const IsActiveIndicator = styled.div`
  position: absolute;
  left: 0;
  top: 10px;
  height: 24px;
  width: 4px;
  background-color: #FFFFFF;
  border-radius: 0 4px 4px 0;
`;

const StyledList = styled(List)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 0 8px 0;
  gap: 8px;
`;

const StyledTitleItem = styled(ListItem)`
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  user-select: none;
  height: 46px;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: unset;
  width: 20px;
  display: flex;
`;

const TitleItemText = styled(ListItemText)`
  padding: 4px 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const BoxLine = styled(Box)`
  display: flex;
  padding: 0px 8px;
  flex-direction: column;
  align-self: center;
  height: 1px;
  width: 100%;
  background-color: #ffffff7f;
  box-sizing: border-box;
`;

export default ServiceTeamMemberDetails;