import React, { FC } from 'react';
import { DetailSectionProps, ShiftApproval } from '../types';
import { Button, Stack, Typography } from '@mui/material';
import { ServiceStatus } from 'components/common/ServiceStatus';
import DetailsSection from './DetailsSection';
import ShiftApprovalTabs from './ShiftApprovalTabs';

const ShiftApprovalDetails: FC<ShiftApproval> = ({ currentService, setCurrentService }) => {

  if (!currentService) return null;

  return (
    <Stack gap='32px' sx={{ overflowY: 'auto', width: '100%' }}>
      <Stack padding='24px' gap='32px'>
        <Stack flexDirection='row' gap='16px' justifyContent='space-between'>
          <Stack flexDirection='row' gap='16px' justifyContent='space-between' alignItems='center'>
            <Typography variant='subtitle2' fontSize='18px'>Service Details</Typography>
            <ServiceStatus status={currentService.status} />
          </Stack>
          <Stack flexDirection='row' gap='16px' justifyContent='space-between'>
            <Button variant='outlined' color='primary' >Submit</Button>
            <Button variant='contained' color='primary'>Submit & Next</Button>
          </Stack>
        </Stack>
        <DetailsSection currentService={currentService} />
      </Stack>
      <ShiftApprovalTabs currentService={currentService} />
    </Stack>
  );
};

export default ShiftApprovalDetails;