import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ButtonWithDropdownProps } from './types';

const ButtonWithDropdown: FC<ButtonWithDropdownProps> = ({ label, isDisabled, color, dropdownContents }) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // This is used to close the dropdown when clicking outside of it and outside the button.
  // The reason we need to check if the button is clicked is because the filter button handles it's own click event and we don't want to trigger both.
  const handleClickOutside = (event: MouseEvent) => {
    event.stopPropagation();
    if (
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  // This is used to open and close the dropdown when clicking the button
  const handleDropdown = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  // Cleanup the event listener when the component is unmounted
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <ButtonWrapper ref={buttonRef}>
      <StyledButton variant='contained' color={color} onClick={handleDropdown} disabled={isDisabled} >
        <Typography variant='button'>
          {label}
        </Typography>
        <StyledDownArrow />
      </StyledButton>
      {isOpen &&
        <DropDownContainer ref={dropdownRef}>
          {dropdownContents}
        </DropDownContainer>
      }
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  position: relative;
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
  height: 42px;
`;

const DropDownContainer = styled(Stack)`
  position: absolute;
  top: 45px;
  right: 0;
  width: 185px;
  min-width: fit-content;
  height: fit-content;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 3px 8px -1px #E0E0E0;
  border-radius: 4px;
  gap: 6px;
  z-index: 300;
  padding: 8px 0;
`;

const StyledDownArrow = styled(KeyboardArrowDownIcon)`
  transform: translate(4px, -1px);
`;

export default ButtonWithDropdown;