import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { formatISODateTime } from 'utils/helpers';
import ListServiceTypes from 'components/features/ListServiceTypes';
import variables from 'styles/variables';
import ViewIcon from './ViewIcon';

export const Columns: GridColDef[] = [
  {
    field: 'view',
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <ViewIcon service={params.row} />
    ),
    sortable: false,
  },
  {
    field: 'participantName',
    headerName: 'Participant Name',
    width: 350,
    renderCell: (params) => (
      <Typography variant='body2' fontWeight='600'>
        {params.row.participant.fullName.firstName} {params.row.participant.fullName.lastName}
      </Typography>
    ),
  },
  {
    field: 'serviceType',
    headerName: 'Service Types',
    width: 200,
    renderCell: (params) => (
      <ListServiceTypes
        serviceTypesDelivered={params.row.serviceTypes}
      />
    ),
  },
  {
    field: 'scheduledStart',
    headerName: 'Scheduled Start',
    width: 200,
    renderCell: (params) => (
      <Typography color={variables.colors.text.secondary} variant='body2'>
        {params.row?.scheduledStart && formatISODateTime(params.row?.scheduledStart)}
      </Typography>
    ),
  },
  {
    field: 'scheduledEnd',
    headerName: 'Scheduled End',
    minWidth: 200,
    flex: 1,
    renderCell: (params) => (
      <Typography color={variables.colors.text.secondary} variant='body2'>
        {params.row?.scheduledEnd && formatISODateTime(params.row?.scheduledEnd)}
      </Typography>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    // renderCell: (params) => <EditIsActive positionToEdit={params.row} />
  },
];