import React, { FC, useEffect, useState } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { Controller, useForm } from 'react-hook-form';
import { UserPosition } from 'types/dbSchema/userPositions';
import { closeModalAtom } from 'atoms/modalAtom';
import TextInput from 'components/common/InputFields/TextInput';
import { createPosition, updatePosition } from 'api/organisations/positions';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import LoadingOverlay from 'components/common/LoadingOverlay';
import useErrorMessage from 'hooks/useErrorMessage';
import { ServiceType as IServiceType } from 'types/dbSchema/schedulingSettings';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import ServiceTypesMultiSelectLookupField from 'components/common/MultiSelectLookupField/ServiceTypesMultiSelectLookupField';

export interface UserPositionFormProps {
  positionToEdit?: UserPosition;
}

const UserPositionForm: FC<UserPositionFormProps> = ({ positionToEdit }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [organisations] = useUserOrganisations();
  const [isLoading, setIsLoading] = useState(false);
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();
  const [isEdit, setIsEdit] = useState(false);
  const { control, handleSubmit, formState: { errors, isDirty } } = useForm({
    mode: 'onChange',
    defaultValues: positionToEdit ? {
      ...positionToEdit,
      serviceTypesDelivered: (positionToEdit.serviceTypesDelivered as IServiceType[]).map((serviceType: IServiceType) => serviceType.id)
    } : {
      description: undefined,
      id: undefined,
      name: undefined,
      serviceTypesDelivered: [],
      usersCount: undefined,
      isActive: true // Default value for isActive is required for the IsActiveDropdown component
    }
  });

  useEffect(() => {
    positionToEdit ? setIsEdit(true) : setIsEdit(false);
  }, [positionToEdit]);

  const handleEditPosition = async (data: UserPosition) => {
    try {
      const response = await updatePosition(organisations[0].organisation.globalId, data);
      if (response) {
        setInvalidateData((prev) => ({ ...prev, 'user-position': true }));
        closeModal();
      }
    } catch (error) {
      showError((error as Error).message);
    }
  };

  const handleCreatePosition = async (data: Partial<UserPosition>) => {
    try {
      const response = await createPosition(organisations[0].organisation.globalId, data);
      if (response) {
        setInvalidateData((prev) => ({ ...prev, 'user-position': true }));
        closeModal();
      }
    } catch (error) {
      showError((error as Error).message);
    }
  };

  const submitForm = async (data?: Partial<UserPosition>) => {
    try {
      if (!data) return;
      setIsLoading(true);
      if (isEdit) {
        handleEditPosition(data as UserPosition);
      } else {
        handleCreatePosition(data);
      }
      setIsLoading(false);
    } catch (error) {
      showError((error as Error).message);
      setIsLoading(false);
    }
  };

  return (
    <form style={{ position: 'relative', width: '500px', padding: '32px' }} onSubmit={handleSubmit((data) => submitForm(data))}>
      {isLoading && <LoadingOverlay sx={{ borderRadius: '12px' }} />}
      <Stack height='fit-content' gap='24px'>
        <Typography variant='h6' fontWeight='600' mb={2}>
          {isEdit ? 'Edit Position' : 'Add New Position'}
        </Typography>
        <Controller
          name="name"
          control={control}
          rules={{ required: 'Position name is required' }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='name'
              label='Position Name'
              placeholder='Enter position'
              error={errors.name ? true : false}
              errorText={errors?.name?.message}
              isMandatory
            />
          }
        />
        <Controller
          name="description"
          control={control}
          render={({ field }) =>
            <TextInput
              {...field}
              id='description'
              label='Description'
              placeholder='Enter Description'
              error={errors.description ? true : false}
              errorText={errors?.description?.message}
            />
          }
        />
        <Controller
          name="serviceTypesDelivered"
          control={control}
          render={({ field }) =>
            <ServiceTypesMultiSelectLookupField
              {...field}
              value={field.value as string[]}
              id='serviceTypesDelivered'
              label='Service Types Delivered'
              validationError={errors.serviceTypesDelivered ? true : false}
              errorText={errors.serviceTypesDelivered?.message}
              placeholder='Select'
            />}
        />
        <Controller
          name="isActive"
          control={control}
          rules={{
            validate: value => value !== undefined && value !== null || 'Status is required'
          }}
          render={({ field }) =>
            <IsActiveDropdown
              isActive={(field.value === undefined || field.value === null) ? true : field.value}
              setIsActive={field.onChange}
            />
          }
        />
        <Stack flexDirection='row' justifyContent='flex-end' gap='16px' padding='24px 0 0 0'>
          <Button variant='outlined' color='primary' onClick={closeModal}>
            Cancel
          </Button>
          <Button type='submit' variant='contained' color='primary' disabled={!isDirty}>
            {isEdit ? 'Update' : 'Create'}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export interface EditProps {
  positionToEdit: UserPosition;
}

export const Edit: FC<EditProps> = ({ positionToEdit }) => {
  const [modalState, setModalState] = useAtom(modalStateAtom);

  const handleImportModalOpen = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: UserPositionForm,
      props: {
        dataTableName: 'user-positions',
        positionToEdit,
      }
    });
  };

  return (
    <Stack width='100%' height='100%' justifyContent='center' alignItems='center'>
      <EditOutlinedIcon
        sx={{ color: '#9CA3AF', cursor: 'pointer' }}
        onClick={handleImportModalOpen}
      />
    </Stack>
  );
};

export default UserPositionForm;