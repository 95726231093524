import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import variables from 'styles/variables';
import { useNavigate } from 'react-router-dom';

const HelpCentreCTA = () => {
  const navigate = useNavigate();

  return (
    <Stack
      width='100%'
      gap='24px'
      padding='32px'
      borderRadius='12px'
      boxShadow='0px 0px 24px -4px rgba(48, 29, 68, 0.08), 0px 0px 4px -2px rgba(48, 29, 68, 0.03)'
      sx={{
        backgroundColor: 'white',
        backdropFilter: 'blur(5px)'
      }}
    >
      <Stack gap='8px'>
        <Typography variant='subtitle1' fontSize='18px'>
          Need help figuring things out?
        </Typography>
        <Typography variant='body1' color={variables.colors.text.secondary}>
          Navigate through our knowledge base articles to find answers to your questions.
        </Typography>
      </Stack>
      <Button variant='contained' color='secondary' endIcon={<ArrowForwardOutlinedIcon />} sx={{ width: 'fit-content' }} onClick={() => navigate('/help-centre/help-articles')}>
        Help Centre
      </Button>
    </Stack>
  );
};

export default HelpCentreCTA;