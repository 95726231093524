import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import variables from 'styles/variables';
import { Typography } from '@mui/material';
import EditIsActive from './EditIsActive';
import EditIcon from './EditIcon';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { ParticipantAlert } from 'types/dbSchema/alerts';
import DeleteIcon from './DeleteIcon';

export const Columns: GridColDef[] = [
  {
    field: 'edit',
    headerName: '',
    width: 50,
    sortable: false,
    renderCell: (params) => (
      <EditIcon alertToEdit={params.row} />
    ),
  },
  {
    field: 'delete',
    headerName: '',
    width: 50,
    sortable: false,
    renderCell: (params) => (
      <DeleteIcon alertToEdit={params.row} />
    ),
  },
  {
    field: 'content',
    headerName: 'Alerts',
    flex: 1,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {params.row.content}
      </Typography>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => <EditIsActive alertToEdit={params.row} />
  },
  {
    field: 'displayLocationParticipantProfile',
    headerName: 'Participant Profile',
    width: 150,
    renderCell: (params) => (
      (params.row as ParticipantAlert).displayLocations.includes('Participant Profile page') ?
        <CheckBoxIcon sx={{ color: variables.colors.icon.standard }} /> :
        <CheckBoxOutlineBlankIcon sx={{ color: variables.colors.icon.standard }} />
    )
  },
  {
    field: 'displayLocationServiceAllocation',
    headerName: 'Service Allocation',
    width: 150,
    renderCell: (params) => (
      (params.row as ParticipantAlert).displayLocations.includes('Service Allocation page') ?
        <CheckBoxIcon sx={{ color: variables.colors.icon.standard }} /> :
        <CheckBoxOutlineBlankIcon sx={{ color: variables.colors.icon.standard }} />
    )
  },
  {
    field: 'displayLocationScheduleOfSupports',
    headerName: 'Schedule of Supports',
    width: 150,
    renderCell: (params) => (
      (params.row as ParticipantAlert).displayLocations.includes('Schedule of Supports page') ?
        <CheckBoxIcon sx={{ color: variables.colors.icon.standard }} /> :
        <CheckBoxOutlineBlankIcon sx={{ color: variables.colors.icon.standard }} />
    )
  },
  {
    field: 'displayLocationSkymoMobileApp',
    headerName: 'Mobile App',
    width: 150,
    renderCell: (params) => (
      (params.row as ParticipantAlert).displayLocations.includes('Skymo mobile app') ?
        <CheckBoxIcon sx={{ color: variables.colors.icon.standard }} /> :
        <CheckBoxOutlineBlankIcon sx={{ color: variables.colors.icon.standard }} />
    )
  },
];