import { userMessageAtom } from 'atoms';
import { useAtom } from 'jotai';

const useErrorMessage = () => {
  const [, setUserMessage] = useAtom(userMessageAtom);

  const showMessage = (message: string) => {
    setUserMessage({
      message,
      title: "An error has occurred.",
      variant: "error",
      open: true,
      autoHideDuration: 10000, // 10 seconds
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    });
  };

  return showMessage;
};

export default useErrorMessage;