import React, { FC } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { Button } from '@mui/material';
import UserPositionForm from 'components/features/Forms/UserPositionForm';

const Actions: FC = () => {
  const [modalState, setModalState] = useAtom(modalStateAtom);

  const handleModalOpen = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: UserPositionForm,
      props: {
        dataTableName: 'user-positions',
        type: 'create',
      }
    });
  };

  return (
    <Button color='primary' variant='contained' onClick={() => handleModalOpen()} startIcon={<AddOutlinedIcon />}>
      Add
    </Button>
  );
};

export default Actions;