import React from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@mui/material';

// Utility function to convert kebab-case to Title Case
const toTitleCase = (str: string) => {
  return str
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const CustomBreadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {pathnames.map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
        return (
          <Typography variant='body2' key={to} sx={{ color: 'rgba(0,0,0,0.6)'}}>
            {toTitleCase(value)}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;