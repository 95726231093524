import React, { FC } from 'react';
import variables from 'styles/variables';
import styled from 'styled-components';
import Topbar from './Topbar';
import Sidebar from './Sidebar';
import FullScreenContainer from 'components/common/FullScreenContainer';
import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { SidebarAsideProps } from './types';
import { useAtom } from 'jotai';
import { sidebarAtom } from 'atoms/sidebarAtom';

const Layout: FC = () => {
  const [sidebarState] = useAtom(sidebarAtom);

  return (
    <FullScreenContainer>
      <Topbar withSearchbar />
      <Stack flexDirection='row'>
        <Sidebar />
        <StyledMain sidebarOpen={sidebarState.isOpen}>
          <Outlet />
        </StyledMain>
      </Stack>
    </FullScreenContainer>
  );
};

const StyledMain = styled.main.withConfig({
  shouldForwardProp: (prop) => prop !== 'sidebarOpen',
}) <SidebarAsideProps>`
  transition: width 0.3s ease;
  background: #F3F4F6;
  height: calc(100vh - ${variables.heights.topBar});
  width: ${({ sidebarOpen }) => (sidebarOpen ? `calc(100% - ${variables.widths.sidebarOpen})` : `calc(100% - ${variables.widths.sidebarClosed})`)};
`;

export default Layout;