import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import variables from 'styles/variables';
import { Typography } from '@mui/material';
import ViewIcon from './ViewIcon';
import { formatISODate } from 'utils/helpers';

export const Columns: GridColDef[] = [
  {
    field: 'view',
    headerName: '',
    width: 50,
    sortable: false,
    renderCell: (params) => (
      <ViewIcon serviceContractToView={params.row} />
    ),
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2'>
        {params.row.type}
      </Typography>
    ),
  },
  {
    field: 'note',
    headerName: 'Note',
    minWidth: 200,
    flex: 1,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {params.row.type}
      </Typography>
    ),
  },
  {
    field: 'createdBy',
    headerName: 'Created by',
    width: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary}>
        {formatISODate(params.row.startDate)}
      </Typography>
    ),
  },
  {
    field: 'createdDate',
    headerName: 'Created date',
    width: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary}>
        {formatISODate(params.row.createdDate)}
      </Typography>
    ),
  },
];