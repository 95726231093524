import React, { FC } from 'react';
import { useCallback } from "react";
import { useAtom } from "jotai";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { modalStateAtom } from "atoms";
import { Button, Stack } from "@mui/material";
import variables from 'styles/variables';
import { ParticipantAlert } from 'types/dbSchema/alerts';
import { deleteAlertById } from 'api/organisations/participants/alerts';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useParams } from 'react-router-dom';

export interface DeleteIconProps {
  alertToEdit: ParticipantAlert;
}

const DeleteIcon: FC<DeleteIconProps> = ({ alertToEdit }) => {
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();
  const [organisations] = useUserOrganisations();
  const { id } = useParams();

  const handleDelete = async () => {
    try {
      await deleteAlertById(organisations[0].organisation.globalId, id as string, alertToEdit.id);
      setInvalidateData((prev) => ({ ...prev, 'participant-alerts': true }));
    } catch (error) {
      showError('Failed to delete participant alert');
    }
  };

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleDelete}>
      <DeleteOutlinedIcon sx={{ color: '#C62828' }} />
    </Stack>
  );
};

export default DeleteIcon;