import { atom } from 'jotai';
import { TSidebarTypeAtom } from 'atoms/types';

export interface Sidebar {
  isOpen: boolean;
  type: TSidebarTypeAtom;
  content: JSX.Element | null;
}
const isSettingsPage = location.pathname.includes('/settings');

export const sidebarAtom = atom<Sidebar>({
  isOpen: true,
  type: isSettingsPage ? 'settings' : 'main',
  content: null,
});