import React, { FC } from 'react';
import { Typography } from '@mui/material';
import variables from 'styles/variables';
import styled from 'styled-components';

export interface CurrentStepIndicatorProps {
  currentStep: number;
}

const CurrentStepIndicator: FC<CurrentStepIndicatorProps> = ({ currentStep }) => {
  return (
    <StyledTypography variant='subtitle1' data-testid='current-step-indicator'>
      Step {currentStep + 1} of 4
    </StyledTypography>
  );
};

const StyledTypography = styled(Typography)`
  display: none;
  color: ${variables.colors.primary.main};
  @media (max-width: 620px) {
    display: block;
  }
`;

export default CurrentStepIndicator;