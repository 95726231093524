import React, { useState } from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import { permissions } from 'types/dbSchema/permissions';
import { Box, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import variables from 'styles/variables';
import { useNavigate } from 'react-router-dom';
import { useHasPermission } from 'hooks/useHasPermission';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import OutboundOutlinedIcon from '@mui/icons-material/OutboundOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

const QuickLinks = () => {
  const navigate = useNavigate();
  const filteredQuickLinks = quickLinks.filter((item) => useHasPermission(item.requiredPermissions));

  return (
    <StyledBox
      width='100%'
      borderRadius='12px'
      border='1px solid #E5E7EB'
      boxShadow='0px 0px 24px -4px rgba(48, 29, 68, 0.08), 0px 0px 4px -2px rgba(48, 29, 68, 0.03)'
      sx={{
        backdropFilter: 'blur(5px)'
      }}
    >
      {filteredQuickLinks.map((link, index) => {
        const Icon = link.icon;
        return (
          <Item gap='8px' onClick={() => navigate(link.link)} key={index} className='box'>
            <Icon sx={{ color: link.iconColor, height: '40px', width: '40px', backgroundColor: link.iconBgColor, borderRadius: '8px', padding: '8px' }} />
            <Stack gap='8px'>
              <Box>
                <Typography variant='subtitle1' fontSize='18px'>
                  {link.title}
                </Typography>
              </Box>
              <Box>
                <Typography variant='body1' color={variables.colors.text.secondary}>
                  {link.description}
                </Typography>
              </Box>
            </Stack>
          </Item>
        );
      })}
    </StyledBox>
  );
};

const Item = styled(Stack)`
  position: relative;
  height: 100%;
  padding: 24px;
  background-color: white;
  display: flex;
  cursor: pointer;
  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: #E5E7EB;
    z-index: 1;
    content: '';
    position: absolute;
    background-color: #E5E7EB;
    z-index: 1;
  }
  &::after {
    inline-size: 100vw;
    block-size: 1px;
    inset-inline-start: 0;
    inset-block-start: -1px;
 }
  &::before {
    inline-size: 1px;
    block-size: 100vh;
    inset-block-start: 0;
    inset-inline-start: -1px;
  }
  &:hover {
    background-color: ${variables.colors.lightNeutral.subtle};
  }
  &:focus-visible {
    outline: none;
    z-index: 1000;
  }
  &:focus {
    outline: none;
    z-index: 1000;
    padding: 23px;
    background-color: ${variables.colors.lightNeutral.subtle};
  }
`;

const StyledBox = styled(Box)`
  overflow: hidden;
  display: grid;
  grid-template-columns: 2fr 2fr;
  & :last-child:nth-last-child(odd) {
    grid-column: auto / span 2;
    z-index: 1000; 
    &::before {
      display: none;
    }
  }
  & :first-child:nth-last-child(even),
  & :first-child:nth-last-child(even) ~ .box {
    grid-column: auto / span 1;
  }
`;

export interface QuickLink {
  title: string;
  description: string;
  icon: any;
  iconColor: string;
  iconBgColor: string;
  link: string;
  requiredPermissions: string[];
}

const quickLinks = [
  {
    title: 'Create Participants',
    description: 'Add new participants and customize their profiles by entering detailed information.',
    icon: PersonAddAlt1OutlinedIcon,
    iconColor: '#AC4FC6',
    iconBgColor: 'rgba(172, 79, 198, 0.10)',
    link: '/participant-centre/participants/create',
    requiredPermissions: [
      permissions.VIEW_PARTICIPANT_CENTRE,
      permissions.VIEW_PARTICIPANTS,
      permissions.CREATE_PARTICIPANTS
    ],
  },
  {
    title: 'Manage Users',
    description: 'Add, edit, or deactivate users in your organisation through the settings tab, while managing their details and system access.',
    icon: BadgeOutlinedIcon,
    iconColor: '#AC4FC6',
    iconBgColor: '#AC4FC61A',
    link: '/settings/user-management/user-profiles',
    requiredPermissions: [
      permissions.VIEW_USER_MANAGEMENT_SETTINGS,
      permissions.VIEW_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      permissions.CREATE_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      permissions.MODIFY_USER_MANAGEMENT_SETTINGS_USER_PROFILES
    ],
  },
  {
    title: 'Manage Organisation Settings',
    description: 'Update your organisation\'s details and payment information.',
    icon: CorporateFareOutlinedIcon,
    iconColor: '#7E57C2',
    iconBgColor: '#7E57C21A',
    link: '/settings/organisation/organisation-settings',
    requiredPermissions: [
      permissions.VIEW_ORGANISATION_DETAILS,
      permissions.MODIFY_ORGANISATION_DETAILS
    ],
  },
  {
    title: 'Release Shifts',
    description: 'Open the shift release page to make shifts available for reassignment and track unreleased shifts.',
    icon: OutboundOutlinedIcon,
    iconColor: '#AC4FC6',
    iconBgColor: '#AC4FC61A',
    link: '/scheduling/shift-releases',
    requiredPermissions: [
      permissions.VIEW_SCHEDULING,
      permissions.RELEASE_SHIFTS,
      permissions.VIEW_SHIFT_RELEASE
    ],
  },
  {
    title: 'Manage Participants',
    description: 'Access the participant list to update records, track progress notes and goals, manage alerts. Keep participant details and contacts up to date.',
    icon: ManageAccountsOutlinedIcon,
    iconColor: '#2196F3',
    iconBgColor: '#2196F31A',
    link: '/participant-centre/participants',
    requiredPermissions: [
      permissions.VIEW_PARTICIPANT_CENTRE,
      permissions.VIEW_PARTICIPANTS,
      permissions.CREATE_PARTICIPANTS,
      permissions.CREATE_PARTICIPANT_DETAILS,
      permissions.MODIFY_PARTICIPANT_DETAILS
    ]
  },
  {
    title: 'Manage Participant Claims',
    description: 'Review submitted NDIS claims for participants and ensure they align with service provisions.',
    icon: DescriptionOutlinedIcon,
    iconColor: '#AC4FC6',
    iconBgColor: '#AC4FC61A',
    link: '/billing/service-ndis-claims',
    requiredPermissions: [
      permissions.VIEW_BILLING,
      permissions.VIEW_SERVICE_NDIS_CLAIMS,
      permissions.CREATE_SERVICE_NDIS_CLAIMS,
      permissions.MODIFY_SERVICE_NDIS_CLAIMS,
    ],
  },
  {
    title: 'Check Unallocated Services',
    description: 'View unassigned Services and distribute them based on team availability and skillsets.',
    icon: CircleOutlinedIcon,
    iconColor: '#DA3F43',
    iconBgColor: '#DA3F431A',
    link: '/scheduling/unallocated-services',
    requiredPermissions: [
      permissions.VIEW_SCHEDULING,
      permissions.VIEW_UNALLOCATED_SERVICES,
    ],
  },
  {
    title: 'Create Schedules',
    description: 'Create a new schedule of supports for participants and allocate services.',
    icon: EventOutlinedIcon,
    iconColor: '#2196F3',
    iconBgColor: '#2196F31A',
    link: '/scheduling/scheduling-supports',
    requiredPermissions: [
      permissions.VIEW_SCHEDULING,
      permissions.VIEW_SCHEDULING_SUPPORT,
      permissions.CREATE_SCHEDULING_SUPPORT,
    ],
  },
  {
    title: 'Manage Participant Invoicing',
    description: 'Access the invoice batch view to manage participant invoicing efficiently.',
    icon: ReceiptLongOutlinedIcon,
    iconColor: '#2196F3',
    iconBgColor: '#2196F31A',
    link: '/billing/service-invoices',
    requiredPermissions: [
      permissions.VIEW_BILLING,
      permissions.VIEW_SERVICE_INVOICE,
      permissions.CREATE_SERVICE_INVOICE,
      permissions.MODIFY_SERVICE_INVOICE,
    ],
  },
  {
    title: 'Manage Schedules',
    description: 'View and manage participant schedules by adding services, and tracking budget allocations.',
    icon: EventOutlinedIcon,
    iconColor: '#00ACC1',
    iconBgColor: '#00ACC11A',
    link: '/scheduling/participation-schedules',
    requiredPermissions: [
      permissions.VIEW_SCHEDULING,
      permissions.VIEW_SCHEDULING_SUPPORT,
      permissions.CREATE_SCHEDULING_SUPPORT,
      permissions.MODIFY_SCHEDULING_SUPPORT,
    ],
  },
  {
    title: 'Manage Payroll',
    description: 'View payroll details through the Payfile, reviewing payments, and managing staff compensation.',
    icon: PaidOutlinedIcon,
    iconColor: '#00ACC1',
    iconBgColor: '#00ACC11A',
    link: '/billing/payroll',
    requiredPermissions: [
      permissions.VIEW_BILLING,
      permissions.VIEW_PAYROLL,
      permissions.CREATE_PAYROLL,
      permissions.MODIFY_PAYROLL,
    ],
  },
  {
    title: 'Manage Service Team',
    description: 'Oversee the service team\'s calendar, assign shifts, update availability, manage leave, and handle team members\' skills and qualifications.',
    icon: PeopleAltOutlinedIcon,
    iconColor: '#FF8A8A',
    iconBgColor: '#FF8A8A1A',
    link: '/scheduling/service-team',
    requiredPermissions: [
      permissions.VIEW_SCHEDULING,
      permissions.VIEW_SERVICE_TEAM,
    ],
  },
  {
    title: 'Check a Support Code',
    description: 'Browse the Products and Services section to search for and review support codes as needed.',
    icon: PeopleAltOutlinedIcon,
    iconColor: '#336666',
    iconBgColor: '#3366661A',
    link: '/resources/products-services',
    requiredPermissions: [
      permissions.VIEW_RESOURCES,
      permissions.VIEW_PRODUCTS_SERVICES,
    ],
  },
  {
    title: 'Change Settings',
    description: 'Tailor your organisation\'s global settings to fit your operational needs, impacting all system sections.',
    icon: SettingsOutlinedIcon,
    iconColor: '#0057B2',
    iconBgColor: '#0057B21A',
    link: '/settings/scheduling-settings',
    requiredPermissions: [
      permissions.VIEW_USER_MANAGEMENT_SETTINGS,
      permissions.VIEW_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      permissions.CREATE_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      permissions.MODIFY_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      permissions.DELETE_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      permissions.VIEW_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      permissions.CREATE_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      permissions.MODIFY_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      permissions.DELETE_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      permissions.VIEW_USER_MANAGEMENT_SETTINGS_USER_ROLES,
      permissions.VIEW_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      permissions.CREATE_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      permissions.MODIFY_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      permissions.DELETE_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      permissions.VIEW_PARTICIPANT_SETTINGS,
      permissions.VIEW_PARTICIPANT_SETTINGS_STATUS,
      permissions.VIEW_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      permissions.VIEW_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      permissions.VIEW_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      permissions.VIEW_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      permissions.VIEW_PARTICIPANT_SETTINGS_EXIT_REASON,
      permissions.CREATE_PARTICIPANT_SETTINGS_STATUS,
      permissions.CREATE_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      permissions.CREATE_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      permissions.CREATE_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      permissions.CREATE_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      permissions.CREATE_PARTICIPANT_SETTINGS_EXIT_REASON,
      permissions.MODIFY_PARTICIPANT_SETTINGS_STATUS,
      permissions.MODIFY_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      permissions.MODIFY_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      permissions.MODIFY_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      permissions.MODIFY_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      permissions.MODIFY_PARTICIPANT_SETTINGS_EXIT_REASON,
      permissions.DELETE_PARTICIPANT_SETTINGS_STATUS,
      permissions.DELETE_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      permissions.DELETE_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      permissions.DELETE_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      permissions.DELETE_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      permissions.DELETE_PARTICIPANT_SETTINGS_EXIT_REASON,
      permissions.VIEW_SCHEDULE_SETTINGS,
      permissions.VIEW_SCHEDULE_SETTINGS_SERVICE_TYPES,
      permissions.VIEW_SCHEDULE_SETTINGS_CANCELLATION_CODES,
      permissions.VIEW_SCHEDULE_SETTINGS_SHIFT_LOCATIONS,
      permissions.CREATE_SCHEDULE_SETTINGS_SERVICE_TYPES,
      permissions.CREATE_SCHEDULE_SETTINGS_CANCELLATION_CODES,
      permissions.CREATE_SCHEDULE_SETTINGS_SHIFT_LOCATIONS,
      permissions.MODIFY_SCHEDULE_SETTINGS_SERVICE_TYPES,
      permissions.MODIFY_SCHEDULE_SETTINGS_CANCELLATION_CODES,
      permissions.MODIFY_SCHEDULE_SETTINGS_SHIFT_LOCATIONS,
      permissions.VIEW_SERVICE_TEAM_SETTINGS,
      permissions.VIEW_SERVICE_TEAM_SETTINGS_PAY_PERIODS,
      permissions.VIEW_SERVICE_TEAM_SETTINGS_TAGS,
      permissions.VIEW_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      permissions.VIEW_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      permissions.VIEW_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      permissions.CREATE_SERVICE_TEAM_SETTINGS_TAGS,
      permissions.CREATE_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      permissions.CREATE_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      permissions.CREATE_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      permissions.MODIFY_SERVICE_TEAM_SETTINGS_PAY_PERIODS,
      permissions.MODIFY_SERVICE_TEAM_SETTINGS_TAGS,
      permissions.MODIFY_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      permissions.MODIFY_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      permissions.MODIFY_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      permissions.DELETE_SERVICE_TEAM_SETTINGS_TAGS,
      permissions.DELETE_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      permissions.DELETE_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      permissions.DELETE_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      permissions.VIEW_SERVICE_PROVIDER_SETTINGS,
      permissions.VIEW_SERVICE_PROVIDER_SETTINGS_POSITIONS,
      permissions.VIEW_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES,
      permissions.CREATE_SERVICE_PROVIDER_SETTINGS_POSITIONS,
      permissions.CREATE_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES,
      permissions.MODIFY_SERVICE_PROVIDER_SETTINGS_POSITIONS,
      permissions.MODIFY_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES,
      permissions.VIEW_AVAILABILITY_SETTINGS,
      permissions.VIEW_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      permissions.VIEW_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      permissions.CREATE_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      permissions.CREATE_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      permissions.MODIFY_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      permissions.MODIFY_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      permissions.DELETE_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      permissions.DELETE_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      permissions.VIEW_NOTIFICATION_SETTINGS,
      permissions.CREATE_NOTIFICATION_SETTINGS,
      permissions.MODIFY_NOTIFICATION_SETTINGS,
      permissions.DELETE_NOTIFICATION_SETTINGS,
      permissions.VIEW_INTEGRATION_SETTINGS,
      permissions.CREATE_INTEGRATION_SETTINGS,
      permissions.MODIFY_INTEGRATION_SETTINGS,
      permissions.DELETE_INTEGRATION_SETTINGS,
    ],
  },
] as QuickLink[];

export default QuickLinks;