import React, { FC, createElement } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import variables from 'styles/variables';
import { kebabFormat } from 'utils/helpers';
import { useAtom } from 'jotai';
import { SidebarItemProps, StyledTitleItemProps } from '../../types';
import { sidebarAtom } from 'atoms/sidebarAtom';

const SidebarItem: FC<SidebarItemProps> = ({ label, icon, to }) => {
  const [sidebarState] = useAtom(sidebarAtom);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isActive = pathname === to;

  const handleNavigate = (to: string) => {
    navigate(to);
  };

  return (
    <StyledList data-testid={`sidebarItem-${kebabFormat(label)}`}>
      <StyledTitleItem onClick={() => handleNavigate(to)} isActive={isActive}>
        <StyledListItemIcon>
          {createElement(icon)}
        </StyledListItemIcon>
        {sidebarState.isOpen &&
          <TitleItemText>
            <Typography variant='subtitle2' color='white' fontWeight='600'>
              {label}
            </Typography>
          </TitleItemText>
        }
      </StyledTitleItem>
    </StyledList>
  );
};

const StyledList = styled(List)`
  width: 100%;
  padding: 0;
  gap: 8px;
`;

const StyledTitleItem = styled(ListItem).withConfig({
  shouldForwardProp: (prop) => prop !== 'isActive',
}) <StyledTitleItemProps>`
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  user-select: none;
  height: 46px;
  background-color: ${({ isActive }) => isActive && variables.colors.primary.main30};
  &:hover {
    background-color: ${({ isActive }) => !isActive && variables.colors.primary.main20};
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: unset;
  width: 20px;
  display: block;
  & > svg {
    color: ${variables.colors.secondary.lighter};
    width: 100%;
  }
`;

const TitleItemText = styled(ListItemText)`
  padding: 4px 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default SidebarItem;