import React, { useState, useRef, forwardRef, useEffect, useImperativeHandle } from 'react';
import { Stack, Typography } from '@mui/material';
import variables from 'styles/variables';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchInput from '../SearchInput';
import { ColorSelectInputProps, ColorSelectInputRef, StyledColorSelectInputProps } from './types';
import ColorDisplayBox from 'components/common/ColorDisplayBox';

const ColorSelectInput = forwardRef<ColorSelectInputRef, ColorSelectInputProps>(({
  label,
  id,
  error,
  errorText,
  helperText,
  filterOut, // The filterOut array is a list of values (hex codes) that should be filtered out of the options list
  placeholder,
  colorList,
  onChange,
  isMandatory,
  value: controlledValue,
  ...props }, ref) => {

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filterOptions, setFilteredOptions] = useState(colorList);
  const internalRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // This is used to filter the options based on the search value
  useEffect(() => {
    // Filter options based on search value or return all options if searchValue is empty
    let filtered = searchValue ? colorList.filter((option) => option.label.toLowerCase() === searchValue.toLowerCase()) : colorList;
    // Filter out any options that are in the filterOut array
    filtered = filterOut ? filtered.filter((option) => !filterOut.includes(option.value)) : filtered;
    setFilteredOptions(filtered);
  }, [searchValue, filterOut]);

  // Expose functions to parent component, essentially combining the two refs (internalRef and the ref passed to the component from react-hook-form)
  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
    getDOMNode: () => internalRef.current,
  }));

  useEffect(() => {
    // Function to check if the click is outside the component
    const handleClickOutside = (event: MouseEvent) => {
      if (internalRef.current && !internalRef.current.contains(event.target as Node) &&
        dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);
    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [internalRef]);

  const handleOptionClick = (optionValue: string) => {
    onChange(optionValue);
    setIsOpen(false);
  };

  const selectedOptionLabel = filterOptions.find((option) => option.value === controlledValue)?.label || placeholder;

  return (
    <div ref={internalRef} style={{ margin: '0', position: 'relative', width: '100%' }} {...props}>
      {label && (
        <label htmlFor={id}>
          <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
            {label} {isMandatory && <span style={{ color: 'red' }}>*</span>}
          </Typography>
        </label>
      )}
      <StyledColorSelectInput error={error} onClick={() => setIsOpen(!isOpen)} isOpen={isOpen} tabIndex={0}>
        {selectedOptionLabel}
        <KeyboardArrowDownIcon sx={{ transform: isOpen ? 'rotate(180deg)' : '' }} />
        {isOpen &&
          <Dropdown ref={dropdownRef}>
            <SearchBox onClick={(e) => e.stopPropagation()}>
              <SearchInput placeholderText='Search' searchValue={searchValue} setSearchValue={setSearchValue} />
            </SearchBox>
            <Stack maxHeight='200px' overflow='auto' padding='0 14px'>
              {filterOptions.map((option, index) => (
                <ColorOption key={index} label={option.label} value={option.value} onClick={() => handleOptionClick(option.value)} />
              ))}
            </Stack>
          </Dropdown>
        }
      </StyledColorSelectInput>
      {(error && errorText) ? <Typography variant='subtitle2' color='red' fontWeight='400'>{errorText}</Typography>
        : helperText && <Typography variant='subtitle2' color={variables.colors.text.secondary} fontWeight='400'>{helperText}</Typography>
      }
    </div >
  );
});

const ColorOption = ({ value, label, onClick }: { value: string, label: string, onClick: () => void }) => (
  <Stack flexDirection='row' onClick={onClick} sx={{ cursor: 'pointer', minHeight: '36px', justifyContent: 'flex-start', alignItems: 'center', gap: '4px' }}>
    <ColorDisplayBox color={value} />
    <Typography variant='body2'>
      {label}
    </Typography>
  </Stack>
);

const StyledColorSelectInput = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'error' && prop !== 'isOpen',
}) <StyledColorSelectInputProps>`
  box-sizing: border-box;
  border-radius: 4px;
  border: ${({ error, isOpen }) => error ? '1px solid red' : isOpen ? `2px solid ${variables.colors.primary.darker}` : '1px solid #ccc'};
  padding: ${({ isOpen }) => isOpen ? '0 9px' : '0 10px'}; // Adjust padding to account for the increased border width - for some reason the box-sizing property doesn't work here 🤷‍♂️
  width: 100%;
  position: relative;
  cursor: pointer;  
  height: 42px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  font-family: ${variables.fonts.family.primary};
  font-size: 14px;
  &:focus-visible {
    border: 2px solid #5C068C !important;
    outline: none;
    padding: 0 9px;
  }
`;

const Dropdown = styled(Stack)`
  z-index: 1000;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  gap: 8px;
  position: absolute;
  top: 40px;
  left: 0;
  background-color: white;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 3px 8px -1px #E0E0E0;
  border-radius: 4px;
`;

const SearchBox = styled.div`
  border-bottom: 1px solid #E0E0E0;
  background-color: white;
  min-width: 100%;
  & * {
    border: none;
    width: 100%;
  }
  .MuiInputAdornment-positionEnd {
    width: unset;
  }
`;

ColorSelectInput.displayName = 'ColorSelectInput';

export default ColorSelectInput;