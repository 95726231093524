import React, { FC } from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { currentDataPointAtom } from 'atoms';
import variables from 'styles/variables';
import { ExternalContact } from 'types/dbSchema/externalContacts';

export interface ViewIconProps {
  externalContact: ExternalContact;
}

const ViewIcon: FC<ViewIconProps> = ({ externalContact }) => {
  const navigate = useNavigate();

  const handleViewContact = () => {
    navigate(`/participant-centre/contacts-console/view/${externalContact.id}`);
  };

  return (
    <StyledBox onClick={handleViewContact}>
      <RemoveRedEyeOutlinedIcon sx={{ color: variables.colors.icon.standard }} />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export default ViewIcon;