import React, { FC, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import variables from 'styles/variables';
import { ChangeLogData } from './types';
import { formatISODateTime } from 'utils/helpers';

const ChangeLog: FC<{ changeLogData: ChangeLogData[] }> = ({ changeLogData }) => {
  const [isChangeLogOpen, setIsChangeLogOpen] = useState<boolean>(true);

  return (
    <ChangeLogStack>
      <ChangeLogTitleStack isChangeLogOpen={isChangeLogOpen} onClick={() => setIsChangeLogOpen(!isChangeLogOpen)} data-testid='change-log-title'>
        <KeyboardArrowDownOutlinedIcon sx={{ transform: isChangeLogOpen ? 'none' : 'rotate(-90deg)' }} />
        <Typography variant='h6'>
          Change Log
        </Typography>
      </ChangeLogTitleStack>
      <ChangeLogDetails changeLogData={changeLogData} isChangeLogOpen={isChangeLogOpen} />
    </ChangeLogStack>
  );
};

const ChangeLogDetails: FC<{ changeLogData: ChangeLogData[], isChangeLogOpen: boolean }> = ({ changeLogData, isChangeLogOpen }) => {
  return (
    <ChangeLogDetailsStack isChangeLogOpen={isChangeLogOpen} data-testid='change-log-details'>
      <Stack
        gap='16px'
        width='100%'
        flexDirection='row'
        padding='16px 24px'
        sx={{
          backgroundColor: '#F9FAFB',
          boxSizing: 'border-box',
          borderRadius: '8px 8px 0 0',
          borderBottom: '1px solid #E5E7EB'
        }}
      >
        <Box width='200px'>
          <Typography variant='subtitle2'>
            Date and Time
          </Typography>
        </Box>
        <Box width='100%'>
          <Typography variant='subtitle2'>
            Details
          </Typography>
        </Box>
      </Stack>
      {changeLogData?.map((changeLog, index) => (
        <Stack
          gap='16px'
          width='100%'
          flexDirection='row'
          padding='16px 24px'
          key={index}
          sx={{
            boxSizing: 'border-box',
            borderBottom: index === changeLogData.length - 2 ? '1px solid #E5E7EB' : 'none'
          }}
        >
          <Stack flexDirection='row' width='200px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              {changeLog?.date && formatISODateTime(changeLog.date)}
            </Typography>
          </Stack>
          <Box width='100%'>
            <Typography variant='body2'>
              {changeLog.details}
            </Typography>
          </Box>
        </Stack>
      ))}
    </ChangeLogDetailsStack>
  );
};

const ChangeLogStack = styled(Stack)`
  display: flex;
  padding: 24px 16px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  height: fit-content;
  overflow: hidden;
`;

const ChangeLogTitleStack = styled(Stack).withConfig({
  shouldForwardProp: (prop) => !['isChangeLogOpen'].includes(prop),
}) <ChangeLogDetailsStackProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
  cursor: pointer;
  margin-bottom: 16px;
`;

export interface ChangeLogDetailsStackProps {
  isChangeLogOpen: boolean;
}

const ChangeLogDetailsStack = styled(Stack).withConfig({
  shouldForwardProp: (prop) => !['isChangeLogOpen'].includes(prop),
}) <ChangeLogDetailsStackProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-color: #E0E0E0;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  min-width: fit-content;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  transition: max-height 480ms, margin-bottom 340ms;
  max-height: ${props => props.isChangeLogOpen ? '300px' : '0px'};
  margin-bottom: ${props => props.isChangeLogOpen ? '16px' : '-2px'};
`;
export default ChangeLog;