import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import DataTableControls from './Controls';
import DataTable from 'components/common/DataTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { GridRowId } from '@mui/x-data-grid';
import { Columns } from './Controls/Columns';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { Data, PaginationModel } from 'components/common/DataTable/types';
import GeneralNoteForm from 'components/features/Forms/GeneralNoteForm';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { useParams } from 'react-router-dom';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { listGeneralNotes } from 'api/organisations/participants/generalnotes';
import useInvalidateData from 'hooks/useInvalidateData';

const GeneralNotesDataTable: FC = () => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({ page: 0, pageSize: 20 });
  const [, setModalState] = useAtom(modalStateAtom);
  const [organisations] = useUserOrganisations();
  const { id } = useParams();
  useInvalidateData('general-notes');

  const handleModalOpen = () => {
    setModalState((prevState) => ({
      ...prevState,
      status: 'open',
      position: 'right',
      component: GeneralNoteForm,
      disableClickAway: true,
      props: {
        dataTableName: 'general-notes',
        type: 'create',
        participantId: id,
      }
    }));
  };

  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: ['general-notes', paginationModel, selectedFilters],
    queryFn: () =>
      listGeneralNotes(
        organisations[0].organisation.globalId,
        id as string,
        selectedFilters,
        paginationModel.page + 1,
        paginationModel.pageSize
      ),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    // enabled: organisations.length > 0,
    staleTime: cachingInvalidation.generalNotes,
  });

  return (
    <Stack height='100%' width='100%' sx={{ backgroundColor: 'white' }}>
      <DataTableControls
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        searchText={searchText}
        setSearchText={setSearchText}
        selectedRowIds={selectedRowIds}
      />
      <DataTable
        data={data as Data}
        columns={Columns}
        setSelectedRowIds={setSelectedRowIds}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isPending={isPending || isFetching}
        isError={isError}
        title='General Notes'
        dataTableName='general-notes'
        checkboxSelection={false}
        paginationMode='server'
        onNoData={handleModalOpen}
      />
    </Stack>
  );
};

export default GeneralNotesDataTable;