import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import variables from 'styles/variables';
import { formatISODate } from 'utils/helpers';
import ChangeLog from 'components/common/ChangeLog';
import { NameAndID, User } from 'types/dbSchema/userProfiles';
import Line from 'components/common/Line';
import LineHeader from 'components/common/LineHeader';
import { pronounOptions } from 'types';
import { RelationshipType } from 'types/dbSchema/participantSettings';
import { UserPosition } from 'types/dbSchema/userPositions';

const UserDetails: FC<{ userProfile: User }> = ({ userProfile }) => {

  return (
    <OuterStack>
      <UserDetailsStack>
        <PersonalDetails userProfile={userProfile} />
        <ContactDetails userProfile={userProfile} />
        <NextOfKinDetails userProfile={userProfile} />
        <EmploymentDetails userProfile={userProfile} />
      </UserDetailsStack>
      <ChangeLog changeLogData={userProfile.changeLog} />
    </OuterStack>
  );
};

const PersonalDetails: FC<{ userProfile: User }> = ({ userProfile }) => {
  return (
    <WebAppUserDetailsStack>
      <LineHeader>
        <Typography variant='subtitle1'>
          Personal Details
        </Typography>
      </LineHeader>
      <Line>
        <Stack width='100%'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Name
          </Typography>
          <Stack gap='4px' flexDirection='row'>
            {userProfile?.salutation &&
              <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
                {userProfile.salutation}
              </Typography>
            }
            <Typography variant='body2' color={variables.colors.text.main} fontWeight='600'>
              {userProfile.fullName.firstName} {userProfile.fullName.lastName}
            </Typography>
          </Stack>
        </Stack>
        <Stack width='100%'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Date of Birth
          </Typography>
          <Typography variant='body2'>
            {userProfile?.dob && formatISODate(userProfile.dob)}
          </Typography>
        </Stack>
      </Line>
      <Line noBottomBorder>
        <Stack width='100%'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Gender
          </Typography>
          <Typography variant='body2' textTransform='capitalize'>
            {userProfile.gender}
          </Typography>
        </Stack>
        <Stack width='100%'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Pronoun
          </Typography>
          {userProfile?.pronoun && (
            <Typography variant='body2' textTransform='capitalize'>
              {pronounOptions.find(pronoun => pronoun.value === userProfile.pronoun)?.label}
            </Typography>
          )}
        </Stack>
      </Line>
    </WebAppUserDetailsStack>
  );
};

const ContactDetails: FC<{ userProfile: User }> = ({ userProfile }) => {

  return (
    <WebAppUserDetailsStack>
      <LineHeader>
        <Typography variant='subtitle1'>
          Contact Details
        </Typography>
      </LineHeader>
      <Line>
        <Stack width='100%'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Mobile
          </Typography>
          <Typography variant='body2'>
            {userProfile.primaryMobile}
          </Typography>
        </Stack>
        <Stack width='100%'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Phone
          </Typography>
          <Typography variant='body2'>
            {userProfile?.phone}
          </Typography>
        </Stack>
      </Line>
      <Line>
        <Stack width='100%'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Work Email
          </Typography>
          <Typography variant='body2' sx={{
            overflowWrap: 'anywhere'
          }}>
            {userProfile.workEmail}
          </Typography>
        </Stack>
        <Stack width='100%'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Personal Email
          </Typography>
          <Typography variant='body2' sx={{ overflowWrap: 'anywhere' }}>
            {userProfile.personalEmail}
          </Typography>
        </Stack>
      </Line>
      <Line noBottomBorder>
        <Stack width='100%'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Primary Address
          </Typography>
          <Typography variant='body2' textTransform='capitalize'>
            {userProfile.address?.streetAddress && userProfile.address.streetAddress && `${userProfile.address.streetAddress}, `}
            {userProfile.address?.suburb && userProfile.address.suburb && `${userProfile.address.suburb}, `}
            {userProfile.address?.state && userProfile.address.state && `${userProfile.address.state}, `}
            {userProfile.address?.country && userProfile.address.country && `${userProfile.address.country}, `}
            {userProfile.address?.postCode && userProfile.address.postCode}
          </Typography>
        </Stack>
      </Line>
    </WebAppUserDetailsStack>
  );
};

const NextOfKinDetails: FC<{ userProfile: User }> = ({ userProfile }) => {
  return (
    <WebAppUserDetailsStack>
      <LineHeader>
        <Typography variant='subtitle1'>
          Next of Kin
        </Typography>
      </LineHeader>
      <Line>
        <Stack flexDirection='row' gap='8px' width='100%'>
          <Stack width='100%'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Name
            </Typography>
            <Typography variant='body2'>
              {userProfile.nextOfKinFullName}
            </Typography>
          </Stack>
          <Stack width='100%'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Relationship
            </Typography>
            <Typography variant='body2'>
              {userProfile.nextOfKinRelationship && (userProfile.nextOfKinRelationship as RelationshipType).name}
            </Typography>
          </Stack>
        </Stack>
      </Line>
      <Line noBottomBorder>
        <Stack flexDirection='row' gap='8px' width='100%'>
          <Stack width='100%'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Mobile
            </Typography>
            <Typography variant='body2'>
              {userProfile.nextOfKinMobile}
            </Typography>
          </Stack>
          <Stack width='100%'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Phone
            </Typography>
            <Typography variant='body2'>
              {userProfile.nextOfKinPhone}
            </Typography>
          </Stack>
        </Stack>
      </Line>
    </WebAppUserDetailsStack>
  );
};

const EmploymentDetails: FC<{ userProfile: User }> = ({ userProfile }) => {
  return (
    <WebAppUserDetailsStack>
      <LineHeader>
        <Typography variant='subtitle1'>
          Employment Details
        </Typography>
      </LineHeader>
      <Line>
        <Stack flexDirection='row' gap='8px' width='100%'>
          <Stack width='100%'>
            <Typography variant='body2'>
              Position
            </Typography>
            <Typography variant='subtitle2'>
              {userProfile?.position && (userProfile?.position as UserPosition).name}
            </Typography>
          </Stack>
          <Stack width='100%'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Employment Type
            </Typography>
            <Typography variant='body2'>
              {userProfile?.employeeType}
            </Typography>
          </Stack>
        </Stack>
      </Line>
      <Line>
        <Stack flexDirection='row' gap='8px' width='100%'>
          <Stack width='100%'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Manager
            </Typography>
            <Typography variant='body2'>
              {userProfile?.manager && (userProfile?.manager as NameAndID)?.fullName}
            </Typography>
          </Stack>
          <Stack width='100%'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Service Region
            </Typography>
            <Typography variant='body2'>
              {/* {userProfile.serviceRegion?.name} */}
            </Typography>
          </Stack>
        </Stack>
      </Line>
      <Line noBottomBorder>
        <Stack flexDirection='row' gap='8px' width='100%'>
          <Stack width='100%'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Employee Number
            </Typography>
            <Typography variant='body2'>
              {userProfile.employeeNo}
            </Typography>
          </Stack>
          <Stack width='100%'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Employment Start Date
            </Typography>
            <Typography variant='body2'>
              {userProfile?.employmentStartDate && formatISODate(userProfile.employmentStartDate)}
            </Typography>
          </Stack>
        </Stack>
      </Line>
    </WebAppUserDetailsStack>
  );
};

const OuterStack = styled(Stack)`
  width:100%;
  gap:32px;
  @media (max-width: 1050px) {
    width: 50%;
    min-width: unset;
  }
`;

const UserDetailsStack = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  box-sizing: border-box;
  height: fit-content;
`;

const WebAppUserDetailsStack = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
`;

export default UserDetails;