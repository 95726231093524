import axiosInstance from "api/config";
import { ShiftCancellationReason, ShiftCancellationReasonList } from "types/dbSchema/serviceTeamSettings";

// Returns a list of shift cancellation reasons for the given organisation ID
export const listShiftCancellationReasons = async (organisationId: string, page?: number, pageSize?: number): Promise<ShiftCancellationReasonList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID, page, and page size are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/service-team/shift-cancellation-reasons`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing shift cancellation reasons for the organisation', error);
    throw error;
  }
};

// Returns a shift cancellation reason by ID
export const getShiftCancellationReasonByID = async (organisationId: string, shiftCancellationReasonID: string): Promise<ShiftCancellationReason> => {
  try {
    if (!shiftCancellationReasonID || !organisationId) throw new Error('Organisation ID and ShiftCancellationReason ID are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/service-team/shift-cancellation-reasons/${shiftCancellationReasonID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the shift cancellation reason', error);
    throw error;
  }
};

// Create a new shift cancellation reason
export const createShiftCancellationReason = async (organisationId: string, data: ShiftCancellationReason) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/${organisationId}/settings/service-team/shift-cancellation-reasons`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the shift cancellation reason', error);
    throw error;
  }
};

// Update a shiftCancellationReason
export const updateShiftCancellationReason = async (organisationId: string, data: ShiftCancellationReason) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.put(`/api/${organisationId}/settings/service-team/shift-cancellation-reasons/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the shift cancellation reason', error);
    throw error;
  }
};

// Delete a shift cancellation reason
export const deleteShiftCancellationReason = async (organisationId: string, shiftCancellationReasonID: string) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.delete(`/api/${organisationId}/settings/service-team/shift-cancellation-reasons/${shiftCancellationReasonID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the shift cancellation reason', error);
    throw error;
  }
};