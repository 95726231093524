import { Stack, Box, LinearProgress, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import variables from 'styles/variables';
import styled from 'styled-components';
import SearchInput from 'components/common/InputFields/SearchInput';

const dummyData = [
  'Fernanda Thielen',
  'Stevie Hale',
  'Matt Webb',
  'Madeleine Roberts',
  'Michael Randall',
  'Dean McPherson',
  'Devon Hamon'
];

const GlobalSearchbar = () => {
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchSearchResults = useCallback(async () => {
    setIsLoading(true);
    // TODO: This is a placeholder - Simulate fetching search results
    const results = dummyData.filter(item => item.toLowerCase().includes(searchValue.toLowerCase()));
    setSearchResults(results);
    setIsLoading(false);
  }, [searchValue]);

  useEffect(() => {
    if (searchValue.trim()) {
      fetchSearchResults();
    } else {
      setSearchResults([]);
    }
  }, [searchValue]);

  return (
    <Box sx={{ position: 'relative', height: '42px' }} data-testid='searchbar'>
      <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholderText='Search Participants' />
      {searchValue.length > 0 &&
        <Results>
          {isLoading ?
            <LinearProgress /> : (
              <>
                {searchResults.length > 0 ? (
                  searchResults.map((result, index) => (
                    <IndividualResult key={index}>
                      {result}
                    </IndividualResult>
                  ))
                ) : (
                  <IndividualResult>
                    <Typography>
                      No results found
                    </Typography>
                  </IndividualResult>
                )}
              </>
            )}
        </Results>}
    </Box>
  );
};

const Results = styled(Stack)`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid ${variables.colors.lightNeutral.darker};
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 10;
  overflow-y: auto;
  min-height: 36px;
  height: auto;
  max-height: 150px;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${variables.colors.primary.darker};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${variables.colors.primary.darkest};
  }
`;

const IndividualResult = styled(Box)`
  padding: 8px 16px;
  color: ${variables.colors.text.secondary};
  cursor: pointer;
  &:hover {
    background-color: ${variables.colors.lightNeutral.main};
  }
`;

export default GlobalSearchbar;