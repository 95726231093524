import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { TitleWithCloseProps } from './types';

const TitleWithClose: FC<TitleWithCloseProps> = ({ title, handleClose }) => {
  return (
    <Stack flexDirection='row' justifyContent='space-between' alignItems='center' width='100%' data-testid='title-with-close'>
      <Typography variant="h6">{title}</Typography>
      <CloseOutlinedIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />
    </Stack>
  );
};

export default TitleWithClose;