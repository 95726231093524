import { Button, Stack, Typography } from "@mui/material";
import { closeModalAtom } from "atoms/modalAtom";
import { useAtom } from "jotai";
import React, { FC } from "react";
import variables from "styles/variables";

const LearnMoreModal: FC = () => {
  const [, closeModal] = useAtom(closeModalAtom);

  return (
    <Stack width='500px' boxSizing='border-box'>
      <Stack flexDirection='row' gap='24px' width='100%' padding='32px' boxSizing='border-box'>
        <Stack gap='12px'>
          <Typography variant='h6'>
            Adding availability manually
          </Typography>
          <ul style={{ paddingLeft: '16px', color: variables.colors.text.secondary }}>
            <li>
              <Typography variant='body2'>
                Drag to select the desired time slots, or click on a single slot to open the Update Availability window.
              </Typography>
            </li>
            <li>
              <Typography variant='body2'>
                Day: The day will be prefilled with the day of the selected slot. You can change it if needed, and the slots will adjust accordingly.
              </Typography>
            </li>
            <li>
              <Typography variant='body2'>
                Start and End Times: Prefilled to match the selected slots. You can change this if needed.
              </Typography>
            </li>
            <li>
              <Typography variant='body2'>
                Choose Recurrence: Pick how often this availability repeats: never, weekly, or every 2 weeks. The recurrence will be applied to the weekday selected.
              </Typography>
            </li>
            <li>
              <Typography variant='body2'>
                End Date: Set an end date for the recurrence or check &quot;never&quot; if it doesn&apos;t end.
              </Typography>
            </li>
            <li>
              <Typography variant='body2'>
                Save to create the availability.
              </Typography>
            </li>
          </ul>
        </Stack>
      </Stack>
      <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' padding='16px 32px' boxSizing='border-box' sx={{ backgroundColor: variables.colors.lightNeutral.subtle, borderRadius: '0 0 12px 12px' }} >
        <Button variant='contained' color='primary' onClick={closeModal}>Okay</Button>
      </Stack>
    </Stack>
  );
};

export default LearnMoreModal;