import axiosInstance from "api/config";
import { PayPeriod, PayPeriodList } from "types/dbSchema/serviceTeamSettings";

// Returns a list of pay periods for the given organisation ID
export const listPayPeriods = async (organisationId: string, page?: number, pageSize?: number): Promise<PayPeriodList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID, page, and page size are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/service-team/pay-periods`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing pay periods for the organisation', error);
    throw error;
  }
};

// Returns a pay period by ID
export const getPayPeriodByID = async (organisationId: string, payPeriodID: string): Promise<PayPeriod> => {
  try {
    if (!payPeriodID || !organisationId) throw new Error('Organisation ID and PayPeriod ID are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/service-team/pay-periods/${payPeriodID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the pay period', error);
    throw error;
  }
};

// Create a new pay period
export const createPayPeriod = async (organisationId: string, data: PayPeriod) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/${organisationId}/settings/service-team/pay-periods`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the pay period', error);
    throw error;
  }
};

// Update a payPeriod
export const updatePayPeriod = async (organisationId: string, data: PayPeriod) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.put(`/api/${organisationId}/settings/service-team/pay-periods/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the pay period', error);
    throw error;
  }
};

// Delete a pay period
export const deletePayPeriod = async (organisationId: string, payPeriodID: string) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.delete(`/api/${organisationId}/settings/service-team/pay-periods/${payPeriodID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the pay period', error);
    throw error;
  }
};