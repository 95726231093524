import { useEffect } from 'react';

// This hook scrolls to the element with the ID specified in the URL hash
// We need this because the browser doesn't automatically scroll to the element with the ID specified in the URL hash
// We can blame react-router for this
const useHashScroll = () => {
  useEffect(() => {
    // Function to scroll to the element with the ID specified in the URL hash
    const scrollToHash = () => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          // A timeout of 0 to ensure that the element is rendered before scrolling
          setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth' });
          }, 0);
        }
      }
    };

    // Execute on mount
    scrollToHash();

    // Re-execute if URL hash changes
    window.addEventListener('hashchange', scrollToHash, { passive: true });

    // Cleanup
    return () => {
      window.removeEventListener('hashchange', scrollToHash);
    };
  }, []);
};

export default useHashScroll;