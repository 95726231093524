import React, { FC, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import variables from 'styles/variables';
import styled from 'styled-components';
import SearchInput from 'components/common/InputFields/SearchInput';
import Actions from './Actions';
import { goalStatuses, goalTypes } from 'types/dbSchema/participantGoals';
import { ControlsProps } from '../../types';

const Controls: FC<ControlsProps> = ({
  selectedFilters,
  setSelectedFilters,
  searchText,
  setSearchText,
}) => {

  return (
    <StyledStack>
      <Stack justifyContent='space-between' height='100%' gap='16px'>
        <ActionStack>
          <Typography variant='subtitle2' minWidth='fit-content' fontSize='18px'>
            Contacts
          </Typography>
          <Stack flexDirection='row' gap='16px' height='36px' width='fit-content'>
            <SearchInput searchValue={searchText} setSearchValue={setSearchText} placeholderText='Search' height='100%' />
            <Actions />
          </Stack>
        </ActionStack>
      </Stack>
    </StyledStack>
  );
};

const StyledStack = styled(Stack)`
  height: fit-content;
  padding: 24px;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
`;

const ActionStack = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export default Controls;