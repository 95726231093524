import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import styled from 'styled-components';

const UpgradePlan = () => {
  return (
    <StyledStack>
      <Stack gap='4px'>
        <img src="/assets/images/_illustrations_@2x.png" alt="Upgrade Plan" />
        <Typography variant="h6" align="center">
          Upgrade Plan to Unlock
        </Typography>
        <Typography variant="body1" align="center">
          Custom Roles are available for Premium Subscriptions
        </Typography>
        <Button variant="text" color="secondary" size='large'>
          Learn more about Custom Roles
        </Button>
      </Stack>
      <Button variant="contained" color="primary" size='large'>
        View available plans
      </Button>
    </StyledStack>
  );
};

const StyledStack = styled(Stack)`
  display: flex;
  padding: 48px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

export default UpgradePlan;