import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import CustomBreadcrumbs from 'components/common/Breadcrumbs';
import styled from 'styled-components';
import SearchInput from 'components/common/InputFields/SearchInput';
import { ControlsProps } from '../types';

const Controls: FC<ControlsProps> = ({
  searchText,
  setSearchText,
}) => {

  return (
    <StyledStack>
      <CustomBreadcrumbs />
      <Stack justifyContent='space-between' height='100%' gap='16px' flexDirection='row'>
        <Typography variant='h4' minWidth='fit-content'>
          Unallocated Services
        </Typography>
        <SearchInput searchValue={searchText} setSearchValue={setSearchText} placeholderText='Search' />
      </Stack>
    </StyledStack>
  );
};

const StyledStack = styled(Stack)`
  height: fit-content;
  padding: 16px 24px 32px 24px;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
`;

export default Controls;