import React, { FC } from 'react';
import { ViewServiceContractsProps } from '..';
import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import variables from 'styles/variables';
import { formatISODate } from 'utils/helpers';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';

const CategoryBudgets: FC<ViewServiceContractsProps> = ({ serviceContract }) => {
  return (
    <Stack gap='32px' padding='24px'>
      <Overview serviceContract={serviceContract} />
      <CategoryBudgetList serviceContract={serviceContract} />
    </Stack>
  );
};

const Overview: FC<ViewServiceContractsProps> = ({ serviceContract }) => {
  return (
    <OverviewContainer>
      <Stack gap='8px' height='100%'>
        <MonetizationOnOutlinedIcon sx={{ color: variables.colors.primary.main }} />
        <Stack>
          <Typography variant='body2'>
            Starting Funds
          </Typography>
          <Typography variant='subtitle1'>
            ${serviceContract.startingFunds.toFixed(2)}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap='8px' height='100%'>
        <RequestQuoteOutlinedIcon sx={{ color: variables.colors.primary.main }} />
        <Stack justifyContent='space-between'>
          <Typography variant='body2'>
            Delivered Amount
          </Typography>
          <Typography variant='subtitle1'>
            ${serviceContract.deliveredAmount.toFixed(2)}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap='8px' height='100%'>
        <AccountBalanceWalletOutlinedIcon sx={{ color: variables.colors.primary.main }} />
        <Stack>
          <Typography variant='body2'>
            Remaining Funds
          </Typography>
          <Typography variant='subtitle1'>
            ${serviceContract.remainingAmount.toFixed(2)}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap='8px' height='100%'>
        <TodayOutlinedIcon sx={{ color: variables.colors.primary.main }} />
        <Stack>
          <Typography variant='body2'>
            Start Date
          </Typography>
          <Typography variant='subtitle1'>
            {formatISODate(serviceContract.startDate)}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap='8px' height='100%'>
        <EventOutlinedIcon sx={{ color: variables.colors.primary.main }} />
        <Stack>
          <Typography variant='body2'>
            End Date
          </Typography>
          <Typography variant='subtitle1'>
            {formatISODate(serviceContract.endDate)}
          </Typography>
        </Stack>
      </Stack>
    </OverviewContainer>
  );
};

const CategoryBudgetList: FC<ViewServiceContractsProps> = ({ serviceContract }) => {
  return (
    <Stack gap='24px'>
      <Typography fontSize='18px' fontWeight='600'>Category Budgets</Typography>
    </Stack>
  );
};

const OverviewContainer = styled(Stack)`
  border: 1px solid rgba(92, 6, 140, 0.10);
  border-radius: 8px;
  flex-direction: row;
  & > div {
    border-right: 1px solid rgba(92, 6, 140, 0.10);
    width: 100%;
    padding: 16px 24px;
  }
  & :last-child {
    border-right: none;
  }
  & > :nth-child(1), & > :nth-child(2), & > :nth-child(3) {
    background-color: rgba(92, 6, 140, 0.04)
  }
`;

export default CategoryBudgets;