import React, { FC } from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { ParticipantGoal } from 'types/dbSchema/participantGoals';
import { useNavigate, useParams } from 'react-router-dom';

export interface ViewIconProps {
  goal: ParticipantGoal;
}

const ViewIcon: FC<ViewIconProps> = ({ goal }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const handleViewGoal = () => navigate(`/participant-centre/participants/view/${id}/goals/view/${goal.id}`);

  return (
    <StyledBox onClick={handleViewGoal}>
      <RemoveRedEyeOutlinedIcon />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export default ViewIcon;