import React, { FC, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { LinearProgress } from '@mui/material';

// A provider that wraps the app, handles authentication and redirects
const AuthProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { instance: msalInstance, inProgress } = useMsal();

  // NOTE: Redirection handling will happen in the ProtectedRoute component (or page unprotected), this is just to handle the initial authentication.
  // REASON: MSAL abstracts away and handles the redirect promise - we need to call an API to check if the user is associated with an organisation to determine where to redirect them to.
  // We can pass in a redirect parameter msalConfig (this is buggy and triggers twice because of React Router) but we would need to define the url prior to initialising the MSAL instance,
  // but we can't do that because the API we use, uses the user's ID from being logged in to check if they are associated with an organisation.
  useEffect(() => {
    msalInstance.initialize().then(() => {
      msalInstance.handleRedirectPromise();
    });
  }, []);

  // If inProgress is anything other than 'none' display a loader to prevent the app from rendering before authentication is complete
  if (inProgress !== 'none') {
    return (
      <LinearProgress />
    );
  }

  // If inProgress is 'none' render the app
  return (<>{children}</>);
};

export default AuthProvider;