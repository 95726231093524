import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import styled from 'styled-components';
import variables from 'styles/variables';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import Tabs from 'components/common/Tabs';
import DetailsTab from './DetailsTab';
import { ViewServiceProviderProps } from './types';
import ContactsTab from './ContactsTab';
import NotesTab from './NotesTab';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { deleteServiceProvider, updateServiceProvider } from 'api/organisations/serviceproviders';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useErrorMessage from 'hooks/useErrorMessage';
import { useAtom } from 'jotai';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import { triggerRefreshCurrentDataPointAtom } from 'atoms';
import { ServiceType } from 'types/dbSchema/schedulingSettings';

const ViewServiceProvider: FC<ViewServiceProviderProps> = ({ serviceProvider }) => {

  return (
    <Stack width='100%' justifyContent='flex-start' alignItems='center' sx={{
      backgroundColor: 'white',
      minHeight: `calc(100vh - ${variables.heights.topBar})`,
      height: '100%',
      overflowY: 'auto',
    }}>
      <PageHeader serviceProvider={serviceProvider} />
      <Tabs tabs={[
        {
          label: 'Details',
          content: <DetailsTab serviceProvider={serviceProvider} />,
          requiredPermissions: [],
        },
        {
          label: 'Contacts',
          content: <ContactsTab serviceProvider={serviceProvider} />,
          requiredPermissions: [],
        },
        {
          label: 'Notes',
          content: <NotesTab serviceProvider={serviceProvider} />,
          requiredPermissions: [],
        },
      ]} />
    </Stack>
  );
};

const PageHeader: FC<ViewServiceProviderProps> = ({ serviceProvider }) => {
  // TODO: Include logic to consider if the user has permission to delete,
  // the service provider records Has any contacts added or is listed as a participant’s Plan Manager
  const [showDeleteIcon, setShowDeleteIcon] = useState(true);
  const navigate = useNavigate();
  const [organisations] = useUserOrganisations();
  const showError = useErrorMessage();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const [, setTriggerRefresh] = useAtom(triggerRefreshCurrentDataPointAtom);

  const handleDelete = async () => {
    try {
      if (!organisations[0].organisation.globalId || !serviceProvider?.id)
        throw new Error('Organisation globalId or serviceProvider id is missing');

      await deleteServiceProvider(organisations[0].organisation.globalId, serviceProvider.id);
      setInvalidateData((prev) => ({ ...prev, 'service-providers': true }));
      navigate('/resources/service-providers');
    } catch (error) {
      showError((error as Error).message);
    }
  };

  const handleStatusChange = async (status: boolean) => {
    try {
      await updateServiceProvider(
        organisations[0].organisation.globalId,
        {
          ...serviceProvider,
          serviceTypes: (serviceProvider.serviceTypes as ServiceType[]).map((serviceType) => serviceType.id),
          isActive: status
        }
      );
      setInvalidateData((prev) => ({ ...prev, 'service-providers': true }));
      setTriggerRefresh((prev) => prev + 1);
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <PageHeaderStack>
      <Button variant='text'
        sx={{
          color: variables.colors.text.secondary,
          textTransform: 'none',
          fontWeight: 400,
          padding: 0
        }}
        onClick={() => navigate('/resources/service-providers')}
      >
        <ArrowBackOutlinedIcon sx={{
          padding: '4px',
          boxSizing: 'border-box',
          height: '24px',
          width: '24px',
        }}
        />
        Service Providers
      </Button>
      <Stack flexDirection='row' gap='16px' alignItems='center' width='100%' justifyContent='space-between'>
        <Stack flexDirection='row' gap='32px' alignItems='center' width='100%'>
          <Typography variant='h5' minWidth='fit-content'>
            {serviceProvider.companyName}
          </Typography>
          <IsActiveDropdown
            isActive={serviceProvider.isActive}
            setIsActive={handleStatusChange} />
        </Stack>
        {showDeleteIcon && <DeleteOutlinedIcon onClick={handleDelete} sx={{ cursor: 'pointer' }} />}
      </Stack>
    </PageHeaderStack>
  );
};


const PageHeaderStack = styled(Stack)`
  display: flex;
  width: 100%;
  padding: 32px 24px 0 24px;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 8px;
`;

export default ViewServiceProvider;