import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import React, { Dispatch, FC, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import variables from 'styles/variables';
import SearchInput from 'components/common/InputFields/SearchInput';
import { FiltersDropdownProps, IFilter, SelectedFilter } from '../types';

const FiltersDropdown: FC<FiltersDropdownProps> = ({
  filter,
  selectedFilters,
  setSelectedFilters,
  hasNextPage,
  fetchNextPage,
  filterDropdownRef,
  searchValue,
  setSearchValue,
}) => {
  const selectedOptions = useMemo(() => new Set(selectedFilters.find(f => f.field === filter.field)?.valuesSelected), [selectedFilters, filter.field]);
  const isOptionSelected = (option: string) => selectedOptions.has(option);
  const [options] = useState(filter.options);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = (event: any) => {
      if (!event.target) return;
      const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
      if (bottom && hasNextPage) {
        fetchNextPage();
      }
    };

    const selectElement = dropdownRef.current as HTMLElement | null;
    if (selectElement) {
      selectElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (selectElement) {
        selectElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [fetchNextPage, hasNextPage]);

  return (
    <FilterDropDownContainer ref={filterDropdownRef}>
      <SearchBox>
        <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholderText='Search' />
      </SearchBox>
      <StyledFilterDropdown ref={dropdownRef}>
        {options.map((option, index) => (
          <StyledStack key={index}>
            <FormControlLabel
              label={option.label}
              control={
                <Checkbox
                  onChange={() => handleFilterClick(filter, option.value, setSelectedFilters)}
                  checked={isOptionSelected(option.value)}
                />
              }
            />
          </StyledStack>
        ))}
        {options.length === 0 && (
          <Typography variant='body2' color={variables.colors.text.secondary} fontWeight='600' padding='6px 8px'>
            No filters found
          </Typography>
        )}
      </StyledFilterDropdown>
      {selectedOptions.size > 0 && (
        <ClearSelectionBox onClick={() => setSelectedFilters((prev: SelectedFilter[]) => prev.filter((f: SelectedFilter) => f.field !== filter.field))}>
          <Typography variant='body2' fontWeight='600'>
            Clear Selection
          </Typography>
        </ClearSelectionBox>
      )}
    </FilterDropDownContainer>
  );
};

const FilterDropDownContainer = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  width: fit-content;
  height: fit-content;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 3px 8px -1px #E0E0E0;
  border-radius: 4px;
`;

const SearchBox = styled.div`
  border-bottom: 1px solid #E0E0E0;
  background-color: white;
  & * {
    border: none;
  }
`;

const StyledFilterDropdown = styled(Stack)`
  box-sizing: border-box;
  width: 240px;
  padding: 8px;
  position: relative;
  background-color: white;
  border-radius: 4px;
`;

const ClearSelectionBox = styled(Stack)`
  padding: 14px 16px;
  border-top: 1px solid #E0E0E0;
  cursor: pointer;
  background-color: white;
  position: relative;
  border-radius: 0 0 4px 4px;
  &:hover {
    background-color: #F5F5F5;
  }
`;

const StyledStack = styled(Stack)`
  padding: 0 8px;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
`;

// This function is used to handle the filter click event
const handleFilterClick = (
  filter: IFilter,
  option: string,
  setSelectedFilters: Dispatch<React.SetStateAction<SelectedFilter[]>>
) => {
  setSelectedFilters((prev) => {
    const existingFilterIndex = prev.findIndex((prevFilter) => prevFilter.field === filter.field);

    if (existingFilterIndex !== -1) {
      const newFilters = [...prev];
      const existingFilter = newFilters[existingFilterIndex];
      const optionIndex = existingFilter.valuesSelected.indexOf(option);

      // Check if the option is already selected; if so, remove it, otherwise add it
      if (optionIndex === -1) {
        existingFilter.valuesSelected.push(option);
      } else {
        existingFilter.valuesSelected.splice(optionIndex, 1);
      }

      newFilters[existingFilterIndex] = { ...existingFilter };
      // Clear the filter out if it has no values
      if (newFilters[existingFilterIndex].valuesSelected.length === 0) {
        newFilters.splice(existingFilterIndex, 1);
      }

      return newFilters;
    } else {
      return [...prev, { field: filter.field, valuesSelected: [option] }];
    }
  });
};

export default FiltersDropdown;