import React, { FC } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { Controller, useForm } from 'react-hook-form';
import { closeModalAtom } from 'atoms/modalAtom';
import SelectInput from 'components/common/InputFields/SelectInput';
import { GridRowId } from '@mui/x-data-grid';
import useErrorMessage from 'hooks/useErrorMessage';

export interface LinkToGoalFormProps {
  selectedRowIds: GridRowId[];
}

const LinkToGoalForm: FC<LinkToGoalFormProps> = ({ selectedRowIds }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const { control, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', defaultValues: { goal: '' } });
  const showError = useErrorMessage();

  const handleLinkToGoal = (data: any) => {
    try {
      console.log(data);
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <form style={{ width: '500px', padding: '32px' }} onSubmit={handleSubmit((data) => handleLinkToGoal(data))}>
      <Stack height='fit-content' gap='24px'>
        <Typography variant='h6' fontWeight='600' mb={2}>
          Link to a goal
        </Typography>
        <Controller
          name="goal"
          control={control}
          rules={{ required: 'Goal is required' }}
          render={({ field }) =>
            <SelectInput
              {...field}
              id='goal'
              label='Goal'
              placeholder='Select'
              error={errors.goal ? true : false}
              errorText={errors?.goal?.message}
              isMandatory
              options={
                [
                  { value: 'goal1', label: 'goal1' },
                  { value: 'goal2', label: 'goal2' },
                ]
              }
            />
          }
        />
        <Stack flexDirection='row' justifyContent='flex-end' gap='16px' padding='24px 0 0 0'>
          <Button variant='outlined' color='primary' onClick={closeModal}>
            Cancel
          </Button>
          <Button type='submit' variant='contained' color='primary'>
            Create
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export interface EditProps {
  selectedRowId: string;
}

export const Edit: FC<EditProps> = ({ selectedRowId }) => {
  const [modalState, setModalState] = useAtom(modalStateAtom);

  const handleImportModalOpen = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: LinkToGoalForm,
      props: {
        dataTableName: 'user-positions',
        selectedRowIds: [selectedRowId],
      }
    });
  };

  return (
    <Stack width='100%' height='100%' justifyContent='center' alignItems='center'>
      <EditOutlinedIcon
        sx={{ color: '#9CA3AF', cursor: 'pointer' }}
        onClick={handleImportModalOpen}
      />
    </Stack>
  );
};

export default LinkToGoalForm;