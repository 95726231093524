import axiosInstance from "api/config";
import { CancellationCode, CancellationCodeList } from "types/dbSchema/schedulingSettings";

// Returns a list of cancellation codes for the given organisation ID
export const listCancellationCodes = async (organisationId: string, page?: number, pageSize?: number): Promise<CancellationCodeList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID, page, and page size are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/scheduling/cancellation-codes`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing cancellation codes for the organisation', error);
    throw error;
  }
};

// Returns a cancellation code by ID
export const getCancellationCodeByID = async (organisationId: string, cancellationCodeID: string): Promise<CancellationCode> => {
  try {
    if (!cancellationCodeID || !organisationId) throw new Error('Organisation ID and CancellationCode ID are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/scheduling/cancellation-codes/${cancellationCodeID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the cancellation code', error);
    throw error;
  }
};

// Create a new cancellation code
export const createCancellationCode = async (organisationId: string, data: CancellationCode) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/${organisationId}/settings/scheduling/cancellation-codes`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the cancellation code', error);
    throw error;
  }
};

// Update a cancellation code
export const updateCancellationCode = async (organisationId: string, data: CancellationCode) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.put(`/api/${organisationId}/settings/scheduling/cancellation-codes/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the cancellation code', error);
    throw error;
  }
};

// Delete a cancellation code
export const deleteCancellationCode = async (organisationId: string, cancellationCodeID: string) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.delete(`/api/${organisationId}/settings/scheduling/cancellation-codes/${cancellationCodeID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the cancellation code', error);
    throw error;
  }
};