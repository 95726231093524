import React, { FC, createElement } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import variables from 'styles/variables';
import { AccordionContentProps, Permission } from '../types';

const AccordionContent: FC<AccordionContentProps> = ({ text, permissionArray }) => {
  return (
    <Stack width='100%' padding='0 0 0 54px'>
      <Stack width='100%' justifyContent='space-between'>
        <Typography variant='body2' dangerouslySetInnerHTML={{ __html: text }} />
        <Stack flexDirection='row' gap='8px' flexWrap='wrap'>
          {permissionArray.map((status: Permission, index: number) => (
            <AdditionalText key={index}>
              {createElement(status.icon, { fontSize: 'small', sx: { marginRight: '4px', color: variables.colors.primary.darker } })}
              <Typography fontSize='12px' variant='body2' textTransform='uppercase' dangerouslySetInnerHTML={{ __html: status.text }} />
            </AdditionalText>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

const AdditionalText = styled(Box)`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  min-width: fit-content;
  background-color: ${variables.colors.primary.subtle};
  height: 36px;
`;

export default AccordionContent;