import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Stack, Typography } from '@mui/material';
import variables from 'styles/variables';


const Greeting = ({ type }: { type: 'user' | 'org' }) => {
  const { accounts } = useMsal();
  if (type === 'user') return (
    <Stack gap='8px' sx={{ backgroundColor: '#EEF9FC', padding: '16px' }} data-testid='greeting'>
      <Typography variant="h6" sx={{ color: variables.colors.secondary.main, wordWrap: 'break-word', whiteSpace: 'normal', overflowWrap: 'anywhere' }}>
        Please register your information
      </Typography>
      <Typography variant="body2" color={variables.colors.darkNeutral.lighter}>
        Please follow the next steps to finish setting up your account, this will only take a few minutes.
      </Typography>
    </Stack>
  );
  return (
    <Stack gap='8px' sx={{ backgroundColor: '#EEF9FC', padding: '16px' }} data-testid='greeting'>
      <Typography variant="h6" sx={{ color: variables.colors.secondary.main, wordWrap: 'break-word', whiteSpace: 'normal', overflowWrap: 'anywhere' }}>
        Please register your organisation
      </Typography>
      <Typography variant="body2" color={variables.colors.darkNeutral.lighter}>
        Please follow the next steps to register/validate your NDIS provider organisation.
      </Typography>
    </Stack>
  );
};

export default Greeting;