import axiosInstance from "api/config";
import { ProgressNoteType, ProgressNoteTypeList } from "types/dbSchema/participantSettings";

// Returns a list of progress note types for the given organisation ID
export const listProgressNoteTypes = async (organisationId: string, page?: number, pageSize?: number): Promise<ProgressNoteTypeList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID, page, and page size are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/participants/progress-note-types`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing progress note types for the organisation', error);
    throw error;
  }
};

// Returns a progress note type by ID
export const getProgressNoteTypeByID = async (organisationId: string, ProgressNoteTypeID: string): Promise<ProgressNoteType> => {
  try {
    if (!ProgressNoteTypeID || !organisationId) throw new Error('Organisation ID and ProgressNoteType ID are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/participants/progress-note-types/${ProgressNoteTypeID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the progress note type', error);
    throw error;
  }
};

// Create a new progress note type
export const createProgressNoteType = async (organisationId: string, data: ProgressNoteType) => {
  console.log(data);
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/${organisationId}/settings/participants/progress-note-types`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the progress note type', error);
    throw error;
  }
};

// Update a progress note type
export const updateProgressNoteType = async (organisationId: string, data: ProgressNoteType) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.put(`/api/${organisationId}/settings/participants/progress-note-types/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the progress note type', error);
    throw error;
  }
};

// Delete a progress note type
export const deleteProgressNoteType = async (organisationId: string, ProgressNoteTypeID: string) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.delete(`/api/${organisationId}/settings/participants/progress-note-types/${ProgressNoteTypeID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the progress note type', error);
    throw error;
  }
};