import React, { FC, useState } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { Controller, useForm } from 'react-hook-form';
import { closeModalAtom } from 'atoms/modalAtom';
import SelectInput from 'components/common/InputFields/SelectInput';
import TextInput from 'components/common/InputFields/TextInput';
import { SchedulingSupport, serviceTimeslots, serviceTimeslotsObj, serviceTimeslot } from 'types/dbSchema/schedulingSupports';
import MultiSelectInput from 'components/common/InputFields/MultiSelectInput';
import TimePickerInput from 'components/common/InputFields/TimePickerInput';
import NumberInput from 'components/common/InputFields/NumberInput';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { serviceTypesMasterList } from 'utils/helpers/getDBData';
import variables from 'styles/variables';
import { ServiceType as IServiceType } from 'types/dbSchema/schedulingSettings';
import ServiceType from 'components/common/ServiceType';
import { differenceInMinutes, parse } from 'date-fns';

export interface SchedulingSupportsFormProps {
  schedulingSupport?: SchedulingSupport;
  type: 'create' | 'edit';
}

const SchedulingSupportsForm: FC<SchedulingSupportsFormProps> = ({ schedulingSupport, type }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [isEditable, setIsEditable] = useState<boolean>(false);

  const { control, handleSubmit, watch, setValue, formState: { errors, isDirty } } = useForm({
    mode: 'onChange',
    defaultValues: schedulingSupport || {}
  });

  const handleServiceChange = (service: serviceTimeslot) => {
    const serviceType = serviceTimeslotsObj[service];

    const startTime = parse(serviceType.startTime, 'HH:mm', new Date());
    const endTime = parse(serviceType.endTime, 'HH:mm', new Date());

    setValue('startTime', startTime.toString());
    setValue('endTime', endTime.toString());
    setValue('hours', serviceType.totalHours);
    setIsEditable(serviceType.editable);
  };

  const calculateHours = (startTime: string, endTime: string) => {
    if (!startTime || !endTime) return;

    // Calculate the difference in minutes and convert to hours
    const minutesDifference = differenceInMinutes(endTime, startTime);
    const hours = minutesDifference / 60;

    setValue('hours', Math.round(hours * 100) / 100);
  };

  return (
    <form style={{ width: '600px', padding: '32px' }} onSubmit={handleSubmit((data) => console.log(data))}>
      <Stack height='fit-content' gap='24px'>
        <Typography variant='h6' fontWeight='600'>
          {type === 'create' ? 'Add Scheduling Support' : 'Edit Scheduling Support'}
        </Typography>
        {/* TODO: Change this to a lookup field */}
        <Controller
          name="service"
          control={control}
          rules={{ required: 'Position name is required' }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='service'
              label='Service Item'
              placeholder='Enter position'
              error={errors.service ? true : false}
              errorText={errors?.service?.message}
              isMandatory
            />
          }
        />
        <Controller
          name="serviceTimeslot"
          control={control}
          render={({ field }) =>
            <SelectInput
              {...field}
              id='serviceTimeslot'
              label='Service Timeslot'
              placeholder='Select'
              onChange={(e: any) => {
                field.onChange(e);
                handleServiceChange(e);
              }}
              error={errors.serviceTimeslot ? true : false}
              errorText={errors?.serviceTimeslot?.message}
              options={serviceTimeslots.map((serviceTime) => ({ value: serviceTime, label: serviceTime }))}
            />
          }
        />
        <Stack direction='row' gap='16px'>
          <Controller
            name="startTime"
            control={control}
            rules={{ required: 'Start Time is required' }}
            render={({ field }) =>
              <TimePickerInput
                {...field}
                ampm={false}
                id='startTime'
                label='Start Time'
                error={errors.startTime ? true : false}
                errorText={errors?.startTime?.message}
                isMandatory
                onChange={(e: any) => {
                  field.onChange(e);
                  calculateHours(e, watch('endTime'));
                }}
                disabled={!isEditable}
              />
            }
          />
          <Controller
            name="endTime"
            control={control}
            rules={{ required: 'End Time is required' }}
            render={({ field }) =>
              <TimePickerInput
                {...field}
                ampm={false}
                id='endTime'
                label='End Time'
                error={errors.endTime ? true : false}
                errorText={errors?.endTime?.message}
                isMandatory
                onChange={(e: any) => {
                  field.onChange(e);
                  calculateHours(watch('startTime'), e);
                }}
                disabled={!isEditable}
              />
            }
          />
          <Controller
            name="hours"
            control={control}
            rules={{ required: 'End Time is required' }}
            render={({ field }) =>
              <NumberInput
                {...field}
                id='hours'
                label='Hours'
                error={errors.hours ? true : false}
                errorText={errors?.hours?.message}
                placeholder=''
                isMandatory
                disabled
              />
            }
          />
        </Stack>
        <Stack gap='12px'>
          <Stack>
            <Typography variant='subtitle2' fontWeight='500'>
              Service Types for billables
            </Typography>
            <Typography variant='body2' fontSize='13px' color={variables.colors.text.secondary}>
              If you can’t find a service type from the list below, make sure they are added in the <a href='/settings/scheduling-settings'>Service Types Settings</a> page.
            </Typography>
          </Stack>
          <Controller
            name="serviceTypes"
            control={control}
            rules={{ required: 'Service Types Delivered is required' }}
            render={({ field }) =>
              <MultiSelectInput
                {...field}
                id='serviceTypesDelivered'
                label='Service Types Delivered'
                error={errors.serviceTypes ? true : false}
                errorText={errors?.serviceTypes?.message}
                placeholder='Select'
                isMandatory
                items={serviceTypesMasterList.map((serviceType: IServiceType) => (
                  { value: serviceType.name, component: () => <ServiceType serviceType={serviceType} /> })
                )}
              />
            }
          />
        </Stack>
        <Stack flexDirection='row' justifyContent='flex-start' alignItems='center' gap='16px'>
          <Typography variant='subtitle2' fontWeight='500'>
            Status
          </Typography>
          <Controller
            name="isActive"
            control={control}
            rules={{ required: 'Status is required' }}
            render={({ field }) =>
              <IsActiveDropdown
                setIsActive={field.onChange}
                isActive={field.value || true}
              />
            }
          />
        </Stack>
        <Stack flexDirection='row' justifyContent='flex-end' gap='16px' padding='24px 0 0 0'>
          <Button variant='outlined' color='primary' onClick={closeModal}>
            Cancel
          </Button>
          <Button type='submit' variant='contained' color='primary' disabled={!isDirty}>
            {type === 'create' ? 'Create' : 'Update'}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export interface EditProps {
  selectedRowId: string;
}

export const Edit: FC<EditProps> = ({ selectedRowId }) => {
  const [modalState, setModalState] = useAtom(modalStateAtom);

  const handleImportModalOpen = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: SchedulingSupportsForm,
      props: {
        dataTableName: 'user-positions',
        selectedRowIds: [selectedRowId],
      }
    });
  };

  return (
    <Stack width='100%' height='100%' justifyContent='center' alignItems='center'>
      <EditOutlinedIcon
        sx={{ color: '#9CA3AF', cursor: 'pointer' }}
        onClick={handleImportModalOpen}
      />
    </Stack>
  );
};

export default SchedulingSupportsForm;