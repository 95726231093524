import React, { FC, useCallback } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ExternalContactForm from 'components/features/Forms/ExternalContactForm';
import { modalStateAtom } from 'atoms';
import { useAtom } from 'jotai';

const Actions: FC = () => {
  const [, setModalState] = useAtom(modalStateAtom);

  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: ExternalContactForm,
      props: {
        dataTableName: 'external-contacts',
        type: 'create',
      }
    }));
  }, [setModalState]);

  return (
    <Button color='primary' variant='contained' onClick={() => handleModalOpen()} startIcon={<AddOutlinedIcon />} sx={{ minWidth: 'fit-content', height: '100%' }}>
      Add
    </Button>
  );
};

export default Actions;