import React, { FC } from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined';
import MobileScreenShareOutlinedIcon from '@mui/icons-material/MobileScreenShareOutlined';
import { Stack } from '@mui/material';
import variables from 'styles/variables';
import { DeliveryMethod as DeliveryMethodProps } from 'types/dbSchema/notificationSettings';

const DeliveryMethod: FC<DeliveryMethodProps> = ({ email, systemAlert, sms }) => {
  return (
    <Stack flexDirection='row' gap='8px'>
      <EmailOutlinedIcon sx={{ color: email ? variables.colors.success.main : variables.colors.icon.standard }} />
      <ComputerOutlinedIcon sx={{ color: systemAlert ? variables.colors.success.main : variables.colors.icon.standard }} />
      <MobileScreenShareOutlinedIcon sx={{ color: sms ? variables.colors.success.main : variables.colors.icon.standard }} />
    </Stack>
  );
};

export default DeliveryMethod;