import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { Edit } from 'components/features/Forms/UserPositionForm';
import EditIsActive from './EditIsActive';
import ListServiceTypes from 'components/features/ListServiceTypes';
import { DeleteIcon } from './DeleteIcon';

export const Columns: GridColDef[] = [
  {
    field: 'delete',
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <DeleteIcon userPosition={params.row} />
    ),
    sortable: false,
  },
  {
    field: 'edit',
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <Edit positionToEdit={params.row} />
    ),
    sortable: false,
  },
  {
    field: 'name',
    headerName: 'Position Name',
    width: 350,
    renderCell: (params) => (
      <Typography variant='body2' fontWeight='600'>
        {params.row.name}
      </Typography>
    ),
  },
  {
    field: 'serviceTypesDelivered',
    headerName: 'Service Types Delivered',
    minWidth: 200,
    flex: 1,
    renderCell: (params) => (
      <ListServiceTypes
        serviceTypesDelivered={params.row.serviceTypesDelivered}
      />
    ),
  },
  {
    field: 'usersCount',
    headerName: 'Users',
    width: 100,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => <EditIsActive positionToEdit={params.row} />
  },
];