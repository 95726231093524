import axiosInstance from "api/config";
import { ShiftLocation, ShiftLocationList } from "types/dbSchema/schedulingSettings";

// Returns a list of shift locations for the given organisation ID
export const listShiftLocations = async (organisationId: string, page?: number, pageSize?: number): Promise<ShiftLocationList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID, page, and page size are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/scheduling/shift-locations`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing shift locations for the organisation', error);
    throw error;
  }
};

// Returns a shift location by ID
export const getShiftLocationByID = async (organisationId: string, shiftLocationID: string): Promise<ShiftLocation> => {
  try {
    if (!shiftLocationID || !organisationId) throw new Error('Organisation ID and ShiftLocation ID are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/scheduling/shift-locations/${shiftLocationID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the shift location', error);
    throw error;
  }
};

// Create a new shift location
export const createShiftLocation = async (organisationId: string, data: ShiftLocation) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/${organisationId}/settings/scheduling/shift-locations`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the shift location', error);
    throw error;
  }
};

// Update a shift location
export const updateShiftLocation = async (organisationId: string, data: ShiftLocation) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.put(`/api/${organisationId}/settings/scheduling/shift-locations/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the shift location', error);
    throw error;
  }
};

// Delete a shift location
export const deleteShiftLocation = async (organisationId: string, shiftLocationID: string) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.delete(`/api/${organisationId}/settings/scheduling/shift-locations/${shiftLocationID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the shift location', error);
    throw error;
  }
};