import { Box, Grid, Stack, Typography } from '@mui/material';
import CustomiseDropdown from 'components/features/CustomiseDropdown';
import HelpCentreCTA from 'components/features/HelpCentreCTA';
import QuickLinks from 'components/features/QuickLinks';
import UnallocatedServicesWidget from 'components/features/Widgets/UnallocatedServicesWidget';
import UnreleasedShiftsWidget from 'components/features/Widgets/UnreleasedShiftsWidget';
import useCurrentUser from 'hooks/useCurrentUser';
import useHomePageColor from 'hooks/useHomePageColor';
import React, { FC } from 'react';
import styled from 'styled-components';


const HomePage: FC = () => {
  const [homePageColor] = useHomePageColor();
  const [user] = useCurrentUser();

  return (
    <Stack height='100%' width='100%' padding='24px' overflow='auto' sx={{ backgroundColor: homePageColor.bgColor }}>
      <Stack direction='row' justifyContent='flex-end' gap='16px'>
        <CustomiseDropdown />
      </Stack>
      <MaxWidthContainer gap='32px'>
        <iframe
          width="640px"
          height="1000px"
          src="https://forms.office.com/Pages/ResponsePage.aspx?id=4zHzUx_B1EqAADviwNlhDH8vLuVif_9Aqmfr0fg47LtUMFMxSjRNTVA5RzRTNlVEWUVFREZWN1VDNi4u&embed=true"
          allowFullScreen
        />
        <Stack padding='32px 0'>
          <Typography textAlign='center' fontSize='18px' color={homePageColor.color}>
            {new Date().toLocaleDateString('en-GB', { weekday: 'long', day: 'numeric', month: 'long' })}
          </Typography>
          <Typography textAlign='center' variant='h4' color={homePageColor.color}>
            Welcome back, {user?.fullName.firstName}
          </Typography>
        </Stack>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <UnallocatedServicesWidget />
          </Grid>
          <Grid item xs={6}>
            <UnreleasedShiftsWidget />
          </Grid>
        </Grid>
        <QuickLinks />
        <HelpCentreCTA />
      </MaxWidthContainer>
    </Stack>
  );
};

const MaxWidthContainer = styled(Stack)`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`;

export default HomePage;