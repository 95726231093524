import axiosInstance from "api/config";
import { ParticipantAlertList } from "types/dbSchema/alerts";

// Returns a list of participant alerts for the given organisation ID and participant ID
export const listParticipantAlerts = async (
  organisationId: string,
  participantId: string,
  page: number,
  pageSize: number
): Promise<ParticipantAlertList> => {


  try {
    if (!organisationId || !participantId) throw new Error('Organisation ID and Participant ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants/${participantId}/alerts`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing participant alerts:', error);
    throw error;
  }
};

// Creates a participant alert for the given organisation ID and participant ID
export const createParticipantAlert = async (
  organisationId: string,
  participantId: string,
  data: any
) => {
  try {
    if (!organisationId || !participantId) throw new Error('Organisation ID and Participant ID are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/participants/${participantId}/alerts`, data);

    return response.data;
  } catch (error) {
    console.error('There was an error creating participant alert:', error);
    throw error;
  }
};

// Returns a participant alert by ID for the given organisation ID and participant ID
export const getAlertById = async (
  organisationId: string,
  participantId: string,
  alertId: string
) => {
  try {
    if (!organisationId || !participantId || !alertId) throw new Error('Organisation ID, Participant ID and Alert ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants/${participantId}/alerts/${alertId}`);

    return response.data;
  } catch (error) {
    console.error('There was an error getting participant alert:', error);
    throw error;
  }
};

// Updates a participant alert by ID for the given organisation ID and participant ID
export const updateAlertById = async (
  organisationId: string,
  participantId: string,
  alertId: string,
  data: any
) => {
  try {
    if (!organisationId || !participantId || !alertId) throw new Error('Organisation ID, Participant ID and Alert ID are required');

    const response = await axiosInstance.put(`/api/organisations/${organisationId}/participants/${participantId}/alerts/${alertId}`, data);

    return response.data;
  } catch (error) {
    console.error('There was an error updating participant alert:', error);
    throw error;
  }
};

// Deletes a participant alert by ID for the given organisation ID and participant ID
export const deleteAlertById = async (
  organisationId: string,
  participantId: string,
  alertId: string
) => {
  try {
    if (!organisationId || !participantId || !alertId) throw new Error('Organisation ID, Participant ID and Alert ID are required');

    const response = await axiosInstance.delete(`/api/organisations/${organisationId}/participants/${participantId}/alerts/${alertId}`);

    return response.data;
  } catch (error) {
    console.error('There was an error deleting participant alert:', error);
    throw error;
  }
};

// Dismisses a participant alert by ID for the given organisation ID and participant ID
export const dismissAlertById = async (
  organisationId: string,
  participantId: string,
  alertId: string
) => {
  try {
    if (!organisationId || !participantId || !alertId) throw new Error('Organisation ID, Participant ID and Alert ID are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/participants/${participantId}/alerts/${alertId}/dismiss`);

    return response.data;
  } catch (error) {
    console.error('There was an error dismissing participant alert:', error);
    throw error;
  }
};

// Dismisses all participant alerts for the given organisation ID and participant ID
export const dismissAllAlerts = async (
  organisationId: string,
  participantId: string
) => {
  try {
    if (!organisationId || !participantId) throw new Error('Organisation ID and Participant ID are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/participants/${participantId}/alerts/dismiss-all`);

    return response.data;
  } catch (error) {
    console.error('There was an error dismissing all participant alerts:', error);
    throw error;
  }
};

// Returns a list of participant alerts (not dismissed) for the given organisation ID and participant ID
export const listNotDismissedAlerts = async (
  organisationId: string,
  participantId: string
) => {
  try {
    if (!organisationId || !participantId) throw new Error('Organisation ID and Participant ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants/${participantId}/alerts/not-dismissed`);

    return response.data;
  } catch (error) {
    if ((error as any).response.status === 404) return [];
    console.error('There was an error listing not dismissed participant alerts:', error);
    throw error;
  }
};

// Returns a list of participant alerts for the given organisation ID, participant ID, and keyword
export const searchParticipantAlerts = async (
  organisationId: string,
  participantId: string,
  search: string,
  page: number,
  pageSize: number
) => {
  try {
    if (!organisationId || !participantId) throw new Error('Organisation ID and Participant ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants/${participantId}/alerts/search`, {
      params: {
        keyword: search,
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error searching participant alerts:', error);
    throw error;
  }
};

// Returns the count of participant alerts for the given organisation ID and participant ID
export const getAlertsCount = async (
  organisationId: string,
  participantId: string
) => {
  try {
    if (!organisationId || !participantId) throw new Error('Organisation ID and Participant ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants/${participantId}/alerts/count`);

    return response.data;
  } catch (error) {
    console.error('There was an error getting alerts count:', error);
    throw error;
  }
};