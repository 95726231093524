import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { AddOutlined, CloseOutlined } from '@mui/icons-material';
import variables from 'styles/variables';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { CreateParticipantAndContactLink, ParticipantAndContactLink } from 'types/dbSchema/participantContact';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import TextInput from 'components/common/InputFields/TextInput';
import RelationshipTypeLookupField from 'components/common/LookupInputField/RelationshipTypeLookupField';
import CheckboxInput from 'components/common/InputFields/CheckboxInput';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { closeModalAtom } from 'atoms/modalAtom';
import PersonAddDisabledOutlinedIcon from '@mui/icons-material/PersonAddDisabledOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import { ExternalContact } from 'types/dbSchema/externalContacts';
import ContactLookupField from 'components/common/LookupInputField/ContactLookupField';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { createExternalContact, getExternalContactByID } from 'api/organisations/externalcontacts';
import { linkParticipantToExternalContact } from 'api/organisations/participants/externalcontacts';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import useCurrentUser from 'hooks/useCurrentUser';

const LinkContactToParticipantForm: FC = () => {
  const [linkOrCreate, setLinkOrCreate] = useState<'link' | 'create'>('link');
  const navigate = useNavigate();

  const handleCancel = () => navigate(-1);

  return (
    <StyledBox>
      <Banner>
        <MaxWidthContainer>
          <Stack flexDirection='row' gap={1} alignItems='center' minWidth='fit-content'>
            <AddOutlined sx={{ borderRadius: '100%', border: ' 2px dashed #81D4FA', color: '#81D4FA', padding: '4px', height: '28px', width: '28px', boxSizing: 'border-box' }} />
            <Typography variant='h5'>
              Link Contact
            </Typography>
          </Stack>
          <CloseOutlined sx={{ cursor: 'pointer' }} onClick={handleCancel} />
        </MaxWidthContainer>
      </Banner>
      <Stack padding='32px' width='100%' height='fit-content' justifyContent='center' alignItems='center' boxSizing='border-box' gap='32px' overflow='auto'>
        <DetailsStack>
          <Typography variant='h6' fontWeight='600'>
            Link a contact
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            {`Easily link contacts to participants by searching existing entries. 
            If the contact isn't listed, utilise the "add new contact" shortcut to include them in the Contacts Console, simultaneously linking them to the participant profile.`}
          </Typography>
          <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-start'>
            <Button variant={linkOrCreate === 'link' ? 'contained' : 'outlined'} onClick={() => setLinkOrCreate('link')}>Link a Contact</Button>
            <Button variant={linkOrCreate === 'create' ? 'contained' : 'outlined'} onClick={() => setLinkOrCreate('create')}>Add New Contact</Button>
          </Stack>
        </DetailsStack>
        {linkOrCreate === 'link' ? <LinkContactDetails /> : <CreateContact />}
      </Stack>
    </StyledBox>
  );
};


// This component links an existing contact to the participant
export const LinkContactDetails: FC = () => {
  const navigate = useNavigate();
  const handleCancel = () => navigate(-1);
  const [selectedContact, setSelectedContact] = useState<ExternalContact | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [organisations] = useUserOrganisations();
  const [currentUser] = useCurrentUser();
  const { id } = useParams();
  const showError = useErrorMessage();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const { control, handleSubmit, formState: { errors, isDirty }, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      createdBy: currentUser?.id || ''
    } as CreateParticipantAndContactLink
  });

  useEffect(() => {
    const contactId = watch().contactId;
    if (!contactId) return;
    const fetchParticipant = async () => {
      setIsLoading(true);
      await getExternalContactByID(organisations[0].organisation.globalId, contactId)
        .then((response) => setSelectedContact(response))
        .finally(() => setIsLoading(false));
    };
    fetchParticipant();
  }, [watch().contactId]);

  const onSubmit = async (data: CreateParticipantAndContactLink) => {
    try {
      if (!data.contactId) return null;
      const contactId = data.contactId;
      delete data.contactId;
      await linkParticipantToExternalContact(
        organisations[0].organisation.globalId,
        contactId,
        id as string,
        data
      );
      setInvalidateData((prev) => ({ ...prev, 'participant-contacts': true }));
      navigate(-1);
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px'
      }}
    >
      <DetailsStack>
        <Stack padding='24px' sx={{ backgroundColor: '#F9FAFB' }}>
          <Stack gap='8px'>
            <Typography variant='h6' fontWeight='600'>
              Link a contact
            </Typography>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Find contacts across Contacts Console, Participants and Service Provider lists. Search by adding name and/or email.
            </Typography>
            <Controller
              name="contactId"
              control={control}
              rules={{
                required: 'Contact is required',
              }}
              render={({ field }) =>
                <ContactLookupField
                  {...field}
                  id='contactId'
                  label=''
                  placeholder='Select'
                  validationError={errors.relationshipType ? true : false}
                  errorText={errors.relationshipType?.message}
                  isMandatory
                />
              }
            />
          </Stack>
        </Stack>
        <Stack gap='32px'>
          <Typography variant='h6' fontWeight='600'>
            Contact Details
          </Typography>
          <Stack flexDirection='row' gap='32px' width='100%'>
            {selectedContact ? (
              <Stack justifyContent='center' border={`1px dashed ${variables.colors.primary.lighter}`} borderRadius='8px' padding='16px' width='100%' sx={{ boxSizing: 'border-box' }}>
                <Stack height='fit-content' flexDirection='row' gap='16px'>
                  <Box
                    sx={{
                      borderRadius: '100%', background: 'rgba(75, 85, 99, 0.12)', padding: '8px', height: '40px', width: '40px', display: 'flex', alignSelf: 'flex-start', boxSizing: 'border-box'
                    }}
                  >
                    <AccountBoxOutlinedIcon sx={{ color: variables.colors.icon.standard }} />
                  </Box>
                  <Stack>
                    <Typography fontSize='18px' fontWeight='600'>
                      {selectedContact.fullName.firstName} {selectedContact.fullName.lastName}
                    </Typography>
                    <Stack flexDirection='row' gap='8px'>
                      <Typography variant='body2' color={variables.colors.text.secondary}>
                        Email
                      </Typography>
                      <Typography variant='body2'>
                        {selectedContact.email}
                      </Typography>
                    </Stack>
                    <Stack flexDirection='row' gap='8px'>
                      <Typography variant='body2' color={variables.colors.text.secondary}>
                        Mobile
                      </Typography>
                      <Typography variant='body2'>
                        {selectedContact.mobile}
                      </Typography>
                    </Stack>
                    <Stack flexDirection='row' gap='8px'>
                      <Typography variant='body2' color={variables.colors.text.secondary}>
                        Phone
                      </Typography>
                      <Typography variant='body2'>
                        {selectedContact.phone}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            ) : (
              <Stack justifyContent='flex-start' border={`1px dashed ${variables.colors.primary.lighter}`} height='200px' borderRadius='8px' padding='16px' gap='30px' width='100%'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  Your selected Contact will be displayed here.
                </Typography>
                <Stack height='fit-content' flexDirection='row' gap='16px'>
                  <Skeleton animation={isLoading ? 'wave' : false} variant="circular" width={48} height={48} />
                  <Stack>
                    <Skeleton animation={isLoading ? 'wave' : false} width={126} height={20} />
                    <Skeleton animation={isLoading ? 'wave' : false} width={214} height={16} />
                    <Skeleton animation={isLoading ? 'wave' : false} width={202} height={16} />
                    <Skeleton animation={isLoading ? 'wave' : false} width={137} height={16} />
                  </Stack>
                </Stack>
              </Stack>
            )}
            <Stack width='100%' gap='24px'>
              <Controller
                name="relationshipType"
                control={control}
                rules={{
                  required: 'Relationship is required',
                }}
                render={({ field }) =>
                  <RelationshipTypeLookupField
                    {...field}
                    id='relationshipType'
                    label='Relationship'
                    placeholder='Select'
                    validationError={errors.relationshipType ? true : false}
                    errorText={errors.relationshipType?.message}
                    isMandatory
                  />
                }
              />
              <Stack>
                <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
                  Contact Tags
                </Typography>
                <Controller
                  name="isPrimaryContact"
                  control={control}
                  render={({ field }) =>
                    <CheckboxInput
                      {...field}
                      id='isPrimaryContact'
                      label="Primary Contact"
                      error={errors.isPrimaryContact ? true : false}
                      checked={field.value ? true : false}
                    />}
                />
                <Controller
                  name="isEmergencyContact"
                  control={control}
                  render={({ field }) =>
                    <CheckboxInput
                      {...field}
                      id='isEmergencyContact'
                      label="Emergency Contact"
                      error={errors.isEmergencyContact ? true : false}
                      checked={field.value ? true : false}
                    />}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </DetailsStack>
      <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' boxSizing='border-box'>
        <Button variant='text' onClick={handleCancel}>Cancel</Button>
        <Button variant='contained' type='submit'>Link Contact</Button>
      </Stack>
    </form>
  );
};

export type ContactAndParticipantLinkFormProps = ExternalContact & ParticipantAndContactLink;

// This component creates a new contact and links it to the participant
export const CreateContact: FC = () => {
  const navigate = useNavigate();
  const handleCancel = () => navigate(-1);
  const [, closeModal] = useAtom(closeModalAtom);
  const [selectedContactId, setSelectedContactId] = useState<ExternalContact | null>(null);
  const [, setModalState] = useAtom(modalStateAtom);
  const [organisations] = useUserOrganisations();
  const [currentUser] = useCurrentUser();
  const { id } = useParams();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();
  const { control, handleSubmit, formState: { errors, isDirty } } = useForm({
    mode: 'onChange',
    defaultValues: {
      isActive: true, // Default value for isActive is required for the IsActiveDropdown component
      createdBy: currentUser?.id || ''
    } as ContactAndParticipantLinkFormProps
  });

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: AlreadyExistsModal,
      props: {
        selectedContactId
      }
    }));
  }, [setModalState]);

  const handleCreateExternalContact = async (data: ExternalContact) => {
    try {
      const resp = await createExternalContact(organisations[0].organisation.globalId, data);
      setInvalidateData((prev) => ({ ...prev, 'external-contacts': true }));
      return resp;
    } catch (error) {
      showError((error as Error).message);
    }
  };

  const handleLinkContactAndParticipant = async (data: CreateParticipantAndContactLink, contactId: string) => {
    try {
      await linkParticipantToExternalContact(
        organisations[0].organisation.globalId,
        contactId,
        id as string,
        data
      );
      setInvalidateData((prev) => ({ ...prev, 'external-contacts': true, 'participant-contacts': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  const handleCreateNewContact = async (data: ContactAndParticipantLinkFormProps) => {

    const contactData = {
      createdBy: data.createdBy,
      createdDateTime: new Date().toISOString(),
      fullName: {
        firstName: data.fullName.firstName,
        lastName: data.fullName.lastName,
      },
      email: data.email,
      mobile: data.mobile,
      phone: data.phone,
      relationship: data.relationship,
      isPrimaryContact: data.isPrimaryContact,
      isEmergencyContact: data.isEmergencyContact,
      isActive: data.isActive,
      linkedParticipants: [],
      linkedParticipantsCount: 0,
    } as ExternalContact;

    const linkData = {
      isEmergencyContact: data.isEmergencyContact,
      isPrimaryContact: data.isPrimaryContact,
      relationshipType: data.relationship,
      createdBy: data.createdBy,
    } as CreateParticipantAndContactLink;

    try {
      const resp = await handleCreateExternalContact(contactData);
      if (resp.success) {
        await handleLinkContactAndParticipant(linkData, resp?.externalContactId as string);
        closeModal();
      } else {
        setSelectedContactId(resp.externalContactId);
        handleModalOpen();
      }
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(handleCreateNewContact)}
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px'
      }}
    >
      <DetailsStack>
        <Stack gap='8px'>
          <Typography variant='subtitle1'>
            Add New Contact
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Contacts will be created in the Contacts Console list and establish a link to Participants Contacts for seamless integration.
          </Typography>
        </Stack>
        <Stack gap='20px' flexDirection='row'>
          <Controller
            name="fullName.firstName"
            control={control}
            rules={{ required: 'First Name is required' }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='firstName'
                label='First Name'
                error={errors.fullName?.firstName ? true : false}
                errorText={errors.fullName?.firstName?.message}
                isMandatory
              />
            }
          />
          <Controller
            name="fullName.lastName"
            control={control}
            rules={{ required: 'Last Name is required' }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='lastName'
                label='Last Name'
                error={errors.fullName?.lastName ? true : false}
                errorText={errors?.fullName?.lastName?.message}
                isMandatory
              />
            }
          />
        </Stack>
        <Stack gap='20px' flexDirection='row'>
          <Controller
            name="relationship"
            control={control}
            rules={{
              required: 'Relationship is required',
            }}
            render={({ field }) =>
              <RelationshipTypeLookupField
                {...field}
                id='relationship'
                label='Relationship'
                placeholder='Select'
                validationError={errors.relationship ? true : false}
                errorText={errors.relationship?.message}
                isMandatory
              />
            }
          />
          <Controller
            name="email"
            control={control}
            rules={{
              required: 'Email is required',
              pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: 'Invalid Email Address' },
            }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='email'
                label='Email'
                error={errors.email ? true : false}
                errorText={errors?.email?.message}
                isMandatory
              />
            }
          />
        </Stack>
        <Stack gap='20px' flexDirection='row'>
          <Controller
            name="mobile"
            control={control}
            rules={{
              required: 'Mobile is required',
              pattern: { value: /^[0-9]{10}$/, message: 'Invalid mobile number' },
            }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='mobile'
                label='Mobile'
                error={errors.mobile ? true : false}
                errorText={errors?.mobile?.message}
                isMandatory
              />
            }
          />
          <Controller
            name="phone"
            control={control}
            rules={{ required: 'Phone is required' }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='phone'
                label='Phone'
                error={errors.phone ? true : false}
                errorText={errors?.phone?.message}
                isMandatory
              />
            }
          />
        </Stack>
        <Stack>
          <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
            Contact Tags
          </Typography>
          <Stack gap='20px' flexDirection='row'>
            <Controller
              name="isPrimaryContact"
              control={control}
              render={({ field }) =>
                <CheckboxInput
                  {...field}
                  id='isPrimaryContact'
                  label="Primary Contact"
                  error={errors.isPrimaryContact ? true : false}
                  checked={field.value ? true : false}
                />}
            />
            <Controller
              name="isEmergencyContact"
              control={control}
              render={({ field }) =>
                <CheckboxInput
                  {...field}
                  id='isEmergencyContact'
                  label="Emergency Contact"
                  error={errors.isEmergencyContact ? true : false}
                  checked={field.value ? true : false}
                />}
            />
          </Stack>
        </Stack>
        <Stack flexDirection='row' justifyContent='flex-start' alignItems='center' gap='16px'>
          <Typography variant='subtitle2' fontWeight='500'>
            Status
          </Typography>
          <Controller
            name="isActive"
            control={control}
            rules={{
              validate: value => value !== undefined && value !== null || 'Status is required'
            }}
            render={({ field }) =>
              <IsActiveDropdown
                isActive={(field.value === undefined || field.value === null) ? true : field.value}
                setIsActive={field.onChange}
              />
            }
          />
        </Stack>
      </DetailsStack>
      <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' boxSizing='border-box'>
        <Button variant='text' onClick={handleCancel}>Cancel</Button>
        <Button variant='contained' type='submit'>Create and Link Contact</Button>
      </Stack>
    </form >
  );
};

export const AlreadyExistsModal = ({ selectedContactId }: { selectedContactId: string }) => {
  console.log('🚀 ~ AlreadyExistsModal ~ selectedContactId:', selectedContactId);
  const [, closeModal] = useAtom(closeModalAtom);

  const handleLink = () => {
    console.log('Linked contact from Participant', selectedContactId);
    closeModal();
  };

  return (
    <Stack alignItems="center" width='500px'>
      <Stack flexDirection="row" alignItems="center" gap='24px' sx={{ padding: '32px' }}>
        <Box sx={{ borderRadius: '100%', background: '#FFECB3', padding: '8px', height: '40px', width: '40px', display: 'flex', alignSelf: 'flex-start', boxSizing: 'border-box' }}>
          <PersonAddDisabledOutlinedIcon color='warning' />
        </Box>
        <Stack gap='16px'>
          <Typography variant='h6'>
            Contact already in the system
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            The contact was found in the system. Would you like to link this contact with the participant?
          </Typography>
        </Stack>
      </Stack>
      <Box sx={{ padding: '0 32px 32px 32px', width: '100%' }}>
        <Stack flexDirection='row' border={`1px dashed ${variables.colors.primary.lighter}`} borderRadius='8px' padding='16px' gap='16px' width='100%' sx={{ boxSizing: 'border-box' }}>
          <Box sx={{
            borderRadius: '100%', background: 'rgba(75, 85, 99, 0.12)', padding: '8px', height: '40px', width: '40px', display: 'flex', alignSelf: 'flex-start', boxSizing: 'border-box'
          }}>
            <AccountBoxOutlinedIcon sx={{ color: variables.colors.icon.standard }} />
          </Box>
          <Stack>
            <Typography fontSize='18px' fontWeight='600'>
              Full Name
            </Typography>
            <Stack flexDirection='row' gap='8px'>
              <Typography variant='body2' color={variables.colors.text.secondary}>
                Email
              </Typography>
              <Typography variant='body2'>
                Email
              </Typography>
            </Stack>
            <Stack flexDirection='row' gap='8px'>
              <Typography variant='body2' color={variables.colors.text.secondary}>
                Mobile
              </Typography>
              <Typography variant='body2'>
                Mobile
              </Typography>
            </Stack>
            <Stack flexDirection='row' gap='8px'>
              <Typography variant='body2' color={variables.colors.text.secondary}>
                Phone
              </Typography>
              <Typography variant='body2'>
                Phone
              </Typography>
            </Stack>
            <Stack flexDirection='row' gap='8px'>
              <Typography variant='body2' color={variables.colors.text.secondary}>
                Service Provider
              </Typography>
              <Typography variant='body2'>
                Service Provider
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box >
      <ButtonStack>
        <Button variant='outlined' onClick={closeModal}>Cancel</Button>
        <Button variant='contained' color='primary' onClick={handleLink}>Link this contact</Button>
      </ButtonStack>
    </Stack >
  );
};

const ButtonStack = styled(Stack)`
  flex-direction: row;
  width: 100%;
  padding: 16px 32px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px 0px 12px 12px;
  background: #F9FAFB;
  box-sizing: border-box;
`;

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  background-color: ${variables.colors.primary.darker};
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  color: white;
  position: fixed;
  padding: 16px 32px;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 70px;
`;

const MaxWidthContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  max-width: 960px;
`;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  height: fit-content;
  background-color: #F3F4F6;
  margin-top: 70px;
  min-height: calc(100vh - 70px);
`;

const DetailsStack = styled(Stack)`
  background-color: white;
  padding: 32px;
  gap: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
`;

export default LinkContactToParticipantForm;