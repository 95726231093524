import React, { FC } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { Participant } from 'types/dbSchema/participants';
import SelectInput from 'components/common/InputFields/SelectInput';
import AddressLookupField from 'components/common/InputFields/AddressLookupField';
import { Address } from 'types';

export interface AddressDetailsProps {
  control: Control<Participant>;
  errors: FieldErrors<Participant>;
  setValue: UseFormSetValue<Participant>;
}

const AddressDetails: FC<AddressDetailsProps> = ({ control, errors, setValue }) => {

  const handleSetAddress = (values: Address) => {
    setValue('homeAddress', { ...values, isPrimary: true }, { shouldValidate: true });
  };

  const handleSetOtherAddress = (values: Address) => {
    setValue('otherAddress', values, { shouldValidate: true });
  };

  return (
    <AddressDetailsStack>
      <Typography variant='h6' fontWeight='600' color='text.primary'>
        Address Details
      </Typography>
      <Row>
        <Controller
          name="serviceRegion"
          control={control}
          rules={{ required: 'Service Region is required' }}
          render={({ field }) =>
            <SelectInput
              {...field}
              id='serviceRegion'
              label="Service Region"
              error={errors.serviceRegion ? true : false}
              errorText={errors.serviceRegion?.message}
              placeholder='Select'
              isMandatory
              options={[
                { value: 'He/Him', label: 'He/Him' },
                { value: 'She/Her', label: 'She/Her' },
                { value: 'They/Them', label: 'They/Them' },
                { value: 'Other', label: 'Other' },
              ]}
            />}
        />
      </Row>
      <Stack>
        <Controller
          name="homeAddress"
          control={control}
          rules={{ required: 'Home Address is required' }}
          render={({ field }) =>
            <AddressLookupField
              {...field}
              id='homeAddress'
              setValue={handleSetAddress}
              label='Home Address'
              isMandatory
              error={errors.homeAddress ? true : false}
              errorText='Home Address is required'
            />
          }
        />
      </Stack>
      <Stack>
        <Controller
          name="otherAddress"
          control={control}
          render={({ field }) =>
            <AddressLookupField
              {...field}
              id='otherAddress'
              setValue={handleSetOtherAddress}
              label='Other Address'
              error={errors.otherAddress ? true : false}
              errorText='Other Address is required'
            />
          }
        />
      </Stack>
    </AddressDetailsStack>
  );
};

const AddressDetailsStack = styled(Stack)`
  background-color: white;
  padding: 32px;
  gap: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
`;

const Row = styled(Stack)`
  gap: 24px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export default AddressDetails;