import React from 'react';
import { LinearProgress } from '@mui/material';
import ServiceTeamAvailability from 'components/features/Scheduling/ServiceTeamSchedule/ServiceTeamAvailability';
import { User } from 'types/dbSchema/userProfiles';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { getUserById } from 'api/organisations/users';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useParams } from 'react-router-dom';

const ViewServiceTeamAvailabilityPage = () => {
  const [organisations] = useUserOrganisations();
  const { id } = useParams();

  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
    getByIDFunc: () => getUserById(organisations[0].organisation.globalId, id as string)
  });

  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return <ServiceTeamAvailability serviceTeamMember={currentDataPoint as User} />;
};

export default ViewServiceTeamAvailabilityPage;