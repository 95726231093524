import { PaginationBase } from "types";

export type DisplayLocation = "Participant Profile page" | "Service Allocation page" | "Schedule of Supports page" | "Skymo mobile app";
export type Frequency = "Daily" | "Weekly" | "Hourly";

export const displayLocationOptions: DisplayLocation[] = ["Participant Profile page", "Service Allocation page", "Schedule of Supports page", "Skymo mobile app"];
export const frequencyOptions: Frequency[] = ["Hourly", "Daily", "Weekly"];

export interface ParticipantAlert {
  id: string,
  content: string,
  isActive: boolean,
  frequency: Frequency,
  isDismissed: true,
  createdAt: string, // This format 2024-09-30T00:28:45.123Z
  updatedAt: string, // This format 2024-09-30T00:28:45.123Z
  displayLocations: DisplayLocation[]
}

export type ParticipantAlertList = PaginationBase<ParticipantAlert>;