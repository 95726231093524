import React, { FC } from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ServiceContract } from 'types/dbSchema/serviceContracts';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';

export interface ViewIconProps {
  serviceContractToView: ServiceContract;
}

const ViewIcon: FC<ViewIconProps> = ({ serviceContractToView }) => {
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const { id, serviceContractId } = useParams();

  const handleModalOpen = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'right',
      component: null,
      props: {
        dataTableName: 'service-contracts-budget-notes',
        type: 'view',
        participantId: id,
        serviceContractId,
      }
    });
  };


  return (
    <StyledBox onClick={() => handleModalOpen()} >
      <RemoveRedEyeOutlinedIcon />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export default ViewIcon;