import React from 'react';

// This is a placeholder page for pages that are not yet implemented.
const PlaceholderPage = ({ label }: { label: string }) => {
  return (
    <div>
      <h1>{label}</h1>
      <p>This page is a placeholder and is not yet implemented.</p>
    </div>
  );
};

export default PlaceholderPage;