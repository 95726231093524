import React, { useState } from 'react';
import ShiftApprovalList from './ShiftApprovalList';
import { Button, Stack, Typography } from '@mui/material';
import ShiftApprovalDetails from './ShiftApprovalDetails';
import { Service } from 'types/dbSchema/services';
import variables from 'styles/variables';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

const ShiftApproval = () => {
  const [currentService, setCurrentService] = useState<Service | null>(null);

  return (
    <Stack sx={{ backgroundColor: 'white' }} height='100%'>
      <Stack
        flexDirection='row'
        gap='16px'
        justifyContent='space-between'
        padding='16px'
        borderBottom={`1px solid ${variables.colors.lightNeutral.darker}`}
      >
        <Stack flexDirection='row' gap='16px'>
          <Typography variant='h5'>Shift Approval</Typography>
        </Stack>
        <Button variant='outlined' color='primary' startIcon={<FileUploadOutlinedIcon />}>Export Summary</Button>
      </Stack>
      <Stack flexDirection='row' height='100%'>
        <ShiftApprovalList currentService={currentService} setCurrentService={setCurrentService} />
        {currentService ?
          <ShiftApprovalDetails currentService={currentService} setCurrentService={setCurrentService} /> :
          <Stack flexDirection='row' height='100%' width='100%' alignItems='center' justifyContent='center'>
            <Typography variant='body1' padding='16px'>Select a Service to review the details</Typography>
          </Stack>
        }
      </Stack>
    </Stack>
  );
};

export default ShiftApproval;