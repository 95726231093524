import React, { FC } from 'react';
import { AvailabilityTemplate } from 'types/dbSchema/availabilitySettings';
import { Button, Stack } from '@mui/material';
import { CalendarEvent, TemplateEvent } from 'types/fullCalendar';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import TextInput from 'components/common/InputFields/TextInput';
import TextareaInput from 'components/common/InputFields/TextareaInput';
import AvailableTimes from '../AvailableTimes';
import useErrorMessage from 'hooks/useErrorMessage';
import { checkForEventOverlaps, validateCalendarEvents, validateEventTimes } from 'utils/helpers/fullCalendarTemplates';
import { useAtom } from 'jotai';
import { userMessageAtom } from 'atoms';

export interface SideControlsProps {
  events: any[];
  setEvents: React.Dispatch<React.SetStateAction<CalendarEvent[]>>;
  availabilityTemplate?: AvailabilityTemplate;
}

const SideControls: FC<SideControlsProps> = ({ events, setEvents, availabilityTemplate }) => {
  const navigate = useNavigate();
  const showError = useErrorMessage();
  const [, setUserMessage] = useAtom(userMessageAtom);
  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: availabilityTemplate || {
      isActive: true,
    }
  });

  const handleCreate = () => {
    console.log('🚀 ~ events:', events);
    try {
      console.log('Create'); // TODO: add api call here
      navigate('/settings/availability-settings');
    } catch (error) {
      showError((error as Error).message);
    }
  };

  const handleEdit = () => {
    console.log('🚀 ~ events:', events);
    try {
      console.log('Edit'); // TODO: add api call here
      navigate('/settings/availability-settings');
    } catch (error) {
      showError((error as Error).message);
    }
  };

  const validateSubmission = () => {
    const isValidEvents = validateCalendarEvents(events);
    const hasOverlaps = checkForEventOverlaps(events);
    if (hasOverlaps) {
      setUserMessage({
        message: `Overlapping events detected. Please resolve the overlaps before ${availabilityTemplate ? `updating` : `saving`}.`,
        title: "Warning",
        variant: "warning",
        open: true,
        autoHideDuration: 5000,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      });
      return false;
    }
    if (!isValidEvents) {
      setUserMessage({
        message: `Invalid times detected. Please ensure that the start and end times are included for each event.`,
        title: "Warning",
        variant: "warning",
        open: true,
        autoHideDuration: 5000,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      });
      return false;
    }
  };

  const onSubmit = (data: any) => {
    const isValid = validateSubmission();
    if (!isValid) return;
    if (availabilityTemplate) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <StyledSideControls>
        <Stack gap='32px' sx={{ overflowY: 'auto' }} padding='32px'>
          <Controller
            name="name"
            control={control}
            rules={{ required: 'Template is required' }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='templateName'
                label='Template Name'
                error={errors.name ? true : false}
                errorText={errors?.name?.message}
                isMandatory
              />
            }
          />
          <Controller
            name="description"
            control={control}
            render={({ field }) =>
              <TextareaInput
                {...field}
                id='description'
                label='Description'
                error={errors.description ? true : false}
                errorText={errors?.description?.message}
              />
            }
          />
          <AvailableTimes events={events} setEvents={setEvents} />
        </Stack>
        <ButtonStack>
          <Button variant='outlined' onClick={() => navigate(-1)}>Cancel</Button>
          <Button variant='contained' color={availabilityTemplate ? 'secondary' : 'primary'} type='submit'>
            {availabilityTemplate ? 'Update' : 'Create'}
          </Button>
        </ButtonStack>
      </StyledSideControls>
    </form>
  );
};

const StyledSideControls = styled(Stack)`
  box-shadow: 0px 2px 8px 0px rgba(55, 65, 81, 0.05), 0px 4px 20px 0px rgba(55, 65, 81, 0.10);
  background-color: white;
  width: 400px;
  min-width: 400px;
  height: 100%;
  justify-content: space-between;
`;

const ButtonStack = styled(Stack)`
  gap: 16px;
  justify-content: flex-end;
  flex-direction: row;
  padding: 24px;
`;

export default SideControls;