import React, { forwardRef } from 'react';
import { Checkbox as MUICheckbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { CheckboxInputProps } from './types';
import variables from 'styles/variables';

// The CheckboxInput component is a wrapper around MUI's Checkbox component. It uses the forwardRef function to forward the ref to the underlying MUI CheckboxInput component.
const CheckboxInput = forwardRef<HTMLButtonElement, CheckboxInputProps>(({
  label,
  id,
  error,
  helperText,
  errorText,
  checked,
  isMandatory,
  onChange,
  ...props }, ref) => {
  return (
    <FormControl data-testid={`${id}-checkbox`} sx={{ minWidth: 'fit-content' }}>
      <FormControlLabel
        label={
          <>
            {label} {isMandatory && <span style={{ color: 'red' }}>*</span>}
          </>
        }
        sx={{
          '& .MuiFormControlLabel-label': {
            fontSize: '14px !important'
          }
        }}
        control={
          <MUICheckbox
            onChange={onChange}
            checked={checked}
            ref={ref}
            id={id}
            {...props}
          />
        }
      />
      {(error && errorText) ? <FormHelperText sx={{ color: variables.colors.error.main }}>{errorText}</FormHelperText>
        : helperText && <FormHelperText>{helperText}</FormHelperText>
      }
    </FormControl>
  );
});

// We need to set the displayName for the forwardRef to work correctly
CheckboxInput.displayName = 'CheckboxInput';

export default CheckboxInput;