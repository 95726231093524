import axiosInstance from 'api/config';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { User, UsersList } from 'types/dbSchema/userProfiles';
import { UserRole, UserRoleCountList, UserRoleList } from 'types/dbSchema/userRoles';
import { createFilterString } from 'utils/helpers';

// Returns a list of users for the given organisation ID
export const listUsers = async (organisationId: string, filters: SelectedFilter[] | null, page: number, pageSize: number): Promise<UsersList> => {
  // Create a formatted filter string
  let formattedFilters = '';
  if (filters && filters.length > 0) {
    formattedFilters = createFilterString(filters);
  }

  try {
    if (!organisationId) {
      throw new Error('Organisation ID is required');
    }

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/users`, {
      params: {
        filters: formattedFilters,
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing users for the organisation', error);
    throw error;
  }
};

// Assigns a role to a user
export const assignRoleToUser = async (organisationId: string, userId: string, roleName: string) => {
  try {
    if (!roleName || !userId || !organisationId) throw new Error('Organisation ID, User ID, and Role Name are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/users/${userId}/roles`, {
      params: {
        roleName
      }
    });
    return response.data;
  } catch (error) {
    console.error('There was an error assigning a role to the user', error);
    throw error;
  }
};

// Retrieves a user by ID
export const getUserById = async (organisationId: string, userId: string): Promise<User> => {
  try {
    if (!userId || !organisationId) throw new Error('Organisation ID and User ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/users/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error('There was an error retrieving the user profile');
  }
};

// Updates a user by ID
export const updateUserById = async (organisationId: string, userId: string, data: Partial<any>) => {
  try {
    if (!userId || !organisationId || !data) throw new Error('Organisation ID, User ID, and data are required');

    const response = await axiosInstance.patch(`/api/organisations/${organisationId}/users/profile/${userId}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the user profile', error);
    throw error;
  }
};

// Create new User Profile
export const createUserProfile = async (organisationId: string, data: any) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/users/profile`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating a new user profile', error);
    throw error;
  }
};

// Get a list of role templates for the given organisation ID
export const getRoleTemplateList = async (organisationId: string, filters: SelectedFilter[] | null): Promise<UserRoleList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID is required');
    // Create a formatted filter string
    let formattedFilters = '';
    if (filters && filters.length > 0) {
      formattedFilters = createFilterString(filters);
    }


    const response = await axiosInstance.get(`/api/organisations/${organisationId}/users/role-templates`, {
      params: {
        filters: formattedFilters
      }
    });
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the role templates', error);
    throw error;
  }
};

// Get a list of role templates for the given organisation ID with user count
export const getRoleTemplateUserCount = async (organisationId: string): Promise<UserRoleCountList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID is required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/users/role-templates/count`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the role templates', error);
    throw error;
  }
};

export interface BulkUpdateUserField {
  fieldName: string;
  newValue: string;
  userProfileIDs: string[];
}

// Bulk update a field for a list of users
export const bulkUpdateUserField = async (organisationId: string, data: BulkUpdateUserField) => {
  try {
    if (!organisationId) throw new Error('Organisation ID is required');
    if (!data || !data.fieldName || !data.newValue || data.userProfileIDs.length === 0) throw new Error('Data is required');

    const response = await axiosInstance.patch(`/api/organisations/${organisationId}/users/bulk-update`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the role templates', error);
    throw error;
  }
};

export const deleteUserProfile = async (organisationId: string, userId: string) => {
  try {
    if (!organisationId || !userId) throw new Error('Organisation ID and UserID are required');

    const response = await axiosInstance.delete(`/api/organisations/${organisationId}/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the role templates', error);
    throw error;
  }
};