import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Stack, Typography } from '@mui/material';
import Label from 'components/common/Label';
import UserProfileCell from 'components/features/UserProfileCell';
import { UserRole } from 'types/dbSchema/userRoles';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import StatusDropdown from 'components/common/StatusDropdown';
import { userProfileStatuses } from 'types/dbSchema/userProfiles';

export const Columns: GridColDef[] = [
  {
    field: 'combinedInfo',
    headerName: 'Name',
    width: 350,
    renderCell: (params) => (
      <UserProfileCell
        userProfile={params.row}
      />
    ),
    sortable: false,
  },
  { field: 'primaryMobile', headerName: 'Mobile Number', width: 150 },
  {
    field: 'userRoles',
    headerName: 'User Roles',
    minWidth: 300,
    renderCell: (params) => (
      <Stack direction='row' gap='8px'>
        {params.row.userRoles.map((type: UserRole, index: number) => (
          <Label key={index} text={type.name} />
        ))}
      </Stack>
    ),
  },
  {
    field: 'position',
    headerName: 'Position',
    width: 200,
    renderCell: (params) => (
      <Typography variant='body2'>
        {params.row.position && params.row.position.name}
      </Typography>
    ),
  },
  {
    field: 'userTypes',
    headerName: 'User Types',
    minWidth: 300,
    flex: 1,
    renderCell: (params) => (
      <Stack direction='row' gap='8px'>
        {params.row.webAppUserStatus && <Label text='Web App User' />}
        {params.row.mobileAppUserStatus && <Label text='Mobile App User' />}
      </Stack>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => (
      <StatusDropdown
        statuses={userProfileStatuses}
        currentStatus={params.row.status}
        disabled
      />
    ),
  },
];