import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import variables from 'styles/variables';
import { formatISODateTime } from 'utils/helpers';
import { userProfileStatuses, User } from 'types/dbSchema/userProfiles';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Line from 'components/common/Line';
import StatusDropdown from 'components/common/StatusDropdown';
import { UserRole } from 'types/dbSchema/userRoles';

export interface AccessDetailsProps {
  userProfile: User;
}

const AccessDetails: FC<AccessDetailsProps> = ({ userProfile }) => {
  return (
    <AccessDetailsStack>
      <UserProfileStatus userProfile={userProfile} />
      <WebAppUserDetails userProfile={userProfile} />
      <MobileAppUserDetails userProfile={userProfile} />
    </AccessDetailsStack>
  );
};

const UserProfileStatus: FC<AccessDetailsProps> = ({ userProfile }) => {
  return (
    <StatusStack>
      <Typography variant='subtitle2' fontSize='18px' fontWeight='600'>
        Status
      </Typography>
      <StatusDropdown
        statuses={userProfileStatuses}
        currentStatus={userProfile.status}
        disabled
      />
    </StatusStack>
  );
};

const WebAppUserDetails: FC<AccessDetailsProps> = ({ userProfile }) => {
  return (
    <WebAppUserDetailsStack>
      <Stack
        gap='8px'
        width='100%'
        flexDirection='row'
        padding='16px 24px'
        sx={{
          backgroundColor: '#5C068C0A',
          boxSizing: 'border-box',
          borderRadius: '8px 8px 0 0',
          borderBottom: '1px solid #D4A3E3'
        }}
      >
        <Typography variant='subtitle2'>
          Web App User
        </Typography>
      </Stack>
      <Line justifyContent='space-between' alignItems='center'>
        <Stack flexDirection='row' gap='8px' width='fit-content'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Access
          </Typography>
          <Typography variant='subtitle2'>
            {userProfile.webAppUserStatus ? 'Enabled' : 'Disabled'}
          </Typography>
        </Stack>
        {userProfile.webAppUserStatus ? <CheckCircleOutlinedIcon color='success' /> : <CancelOutlinedIcon color='error' />}
      </Line>
      <Line>
        <Typography variant='body2' color={variables.colors.text.secondary}>
          User Role
        </Typography>
        <Typography variant='subtitle2' color='variables.colors.text.main'>
          {(userProfile.userRoles as UserRole[]).map(role => role.name).join(', ')}
        </Typography>
      </Line>
      <Line noBottomBorder>
        <Typography variant='body2' color={variables.colors.text.secondary}>
          Last Login
        </Typography>
        <Typography variant='body2'>
          {userProfile?.lastLoginTime ? formatISODateTime(userProfile.lastLoginTime) : 'Never'}
        </Typography>
      </Line>
    </WebAppUserDetailsStack>
  );
};

const MobileAppUserDetails: FC<AccessDetailsProps> = ({ userProfile }) => {
  return (
    <WebAppUserDetailsStack>
      <Stack
        gap='8px'
        width='100%'
        flexDirection='row'
        padding='16px 24px'
        sx={{
          backgroundColor: '#5C068C0A',
          boxSizing: 'border-box',
          borderRadius: '8px 8px 0 0',
          borderBottom: '1px solid #D4A3E3'
        }}
      >
        <Typography variant='subtitle2'>
          Mobile App User
        </Typography>
      </Stack>
      <Line justifyContent='space-between' alignItems='center'>
        <Stack flexDirection='row' gap='8px' width='fit-content'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Access
          </Typography>
          <Typography variant='subtitle2'>
            {userProfile.mobileAppUserStatus ? 'Enabled' : 'Disabled'}
          </Typography>
        </Stack>
        {userProfile.mobileAppUserStatus ? <CheckCircleOutlinedIcon color='success' /> : <CancelOutlinedIcon color='error' />}
      </Line>
      <Line noBottomBorder>
        <Typography variant='body2' color={variables.colors.text.secondary}>
          Last Login
        </Typography>
        <Typography variant='body2'>
          {userProfile?.lastLoginTimeMobileApp ? formatISODateTime(userProfile.lastLoginTimeMobileApp) : 'Never'}
        </Typography>
      </Line>
    </WebAppUserDetailsStack >
  );
};

const AccessDetailsStack = styled(Stack)`
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 40%;
  min-width: 400px;
  height: fit-content;
  @media (max-width: 1050px) {
    width: 50%;
    min-width: unset;
  }
`;

const WebAppUserDetailsStack = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #D4A3E3;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
`;

const StatusStack = styled(Stack)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #D4A3E3;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
`;

export default AccessDetails;