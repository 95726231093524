import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import ParticipantProfileCell from 'components/features/ParticipantProfileCell';
import ParticipantStatusFlow from 'components/features/ParticipantStatusFlow';

export const Columns: GridColDef[] = [
  {
    field: 'combinedInfo',
    headerName: 'Full Name',
    width: 350,
    renderCell: (params) => (
      <ParticipantProfileCell
        participant={params.row}
      />
    ),
    sortable: false,
  },
  { field: 'preferredName', headerName: 'Preferred Name', width: 200 },
  { field: 'mobile', headerName: 'Mobile Number', width: 200 },
  { field: 'ndisNumber', headerName: 'NDIS Number', width: 200 },
  { field: 'serviceRegion', headerName: 'Service Region', minWidth: 200, flex: 1 },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => (
      <ParticipantStatusFlow participant={params.row} />
    ),
  },
];