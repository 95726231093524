import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { LabelProps } from './types';

const Label: FC<LabelProps> = ({ text }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'rgba(33, 33, 33, 0.04)',
        padding: '2px 8px',
        borderRadius: '4px',
        width: 'fit-content',
      }}
    >
      <Typography variant='caption' fontWeight='600'>
        {text}
      </Typography>
    </Box>
  );
};

export default Label;