import { AppBar } from '@mui/material';
import React, { FC } from 'react';
import styled from 'styled-components';
import variables from 'styles/variables';
import { useNavigate } from 'react-router-dom';
import TopBarElements from './TopBarElements';
import { TopbarProps } from '../types';
import { useAtom } from 'jotai';
import { sidebarAtom } from 'atoms';

const Topbar: FC<TopbarProps> = ({ withSearchbar }) => {
  const [sidebarState, setSidebarState] = useAtom(sidebarAtom);
  const navigate = useNavigate();

  const handleLogoClick = () => {
    setSidebarState({
      ...sidebarState,
      content: null,
    });
    navigate('/');
  };

  return (
    <StyledAppBar position='relative'>
      <ImageBox onClick={handleLogoClick}>
        <img src='/assets/images/skymo-web-logo.png' alt='Skymo Logo' />
      </ImageBox>
      <TopBarElements withSearchbar={withSearchbar} />
    </StyledAppBar>
  );
};

const StyledAppBar = styled(AppBar)`
  height: ${variables.heights.topBar};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background-color: white;
  box-shadow: unset;
  border-bottom: 1px solid ${variables.colors.lightNeutral.darker};
  flex-direction: row;
  flex-wrap: wrap;
`;

const ImageBox = styled.div`
  width: auto;
  height: 100%;
  cursor: pointer;
  & img {
    height: 100%;
    object-fit: contain;
  }
`;

export default Topbar;