import React, { FC } from 'react';
import styled from 'styled-components';
import { FullScreenContainerProps } from './types';
import { Container } from '@mui/material';

// A component that takes up the full screen and has a flex container
const FullScreenContainer: FC<FullScreenContainerProps> = ({ flexDirection = 'column', children }) => (
  <StyledFullScreenContainer sx={{ flexDirection: flexDirection }} data-testid='fullscreenContainer'>
    {children}
  </StyledFullScreenContainer>
);

const StyledFullScreenContainer = styled(Container)`
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  max-width: unset;
  display: flex;
  overflow: hidden;
`;

export default FullScreenContainer;