import React, { useCallback } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import Tabs from 'components/common/Tabs';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import UpcomingLeave, { LeaveModal } from './UpcomingLeave';
import LeaveHistory from './LeaveHistory';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';

const ServiceTeamLeave = () => {
  const [, setModalState] = useAtom(modalStateAtom);

  // Function to open/close the modal.
  const handleCreate = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: LeaveModal,
      props: {
        dataTableName: 'leave-type',
        type: 'create',
      }
    }));
  }, [setModalState]);

  return (
    <Stack sx={{ backgroundColor: 'white', height: '100%', width: '100%' }} alignItems='center' padding='24px 0'>
      <Stack width='100%' maxWidth='1000px'>
        <Stack flexDirection='row' justifyContent='space-between' padding='0 24px'>
          <Typography variant='h6'>Leave</Typography>
          <Button variant='contained' startIcon={<AddOutlinedIcon />} onClick={handleCreate}>Add</Button>
        </Stack>
        <Tabs
          tabs={[
            {
              label: 'Upcoming',
              content: <UpcomingLeave />,
              requiredPermissions: [],
            },
            {
              label: 'History',
              content: <LeaveHistory />,
              requiredPermissions: [],
            },
          ]}
        />
      </Stack>
    </Stack>
  );
};

export default ServiceTeamLeave;