import { Box, CircularProgress } from '@mui/material';
import React, { FC, createElement } from 'react';
import styled from 'styled-components';
import { ModalBoxInnerContainerProps } from './types';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { closeModalAtom } from 'atoms/modalAtom';

const Modal: FC = () => {
  const [modalState] = useAtom(modalStateAtom);
  const [, closeModal] = useAtom(closeModalAtom);

  // Prevents the click event on the inner container from bubbling up to the outer container
  const handleInnerClick = (event: React.MouseEvent) => event.stopPropagation();

  if (modalState.status !== 'closed') return (
    <ModalBoxOuterContainer
      onClick={modalState.disableClickAway ? undefined : closeModal}
      data-testid="modal-outer-container"
      sx={{
        cursor: modalState.disableClickAway ? 'initial' : 'pointer',
      }}
    >
      <ModalBoxInnerContainer position={modalState.position} onClick={handleInnerClick} data-testid="modal-inner-container">
        {modalState.status == 'loading' && (
          <LoadingOverlay>
            <CircularProgress />
          </LoadingOverlay>
        )}
        {modalState.component && createElement(modalState.component, { ...modalState.props })}
      </ModalBoxInnerContainer>
    </ModalBoxOuterContainer>
  );

  return null;
};

export const ModalBoxOuterContainer = styled(Box)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(48, 29, 68, 0.30);
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 100000;
`;

const ModalBoxInnerContainer = styled(Box).withConfig({
  shouldForwardProp: (prop) => prop !== 'position',
}) <ModalBoxInnerContainerProps>`
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 1001;
  cursor: initial;
  max-height: ${({ position }) => position === 'center' && 'calc(100% - 32px)'};
  height: ${({ position }) => position === 'left' || position === 'right' ? 'calc(100% - 16px)' :
    position === 'center' ? 'fit-content' :
      '240px'};
  width: ${({ position }) => position === 'top' || position === 'bottom' ? 'calc(100% - 16px)' :
    position === 'center' ? 'fit-content' :
      '480px'};
  ${({ position }) => position === 'left' ? 'left: 8px; top: 8px; bottom: 0;' :
    position === 'right' ? 'right: 8px; top: 8px; bottom: 0;' :
      position === 'top' ? 'top: 0; left: 0; right: 0;' :
        position === 'bottom' ? 'bottom: 0; left: 0; right: 0;' :
          position === 'center' && 'top: 50%; left: 50%; transform: translate(-50%, -50%);'}
`;

const LoadingOverlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999999;
  width: 100%;
  height: 100%;
  background-color: rgba(48, 29, 68, 0.30);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Modal;