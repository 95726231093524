import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import styled from 'styled-components';
import variables from 'styles/variables';
import { EditOutlined } from '@mui/icons-material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Tabs from 'components/common/Tabs';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import { ParticipantGoal } from 'types/dbSchema/participantGoals';
import GoalDetails from './GoalDetails';
import LinkedProgressNotes from './LinkedProgressNotes';
import { isPastDate } from 'utils/helpers';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import { deleteParticipantGoalById } from 'api/organisations/participants/goals';
import { useAtom } from 'jotai';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import useUserOrganisations from 'hooks/useUserOrganisations';

export interface ViewParticipantsGoalProps {
  participantGoal: ParticipantGoal;
}

const ViewParticipantGoal: FC<ViewParticipantsGoalProps> = ({ participantGoal }) => {

  return (
    <Stack width='100%' justifyContent='flex-start' alignItems='center' height='100%' sx={{
      backgroundColor: 'white',
      minHeight: `calc(100vh - ${variables.heights.topBar})`,
      overflowY: 'auto',
    }}>
      <PageHeader participantGoal={participantGoal} />
      <Stack flexDirection='row' gap='24px' width='100%' height='100%' padding='0' >
        <Tabs tabs={[
          {
            label: 'Goal Details',
            content: <GoalDetails participantGoal={participantGoal} />,
            requiredPermissions: [],
          },
          {
            label: 'Linked Progress Notes',
            content: <LinkedProgressNotes />,
            requiredPermissions: [],
          },
        ]} />
      </Stack>
    </Stack>
  );
};

const PageHeader: FC<ViewParticipantsGoalProps> = ({ participantGoal }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <PageHeaderStack>
      <Button variant='text'
        sx={{
          color: variables.colors.text.secondary,
          textTransform: 'none',
          fontWeight: 400,
          padding: 0
        }}
        onClick={() => navigate(`/participant-centre/participants/view/${id}`)}
      >
        <ArrowBackOutlinedIcon sx={{
          padding: '4px',
          boxSizing: 'border-box',
          height: '24px',
          width: '24px',
        }}
        />
        Goals
      </Button>
      <PageHeaderDetails>
        <Typography variant='h5' sx={{ textWrap: 'wrap' }}>
          {participantGoal.goal}
        </Typography>
        <OverviewLabels participantGoal={participantGoal} />
      </PageHeaderDetails>
    </PageHeaderStack >
  );
};

const OverviewLabels: FC<ViewParticipantsGoalProps> = ({ participantGoal }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isReviewDataPast, setIsReviewDataPast] = useState(false);
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();
  const [organisations] = useUserOrganisations();
  const { id: participantID } = useParams();

  useEffect(() => {
    isPastDate(participantGoal.reviewDate) ? setIsReviewDataPast(true) : setIsReviewDataPast(false);
  }, [participantGoal.reviewDate]);

  const handleDelete = async () => {
    try {
      await deleteParticipantGoalById(organisations[0].organisation.globalId, participantID as string, participantGoal.id);
      setInvalidateData((prev) => ({ ...prev, 'participant-goals': true }));
      navigate(-1);
    } catch (error) {
      showError((error as Error).message);
    }
  };

  const handleEdit = () => {
    navigate(`/participant-centre/participants/view/${id}/goals/edit/${participantGoal.id}`);
  };

  const handleReview = () => {
    navigate(`/participant-centre/participants/view/${id}/goals/review/${participantGoal.id}`);
  };

  return (
    <OverviewLabelStack>
      {isReviewDataPast &&
        <Stack flexDirection='row' gap='8px' alignItems='center' minWidth='fit-content'>
          <WarningOutlinedIcon color='error' sx={{ height: '20px', width: '20px', }} />
          <Typography variant='subtitle2' color='error'>
            Review Due
          </Typography>
        </Stack>
      }
      <Button variant='contained' startIcon={<GradingOutlinedIcon />} onClick={handleReview} color='secondary' sx={{ minWidth: 'fit-content' }}>
        Review Goal
      </Button>
      <Button variant='contained' startIcon={<EditOutlined />} onClick={handleEdit} sx={{ minWidth: 'fit-content' }}>
        Edit Goal
      </Button>
      <StyledDeleteOutlinedIcon onClick={handleDelete} />
    </OverviewLabelStack>
  );
};

const PageHeaderStack = styled(Stack)`
  display: flex;
  width: 100%;
  padding: 32px 24px;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 8px;
`;

const PageHeaderDetails = styled(Stack)`
 flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;
  @media (max-width: 1044px) {
    flex-direction: column;
  }
`;

const OverviewLabelStack = styled(Stack)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  min-width: fit-content;
  @media (max-width: 1044px) {
    /* flex-direction: row-reverse; */
    align-self: flex-end;
  }
`;

const StyledDeleteOutlinedIcon = styled(DeleteOutlinedIcon)`
  color: ${variables.colors.text.secondary};
  cursor: pointer;
  font-size: 20px;
  transition: color 240ms;
  margin: auto 0;
  &:hover {
    color: ${variables.colors.text.main};
  }
`;

export default ViewParticipantGoal;