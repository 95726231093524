import React, { FC, useEffect, useRef, useState } from 'react';
import { Editor, EditorState, Modifier, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { Stack, Button, Typography, Box } from '@mui/material';
import styled from 'styled-components';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import FormatBoldOutlinedIcon from '@mui/icons-material/FormatBoldOutlined';
import FormatItalicOutlinedIcon from '@mui/icons-material/FormatItalicOutlined';
import FormatUnderlinedOutlinedIcon from '@mui/icons-material/FormatUnderlinedOutlined';
import { stateToHTML } from 'draft-js-export-html';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import variables from 'styles/variables';
import LoadingOverlay from 'components/common/LoadingOverlay';

export interface RichTextInputProps {
  characterLimit: number;
  buttonText: string;
  onSubmit: (text: string) => void;
  minHeight?: string;
  exposeInput?: (text: string) => void;
}

const RichTextInput: FC<RichTextInputProps> = ({ characterLimit, buttonText, onSubmit, minHeight, exposeInput }) => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [inputLength, setInputLength] = useState(0);
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const emojiButtonRef = useRef<SVGSVGElement>(null);
  const [dropUp, setDropUp] = useState(false);
  const [isCalculatingDropUp, setIsCalculatingDropUp] = useState(false);

  useEffect(() => {
    if (isEmojiOpen && emojiButtonRef.current) {
      const boundingRect = emojiButtonRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - boundingRect.bottom;
      const dropdownHeight = 300; // Approximate height of the emoji picker

      if (spaceBelow < dropdownHeight) {
        setDropUp(true);
      } else {
        setDropUp(false);
      }
    }
    setIsCalculatingDropUp(false);
  }, [isEmojiOpen]);

  const handleKeyCommand = (command: any, editorState: any) => {
    // We block all commands except bold, italic, underline, backspace and delete
    if (command !== 'bold' && command !== 'italic' && command !== 'underline' && command !== 'backspace' && command !== 'delete') return 'not-handled';
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  const updateInputLength = (editorState: EditorState) => {
    exposeInput && exposeInput(stateToHTML(editorState.getCurrentContent()));
    const contentState = editorState.getCurrentContent();
    const plainText = contentState.getPlainText('');
    setInputLength(plainText.length);
  };

  const onChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    updateInputLength(newEditorState);
  };

  const toggleEmoji = (e: any) => {
    e.preventDefault();
    setIsCalculatingDropUp(true);
    setIsEmojiOpen(!isEmojiOpen);
  };
  const toggleBold = (e: any) => {
    e.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
  };
  const toggleItalic = (e: any) => {
    e.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
  };
  const toggleUnderline = (e: any) => {
    e.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
  };
  const toggleDotList = (e: any) => {
    e.preventDefault();
    setEditorState(RichUtils.toggleBlockType(editorState, 'unordered-list-item'));
  };

  const handleEmojiSelect = (emoji: any) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const emojiText = emoji.native;
    const newContentState = Modifier.insertText(contentState, selection, emojiText);
    const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');
    const plainText = contentState.getPlainText('');
    setInputLength(plainText.length);
    setEditorState(newEditorState);
    setIsEmojiOpen(false);
  };

  const isStyleActive = (style: string) => {
    return editorState.getCurrentInlineStyle().has(style);
  };

  const handleClickOutside = (e: any) => {
    if (emojiButtonRef.current && !emojiButtonRef.current.contains(e.target)) {
      setIsEmojiOpen(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    onSubmit(stateToHTML(editorState.getCurrentContent()));
    setEditorState(EditorState.createEmpty());
    setInputLength(0);
    setIsLoading(false);
  };

  return (
    <>
      <Stack gap='16px' width='100%' justifyContent='space-between' position='relative' minHeight={minHeight}>
        <Editor
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          onChange={onChange}
          placeholder="Type a comment..."
        />
        <Stack flexDirection='row' gap='8px' justifyContent='space-between' width='100%' alignItems='center'>
          <ButtonGroup>
            <StyledEmojiEmotionsOutlinedIcon onMouseDown={toggleEmoji} ref={emojiButtonRef} sx={{ border: isEmojiOpen ? `2px solid  ${variables.colors.text.secondary}` : '2px solid white' }} />
            <StyledFormatBoldOutlinedIcon onMouseDown={toggleBold} sx={{ border: isStyleActive('BOLD') ? `2px solid  ${variables.colors.text.secondary}` : '2px solid white' }} />
            <StyledFormatItalicOutlinedIcon onMouseDown={toggleItalic} sx={{ border: isStyleActive('ITALIC') ? `2px solid  ${variables.colors.text.secondary}` : '2px solid white' }} />
            <StyledFormatUnderlinedOutlinedIcon onMouseDown={toggleUnderline} sx={{ border: isStyleActive('UNDERLINE') ? `2px solid  ${variables.colors.text.secondary}` : '2px solid white' }} />
            <StyledFormatListBulletedOutlinedIcon onMouseDown={toggleDotList} sx={{ border: '2px solid white' }} />
          </ButtonGroup>
          <Stack gap='8px' flexDirection='row' alignItems='center'>
            <Typography variant='body2' fontWeight='600' color='text.secondary'>
              {inputLength}/{characterLimit}
            </Typography>
            <Button variant='contained' color='primary' onClick={handleSubmit}>
              {buttonText}
            </Button>
          </Stack>
        </Stack>
        {isEmojiOpen &&
          // <Box position='absolute' zIndex={1} top='100%' left='0' paddingBottom='24px' sx={{ transform: 'translateY(calc(-100% - 28px))' }} ></Box>
          <EmojiBox dropUp={dropUp} isCalculatingDropUp={isCalculatingDropUp}>
            <Picker
              theme='light'
              data={data}
              onEmojiSelect={handleEmojiSelect}
              previewPosition='none'
              skinTonePosition='search'
              onClickOutside={handleClickOutside}
            />
          </EmojiBox>
        }
      </Stack >
      {isLoading && <LoadingOverlay />
      }
    </>
  );
};

export interface EmojiBoxProps {
  dropUp: boolean;
  isCalculatingDropUp: boolean;
}

const EmojiBox = styled(Box).withConfig({
  shouldForwardProp: (prop) => !['dropUp', 'isCalculatingDropUp'].includes(prop),
}) <EmojiBoxProps>`
  position: absolute;
  z-index: 1;
  top: ${({ dropUp }) => dropUp ? 'unset' : '100%'};
  bottom: ${({ dropUp }) => dropUp ? '100%' : 'unset'};
  left: 0;
  transform: ${({ dropUp }) => dropUp ? 'translateY(75px)' : 'none'};
  opacity: ${({ isCalculatingDropUp }) => isCalculatingDropUp ? '0' : '1'};
`;

const ButtonGroup = styled(Stack)`
  gap: 8px;
  flex-direction: row;
  & > * {
    cursor: pointer;
  }
`;

const StyledEmojiEmotionsOutlinedIcon = styled(EmojiEmotionsOutlinedIcon)`
  border-radius: 8px;
  height: 28px;
  width: 28px;
  color: ${variables.colors.text.secondary}
`;

const StyledFormatBoldOutlinedIcon = styled(FormatBoldOutlinedIcon)`
  border-radius: 8px;
  height: 28px;
  width: 28px;
  color: ${variables.colors.text.secondary}
`;

const StyledFormatItalicOutlinedIcon = styled(FormatItalicOutlinedIcon)`
  border-radius: 8px;
  height: 28px;
  width: 28px;
  color: ${variables.colors.text.secondary}
`;

const StyledFormatUnderlinedOutlinedIcon = styled(FormatUnderlinedOutlinedIcon)`
  border-radius: 8px;
  height: 28px;
  width: 28px;
  color: ${variables.colors.text.secondary}
`;

const StyledFormatListBulletedOutlinedIcon = styled(FormatListBulletedOutlinedIcon)`
  border-radius: 8px;
  height: 28px;
  width: 28px;
  color: ${variables.colors.text.secondary}
`;

export default RichTextInput;