import React, { FC } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components';
import NoDataOverlay from '../NoDataOverlay';
import { DataTableProps } from './types';
import variables from 'styles/variables';
import { Box, LinearProgress } from '@mui/material';

const DataTable: FC<DataTableProps> = ({
  data,
  columns,
  setSelectedRowIds,
  paginationModel,
  setPaginationModel,
  isPending,
  isError,
  title,
  dataTableName,
  checkboxSelection,
  paginationMode,
  onNoData,
}) => {
  return (
    <StyledDataGrid
      rows={data?.items || []}
      columns={columns}
      loading={isPending}
      // error={isError} // TODO: Add error handling when screen is completed
      pageSizeOptions={[5, 10, 20, 50, 100]}
      checkboxSelection={checkboxSelection}
      onRowSelectionModelChange={(newSelectionModel) => setSelectedRowIds && setSelectedRowIds(newSelectionModel)}
      disableColumnFilter
      disableRowSelectionOnClick
      pagination
      rowCount={data?.totalCount || 0}
      paginationMode={paginationMode}
      paginationModel={paginationModel}
      onPaginationModelChange={(pagination) => setPaginationModel(pagination)}
      slots={{
        loadingOverlay: () => <LoadingOverlay />,
        noRowsOverlay: () => (
          <NoDataOverlay
            onNoData={onNoData}
            title={title}
            subtitle="Try adjusting your filters or import data"
            dataTableName={dataTableName}
          />
        ),
      }}
    />
  );
};

// Loading overlay for the data grid
const LoadingOverlay = () => (
  <Box
    display='flex'
    justifyContent='center'
    alignItems='flex-start'
    width='100%'
    height='100%'
    position='relative'
  >
    <Box
      bgcolor='white'
      width='100%'
      height='100%'
      position='absolute'
      zIndex='99'
      sx={{ opacity: 0.5 }}
    />
    <LinearProgress sx={{ width: '100%', zIndex: 100 }} />
  </Box>
);

const StyledDataGrid = styled(DataGrid)`
  border: none;
  padding-left: 24px;
  width: 100%;
  height: 100%;
  & .MuiDataGrid-main {
    border: none;
  }
  & .MuiDataGrid-columnHeaderTitleContainer:focus {
    outline: none;
  }
  & .MuiDataGrid-columnHeader:focus {
    outline: none;
  }
  & .MuiDataGrid-row:hover {
    background-color: ${variables.colors.primary.main10};
  }
  & .MuiDataGrid-cell:focus {
    outline: none;
  }
  & .MuiDataGrid-cell:focus-within {
    outline: none;
  }
  // This fixes the overflow issue in the data grid for dropdowns
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
    overflow: unset;
  }
`;

export default DataTable;