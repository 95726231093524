import React, { FC } from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components';
import FullScreenContainer from '../FullScreenContainer';
import { ScreenWithImageProps } from './types';

const ScreenWithImage: FC<ScreenWithImageProps> = ({ component: Component, imageSrc }) => {
  return (
    <FullScreenContainer flexDirection="row" data-testid='screenWithImage'>
      <Component />
      <ImageBox>
        <img src={imageSrc} alt="Background image" />
      </ImageBox>
    </FullScreenContainer>
  );
};

const ImageBox = styled(Box)`
  height: 100%;
  width: 100%;
  img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
  }
`;

export default ScreenWithImage;