import { atom } from 'jotai';

export const userMessageAtom = atom<UserMessageAtomState>({
  title: '',
  message: '',
  variant: 'info',
  open: false,
  autoHideDuration: 6000,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
});

// Atom to close the modal
export const closeUserMessageAtom = atom(
  null, // no read function
  (get, set) => {
    // Use 'get' to access the current state if needed
    const currentUserMessageState = get(userMessageAtom);
    // Reset the modal state
    set(userMessageAtom, {
      ...currentUserMessageState,
      title: '',
      message: '',
      variant: 'info',
      open: false,
      autoHideDuration: 6000,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
      },
    });
  }
);

export type UserMessageAtomState = {
  title: string;
  message: string;
  variant: UserMessageVariant;
  open: boolean;
  autoHideDuration: number;
  anchorOrigin: AnchorOrigin;
};

export interface AnchorOrigin {
  vertical: AnchorOriginVertical;
  horizontal: AnchorOriginHorizontal;
}

export type UserMessageVariant = 'success' | 'info' | 'warning' | 'error';
export type AnchorOriginVertical = 'top' | 'bottom' | 'center';
export type AnchorOriginHorizontal = 'left' | 'center' | 'right';