import axiosInstance from "api/config";
import { ServiceTeamNoteType, ServiceTeamNoteTypeList } from "types/dbSchema/serviceTeamSettings";

// Returns a list of service team note types for the given organisation ID
export const listServiceTeamNoteTypes = async (organisationId: string, page?: number, pageSize?: number): Promise<ServiceTeamNoteTypeList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID, page, and page size are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/service-team/note-types`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing service team note types for the organisation', error);
    throw error;
  }
};

// Returns a service team note type by ID
export const getServiceTeamNoteTypeByID = async (organisationId: string, serviceTeamNoteTypeID: string): Promise<ServiceTeamNoteType> => {
  try {
    if (!serviceTeamNoteTypeID || !organisationId) throw new Error('Organisation ID and ServiceTeamNoteType ID are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/service-team/note-types/${serviceTeamNoteTypeID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the service team note type', error);
    throw error;
  }
};

// Create a new service team note type
export const createServiceTeamNoteType = async (organisationId: string, data: ServiceTeamNoteType) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/${organisationId}/settings/service-team/note-types`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the service team note type', error);
    throw error;
  }
};

// Update a serviceTeamNoteType
export const updateServiceTeamNoteType = async (organisationId: string, data: ServiceTeamNoteType) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.put(`/api/${organisationId}/settings/service-team/note-types/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the service team note type', error);
    throw error;
  }
};

// Delete a service team note type
export const deleteServiceTeamNoteType = async (organisationId: string, serviceTeamNoteTypeID: string) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.delete(`/api/${organisationId}/settings/service-team/note-types/${serviceTeamNoteTypeID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the service team note type', error);
    throw error;
  }
};