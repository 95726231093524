import React, { FC, useEffect, useRef, useState } from 'react';
import { FormLabel, Stack, Typography } from '@mui/material';
import variables from 'styles/variables';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FilterContainerProps } from 'components/common/FilterWithDropdown/types';
import { ViewDropdownProps } from '../types';

const ViewDropdown: FC<ViewDropdownProps> = ({ handleViewChange }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Pay Period');
  const options = [
    { label: 'Pay Period', value: 'payPeriod' },
    { label: 'Month', value: 'dayGridMonth' },
    { label: 'Week', value: 'timeGridWeek' },
    { label: 'Day', value: 'timeGridDay' },
    { label: 'List', value: 'listMonth' },
  ];

  const filterDropdownRef = useRef<HTMLDivElement>(null);
  const filterButtonRef = useRef<HTMLDivElement>(null);

  const handleFilterDropdown = (event: any) => {
    event.stopPropagation();
    setIsFilterOpen(!isFilterOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      filterButtonRef.current &&
      !filterButtonRef.current.contains(event.target as Node) &&
      filterDropdownRef.current &&
      !filterDropdownRef.current.contains(event.target as Node)) {
      setIsFilterOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOptionSelect = (option: any) => {
    setSelectedOption(option.label);
    handleViewChange(option.value);
    setIsFilterOpen(false);
  };

  return (
    <FilterContainer filterOpen={isFilterOpen} ref={filterButtonRef} data-testid='filter-button'>
      <FilterTextStack onClick={(event) => handleFilterDropdown(event)}>
        <FilterName variant='body2'>
          {selectedOption}
        </FilterName>
        <Stack flexDirection='row' alignItems='center'>
          <KeyboardArrowDownIcon />
        </Stack>
      </FilterTextStack>
      {isFilterOpen && (
        <FilterDropDownContainer ref={filterDropdownRef}>
          <StyledFilterDropdown>
            {options.map((option: any, index) => (
              <StyledStack key={index} onClick={() => handleOptionSelect(option)}>
                <FormLabel sx={{ cursor: 'pointer' }}>
                  {option.label}
                </FormLabel>
              </StyledStack>
            ))}
          </StyledFilterDropdown>
        </FilterDropDownContainer>
      )}
    </FilterContainer >
  );
};

const FilterDropDownContainer = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  width: fit-content;
  height: fit-content;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 3px 8px -1px #E0E0E0;
  border-radius: 4px;
`;

const FilterContainer = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'filterOpen',
}) <FilterContainerProps>`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  position: relative;
  background-color: ${({ filterOpen }) => filterOpen ? '#E0E0E0' : variables.colors.lightNeutral.lighter};
  border-radius: 6px;
  white-space: nowrap;
  z-index: ${variables.zIndices.filterDropdown};
  user-select: none;
  box-sizing: border-box;
  min-width: 0;
  &:hover {
    background-color: #E0E0E0;
  }
`;

const FilterTextStack = styled(Stack)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding: 8px 16px;
  box-sizing: border-box;
  cursor: pointer;
`;

const FilterName = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;


const StyledFilterDropdown = styled(Stack)`
  box-sizing: border-box;
  width: 240px;
  padding: 8px;
  position: relative;
  background-color: white;
  border-radius: 4px;
`;

const StyledStack = styled(Stack)`
  padding: 0 8px;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
`;

export default ViewDropdown;