import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import variables from 'styles/variables';
import React from 'react';

// Creates a theme instance with the specified palette and component overrides
const theme = createTheme({
  palette: {
    primary: {
      main: variables.colors.primary.darker,
    },
    secondary: {
      main: variables.colors.secondary.main,
    },
  },
  breakpoints: {
    values: {
      xs: 0, // Portrait Mobile 0-444
      sm: 444, // Landscape Mobile 445-600
      md: 600, // Tablet 601-768
      lg: 768, // Portrait Tablet 769-900
      xl: 900, // Desktop 901-1200
    },
  },
  typography: {
    fontFamily: variables.fonts.family.primary,
    h1: {
      fontSize: variables.fonts.fontSize.h1,
      fontWeight: variables.fonts.fontWeight.light,
    },
    h2: {
      fontSize: variables.fonts.fontSize.h2,
      fontWeight: variables.fonts.fontWeight.regular,
    },
    h3: {
      fontSize: variables.fonts.fontSize.h3,
      fontWeight: variables.fonts.fontWeight.medium,
    },
    h4: {
      fontSize: variables.fonts.fontSize.h4,
      fontWeight: variables.fonts.fontWeight.semiBold,
    },
    h5: {
      fontSize: variables.fonts.fontSize.h5,
      fontWeight: variables.fonts.fontWeight.semiBold,
    },
    h6: {
      fontSize: variables.fonts.fontSize.h6,
      fontWeight: variables.fonts.fontWeight.semiBold,
    },
    body1: {
      fontSize: variables.fonts.fontSize.body1,
      fontWeight: variables.fonts.fontWeight.regular,
    },
    body2: {
      fontSize: variables.fonts.fontSize.body2,
      fontWeight: variables.fonts.fontWeight.regular,
    },
    caption: {
      fontSize: variables.fonts.fontSize.caption,
      fontWeight: variables.fonts.fontWeight.regular,
    },
    overline: {
      fontSize: variables.fonts.fontSize.h1,
      fontWeight: variables.fonts.fontWeight.light,
    },
    subtitle1: {
      fontSize: variables.fonts.fontSize.subtitle1,
      fontWeight: variables.fonts.fontWeight.semiBold,
    },
    subtitle2: {
      fontSize: variables.fonts.fontSize.subtitle2,
      fontWeight: variables.fonts.fontWeight.bold,
    },
    button: {
      textTransform: 'none'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          padding: 0,
          fontFamily: variables.fonts.family.primary,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontFamily: variables.fonts.family.primary,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textAlign: 'center',
          verticalAlign: 'middle',
          fontWeight: variables.fonts.fontWeight.semiBold,
          borderRadius: '8px',
          height: 'fit-content',
          boxShadow: 'none',
        },
        sizeSmall: {
          fontSize: variables.fonts.fontSize.button.small,
          padding: '4px 8px',
          lineHeight: '22px'
        },
        sizeMedium: {
          fontSize: variables.fonts.fontSize.button.medium,
          padding: '6px 16px',
          lineHeight: '24px'
        },
        sizeLarge: {
          fontSize: variables.fonts.fontSize.button.large,
          padding: '8px 16px',
          lineHeight: '26px'
        },
        containedSecondary: {
          color: 'white',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          color: variables.colors.text.disabled,
        },
      },
    },
  },
});

// A provider that wraps the app, providing the theme to all components
const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <MUIThemeProvider theme={theme}>
      {children}
    </MUIThemeProvider>
  );
};

export default ThemeProvider;