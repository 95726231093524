import React, { useEffect } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { useAtom } from 'jotai';
import { redirectAtom, redirectTypeAtom } from 'atoms/redirectAtom';
import { useLocation } from 'react-router-dom';
import variables from 'styles/variables';

const RedirectScreen = () => {
  const [, setRedirect] = useAtom(redirectAtom);
  const [redirectType] = useAtom(redirectTypeAtom);
  const location = useLocation();

  const nonRedirectPages = [
    '/organisation-registration',
    '/sign-up',
    '/login',
  ];

  useEffect(() => {
    // Check if current path is in the list of non-redirect pages
    const shouldNotRedirect = nonRedirectPages.includes(location.pathname);
    // Set the redirect atom to the opposite of the check, so that the redirect screen is shown when the user is NOT on a non-redirect page
    setRedirect(!shouldNotRedirect);
  }, [location.pathname, setRedirect]);

  return (
    <RedirectScreenContainer data-testid='redirect-screen'>
      <LogoBox>
        <img src="/assets/images/skymo-web-logo.png" alt="Skymo Logo" />
      </LogoBox>
      <ImageBox>
        <img src={`/assets/images/${redirectType === 'wrong device' ? 'wrong-device-redirect.png' : 'after-sign-up-redirect.png'}`} alt="Redirect screen image" />
      </ImageBox>
      <StyledStack>
        <Typography variant="h5" align="center" color={variables.colors.primary.darker}>
          {redirectType === 'wrong device' ? 'Oops.. wrong device' : 'Your account is set!'}
        </Typography>
        <Typography variant="body1" align="center" color={variables.colors.text.secondary}>
          {redirectType === 'wrong device' ? 'For the best experience, our platform is designed for desktop use. Remember to always sign in on a computer or laptop.' : 'For the best experience, our platform is designed for desktop use. Check your email on your desktop to get started.'}
        </Typography>
      </StyledStack>
      <Button variant="contained" color="primary" sx={{ width: 'fit-content' }} aria-label="Back to Home">
        Back to Home
      </Button>
    </RedirectScreenContainer>
  );
};

const RedirectScreenContainer = styled(Stack)`
  display: none;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 12px;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 999999999;
  @media (max-width: 768px) or (max-height: 500px) {
    display: flex;
  }
`;

const StyledStack = styled(Stack)`
  width: 100%;
  max-width: 358px;
  padding: 24px;
  box-sizing: border-box;
  align-items: center;
  gap: 32px;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const LogoBox = styled(Box)`
  width: 220px;
  height: auto;
  img {
    width: 100%;
  }
`;

const ImageBox = styled(Box)`
  width: 100%;
  height: auto;
  box-sizing: border-box;
  max-width: 358px;
  img {
    width: 100%;
  }
  @media (max-height: 540px) {
    display: none;
  }
`;

export default RedirectScreen;