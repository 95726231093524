import axiosInstance from 'api/config';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { GeneralNote, GeneralNoteList } from 'types/dbSchema/generalNotes';
import { createFilterString } from 'utils/helpers';

// Returns a list of participant goals for the given organisation ID and participant ID
export const listGeneralNotes = async (
  organisationId: string,
  participantId: string,
  filters: SelectedFilter[] | null,
  page: number,
  pageSize: number
): Promise<GeneralNoteList> => {

  // Create a formatted filter string
  let formattedFilters = '';
  if (filters && filters.length > 0) {
    formattedFilters = createFilterString(filters);
  }

  try {
    if (!organisationId || !participantId) {
      throw new Error('Organisation ID and Participant ID are required');
    }

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants/${participantId}/generalnotes`, {
      params: {
        filters: formattedFilters,
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing participant general notes for the organisation/participant', error);
    throw error;
  }
};


// Retrieves a participant goal by ID
export const getGeneralNoteById = async (organisationId: string, participantId: string, generalNoteID: string): Promise<GeneralNote> => {
  try {
    if (!participantId || !organisationId || !generalNoteID) throw new Error('Organisation ID, generalNoteID, and ParticipantGoal ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants/${participantId}/generalnotes/${generalNoteID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the general note', error);
    throw error;
  }
};

// Updates a participant goal by ID
export const updateGeneralNoteById = async (organisationId: string, participantId: string, generalNoteID: string, data: Partial<any>) => {
  try {
    if (!participantId || !organisationId || !generalNoteID || !data) throw new Error('Organisation ID, Participant ID, generalNote ID, and data are required');

    const response = await axiosInstance.put(`/api/organisations/${organisationId}/participants/${participantId}/generalnotes/${generalNoteID}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('There was an error updating the general note', error);
    throw error;
  }
};

// Create new ParticipantGoal
export const createGeneralNote = async (organisationId: string, participantId: string, data: any) => {
  try {
    if (!organisationId || !participantId || !data) throw new Error('Organisation ID, Participant ID, and data are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/participants/${participantId}/generalnotes`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });
    return response.data;
  } catch (error) {
    console.error('There was an error creating a new general note', error);
    throw error;
  }
};

// Retrieves a participant goal by ID
export const deleteGeneralNoteById = async (organisationId: string, participantId: string, generalNoteID: string): Promise<GeneralNote> => {
  try {
    if (!participantId || !organisationId || !generalNoteID) throw new Error('Organisation ID, generalNoteID, and ParticipantGoal ID are required');

    const response = await axiosInstance.delete(`/api/organisations/${organisationId}/participants/${participantId}/generalnotes/${generalNoteID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the general note', error);
    throw error;
  }
};