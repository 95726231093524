import React, { FC } from 'react';
import ViewParticipants from 'components/features/ViewParticipant';
import { LinearProgress } from '@mui/material';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { getParticipantById } from 'api/organisations/participants';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useParams } from 'react-router-dom';

const ViewParticipantsPage: FC = () => {
  const { id } = useParams();
  const [organisations] = useUserOrganisations();

  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
    getByIDFunc: () => getParticipantById(organisations[0].organisation.globalId, id as string)
  });

  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return <ViewParticipants participant={currentDataPoint} />;
};

export default ViewParticipantsPage;