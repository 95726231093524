import React, { FC, useState } from 'react';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { Stack } from '@mui/material';
import styled from 'styled-components';
import TitleWithClose from 'components/common/TitleWithClose';
import DragAndDropFileUpload from 'components/common/InputFields/DragAndDropFileUpload';
import { ModalAtomState } from 'atoms/types';
import DownloadTemplate from './DownloadTemplate';
import WarningNotice from './WarningNotice';
import ErrorNotice from './ErrorNotice';
import ButtonBox from './ButtonBox';
import { ImportStatus } from './types';
import { userMessageAtom } from 'atoms/userMessageAtom';
import { closeModalAtom } from 'atoms/modalAtom';
import { UploadedFile } from 'components/common/InputFields/DragAndDropFileUpload/types';

const ImportModal: FC = () => {
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const [, closeModal] = useAtom(closeModalAtom);
  const [currentFiles, setCurrentFiles] = useState<UploadedFile[]>([]);
  const validFileTypes = ['text/csv'];
  const [importStatus, setImportStatus] = useState<ImportStatus>({ status: 'idle', message: '', });
  const [, setUserMessage] = useAtom(userMessageAtom);
  const maxTotalSize = 104857600;
  const maxFileSize = 104857600;

  // Handle upload API here
  const handleImport = async () => {
    // Check if a file is selected
    if (!currentFiles) {
      setImportStatus({ status: 'error', message: 'No file selected for upload.' });
      return;
    }

    // Set the modal status to loading, create a FormData object and append the file to it
    setModalState((prevState: ModalAtomState) => ({ ...prevState, status: 'loading' }));
    const formData = new FormData();
    formData.append('file', currentFiles[0].file);

    // Make the API call
    try {
      // Placeholder for the actual API call
      const response = await new Promise<{ status: number }>((resolve) => {
        console.log(`Uploading to: ${modalState.props.dataTableName}`);
        setTimeout(() => resolve({ status: 200 }), 5000); // Simulate API call
      });
      // const response = await axios.post('/api/import', formData);

      // Handle the response
      if (response.status === 200) {
        setImportStatus({ status: 'success', message: 'File uploaded successfully.' });
        closeModal();
        setUserMessage({
          title: 'Success',
          message: 'File uploaded successfully.',
          variant: 'success',
          open: true,
          autoHideDuration: 6000,
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        });
      } else {
        // We will grab the details of the failed import from the API response
        setImportStatus({ status: 'error', message: 'File upload failed due to server error.' });
        setModalState((prevState: ModalAtomState) => ({ ...prevState, status: 'open' })); // Update modal status to open, instead of loading, for the user to see the error
      }
    } catch (error) {
      setImportStatus({ status: 'error', message: 'An error occurred during file upload.' });
      setModalState((prevState: ModalAtomState) => ({ ...prevState, status: 'open' })); // Update modal status to open, instead of loading, for the user to see the error
    } finally {
      setCurrentFiles([]);
    }
  };

  return (
    <ImportModalBox data-testid='ImportModal'>
      <Stack gap='32px'>
        <TitleWithClose title='Import from CSV' handleClose={closeModal} />
        <DownloadTemplate template={modalState.props.dataTableName} />
        <DragAndDropFileUpload
          setCurrentFiles={setCurrentFiles}
          currentFiles={currentFiles}
          validFileTypes={validFileTypes}
          maxTotalSize={maxTotalSize}
          maxFileSize={maxFileSize}
          onFileUploaded={() => setImportStatus({ status: 'idle', message: '' })} // Reset the import status when a new file is uploaded for UX
        />
      </Stack>
      <Stack gap='16px'>
        {currentFiles.length > 0 && <WarningNotice />}
        {importStatus.status === 'error' && <ErrorNotice errorMessage={importStatus.message} />}
        <ButtonBox handleClose={closeModal} handleImport={handleImport} />
      </Stack>
    </ImportModalBox>
  );
};

const ImportModalBox = styled(Stack)`
  width: 100%;
  height: 100%;
  justify-content: space-between;
  position: relative;
  padding: 32px;
`;

export default ImportModal;