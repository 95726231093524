import React, { FC } from 'react';
import { Participant } from 'types/dbSchema/participants';
import CarePreferences from './CarePreferences';
import DetailsOverview from './DetailsOverview';
import { Stack } from '@mui/material';

export interface ParticipantDetailsProps {
  participant: Participant;
}

const ParticipantDetails: FC<ParticipantDetailsProps> = ({ participant }) => {
  return (
    <Stack flexDirection='row' gap='24px' width='100%' padding='24px'>
      <DetailsOverview participant={participant} />
      <CarePreferences participant={participant} />
    </Stack>
  );
};

export default ParticipantDetails;