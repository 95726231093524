import React, { FC, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import SearchInput from 'components/common/InputFields/SearchInput';
import Actions from './Actions';
import { ControlsProps } from '../../types';

const Controls: FC<ControlsProps> = ({
  selectedFilters,
  setSelectedFilters,
  searchText,
  setSearchText,
  selectedRowIds,
}) => {

  return (
    <StyledStack>
      <ActionStack>
        <Typography variant='h6' minWidth='fit-content'>
          Contacts
        </Typography>
        <Stack flexDirection='row' gap='16px' >
          <SearchInput searchValue={searchText} setSearchValue={setSearchText} placeholderText='Search' />
          <Actions />
        </Stack>
      </ActionStack>
    </StyledStack>
  );
};

// This object is used to define the filters that will be displayed in the filter dropdowns
export const filters = [
  {
    field: 'Linked Participants',
    name: 'Linked Participants',
    options: [
      'Participant 1',
      'Participant 2',
      'Participant 3',
    ],
  },
];

const StyledStack = styled(Stack)`
  height: fit-content;
  padding: 24px;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
`;

const ActionStack = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export default Controls;