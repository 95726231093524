
import React from 'react';
import { Stack, Typography } from '@mui/material';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';

export const ServiceStatus = ({ status }: { status: string }) => {
  if (status === 'Pending') return <PendingStatus />;
  if (status === 'Reviewed') return <ReviewedStatus />;
  return <></>;
};

const PendingStatus = () => {
  return (
    <Stack
      flexDirection='row'
      gap='4px'
      padding='3px 4px'
      borderRadius='4px'
      alignItems='center'
      justifyContent='center'
      height='fit-content'
      sx={{
        backgroundColor: '#F57C004D',
      }}
    >
      <AssignmentLateOutlinedIcon color='error' sx={{ height: '16px', width: '16px' }} />
      <Typography fontSize='12px' color='error' fontWeight='600'>PENDING</Typography>
    </Stack>
  );
};

const ReviewedStatus = () => {
  return (
    <Stack
      flexDirection='row'
      gap='4px'
      padding='3px 4px'
      borderRadius='4px'
      alignItems='center'
      justifyContent='center'
      height='fit-content'
      sx={{
        backgroundColor: '#039BE54D',
      }}
    >
      <AssignmentTurnedInOutlinedIcon sx={{ height: '16px', width: '16px' }} />
      <Typography fontSize='12px' fontWeight='600'>REVIEWED</Typography>
    </Stack>
  );
};