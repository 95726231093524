import React, { useState, useRef, forwardRef, useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import variables from 'styles/variables';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchInput from '../SearchInput';
import { SelectInputProps, StyledSelectInputProps } from './types';

// Recreate the MUI select component to handle custom styling and functionality
//   - This includes a search box to filter the options inside the dropdown
//   - Search box is pinned to the top of the dropdown
//   - Fixing MUIs issue of strange/bad functionality when the select component is inside a modal
//   - Integrating with react-hook-form
//   - Passing the ref down from react-hook-form to the select component
//   - Preventing the dropdown from closing when the search input is clicked
const SelectInput = forwardRef<HTMLDivElement, SelectInputProps>(({
  label,
  id,
  error,
  errorText,
  helperText,
  options,
  placeholder,
  onChange,
  value: controlledValue,
  isMandatory,
  ...props }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filterOptions, setFilteredOptions] = useState(options);
  const [dropUp, setDropUp] = useState(false);
  const [isCalculatingDropUp, setIsCalculatingDropUp] = useState(false);
  const internalRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      const boundingRect = dropdownRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - boundingRect.bottom;

      if (spaceBelow < 20) {
        setDropUp(true);
      } else {
        setDropUp(false);
      }
      setIsCalculatingDropUp(false);
    }
  }, [isOpen]);

  // This is used to filter the options based on the search value
  useEffect(() => {
    const filtered = searchValue ? options.filter((option: { value: string; label: string }) => option.label.toLowerCase().includes(searchValue.toLowerCase())) : options;
    setFilteredOptions(filtered);
  }, [searchValue, options]);

  useEffect(() => {
    // Function to check if the click is outside the component
    const handleClickOutside = (event: MouseEvent) => {
      if (internalRef.current && !internalRef.current.contains(event.target as Node) &&
        dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
        setDropUp(false);
      }
    };
    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);
    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [internalRef]);

  const handleOptionClick = (optionValue: string) => {
    onChange(optionValue);
    setIsOpen(false);
    setDropUp(false);
  };

  const handleInputClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isOpen) {
      setIsOpen(true);
      setIsCalculatingDropUp(true);
    } else {
      setIsOpen(false);
      setDropUp(false);
    }
  };

  const selectedOptionLabel = options.find((option: { value: string; label: string }) => option.value === controlledValue)?.label || placeholder;

  return (
    <div ref={internalRef} style={{ margin: '0', position: 'relative', width: '100%' }} {...props}>
      {label && (
        <label htmlFor={id}>
          <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
            {label} {isMandatory && <span style={{ color: 'red' }}>*</span>}
          </Typography>
        </label>
      )}

      <StyledSelectInput error={error} onClick={handleInputClick} isOpen={isOpen} tabIndex={0} ref={ref}>
        <Typography variant='body2' color={selectedOptionLabel === placeholder ? variables.colors.text.secondary : variables.colors.text.primary}>
          {selectedOptionLabel}
        </Typography>
        <KeyboardArrowDownIcon sx={{ transform: isOpen ? 'rotate(180deg)' : '' }} />
        {isOpen &&
          <Dropdown ref={dropdownRef} dropUp={dropUp} isCalculatingDropUp={isCalculatingDropUp}>
            <SearchBox onClick={(e) => e.stopPropagation()}>
              <SearchInput placeholderText='Search' searchValue={searchValue} setSearchValue={setSearchValue} />
            </SearchBox>
            <Stack maxHeight='200px' overflow='auto'>
              {filterOptions.map((option: { value: string; label: string }, index) => (
                <Stack key={index} onClick={() => handleOptionClick(option.value)} sx={{ cursor: 'pointer', minHeight: '36px', justifyContent: 'center', padding: '0 10px' }}>
                  <Typography variant='body2'>
                    {option.label}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Dropdown>
        }
      </StyledSelectInput>

      {(error && errorText) ? <Typography variant='subtitle2' color='red' fontWeight='400'>{errorText}</Typography>
        : helperText && <Typography variant='subtitle2' color={variables.colors.text.secondary} fontWeight='400'>{helperText}</Typography>
      }
    </div >
  );
});

const StyledSelectInput = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'error' && prop !== 'isOpen',
}) <StyledSelectInputProps>`
  box-sizing: border-box;
  border-radius: 4px;
  border: ${({ error, isOpen }) => error ? '1px solid red' : isOpen ? `2px solid ${variables.colors.primary.darker}` : '1px solid #ccc'};
  padding: ${({ isOpen }) => isOpen ? '0 9px' : '0 10px'}; // Adjust padding to account for the increased border width - for some reason the box-sizing property doesn't work here 🤷‍♂️
  width: 100%;
  position: relative;
  cursor: pointer;  
  height: 42px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  font-family: ${variables.fonts.family.primary};
  font-size: 14px;
  background-color: white;
  &:focus-visible {
    border: 2px solid #5C068C !important;
    outline: none;
    padding: 0 9px;
  }
`;

export interface DropdownProps {
  dropUp: boolean;
  isCalculatingDropUp: boolean;
}

const Dropdown = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'dropUp' && prop !== 'isCalculatingDropUp',
}) <DropdownProps>`
  z-index: 999;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  gap: 8px;
  position: absolute;
  top: ${({ dropUp }) => dropUp ? 'unset' : '100%'};
  bottom: ${({ dropUp }) => dropUp ? '100%' : 'unset'};
  left: 0;
  background-color: white;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 3px 8px -1px #E0E0E0;
  border-radius: 4px;
  opacity: ${({ isCalculatingDropUp }) => isCalculatingDropUp ? '0' : '1'};
`;

const SearchBox = styled.div`
  border-bottom: 1px solid #E0E0E0;
  background-color: white;
  min-width: 100%;
  & * {
    border: none;
    width: 100%;
  }
  .MuiInputAdornment-positionEnd {
    width: unset;
  }
`;

SelectInput.displayName = 'SelectInput';

export default SelectInput;