import axiosInstance from "api/config";
import { GeneralNoteType, GeneralNoteTypeList } from "types/dbSchema/participantSettings";

// Returns a list of general note types for the given organisation ID
export const listGeneralNoteTypes = async (organisationId: string, page?: number, pageSize?: number): Promise<GeneralNoteTypeList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID, page, and page size are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/participants/general-note-types`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing general note types for the organisation', error);
    throw error;
  }
};

// Returns a general note type by ID
export const getGeneralNoteTypeByID = async (organisationId: string, generalNoteTypeID: string): Promise<GeneralNoteType> => {
  try {
    if (!generalNoteTypeID || !organisationId) throw new Error('Organisation ID and GeneralNoteType ID are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/participants/general-note-types/${generalNoteTypeID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the general note type', error);
    throw error;
  }
};

// Create a new general note type
export const createGeneralNoteType = async (organisationId: string, data: GeneralNoteType) => {
  console.log(data);
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/${organisationId}/settings/participants/general-note-types`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the general note type', error);
    throw error;
  }
};

// Update a general note type
export const updateGeneralNoteType = async (organisationId: string, data: GeneralNoteType) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.put(`/api/${organisationId}/settings/participants/general-note-types/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the general note type', error);
    throw error;
  }
};

// Delete a general note type
export const deleteGeneralNoteType = async (organisationId: string, generalNoteTypeID: string) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.delete(`/api/${organisationId}/settings/participants/general-note-types/${generalNoteTypeID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the general note type', error);
    throw error;
  }
};