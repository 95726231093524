import axiosInstance from "api/config";
import { PrimaryDisability, PrimaryDisabilityList } from "types/dbSchema/participantSettings";

// Returns a list of primary disabilities for the given organisation ID
export const listPrimaryDisabilities = async (organisationId: string, page?: number, pageSize?: number): Promise<PrimaryDisabilityList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID, page, and page size are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/participants/primary-disabilities`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing primary disabilities for the organisation', error);
    throw error;
  }
};

// Returns a primary disability by ID
export const getPrimaryDisabilitiesByID = async (organisationId: string, PrimaryDisabilityID: string): Promise<PrimaryDisability> => {
  try {
    if (!PrimaryDisabilityID || !organisationId) throw new Error('Organisation ID and PrimaryDisabilities ID are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/participants/primary-disabilities/${PrimaryDisabilityID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the primary disability', error);
    throw error;
  }
};

// Create a new primary disability
export const createPrimaryDisabilities = async (organisationId: string, data: PrimaryDisability) => {
  console.log(data);
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/${organisationId}/settings/participants/primary-disabilities`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the primary disability', error);
    throw error;
  }
};

// Update a primary disability
export const updatePrimaryDisabilities = async (organisationId: string, data: PrimaryDisability) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.put(`/api/${organisationId}/settings/participants/primary-disabilities/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the primary disability', error);
    throw error;
  }
};

// Delete a primary disability
export const deletePrimaryDisabilities = async (organisationId: string, PrimaryDisabilityID: string) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.delete(`/api/${organisationId}/settings/participants/primary-disabilities/${PrimaryDisabilityID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the primary disability', error);
    throw error;
  }
};