import { Box, Stack, Typography } from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import styled from 'styled-components';
import { IsActiveDropdownProps, isActiveStatusObjects, TStatus } from './types';

const IsActiveDropdown: FC<IsActiveDropdownProps> = ({ isActive, setIsActive, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const filterDropdownRef = useRef<HTMLDivElement>(null);
  const filterButtonRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      filterButtonRef.current &&
      !filterButtonRef.current.contains(event.target as Node) &&
      filterDropdownRef.current &&
      !filterDropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleStatusChange = (status: boolean) => {
    setIsActive && setIsActive(status);
    setIsOpen(false);
  };

  const currentStatusObject = isActiveStatusObjects.find((status) => status.isActive === isActive);

  if (!currentStatusObject) return null;

  return (
    <Box sx={{ position: 'relative' }} width='fit-content' ref={filterButtonRef}>
      <Stack
        sx={{
          backgroundColor: currentStatusObject.backgroundColor,
          padding: '4px 8px',
          borderRadius: '4px',
          width: 'fit-content',
          cursor: `${disabled ? 'initial' : 'pointer'}`,
          position: 'relative'
        }}
        direction='row'
        alignItems='center'
        onClick={() => setIsOpen(!isOpen)}
      >
        <Typography textTransform='uppercase' variant='caption' fontWeight='600' color={currentStatusObject.fontColor}>
          {currentStatusObject.label}
        </Typography>
        {!disabled &&
          <KeyboardArrowDownOutlinedIcon
            sx={{
              fontSize: '1rem',
              marginLeft: '4px',
              color: currentStatusObject.fontColor,
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)'
            }}
          />
        }
        {isOpen && !disabled && setIsActive && (
          <Dropdown ref={filterDropdownRef}>
            {isActiveStatusObjects.map((status: TStatus, key) => (
              <Box padding='8px 16px' key={key} onClick={() => handleStatusChange(status.isActive)}>
                <Box sx={{ backgroundColor: status.backgroundColor, padding: '2px 4px', borderRadius: '4px', width: 'fit-content', cursor: 'pointer', position: 'relative' }}>
                  <Typography variant='body2' fontWeight='600' color={status.fontColor}>
                    {status.label}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Dropdown>
        )}
      </Stack>
    </Box>
  );
};

const Dropdown = styled(Stack)`
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  min-width: 121px;
  width: fit-content;
  height: fit-content;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 3px 8px -1px #E0E0E0;
  border-radius: 4px;
  z-index: 10000;
`;

export default IsActiveDropdown;