import React from 'react';
import Statuses from './Statuses';
import RelationshipTypes from './RelationshipTypes';
import GeneralNotesTypes from './GeneralNotesTypes';
import ProgressNoteTypes from './ProgressNoteTypes';
import PrimaryDisabilities from './PrimaryDisabilities';
import SettingsTabs from 'components/common/SettingsTabs';
import ExitReasons from './ExitReasons';

const ParticipantSettings = () => {
  return (
    <SettingsTabs
      title='Participant Settings'
      tabs={[
        {
          label: 'Statuses',
          content: <Statuses />,
          requiredPermissions: [],
        },
        {
          label: 'General Notes Types',
          content: <GeneralNotesTypes />,
          requiredPermissions: [],
        },
        {
          label: 'Relationship Types',
          content: <RelationshipTypes />,
          requiredPermissions: [],
        },
        {
          label: 'Primary Disabilities',
          content: <PrimaryDisabilities />,
          requiredPermissions: [],
        },
        {
          label: 'Progress Notes Types',
          content: <ProgressNoteTypes />,
          requiredPermissions: [],
        },
        {
          label: 'Exit Reasons',
          content: <ExitReasons />,
          requiredPermissions: [],
        },
      ]}
    />
  );
};

export default ParticipantSettings;