import React from 'react';
import styled from 'styled-components';
import { Box, Stack, Typography } from '@mui/material';
import variables from 'styles/variables';
import LoginModalFooter from './LoginModalFooter';
import AuthenticationButton from 'components/common/AuthenticationButton';

const LoginModal = () => {
  return (
    <LoginModalContainer data-testid='login-form'>
      <ImageBox>
        <img src="/assets/images/skymo-web-logo.png" alt="Skymo Logo" />
      </ImageBox>
      <Stack gap='24px' width='100%' data-testid='login-form-content'>
        <Typography variant="h5" textAlign='center' margin='0 auto'>
          Welcome back
        </Typography>
        <AuthenticationButton fullwidth={false} isValid={true} type='login logout' prompt='login'/>
        <StyledTypography variant="body2" color={variables.colors.text.secondary} textAlign='center'>
          Don&apos;t have and account yet?{' '}
          <AuthenticationButton fullwidth={false} isValid={true} variant='text' type='sign up' prompt='create'/>
        </StyledTypography>
      </Stack>
      <LoginModalFooter />
    </LoginModalContainer >
  );
};

const LoginModalContainer = styled(Stack)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  height: 500px;
  box-sizing: border-box;
  padding: 48px 16px 16px 16px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 768px) {
    right: unset;
    left: 50%;
    top: 50%;
  }
  @media (max-width: 448px) {
    width: calc(100% - 16px);
    left: 0;
    transform: translate(0, -50%);
    margin: 8px;
  }
`;

const ImageBox = styled(Box)`
  display: flex;
  width: 100%;
  max-width: 250px;
  align-self: center;
  height: fit-content;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  img {
    width: 100%;
  }
`;

const StyledTypography = styled(Typography)`
  gap: 8px;
  display: flex;
  justify-content: center;
  button {
    color: ${variables.colors.secondary.main};
    font-size: ${variables.fonts.fontSize.body2};
    font-weight: 400;
    text-transform: none;
    padding: 0;
    justify-content: flex-start;
    line-height: 1.43;
  }
`;

export default LoginModal;