import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Stack } from '@mui/material';
import variables from 'styles/variables';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styled from 'styled-components';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { useNavigate, useParams } from 'react-router-dom';
import { LeaveModal } from '../../ServiceTeamLeave/UpcomingLeave';

const ActionsDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [, setModalState] = useAtom(modalStateAtom);
  const navigate = useNavigate();
  const { id } = useParams();

  const filterDropdownRef = useRef<HTMLDivElement>(null);
  const filterButtonRef = useRef<HTMLButtonElement>(null);

  const handleDropdown = (event: any) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      filterButtonRef.current &&
      !filterButtonRef.current.contains(event.target as Node) &&
      filterDropdownRef.current &&
      !filterDropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Function to open/close the modal.
  const handleNewLeave = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: LeaveModal,
      props: {
        dataTableName: 'service-team-leave',
        type: 'create',
      }
    }));
  }, [setModalState]);

  return (
    <Stack position='relative'>
      <StyledDropdownButton
        ref={filterButtonRef}
        variant='contained'
        onClick={(event) => handleDropdown(event)}
        endIcon={
          <KeyboardArrowDownIcon
            sx={{
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        }
      >
        Actions
      </StyledDropdownButton>
      {isOpen && (
        <FilterDropDownContainer ref={filterDropdownRef}>
          <Button
            sx={{
              minWidth: '183px',
              justifyContent: 'flex-start',
              color: '#1F2937',
              borderRadius: '0',
              fontSize: '14px',
              fontWeight: '400',
            }}
            onClick={() => navigate(`/scheduling/service-team/${id}/availability`)}
            startIcon={<EditCalendarOutlinedIcon sx={{ color: variables.colors.icon.standard }} />}
          >
            Edit Availability
          </Button>
          <Button
            sx={{
              minWidth: '183px',
              justifyContent: 'flex-start',
              color: '#1F2937',
              borderRadius: '0',
              fontSize: '14px',
              fontWeight: '400',
            }}
            onClick={handleNewLeave}
            startIcon={<AddOutlinedIcon sx={{ color: variables.colors.icon.standard }} />}
          >
            Add Leave
          </Button>
        </FilterDropDownContainer>
      )}
    </Stack>
  );
};

const StyledDropdownButton = styled(Button)`
  background-color: rgba(166, 0, 255, 0.1);
  color: ${variables.colors.primary.darker};;
  &:hover {
    background-color: rgba(166, 0, 255, 0.4);
  }
`;

const FilterDropDownContainer = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  width: fit-content;
  height: fit-content;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 3px 8px -1px #E0E0E0;
  border-radius: 4px;
  z-index: 1000;
`;

export default ActionsDropdown;