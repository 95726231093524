import { Stack, Typography } from '@mui/material';
import React from 'react';
import NavigationLink from 'components/common/NavigationLink';
import variables from 'styles/variables';
import styled from 'styled-components';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const LoginFormFooter = () => {
  return (
    <StyledStack flexDirection='row' data-testid='login-form-footer'>
      <Typography variant='caption' textAlign='center' color={variables.colors.text.secondary}>
        &copy; 2024 Skymo Pty Ltd. All rights reserved
      </Typography>
      <StyledSpacerDot />
      <NavigationLink label='Contact us' to='/contact-us' sx={{
        color: variables.colors.secondary.main,
        fontSize: variables.fonts.fontSize.caption
      }} />
      <StyledSpacerDot />
      <NavigationLink label='Terms & Conditions' to='/terms-and-conditions' sx={{
        color: variables.colors.secondary.main,
        fontSize: variables.fonts.fontSize.caption
      }} />
    </StyledStack>
  );
};

const StyledStack = styled(Stack)`
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  gap: 8px;
  box-sizing: border-box;
`;

const StyledSpacerDot = styled(FiberManualRecordIcon)`
  font-size: 8px;
  color: #BDBDBD;
  display: none;
  @media (max-width: 600px) {
  }
`;


export default LoginFormFooter;