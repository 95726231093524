import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import { ServicePreferencesProps } from '../../types';
import TagsMultiSelectLookupField from 'components/common/MultiSelectLookupField/TagsMultiSelectLookupField';

const ServicePreferences: FC<ServicePreferencesProps> = ({ control, errors }) => {
  return (
    <StyledStack>
      <Typography variant='subtitle1' fontWeight='600' color='text.primary'>
        Service Preferences
      </Typography>
      <Typography variant='body2' color='text.primary'>
        The Service Preferences are directly linked to the skills of the Service Team, and they play a crucial role in guiding the
        suggestions engine process of matching service team workers for various tasks. This ensures optimal allocation of resources
        and maximizes efficiency in service delivery.
      </Typography>
      <Controller
        name="servicePreferences"
        control={control}
        render={({ field }) =>
          <TagsMultiSelectLookupField
            {...field}
            value={field.value as string[]}
            id='servicePreferences'
            validationError={errors.servicePreferences ? true : false}
            errorText={errors.servicePreferences?.message}
            placeholder='Select'
          />}
      />
    </StyledStack>
  );
};

const StyledStack = styled(Stack)`
  width: 100%;
  background-color: #F9FAFB;
  padding: 24px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

export default ServicePreferences;