import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import styled from 'styled-components';
import { roles } from 'types/dbSchema/permissions';

const ViewAsDropdown: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const updateViewAs = (viewAs: string) => {
    localStorage.setItem('viewAs', viewAs);
    // refresh the page to apply the new permissions
    window.location.reload();
  };

  // This is used to close the dropdown when clicking outside of it and outside the button
  // The reason we need to check if the button is clicked is because the button handles it's own click event
  const handleClickOutside = (event: MouseEvent) => {
    if (
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Stack position='fixed' bottom='25px' right='25px'>
      <StyledButton
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        variant='outlined'
        startIcon={<RemoveRedEyeOutlinedIcon />}
        endIcon={<KeyboardArrowDownOutlinedIcon sx={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }} />}
      >
        View As
      </StyledButton>
      {isOpen && (
        <Dropdown ref={dropdownRef}>
          {Object.keys(roles).map((role, index) => (
            <Typography
              onClick={() => updateViewAs(role)}
              key={index}
              sx={{
                cursor: 'pointer',
                backgroundColor: '#FFF',
                '&:hover': {
                  backgroundColor: '#F3F4F6',
                },
              }}
            >
              {role}
            </Typography>
          ))}
        </Dropdown>
      )}
    </Stack >
  );
};

const StyledButton = styled(Button)`
  color: black;
  background-color: white;
  width: fit-content;
  border-radius: 4px;
  border: 1px solid #E5E7EB;
  &:hover {
    background-color: #F9FAFB;
    border: 1px solid #E5E7EB;
  }
`;

const Dropdown = styled(Stack)`
  gap: 8px;
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  width: fit-content;
  position: absolute;
  bottom: 40px;
  right: 0;
  border-radius: 8px;
  border: 1px solid  #E5E7EB;
  box-shadow: 0px 0px 1px 0px rgba(55, 65, 81, 0.20), 0px 4px 12px 0px rgba(55, 65, 81, 0.15);
  z-index: 1;
`;

export default ViewAsDropdown;