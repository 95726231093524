import React, { FC } from 'react';
import { LinearProgress } from '@mui/material';
import ParticipantGoalForm from 'components/features/Forms/ParticipantGoalForm';
import { getParticipantGoalById } from 'api/organisations/participants/goals';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useParams } from 'react-router-dom';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';

const EditParticipantGoalPage: FC = () => {
  const [organisations] = useUserOrganisations();
  const { id, goalId } = useParams();

  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
    getByIDFunc: () => getParticipantGoalById(organisations[0].organisation.globalId, id as string, goalId as string),
  });

  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return <ParticipantGoalForm participantGoal={currentDataPoint} />;
};

export default EditParticipantGoalPage;