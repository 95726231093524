import axiosInstance from "api/config";
import { LeaveType, LeaveTypeList } from "types/dbSchema/availabilitySettings";

// Returns a list of leave types for the given organisation ID
export const listLeaveTypes = async (organisationId: string, page?: number, pageSize?: number): Promise<LeaveTypeList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID, page, and page size are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/participants/leave-types`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing leave types for the organisation', error);
    throw error;
  }
};

// Returns a leave type by ID
export const getLeaveTypeByID = async (organisationId: string, leaveTypeId: string): Promise<LeaveType> => {
  try {
    if (!leaveTypeId || !organisationId) throw new Error('Organisation ID and LeaveType ID are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/participants/leave-types/${leaveTypeId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the leave type', error);
    throw error;
  }
};

// Create a new leave type
export const createLeaveType = async (organisationId: string, data: LeaveType) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/${organisationId}/settings/participants/leave-types`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the leave type', error);
    throw error;
  }
};

// Update a leave type
export const updateLeaveType = async (organisationId: string, data: LeaveType) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.put(`/api/${organisationId}/settings/participants/leave-types/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the leave type', error);
    throw error;
  }
};

// Delete a leave type
export const deleteLeaveType = async (organisationId: string, leaveTypeId: string) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.delete(`/api/${organisationId}/settings/participants/leave-types/${leaveTypeId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the leave type', error);
    throw error;
  }
};