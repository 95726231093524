import React from 'react';
import { Box, LinearProgress as MUILinearProgress, Stack, Typography } from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import variables from 'styles/variables';
import styled from 'styled-components';

const steps = ['User Details', 'Organisation Details', 'Subscription Details', 'Confirmation'];

const LinearProgress = ({ currentStep }: { currentStep: number }) => {

  // Function to determine which icon to display for each step
  const getStepIcon = (index: number) => {
    if (index < currentStep) {
      return <CheckOutlinedIcon sx={{ backgroundColor: variables.colors.success.lighter, borderRadius: '50%', height: '32px', width: '32px', padding: '6px' }} />;
    }
    switch (index) {
      case 0:
        return <PersonOutlineOutlinedIcon sx={{ backgroundColor: variables.colors.primary.main, borderRadius: '50%', height: '32px', width: '32px', padding: '6px' }} />;
      case 1:
        return <WorkOutlineOutlinedIcon sx={{ backgroundColor: variables.colors.primary.main, borderRadius: '50%', height: '32px', width: '32px', padding: '6px' }} />;
      case 2:
        return <FactCheckOutlinedIcon sx={{ backgroundColor: variables.colors.primary.main, borderRadius: '50%', height: '32px', width: '32px', padding: '6px' }} />;
      case 3:
        return <CreditCardOutlinedIcon sx={{ backgroundColor: variables.colors.primary.main, borderRadius: '50%', height: '32px', width: '32px', padding: '6px' }} />;
      default:
        return <CheckOutlinedIcon sx={{ backgroundColor: variables.colors.primary.main, borderRadius: '50%', height: '32px', width: '32px', padding: '6px' }} />;
    }
  };

  return (
    <StyledStack data-testid='org-registration-linear-progress'>
      {steps.map((step, index) => (
        <Box key={index} width='25%' data-testid='linear-progress-container'>
          {index < steps.length - 1 &&
            <StyledLinearProgress variant="determinate" value={currentStep > index ? 100 : 0} />
          }
          <Stack
            key={index}
            justifyContent='center'
            alignItems='center'
            gap='16px'
            data-testid='linear-progress-step'
            sx={{
              zIndex: 200,
              opacity: index > currentStep ? 0.3 : 1,
              transition: 'opacity 0.3s',
              width: '100%'
            }} >
            <Typography variant="body2">{step}</Typography>
            {getStepIcon(index)}
          </Stack>
        </Box>
      ))}
    </StyledStack>
  );
};

const StyledStack = styled(Stack)`
  flex-direction: row;
  width: fit-content;
  position: relative;
  margin: 24px 32px;
  width: 100%;
  max-width: 768px;
`;

const StyledLinearProgress = styled(MUILinearProgress)`
  width: calc(25% - 32px);
  height: 2px;
  position: absolute;
  z-index: 1;
  bottom: 16px;
  transform: translate(calc(50% + 32px), 50%);
  background-color: #ac4fc630;
  & .MuiLinearProgress-bar {
    background-color: ${variables.colors.success.lighter};
  }
`;

export default LinearProgress;