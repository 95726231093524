import React from 'react';
import NoteTypes from './NoteTypes';
import Position from './Position';
import SettingsTabs from 'components/common/SettingsTabs';

const ServiceProviderSettings = () => {
  return (
    <SettingsTabs
      title='Service Provider Settings'
      tabs={[
        {
          label: 'Positions',
          content: <Position />,
          requiredPermissions: [],
        },
        {
          label: 'Note Types',
          content: <NoteTypes />,
          requiredPermissions: [],
        }
      ]}
    />
  );
};

export default ServiceProviderSettings;