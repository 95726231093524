import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import { ScoreProps, TScores } from './types';

const scores: TScores = {
  1: {
    fontColor: '#B71C1C',
    color: '#FECDD2',
  },
  2: {
    fontColor: '#E64A19',
    color: '#FFE0B2',
  },
  3: {
    fontColor: '#FFF176',
    color: '#AE6B0B',
  },
  4: {
    fontColor: '#01579B',
    color: '#B3E5FC',
  },
  5: {
    fontColor: '#1B5E20',
    color: '#C8E6C9',
  },
};

const Score: FC<ScoreProps> = ({ score }) => {
  if (score < 1 || score > 5) {
    return (
      <StyledBox>
        <Typography variant='body2' fontWeight='700'>
          N/A
        </Typography>
      </StyledBox >
    );
  }

  return (
    <StyledBox sx={{ backgroundColor: scores[score].color }}>
      <Typography variant='body2' sx={{ color: scores[score].fontColor }} fontWeight='700'>
        {score}
      </Typography>
    </StyledBox >
  );
};

const StyledBox = styled(Box)`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 100%;
`;

export default Score;