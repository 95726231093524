import React from 'react';
import SettingsTabs from 'components/common/SettingsTabs';
import LeaveTypes from './LeaveTypes';
import AvailabilityTemplates from './AvailabilityTemplates';

const AvailabilitySettings = () => {
  return (
    <SettingsTabs
      title='Availability Settings'
      tabs={[
        {
          label: 'Availability Templates',
          content: <AvailabilityTemplates />,
          requiredPermissions: [],
        },
        {
          label: 'Leave Types',
          content: <LeaveTypes />,
          requiredPermissions: [],
        }
      ]}
    />
  );
};

export default AvailabilitySettings;