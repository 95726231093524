import { Box, Button, Stack, Typography } from '@mui/material';
import { closeModalAtom } from 'atoms/modalAtom';
import AddressLookupField from 'components/common/InputFields/AddressLookupField';
import DatePickerInput from 'components/common/InputFields/DatePickerInput';
import SelectInput from 'components/common/InputFields/SelectInput';
import TextInput from 'components/common/InputFields/TextInput';
import RelationshipTypeLookupField from 'components/common/LookupInputField/RelationshipTypeLookupField';
import TitleWithClose from 'components/common/TitleWithClose';
import useCurrentUser from 'hooks/useCurrentUser';
import { useAtom } from 'jotai';
import React, { FC } from 'react';
import { Control, Controller, FieldErrors, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { pronounOptions } from 'types';
import { RelationshipType } from 'types/dbSchema/participantSettings';
import { ServiceRegion } from 'types/dbSchema/serviceRegions';
import { UserPosition } from 'types/dbSchema/userPositions';
import { NameAndID, User } from 'types/dbSchema/userProfiles';
import { Address } from 'types';
import variables from 'styles/variables';
import { formatISODate } from 'utils/helpers';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { updateUserById } from 'api/organisations/users';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useUserOrganisations from 'hooks/useUserOrganisations';

const ViewUserProfileModal = () => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [currentUser] = useCurrentUser();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const [organisations] = useUserOrganisations();
  const { control, handleSubmit, formState: { errors, isSubmitted, touchedFields }, getValues, setValue } = useForm<User>({
    defaultValues: {
      ...currentUser,
      manager: (currentUser?.manager as NameAndID)?.id as string || undefined,
      position: (currentUser?.position as UserPosition)?.id as string || undefined,
      nextOfKinRelationship: (currentUser?.nextOfKinRelationship as RelationshipType)?.id as string || undefined,
      serviceRegion: (currentUser?.serviceRegion as ServiceRegion)?.id as string || undefined,
      address: currentUser?.address || undefined,
    },
    mode: 'onChange'
  });

  // Update the user profile
  // User Roles is not editable from the Update User Profile form and will need to be mapped to the correct format
  const handleUpdate = async (data: any) => {
    data.status = data.status.replace(/\s/g, '');
    // Remove userRoles from the formData as it is not needed for the update
    delete data.userRoles;

    // Update the user profile
    const updatedUser = await updateUserById(organisations[0].organisation.globalId, data.id, data);
    if (!updatedUser) return;

    // Refetch the user profiles to update the list
    setInvalidateData((prev) => ({ ...prev, 'user-profile': true }));

    // Close the modal and navigate to the user profiles page
    closeModal();
  };

  const handleSetAddress = (values: Address) => {
    setValue('address', values, { shouldValidate: true });
  };

  return (
    <form onSubmit={handleSubmit(handleUpdate)}>
      <Stack width='800px' height='calc(100vh - 20px)'>
        <Box padding='24px 32px'>
          <TitleWithClose title='Profile' handleClose={closeModal} />
        </Box>
        <Stack height='100%' overflow='auto'>
          <DisplayOnlyFields currentUser={currentUser} />
          <Stack>
            <Box sx={{ height: '1px', margin: '16px 32px', backgroundColor: '#D1D5DB' }} />
          </Stack>
          <EditFields control={control} errors={errors} handleSetAddress={handleSetAddress} />
        </Stack>
        <Stack direction='row' justifyContent='flex-end' gap='16px' padding='24px 32px'>
          <StyledButton onClick={closeModal}>Close</StyledButton>
          <Button type='submit' variant='contained' color='secondary'>Update</Button>
        </Stack>
      </Stack>
    </form>
  );
};

export interface DisplayFieldsProps {
  currentUser: User | null;
}

export interface EditFieldsProps {
  control: Control<User>;
  errors: FieldErrors<User>;
  handleSetAddress: (values: Address) => void;
}

const DisplayOnlyFields: FC<DisplayFieldsProps> = ({ currentUser }) => {
  return (
    <Stack padding='16px 32px' gap='16px'>
      <Stack>
        <Typography variant='h6' sx={{ color: variables.colors.darkNeutral.darker }}>
          {currentUser?.fullName.firstName} {currentUser?.fullName.lastName}
        </Typography>
        <Typography variant='subtitle2' fontWeight='500' sx={{ color: variables.colors.text.secondary }}>
          {currentUser?.workEmail}
        </Typography>
        <Typography variant='subtitle2' fontWeight='500' sx={{ color: variables.colors.primary.main }}>
          {(currentUser?.position as UserPosition)?.name}
        </Typography>
      </Stack>
      <Stack gap='16px' padding='16px 0'>
        <Line>
          <Stack width='100%'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Position
            </Typography>
            <Typography variant='body2' color={variables.colors.text.main} fontWeight='600'>
              {(currentUser?.position as UserPosition)?.name}
            </Typography>
          </Stack>
          <Stack width='100%'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Employee Type
            </Typography>
            <Typography variant='body2'>
              {currentUser?.employeeType}
            </Typography>
          </Stack>
        </Line>
        <Line>
          <Stack width='100%'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Manager
            </Typography>
            <Typography variant='body2' color={variables.colors.text.main}>
              {(currentUser?.manager as NameAndID)?.fullName}
            </Typography>
          </Stack>
          <Stack width='100%'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Service Region
            </Typography>
            <Typography variant='body2'>
              {(currentUser?.serviceRegion as ServiceRegion)?.name}
            </Typography>
          </Stack>
        </Line>
        <Line>
          <Stack width='100%'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Employee Number
            </Typography>
            <Typography variant='body2' color={variables.colors.text.main}>
              {currentUser?.employeeNo}
            </Typography>
          </Stack>
          <Stack width='100%'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Employee Start Date
            </Typography>
            <Typography variant='body2'>
              {currentUser?.employmentStartDate && formatISODate(currentUser.employmentStartDate)}
            </Typography>
          </Stack>
        </Line>
      </Stack>
    </Stack>
  );
};

const ProfileImage: FC = () => {
  const handleUpload = () => {
    console.log('Upload');
  };

  const handleRemove = () => {
    console.log('Remove');
  };

  return (
    <Stack width='100%' justifyContent='flex-start' alignItems='center' flexDirection='row' gap='16px'>
      <AccountCircleIcon
        sx={{
          color: variables.colors.primary.darker,
          height: '80px',
          width: '80px',
        }} />
      <Stack>
        <Stack flexDirection='row' gap='8px'>
          <Button
            sx={{
              padding: 0,
              fontWeight: '400',
              color: variables.colors.primary.main
            }}
            variant='text'
            color='primary'
            onClick={handleUpload}
          >
            Upload new picture
          </Button>
          <svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4' fill='none' style={{ margin: 'auto 0' }}>
            <circle cx='2' cy='2' r='2' fill={variables.colors.text.secondary} />
          </svg>
          <Button
            sx={{
              padding: 0,
              fontWeight: '400',
              color: variables.colors.primary.main
            }}
            variant='text'
            color='primary'
            onClick={handleRemove}
          >
            Remove picture
          </Button>
        </Stack>
        <Typography variant='body2' color={variables.colors.text.secondary}>
          Pictures help other teammates recognise you
        </Typography>
      </Stack>
    </Stack>
  );
};

const EditFields: FC<EditFieldsProps> = ({ control, errors, handleSetAddress }) => {

  return (
    <Stack>
      <Stack gap='24px' padding='16px 32px'>
        <Typography variant='subtitle1' fontWeight='600' color='text.primary'>
          Personal Details
        </Typography>
        <ProfileImage />
        <Row>
          <Controller
            name="dob"
            control={control}
            rules={{ required: 'Date of Birth is required' }}
            render={({ field }) =>
              <DatePickerInput
                {...field}
                id='dob'
                label='Date of Birth'
                error={errors.dob ? true : false}
                errorText={errors.dob?.message}
                isMandatory
              />}
          />
          <Controller
            name="gender"
            control={control}
            rules={{ required: 'Gender is required' }}
            render={({ field }) =>
              <SelectInput
                {...field}
                id='gender'
                label="Gender"
                error={errors.gender ? true : false}
                errorText={errors.gender?.message}
                placeholder='Select'
                isMandatory
                options={[
                  { value: 'Male', label: 'Male' },
                  { value: 'Female', label: 'Female' },
                  { value: 'Other', label: 'Other' },
                ]}
              />}
          />
        </Row>
        <Row>
          <Controller
            name="pronoun"
            control={control}
            render={({ field }) =>
              <SelectInput
                {...field}
                id='pronoun'
                label="Pronoun"
                error={errors.pronoun ? true : false}
                errorText={errors.pronoun?.message}
                placeholder='Select'
                options={pronounOptions}
              />}
          />
          <Controller
            name="personalEmail"
            control={control}
            rules={{
              pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: 'Invalid Email' },
            }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='personalEmail'
                label="Personal Email"
                error={errors.personalEmail ? true : false}
                errorText={errors.personalEmail?.message}
              />}
          />
        </Row>
        <Row>
          <Controller
            name="primaryMobile"
            control={control}
            rules={{
              required: 'Mobile Number is required',
              pattern: { value: /^[0-9]{10}$/, message: 'Invalid mobile number' },
            }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='mobile'
                label="Mobile Number"
                error={errors.primaryMobile ? true : false}
                errorText={errors.primaryMobile?.message}
                isMandatory
              />}
          />
          <Controller
            name="phone"
            control={control}
            rules={{
              pattern: { value: /^[0-9]{10}$/, message: 'Invalid Phone Number' },
            }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='phone'
                label="Phone"
                error={errors.phone ? true : false}
                errorText={errors.phone?.message}
              />}
          />
        </Row>
        <Stack gap='4px'>
          <Controller
            name="address"
            control={control}
            rules={{
              required: 'Address is required',
            }}
            render={({ field }) =>
              <AddressLookupField
                {...field}
                id='address'
                placeholder='Search for your address'
                setValue={handleSetAddress}
                label='Address'
                isMandatory
                error={errors.address ? true : false}
                errorText='Address is required'
              />}
          />
        </Stack>
      </Stack >
      <Box sx={{ height: '1px', margin: '16px 32px', backgroundColor: '#D1D5DB' }} />
      <Stack gap='24px' padding='16px 32px'>
        <Typography variant='subtitle1' fontWeight='600'>
          Next of Kin
        </Typography>
        <Row>
          <Controller
            name="nextOfKinFullName"
            control={control}
            rules={{
              required: 'Next of Kin Full Name is required',
              pattern: { value: /^[a-zA-Z\s]+$/, message: 'Next of Kin Full Name must only contain letters and spaces' },
              minLength: { value: 2, message: 'Next of Kin Full Name must be at least 2 characters long' },
              maxLength: { value: 50, message: 'Next of Kin Full Name must be at most 50 characters long' }
            }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='nextOfKinFullName'
                label='Full Name'
                error={errors.nextOfKinFullName ? true : false}
                errorText={errors.nextOfKinFullName?.message}
                isMandatory
              />}
          />
          <Controller
            name="nextOfKinRelationship"
            control={control}
            rules={{
              required: 'Next of Kin Relationship is required',
            }}
            render={({ field }) =>
              <RelationshipTypeLookupField
                {...field}
                id='relationshipType'
                label='Relationship'
                placeholder='Select'
                validationError={errors.nextOfKinRelationship ? true : false}
                errorText={errors.nextOfKinRelationship?.message}
                isMandatory
              />
            }
          />
        </Row>
        <Row>
          <Controller
            name="nextOfKinMobile"
            control={control}
            rules={{
              required: 'Next of Kin Mobile is required',
              pattern: { value: /^[0-9]{10}$/, message: 'Invalid mobile number' },
            }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='nextOfKinMobile'
                label='Mobile Number'
                error={errors.nextOfKinMobile ? true : false}
                errorText={errors.nextOfKinMobile?.message}
                isMandatory
              />}
          />
          <Controller
            name="nextOfKinPhone"
            control={control}
            rules={{
              pattern: { value: /^[0-9]{10}$/, message: 'Invalid Phone Number' },
            }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='nextOfKinPhone'
                label='Phone Number'
                error={errors.nextOfKinPhone ? true : false}
                errorText={errors.nextOfKinPhone?.message}
              />}
          />
        </Row>
      </Stack>
    </Stack>
  );
};


const StyledButton = styled(Button)`
  color: black;
  background-color: white;
  width: fit-content;
  border: 1px solid #E5E7EB;
  &:hover {
    background-color: #F9FAFB;
    border: 1px solid #E5E7EB;
  }
`;

const Row = styled(Stack)`
  gap: 24px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Line = styled(Stack)`
  gap:8px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;


export default ViewUserProfileModal;