import { Box, Stack, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useAtom } from 'jotai';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { userMessageAtom } from 'atoms';
import variables from 'styles/variables';
import { UserMessageContainerProps, UserMessageTitleProps } from './types';
import { closeUserMessageAtom } from 'atoms/userMessageAtom';

const UserMessage: FC = () => {
  const [userMessage, setUserMessage] = useAtom(userMessageAtom);
  const [, closeUserMessage] = useAtom(closeUserMessageAtom);

  // Closes the modal and reset values
  const handleClose = () => {
    closeUserMessage();
  };

  // Auto hide the message after a certain duration
  useEffect(() => {
    if (userMessage.open) {
      const timer = setTimeout(() => {
        setUserMessage({
          ...userMessage,
          open: false,
        });
      }, userMessage.autoHideDuration);
      return () => clearTimeout(timer);
    }
  }, [userMessage, setUserMessage]);

  if (userMessage.open) return (
    <UserMessageContainer variant={userMessage.variant} anchorOrigin={userMessage.anchorOrigin} data-testid='UserMessageContainer'>
      <Stack direction="row">
        <Box sx={{ padding: '8px 0', marginRight: '8px' }}>
          {userMessage.variant === 'success' ? <CheckCircleIcon sx={{ color: variables.colors.success.main }} /> :
            userMessage.variant === 'error' ? <ErrorOutlineOutlinedIcon sx={{ color: variables.colors.error.main }} /> :
              userMessage.variant === 'warning' ? <ErrorOutlineOutlinedIcon sx={{ color: variables.colors.warning.main }} /> :
                userMessage.variant === 'info' && <ErrorOutlineOutlinedIcon sx={{ color: variables.colors.info.main }} />
          }
        </Box>
        <Stack padding='8px 0'>
          <UserMessageTitle userMessageVariant={userMessage.variant} variant='body2' fontWeight='600'>
            {userMessage.title}
          </UserMessageTitle>
          <Typography variant='body2'>{userMessage.message}</Typography>
        </Stack>
      </Stack>
      <CloseOutlinedIcon onClick={handleClose} style={{ cursor: 'pointer', paddingTop: '5px' }} />
    </UserMessageContainer>
  );

  return null;
};

const UserMessageContainer = styled(Box).withConfig({
  shouldForwardProp: (prop) => prop !== 'anchorOrigin' && prop !== 'variant',
}) <UserMessageContainerProps>`
  position: fixed;
  width: 475px;
  height: fit-content;
  box-shadow: 0px 4px 10px 0px rgba(33, 33, 33, 0.15);
  box-sizing: border-box;
  padding: 6px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 4px;
  z-index: 99999999;
  background-color: ${({ variant }) => variant === 'success' ? '#f4faf4' :
    variant === 'error' ? '#faf4f4' :
      variant === 'warning' ? '#faf9f4' :
        variant === 'info' && '#f4f5fa'};
  border: 1px solid  ${({ variant }) => variant === 'success' ? variables.colors.success.main :
    variant === 'error' ? variables.colors.error.main :
      variant === 'warning' ? variables.colors.warning.main :
        variant === 'info' && variables.colors.info.main};
  ${({ anchorOrigin }) => anchorOrigin.vertical === 'bottom' ? 'bottom: 20px;' :
    anchorOrigin.vertical === 'top' ? 'top: 20px;' :
      anchorOrigin.vertical === 'center' ? 'top: 50%;' : ''};
  ${({ anchorOrigin }) => anchorOrigin.horizontal === 'left' ? 'left: 20px;' :
    anchorOrigin.horizontal === 'right' ? 'right: 20px;' :
      anchorOrigin.horizontal === 'center' ? 'left: 50%;' : ''};
  transform: ${({ anchorOrigin }) => (anchorOrigin.vertical === 'center' && anchorOrigin.horizontal === 'center') ? 'translate(-50%, -50%)' :
    anchorOrigin.vertical === 'center' ? 'translateY(-50%)' :
      anchorOrigin.horizontal === 'center' ? 'translateX(-50%)' : 'none'};
`;

const UserMessageTitle = styled(Typography).withConfig({
  shouldForwardProp: (prop) => prop !== 'userMessageVariant',
}) <UserMessageTitleProps>`
  color: ${({ userMessageVariant }) => userMessageVariant === 'success' ? variables.colors.success.darker :
    userMessageVariant === 'error' ? variables.colors.error.darker :
      userMessageVariant === 'warning' ? variables.colors.warning.darker :
        userMessageVariant === 'info' && variables.colors.info.darker};
`;

export default UserMessage;