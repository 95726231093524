import ParticipantAlertsDataTable from 'components/features/DataTables/ParticipantAlertsDataTable';
import React, { FC } from 'react';
import { Participant } from 'types/dbSchema/participants';

export interface AlertsProps {
  participant: Participant;
}

const Alerts: FC<AlertsProps> = () => <ParticipantAlertsDataTable />;

export default Alerts;