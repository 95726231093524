import { Stack, Typography } from '@mui/material';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import variables from 'styles/variables';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styled from 'styled-components';
import FiltersDropdown from './FilterDropdown';
import { FilterContainerProps, FilterProps } from './types';

const FilterWithDropdown: FC<FilterProps> = ({ filter, selectedFilters, setSelectedFilters, filterOpen, setFilterOpen }) => {
  const filterButtonRef = useRef<HTMLDivElement>(null);
  const filterDropdownRef = useRef<HTMLDivElement>(null);

  // This is used to close the filter dropdown when clicking outside of it and outside the filter button
  // The reason we need to check if the filter button is clicked is because the filter button handles it's own click event
  const handleClickOutside = (event: MouseEvent) => {
    if (
      filterButtonRef.current &&
      !filterButtonRef.current.contains(event.target as Node) &&
      filterDropdownRef.current &&
      !filterDropdownRef.current.contains(event.target as Node)) {
      setFilterOpen('');
    }
  };

  // This is used to open and close the filter dropdown when clicking the filter button, only one filter dropdown can be open at a time
  const handleFilterDropdown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    if (filterOpen === filter.field) {
      setFilterOpen('');
    } else {
      setFilterOpen(filter.field);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // This is used to count the number of selected filters
  const selectedFilterCount = useMemo(() => selectedFilters.find(f => f.field === filter.field)?.valuesSelected.length ?? 0, [selectedFilters, filter.field]);

  // This is used to check if the filter is open
  const isFilterOpen = useMemo(() => filterOpen === filter.field, [filterOpen, filter.field]);

  return (
    <FilterContainer filterOpen={isFilterOpen} ref={filterButtonRef} data-testid='filter-button'>
      <FilterTextStack onClick={(event) => handleFilterDropdown(event)}>
        <FilterName variant='body2'>
          {filter.name}
        </FilterName>
        <Stack flexDirection='row' alignItems='center'>
          <Typography variant='body2' fontWeight='600'>
            {selectedFilterCount > 0 && `(${selectedFilterCount})`}
          </Typography>
          <KeyboardArrowDownIcon />
        </Stack>
      </FilterTextStack>
      {isFilterOpen && (
        <FiltersDropdown
          filter={filter}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setFilterOpen={setFilterOpen}
          filterDropdownRef={filterDropdownRef}
        />
      )}
    </FilterContainer >
  );
};

const FilterContainer = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'filterOpen',
}) <FilterContainerProps>`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  position: relative;
  background-color: ${({ filterOpen }) => filterOpen ? '#E0E0E0' : variables.colors.lightNeutral.lighter};
  border-radius: 6px;
  white-space: nowrap;
  z-index: ${variables.zIndices.filterDropdown};
  user-select: none;
  box-sizing: border-box;
  min-width: 0;
  &:hover {
    background-color: #E0E0E0;
  }
`;

const FilterTextStack = styled(Stack)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding: 8px 16px;
  box-sizing: border-box;
  cursor: pointer;
`;

const FilterName = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default FilterWithDropdown;