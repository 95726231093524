import { PaginationBase } from "types";

export type PayPeriodList = PaginationBase<PayPeriod>;
export type TagList = PaginationBase<Tag>;
export type ServiceTeamNoteTypeList = PaginationBase<ServiceTeamNoteType>;
export type TimeSheetDeclinedReasonList = PaginationBase<TimeSheetDeclinedReason>;
export type ShiftCancellationReasonList = PaginationBase<ShiftCancellationReason>;

export interface PayPeriod {
  id: string;
  rosterStartDate: string;
  frequency: string;
  isActive: boolean;
  isEditable: boolean;
}

export interface Tag {
  id: string;
  name: string;
  tagCategory: string;
  isActive: boolean;
  isEditable: boolean;
}

export interface ServiceTeamNoteType {
  id: string;
  description?: string;
  name: string;
  isActive: boolean;
  isEditable: boolean;
}

export interface TimeSheetDeclinedReason {
  id: string;
  name: string;
  isActive: boolean;
  isEditable: boolean;
}

export interface ShiftCancellationReason {
  id: string;
  reason: string;
  isActive: boolean;
  isEditable: boolean;
}

export type TagCategory = 'Preferred Carer' | 'Language' | 'Certification' | 'General' | 'License';

export interface TagCategoryOption {
  value: TagCategory;
  label: string;
}

export const tagCategories: TagCategoryOption[] = [
  { value: 'Preferred Carer', label: 'Preferred Carer' },
  { value: 'Language', label: 'Language' },
  { value: 'Certification', label: 'Certification' },
  { value: 'General', label: 'General' },
  { value: 'License', label: 'License' },
];

export const payPeriodFrequency = [
  { label: 'Weekly', value: 'Weekly' },
  { label: 'Fortnightly', value: 'Fortnightly' },
];