// Note: Caching control configuration
export const cachingInvalidation = {
  short: 60 * 1000, // 1 minute
  medium: 60 * 60 * 1000, // 1 hour
  long: 60 * 60 * 24 * 1000, // 1 day
  userProfiles: 60 * 1 * 1000, // 1 minute
  participantProfiles: 60 * 1 * 1000, // 1 minute
  availabilityTemplates: 60 * 1 * 1000, // 1 minute
  leaveTypes: 60 * 1 * 1000, // 1 minute
  externalContacts: 60 * 1 * 1000, // 1 minute
  generalNotes: 60 * 1 * 1000, // 1 minute
  participantContacts: 60 * 1 * 1000, // 1 minute
  participantGoals: 60 * 1 * 1000, // 1 minute
  participantNotifications: 60 * 1 * 1000, // 1 minute
  participantAlerts: 60 * 1 * 1000, // 1 minute
  participantAlertsBox: 60 * 1 * 1000, // 1 minute
  productsAndServices: 60 * 1 * 1000, // 1 minute
  progressNotes: 60 * 1 * 1000, // 1 minute
  pushNotifications: 60 * 1 * 1000, // 1 minute
  schedulingNotifications: 60 * 1 * 1000, // 1 minute
  schedulingSupports: 60 * 1 * 1000, // 1 minute
  serviceProviderContacts: 60 * 1 * 1000, // 1 minute
  serviceProviderNotes: 60 * 1 * 1000, // 1 minute
  serviceProviders: 60 * 1 * 1000, // 1 minute
  serviceRegions: 60 * 1 * 1000, // 1 minute
  templateNotifications: 60 * 1 * 1000, // 1 minute
  unallocatedServices: 60 * 1 * 1000, // 1 minute
  userPositions: 60 * 1 * 1000, // 1 minute
  roleTemplates: 60 * 1 * 1000, // 1 minute
  exitReasons: 60 * 1 * 1000, // 1 minute
  generalNoteTypes: 60 * 1 * 1000, // 1 minute
  primaryDisabilities: 60 * 1 * 1000, // 1 minute
  progressNoteTypes: 60 * 1 * 1000, // 1 minute
  relationshipTypes: 60 * 1 * 1000, // 1 minute
  participantStatuses: 60 * 1 * 1000, // 1 minute
  employeeNotes: 60 * 1 * 1000, // 1 minute
  leaveHistory: 60 * 1 * 1000, // 1 minute
  upcomingLeave: 60 * 1 * 1000, // 1 minute
  serviceTeamRoster: 60 * 1 * 1000, // 1 minute
  payPeriods: 60 * 1 * 1000, // 1 minute
  skillsQualifications: 60 * 1 * 1000, // 1 minute
  cancellationCodes: 60 * 1 * 1000, // 1 minute
  serviceTypes: 60 * 1 * 1000, // 1 minute
  shiftLocations: 60 * 1 * 1000, // 1 minute
  serviceProviderNoteTypes: 60 * 1 * 1000, // 1 minute
  serviceProviderPositions: 60 * 1 * 1000, // 1 minute
  serviceTeamSettingsNoteTypes: 60 * 1 * 1000, // 1 minute
  shiftCancellationReasons: 60 * 1 * 1000, // 1 minute
  serviceTeamSettingsTags: 60 * 1 * 1000, // 1 minute
  timeSheetDeclinedReasons: 60 * 1 * 1000, // 1 minute
};