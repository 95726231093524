import { Stack } from '@mui/material';
import React, { FC } from 'react';
import styled from 'styled-components';

export interface LineHeaderProps {
  children: React.ReactNode;
}
const LineHeader: FC<LineHeaderProps> = ({ children }) => {
  return (
    <StyledLineHeader>
      {children}
    </StyledLineHeader>
  );
};

const StyledLineHeader = styled(Stack)`
  gap: 8px;
  width: 100%;
  flex-direction: row;
  padding: 16px 24px;
  background-color: #F9FAFB;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid #E5E7EB;
`;

export default LineHeader;