import axiosInstance, { getAccessToken } from 'api/config';
import axios from 'axios';

// Checks if the ABN is real/valid
export const abnLookup = async (abn: string) => {
  try {
    // Make the API call
    const response = await axiosInstance.get('/api/abn-lookup',
      {
        params: { abn: abn },
      });

    // Return the data
    return response.data;
  } catch (error) {
    console.error('There was an error fetching the user profiles:', error);
    throw error;
  }
};

// Get the current user's microsoft graph profile - used for testing purposes
// We don't use axiosInstance here because we need to use the access token for the msGraph scope
export const getCurrentUser = async () => {
  try {
    // Retrieve the access token
    const accessToken = await getAccessToken('msGraph');
    const url = 'https://graph.microsoft.com/v1.0/me';

    // Make the API call
    const response = await axios.get(url, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    // Return the data
    return response.data;
  } catch (error) {
    console.error('There was an error fetching the user profiles:', error);
    throw error;
  }
};