import React, { forwardRef } from 'react';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import variables from 'styles/variables';
import { RadioInputProps } from './types';

const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>((
  {
    label,
    id,
    error,
    errorText,
    helperText,
    values,
    value,
    onChange,
    isMandatory
  }, ref) => {

  return (
    <div style={{ margin: '0', position: 'relative', width: '100%' }} ref={ref}>
      {label && (
        <label htmlFor={id}>
          <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
            {label} {isMandatory && <span style={{ color: 'red' }}>*</span>}
          </Typography>
        </label>
      )}
      <RadioGroup
        row
        name="radio-buttons-group"
        defaultValue={value}
        onChange={(e) => onChange(e.target.value)}
        sx={{
          gap: '24px',
          marginLeft: '12px',
        }}
      >
        {values?.map((value, index) => (
          <FormControlLabel key={index} value={value.value} control={<Radio />}
            sx={{
              border: '1px solid #E5E7EB',
              borderRadius: '4px',
              marginRight: '0',
            }}
            label={
              <Typography variant='body2' paddingRight='12px'>
                {value.label}
              </Typography>
            } />
        ))}
      </RadioGroup>
      {(error && errorText) ? <Typography variant='subtitle2' color='red' fontWeight='400'>{errorText}</Typography>
        : helperText && <Typography variant='subtitle2' color={variables.colors.text.secondary} fontWeight='400'>{helperText}</Typography>
      }
    </div >
  );
});

RadioInput.displayName = 'RadioInput';

export default RadioInput;