import axiosInstance from "api/config";
import { ServiceProviderPosition, ServiceProviderPositionList } from "types/dbSchema/serviceProviderSettings";

// Returns a list of service provider positions for the given organisation ID
export const listServiceProviderPositions = async (organisationId: string, page?: number, pageSize?: number): Promise<ServiceProviderPositionList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID, page, and page size are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/service-providers/positions`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing service provider positions for the organisation', error);
    throw error;
  }
};

// Returns a service provider position by ID
export const getServiceProviderPositionByID = async (organisationId: string, serviceProviderPositionID: string): Promise<ServiceProviderPosition> => {
  try {
    if (!serviceProviderPositionID || !organisationId) throw new Error('Organisation ID and ServiceProviderPosition ID are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/service-providers/positions/${serviceProviderPositionID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the service provider position', error);
    throw error;
  }
};

// Create a new service provider position
export const createServiceProviderPosition = async (organisationId: string, data: ServiceProviderPosition) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/${organisationId}/settings/service-providers/positions`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the service provider position', error);
    throw error;
  }
};

// Update a service provider position
export const updateServiceProviderPosition = async (organisationId: string, data: ServiceProviderPosition) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.put(`/api/${organisationId}/settings/service-providers/positions/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the service provider position', error);
    throw error;
  }
};

// Delete a service provider position
export const deleteServiceProviderPosition = async (organisationId: string, serviceProviderPositionID: string) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.delete(`/api/${organisationId}/settings/service-providers/positions/${serviceProviderPositionID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the service provider position', error);
    throw error;
  }
};