import ProgressNotesDataTable from 'components/features/DataTables/ProgressNotesDataTable';
import React, { FC } from 'react';
import { Participant } from 'types/dbSchema/participants';

export interface ProgressNotesProps {
  participant: Participant;
}

const ProgressNotes: FC<ProgressNotesProps> = ({ participant }) => {
  return (
    <ProgressNotesDataTable />
  );
};

export default ProgressNotes;