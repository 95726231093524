import { PaginationBase } from "types";
import { FullName, UserAndDate } from "./userProfiles";

export interface goalTypesObject {
  label: string;
  value: GoalType;
}

export type GoalType = 'shortTerm' | 'longTerm';
export const goalTypes: goalTypesObject[] = [
  {
    label: 'Short-term',
    value: 'shortTerm',
  },
  {
    label: 'Medium to Long-term',
    value: 'longTerm',
  }
];


export type GoalStatus = 'NotStarted' | 'InProgress' | 'Completed';
export const goalStatuses: GoalStatus[] = ['NotStarted', 'InProgress', 'Completed'];
export type GoalCommentType = 'goalReview' | 'generalComment';

export interface ParticipantGoal {
  id: string;
  goal: string;
  description: string;
  status: GoalStatus;
  reviewDate: string;
  goalType: GoalType;
  score: number; // 1 to 5. -1 if not scored and will be displayed n/a
  created: UserAndDate;
  lastUpdated: UserAndDate;
  comments: Comment[];
}

export type ParticipantGoalList = PaginationBase<ParticipantGoal>;

export interface Comment {
  id?: string;
  comment: string;
  createdBy: string;
  createdDate: string;
  goalSnapshot: GoalSnapshot | null;
  commentType: GoalCommentType;
}

export interface GoalSnapshot {
  goalType: GoalType;
  goal: string;
  description: string;
  reviewDate: string;
  score: number;
}

export const statusArray = [
  {
    label: 'Not Started',
    value: 'NotStarted',
    fontColor: '#1F2937',
    backgroundColor: '#6B728033',
  },
  {
    label: 'In Progress',
    value: 'InProgress',
    fontColor: '#01579B',
    backgroundColor: '#03A9F433',
  },
  {
    label: 'Completed',
    value: 'Completed',
    fontColor: '#1B5E20',
    backgroundColor: '#69C64F4D',
  },
];