import ParticipantServiceContractsDataTable from 'components/features/DataTables/ParticipantServiceContractsDataTable';
import React, { FC } from 'react';
import { Participant } from 'types/dbSchema/participants';

export interface ServiceContractsProps {
  participant: Participant;
}

const ServiceContracts: FC<ServiceContractsProps> = ({ participant }) => <ParticipantServiceContractsDataTable />;

export default ServiceContracts;