import React, { FC } from 'react';
import ParticipantContactsDataTable from 'components/features/DataTables/ParticipantContactsDataTable';
import { Participant } from 'types/dbSchema/participants';

export interface ContactsProps {
  participant: Participant;
}

const Contacts: FC<ContactsProps> = ({ participant }) => <ParticipantContactsDataTable participant={participant} />;

export default Contacts;