import React, { useState, useRef, forwardRef, useEffect } from 'react';
import { Checkbox, CircularProgress, FormControlLabel, Stack, Typography } from '@mui/material';
import variables from 'styles/variables';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchInput from '../InputFields/SearchInput';
import { LookupFieldProps, LookupSelectFieldOptions, StyledLookupFieldProps } from './types';
import { CloseOutlined } from '@mui/icons-material';


// Recreate the MUI AutoComplete/ComboBox MultiSelect component
//   - This includes a search box to filter the options inside the dropdown (querying DB)
//   - Allows for multiple selections
//   - Allows for any label (react element) to display in the dropdown
//   - Search box is pinned to the top of the dropdown
//   - Fixing MUIs issue of strange/bad functionality when the component is mounted inside a modal
//   - Integrating with react-hook-form
//   - Passing the ref down from react-hook-form to the component
//   - Preventing the dropdown from closing when the search input is clicked
//   - Infinite scroll to load more options
const MultiSelectLookupField = forwardRef<HTMLDivElement, LookupFieldProps>(({
  id,
  label,
  errorText,
  helperText,
  placeholder,
  hasNextPage,
  isMandatory,
  searchValue,
  isFetching,
  validationError,
  fetchNextPage,
  isFetchingNextPage,
  dataFetchingError,
  onChange,
  options,
  setSearchValue,
  backgroundColor,
  value,
  ...props }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropUp, setDropUp] = useState(false);
  const [isCalculatingDropUp, setIsCalculatingDropUp] = useState(false);
  const internalRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState<LookupSelectFieldOptions[]>([]);

  useEffect(() => {
    setSelectedOption(options.filter(option => value?.includes(option.value)));
  }, [options, value]);

  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      const boundingRect = dropdownRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - boundingRect.bottom;

      if (spaceBelow < 20) {
        setDropUp(true);
      } else {
        setDropUp(false);
      }
      setIsCalculatingDropUp(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleScroll = (event: any) => {
      if (!event.target) return;
      const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
      if (bottom && hasNextPage) {
        fetchNextPage();
      }
    };

    const selectElement = internalRef.current as HTMLElement | null;
    if (selectElement) {
      selectElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (selectElement) {
        selectElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [fetchNextPage, hasNextPage]);

  // Function to close the dropdown when clicking outside the component
  // Does not close when clicking the component or the dropdown
  useEffect(() => {
    // Function to check if the click is outside the component
    const handleClickOutside = (event: MouseEvent) => {
      if (internalRef.current && !internalRef.current.contains(event.target as Node) &&
        dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
        setDropUp(false);
      }
    };
    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);
    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [internalRef]);

  const handleOptionClick = (e: any, optionValue: LookupSelectFieldOptions) => {
    e.stopPropagation();
    if (selectedOption.includes(optionValue)) {
      setSelectedOption(selectedOption.filter((option) => option.value !== optionValue.value));
      onChange(selectedOption.filter((option) => option.value !== optionValue.value).map((option) => option.value));
      return;
    } else {
      setSelectedOption([...selectedOption, optionValue]);
      onChange([...selectedOption, optionValue].map((option) => option.value));
    }
  };

  const handleInputClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isOpen) {
      setIsOpen(true);
      setIsCalculatingDropUp(true);
    } else {
      setIsOpen(false);
      setDropUp(false);
    }
  };

  return (
    <div ref={internalRef} style={{ margin: '0', position: 'relative', width: '100%' }} {...props}>
      {label && (
        <label htmlFor={id}>
          <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
            {label} {isMandatory && <span style={{ color: 'red' }}>*</span>}
          </Typography>
        </label>
      )}

      <StyledSelectInput error={validationError ? true : false} onClick={handleInputClick} isOpen={isOpen} tabIndex={0} ref={ref}>
        {selectedOption?.length ? (
          <Stack gap='6px' flex='1' flexDirection='row' sx={{ overflow: 'hidden', flexWrap: 'wrap' }}>
            {selectedOption.map((option, index) => (
              <Stack key={index} flexDirection='row' alignItems='center' padding='0 4px' sx={{ backgroundColor: backgroundColor, borderRadius: '4px' }}>
                {option.label}
                <CloseOutlined onClick={(e) => handleOptionClick(e, option)} sx={{ fontSize: '16px' }} />
              </Stack>
            ))}
          </Stack>
        ) : (
          <Typography variant='body2' color={selectedOption ? variables.colors.text.primary : variables.colors.text.secondary}>
            {placeholder}
          </Typography>
        )}
        <KeyboardArrowDownIcon sx={{ transform: isOpen ? 'rotate(180deg)' : '' }} />
        {isOpen && (
          <Dropdown ref={dropdownRef} dropUp={dropUp} isCalculatingDropUp={isCalculatingDropUp}>
            <SearchBox onClick={(e) => e.stopPropagation()}>
              <SearchInput placeholderText='Search' searchValue={searchValue} setSearchValue={setSearchValue} />
            </SearchBox>
            <Stack sx={{ paddingLeft: '20px' }} maxHeight='200px' overflow='auto' onScroll={(event: any) => {
              const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
              if (bottom && hasNextPage) {
                fetchNextPage();
              }
            }}>
              {options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  label={option.label}
                  onClick={(e) => e.stopPropagation()}
                  control={
                    <Checkbox
                      checked={selectedOption.some((selected: LookupSelectFieldOptions) => selected.value === option.value)}
                      onChange={(e) => handleOptionClick(e, option)}
                    />
                  }
                />
              ))}
              {isFetchingNextPage && (
                <Stack alignItems='center' marginBottom='10px' >
                  <CircularProgress sx={{ position: 'relative', width: '100%' }} />
                </Stack>
              )}
            </Stack>
          </Dropdown>
        )}
      </StyledSelectInput>

      {((dataFetchingError || validationError) && errorText) ? <Typography variant='subtitle2' color='red' fontWeight='400'>{errorText}</Typography>
        : helperText && <Typography variant='subtitle2' color={variables.colors.text.secondary} fontWeight='400'>{helperText}</Typography>
      }
    </div >
  );
});

const StyledSelectInput = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'error' && prop !== 'isOpen',
}) <StyledLookupFieldProps>`
  box-sizing: border-box;
  border-radius: 4px;
  border: ${({ error, isOpen }) => error ? '1px solid red' : isOpen ? `2px solid ${variables.colors.primary.darker}` : '1px solid #ccc'};
  padding: ${({ isOpen }) => isOpen ? '5px 9px' : '5px 10px'}; // Adjust padding to account for the increased border width - for some reason the box-sizing property doesn't work here 🤷‍♂️
  width: 100%;
  position: relative;
  cursor: pointer;  
  min-height: 42px;
  height: fit-content;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  font-family: ${variables.fonts.family.primary};
  font-size: 14px;
  background-color: white;
  &:focus-visible {
    border: 2px solid #5C068C !important;
    outline: none;
    padding: 0 9px;
  }
`;

export interface DropdownProps {
  dropUp: boolean;
  isCalculatingDropUp: boolean;
}

const Dropdown = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'dropUp' && prop !== 'isCalculatingDropUp',
}) <DropdownProps>`
  z-index: 999;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  gap: 8px;
  position: absolute;
  top: ${({ dropUp }) => dropUp ? 'unset' : '100%'};
  bottom: ${({ dropUp }) => dropUp ? '100%' : 'unset'};
  left: 0;
  background-color: white;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 3px 8px -1px #E0E0E0;
  border-radius: 4px;
  opacity: ${({ isCalculatingDropUp }) => isCalculatingDropUp ? '0' : '1'};
`;

const SearchBox = styled.div`
  border-bottom: 1px solid #E0E0E0;
  background-color: white;
  min-width: 100%;
  & * {
    border: none;
    width: 100%;
  }
  .MuiInputAdornment-positionEnd {
    width: unset;
  }
`;

MultiSelectLookupField.displayName = 'MultiSelectLookupField';

export default MultiSelectLookupField;