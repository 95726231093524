import axiosInstance from "api/config";
import { TimeSheetDeclinedReason, TimeSheetDeclinedReasonList } from "types/dbSchema/serviceTeamSettings";

// Returns a list of time sheet declined reasons for the given organisation ID
export const listTimeSheetDeclinedReasons = async (organisationId: string, page?: number, pageSize?: number): Promise<TimeSheetDeclinedReasonList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID, page, and page size are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/service-team/time-sheet-declined-reasons`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing time sheet declined reasons for the organisation', error);
    throw error;
  }
};

// Returns a time sheet declined reason by ID
export const getTimeSheetDeclinedReasonByID = async (organisationId: string, timeSheetDeclinedReasonID: string): Promise<TimeSheetDeclinedReason> => {
  try {
    if (!timeSheetDeclinedReasonID || !organisationId) throw new Error('Organisation ID and TimeSheetDeclinedReason ID are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/service-team/time-sheet-declined-reasons/${timeSheetDeclinedReasonID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the time sheet declined reason', error);
    throw error;
  }
};

// Create a new time sheet declined reason
export const createTimeSheetDeclinedReason = async (organisationId: string, data: TimeSheetDeclinedReason) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/${organisationId}/settings/service-team/time-sheet-declined-reasons`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the time sheet declined reason', error);
    throw error;
  }
};

// Update a timeSheetDeclinedReason
export const updateTimeSheetDeclinedReason = async (organisationId: string, data: TimeSheetDeclinedReason) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.put(`/api/${organisationId}/settings/service-team/time-sheet-declined-reasons/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the time sheet declined reason', error);
    throw error;
  }
};

// Delete a time sheet declined reason
export const deleteTimeSheetDeclinedReason = async (organisationId: string, timeSheetDeclinedReasonID: string) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.delete(`/api/${organisationId}/settings/service-team/time-sheet-declined-reasons/${timeSheetDeclinedReasonID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the time sheet declined reason', error);
    throw error;
  }
};