import React, { FC, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { LinearProgress } from '@mui/material';
import { ProtectedRouteProps } from './types';
import useUserOrganisations from 'hooks/useUserOrganisations';

// This component restricts access to authenticated users only and redirects based on their organisation status.
// - Unauthenticated users are redirected to the login page (handled by UnauthenticatedTemplate).
// - Authenticated users without an organisation or with a pending organisation are redirected to the organisation registration page.
// - Authenticated users with an associated, active organisation can access the component.

// NOTE: Organisations are fetched asynchronously and the redirect logic should wait for this data to avoid incorrect evaluations.
const ProtectedRoute: FC<ProtectedRouteProps> = ({ component }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [urlRedirect, setUrlRedirect] = useState<string | null>(null);
  const { accounts } = useMsal();
  const location = useLocation();
  const [organisations, , , hasBeenFetched] = useUserOrganisations();

  useEffect(() => {
    // If the user is not authenticated, do not redirect and allow the UnauthenticatedTemplate to handle it
    if (accounts.length === 0) setUrlRedirect(null);

    // If the user is authenticated and the organisations have been fetched, check the user's organisation status
    if (accounts.length > 0 && hasBeenFetched) {
      try {
        // Redirect to organisation registration if no organisation or if the organisation is pending
        // TODO: When we move to a multi-organisation setup, we will need to update this logic
        if (organisations.length === 0 || organisations[0]?.organisation.isPending) {
          setUrlRedirect('/organisation-registration');
        }
      } catch (error) {
        console.error('There was an error checking if the user is associated with an organisation', error);
        setIsLoading(false);
        setUrlRedirect(null); // Allow the UnauthenticatedTemplate to handle the redirect
      }
    }
    setIsLoading(false);
  }, [accounts, organisations, hasBeenFetched]);

  // Loading element to prevent the app from rendering before authentication is complete
  if (isLoading) return <LinearProgress />;

  // Redirect the user to the appropriate page if they are not associated with an organisation
  // No redirect if they are already on the correct page
  if (urlRedirect && location.pathname !== urlRedirect && location.pathname !== '/return' && location.pathname !== '/organisation-registration') {
    return (
      <AuthenticatedTemplate>
        <Navigate to={urlRedirect} />
      </AuthenticatedTemplate>
    );
  }

  // Render the component if the user is authenticated and associated with an organisation
  // Redirect to the login page if the user is not authenticated
  return (
    <>
      <AuthenticatedTemplate>
        {component}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Navigate to="/login" />;
      </UnauthenticatedTemplate>
    </>
  );
};

export default ProtectedRoute;