import { Dispatch } from "react";

export interface IsActiveDropdownProps {
  isActive: boolean;
  setIsActive?: Dispatch<boolean>;
  disabled?: boolean;
}
export interface TStatus {
  label: string;
  fontColor: string;
  backgroundColor: string;
  isActive: boolean;
}

export const isActiveStatusObjects: TStatus[] = [
  {
    label: 'Active',
    fontColor: '#356328',
    backgroundColor: '#69C64F4D',
    isActive: true,
  },
  {
    label: 'Inactive',
    fontColor: '#5C068C',
    backgroundColor: '#5C068C26',
    isActive: false,
  },
];