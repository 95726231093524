import React from 'react';
import { LinearProgress } from '@mui/material';
import ServiceProviderForm from 'components/features/Forms/ServiceProviderForm';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useParams } from 'react-router-dom';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { getServiceProviderByID } from 'api/organisations/serviceproviders';

const EditServiceProvidersPage = () => {
  const [organisations] = useUserOrganisations();
  const { id } = useParams();

  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
    getByIDFunc: () => getServiceProviderByID(organisations[0].organisation.globalId, id as string),
  });

  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return (
    <ServiceProviderForm serviceProvider={currentDataPoint} />
  );
};

export default EditServiceProvidersPage;