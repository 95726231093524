import React, { FC } from 'react';
import { LinearProgress } from '@mui/material';
import ReviewGoal from 'components/features/ViewParticipantGoal/GoalDetails/ReviewGoal';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useParams } from 'react-router-dom';
import { getParticipantGoalById } from 'api/organisations/participants/goals';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';

const ReviewParticipantGoalPage: FC = () => {
  const [organisations] = useUserOrganisations();
  const { id, goalId } = useParams();

  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
    getByIDFunc: () => getParticipantGoalById(organisations[0].organisation.globalId, id as string, goalId as string),
  });

  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;


  return <ReviewGoal participantGoal={currentDataPoint} />;
};

export default ReviewParticipantGoalPage;