import React, { FC, useEffect, useRef, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { ServiceType as TServiceType } from 'types/dbSchema/schedulingSettings';
import ServiceType from 'components/common/ServiceType';

interface ListServiceTypesProps {
  serviceTypesDelivered: TServiceType[];
}

const ListServiceTypes: FC<ListServiceTypesProps> = ({ serviceTypesDelivered }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleCount, setVisibleCount] = useState(serviceTypesDelivered.length);

  useEffect(() => {
    if (containerRef.current) {
      const fullWidth = containerRef.current.offsetWidth;

      let totalWidth = 0;
      let count = 0;
      Array.from(containerRef.current.children).forEach(child => {
        totalWidth += (child as HTMLElement).offsetWidth + 8;
        if (totalWidth < fullWidth) {
          count++;
        }
      });
      setVisibleCount(count);
    }
  }, [serviceTypesDelivered]);

  return (
    <Stack direction='row' gap='8px' width='100%' overflow='hidden' ref={containerRef}>
      {serviceTypesDelivered.slice(0, visibleCount).map((serviceType, index) => (
        <ServiceType key={index} serviceType={serviceType} />
      ))}
      {visibleCount < serviceTypesDelivered.length && (
        <Stack
          flexDirection='row'
          alignItems='center'
          justifyContent='center'
          gap='4px'
          sx={{
            backgroundColor: '#F3F4F6',
            padding: '4px 8px',
            borderRadius: '4px',
          }}
        >
          <Typography variant='caption' fontWeight='600'>
            +{serviceTypesDelivered.length - visibleCount}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default ListServiceTypes;