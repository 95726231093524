import React from "react";
import { invalidateDataAtom } from "atoms/invalidateDataAtom";
import IsActiveDropdown from "components/common/IsActiveDropdown";
import useErrorMessage from "hooks/useErrorMessage";
import useInvalidateData from "hooks/useInvalidateData";
import useUserOrganisations from "hooks/useUserOrganisations";
import { useAtom } from "jotai";
import { updateServiceProviderContact } from "api/organisations/serviceproviders/serviceprovidercontacts";
import { useParams } from "react-router-dom";
import { ServiceProviderContact } from "types/dbSchema/serviceProviders";
import { ServiceProviderPosition } from "types/dbSchema/serviceProviderSettings";

// Handles the status dropdown in the DataTable
const EditStatus = ({ serviceProviderContact }: { serviceProviderContact: ServiceProviderContact }) => {
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  useInvalidateData('availability-template');
  const showError = useErrorMessage();
  const { id } = useParams();

  const handleEdit = async (status: boolean) => {
    try {
      if (!serviceProviderContact.id) throw new Error('Service Provider ID not found');
      await updateServiceProviderContact(
        organisations[0].organisation.globalId,
        id as string,
        serviceProviderContact.id,
        {
          ...serviceProviderContact,
          position: (serviceProviderContact.position as ServiceProviderPosition).id,
          isActive: status
        }
      );
      setInvalidateData((prev) => ({ ...prev, 'service-provider-contacts': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <IsActiveDropdown
      isActive={serviceProviderContact.isActive}
      setIsActive={handleEdit}
    />
  );
};

export default EditStatus;