import axiosInstance from 'api/config';
import { OrganisationDetails } from 'types/dbSchema/organisation';

// Checks if an organisation exists with the given ABN and NDIS ID
export const organisationExists = async (abn: string, ndisId: string) => {
  try {
    if (!abn || !ndisId) {
      throw new Error('ABN and NDIS ID are required');
    }
    const response = await axiosInstance.get('/api/organisations/organisation-exists', {
      params: {
        abn,
        ndisId
      }
    });
    return response.data;
  } catch (error) {
    console.error('There was an error checking if the organisation exists', error);
    throw error;
  }
};

export const getCurrentUserProfile = async () => {
  try {
    const response = await axiosInstance.get(`/api/organisations/current-user`);
    return response.data;
  } catch (error) {
    console.error('There was an error getting the current user profile', error);
    throw error;
  }
};

export const getVersion = async () => {
  try {
    const response = await axiosInstance.get('/api/version');
    return response.data;
  } catch (error) {
    console.error('There was an error getting the version', error);
    throw error;
  }
};

export const getOrganisationDetails = async (organisationId: string) => {
  try {
    if (!organisationId) throw new Error('Organisation ID is required');
    const response = await axiosInstance.get(`/api/organisations/${organisationId}/details`);
    return response.data;
  } catch (error) {
    console.error('There was an error getting the organisation details', error);
    throw error;
  }
};

export const editOrganisationDetails = async (organisationId: string, data: OrganisationDetails) => {
  try {
    if (!organisationId) throw new Error('Organisation ID is required');
    const response = await axiosInstance.put(`/api/organisations/${organisationId}/details`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error editing the organisation details', error);
    throw error;
  }
};