import React, { FC, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { closeModalAtom } from 'atoms/modalAtom';
import { useAtom } from 'jotai';
import DragAndDropFileUpload from 'components/common/InputFields/DragAndDropFileUpload';
import { UploadedFile } from 'components/common/InputFields/DragAndDropFileUpload/types';
import TitleWithClose from 'components/common/TitleWithClose';
import { Controller, useForm } from 'react-hook-form';
import TextInput from 'components/common/InputFields/TextInput';
import SelectInput from 'components/common/InputFields/SelectInput';
import TextareaInput from 'components/common/InputFields/TextareaInput';
import variables from 'styles/variables';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from 'components/common/Tooltip';
import { SubmittedState } from 'components/common/InputFields/DragAndDropFileUpload/FileStates';
import { ServiceProviderNote } from 'types/dbSchema/serviceProviderNotes';
import { convertGeneralNote, convertServiceProviderNote } from 'utils/helpers/postDataConvert';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useCurrentUser from 'hooks/useCurrentUser';
import { createServiceProviderNote, updateServiceProviderNoteById } from 'api/organisations/serviceproviders/notes';
import ServiceProviderNoteTypesLookupField from 'components/common/LookupInputField/ServiceProviderNoteTypesLookupField';

export interface ServiceProviderNoteProps {
  type: 'create' | 'edit';
  serviceProviderNote: ServiceProviderNote | null;
  serviceProviderId: string;
}

const ServiceProviderNoteForm: FC<ServiceProviderNoteProps> = ({ type, serviceProviderNote, serviceProviderId }) => {
  const [currentFiles, setCurrentFiles] = useState<UploadedFile[]>([]);
  const [filesToDelete, setFilesToDelete] = useState<string[]>([]);
  const validFileTypes = [
    'text/csv',
    'application/pdf',
    'image/png',
    'image/jpeg',
    'text/plain'
  ];
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const [, closeModal] = useAtom(closeModalAtom);
  const showError = useErrorMessage();
  const [organisations] = useUserOrganisations();
  const [currentUser] = useCurrentUser();
  const maxTotalSize = 104857600;
  const maxFileSize = 104857600;
  const { control, handleSubmit, formState: { errors, isDirty }, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      ...serviceProviderNote ? convertServiceProviderNote(serviceProviderNote) : {},
      filesChanged: 0 as number
    },
  });

  const handleCreate = async (data: ServiceProviderNote) => {
    if (!serviceProviderId) {
      showError('Participant ID is required');
      return;
    }

    try {
      if (!currentUser) return;
      const formData = new FormData();

      const newData = {
        ...data,
        createdBy: currentUser.id,
      } as ServiceProviderNote;

      // Append other data fields
      for (const [key, value] of Object.entries(newData)) {
        formData.append(key, value);
      }

      // Append files
      currentFiles.forEach((fileObj) => {
        formData.append('attachments', fileObj.file);
      });

      await createServiceProviderNote(
        organisations[0].organisation.globalId,
        serviceProviderId,
        formData
      );
      setInvalidateData((prev) => ({ ...prev, 'service-provider-notes': true }));
      closeModal();
    } catch (error) {
      showError('Failed to create Service Provider Note');
    }
  };

  const handleEdit = async (data: ServiceProviderNote) => {
    try {
      const formData = new FormData();
      const newData = {
        ...data,
        createdBy: currentUser?.id,
        ExistingAttachmentsToRemove: filesToDelete
      } as ServiceProviderNote;

      // Append other data fields
      // The ExistingAttachmentsToRemove field is an array, so we need to append it differently
      for (const [key, value] of Object.entries(newData)) {
        if (key === 'ExistingAttachmentsToRemove' && Array.isArray(value)) {
          value.forEach((v) => formData.append(`${key}[]`, v));
        } else {
          formData.append(key, value);
        }
      }

      // Append files
      currentFiles.forEach((fileObj) => {
        formData.append('attachments', fileObj.file);
      });

      await updateServiceProviderNoteById(
        organisations[0].organisation.globalId,
        serviceProviderId,
        serviceProviderNote?.id as string,
        formData
      );
      setInvalidateData((prev) => ({ ...prev, 'service-provider-notes': true }));
    } catch (e) {
      showError('Failed to update Service Provider Note');
    }
  };

  const onSubmit = async (data: any) => {
    try {
      if (type === 'create') {
        await handleCreate(data);
        closeModal();
      } else if (type === 'edit') {
        await handleEdit(data);
        closeModal();
      }
    } catch (error) {
      showError((error as Error).message);
    }
  };


  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid='serviceprovider-note-form' style={{ height: '100%' }}>
      <Stack height='100%' width='100%' justifyContent='space-between'>
        <Stack padding='32px' gap='16px' sx={{ overflowY: 'auto' }}>
          <Box>
            <TitleWithClose title={type === 'create' ? 'Add New Note' : 'Edit Note'} handleClose={closeModal} />
          </Box>
          {/* Hidden field to track changes to the files */}
          <Controller
            control={control}
            name="filesChanged"
            defaultValue={0}
            render={({ field }) => (
              <input type="hidden" {...field} />
            )}
          />
          <Stack gap='16px' paddingTop='16px'>
            <Controller
              control={control}
              name='noteType'
              rules={{
                required: 'Note Type is required'
              }}
              render={({ field }) => (
                <ServiceProviderNoteTypesLookupField
                  {...field}
                  id='noteType'
                  label='Note Type'
                  validationError={errors.noteType ? true : false}
                  errorText={errors.noteType?.message}
                  isMandatory
                />
              )}
            />
            <Controller
              control={control}
              name='subject'
              rules={{
                maxLength: { value: 100, message: 'Subject is too long' }
              }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  label='Subject'
                  id='subject'
                  error={errors.subject ? true : false}
                  errorText={errors.subject?.message}
                  helperText='Max. 100 characters'
                />
              )}
            />
            <Controller
              control={control}
              name='note'
              rules={{
                required: 'Note is required',
                maxLength: { value: 200, message: 'Note is too long' }
              }}
              render={({ field }) => (
                <TextareaInput
                  {...field}
                  id='note'
                  label='Note'
                  error={errors.note ? true : false}
                  errorText={errors.note?.message}
                  isMandatory
                />
              )}
            />
          </Stack>
          <Stack gap='4px'>
            <Stack flexDirection='row' gap='4px' alignItems='center'>
              <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary}>
                Documents
              </Typography>
              <Tooltip
                toolTipTitle='Supported documents'
                toolTipText={`You can upload multiple files, summing up to maximum ${(maxTotalSize / 1048576).toFixed(0)}MB. Files supported: ${validFileTypes.join(', ')}.`}
                toolTipTriggerComponent={
                  <Box height='16px' width='16px' position='relative' minWidth='16px'>
                    <InfoOutlinedIcon sx={{ height: '100%', width: '100%' }} />
                  </Box>
                }
              />
            </Stack>
            <DragAndDropFileUpload
              setCurrentFiles={setCurrentFiles}
              currentFiles={currentFiles}
              validFileTypes={validFileTypes}
              maxTotalSize={maxTotalSize}
              maxFileSize={maxFileSize}
              multiple
            />
            {serviceProviderNote?.attachments.filter((f) => !filesToDelete.includes(f.id)).map((file, index) => (
              <SubmittedState
                key={index}
                uploadedFile={file}
                showDeleteIcon
                removeFile={() => {
                  setFilesToDelete((prevFiles) => [...prevFiles, file.id]);
                  console.log(filesToDelete);
                  setCurrentFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
                }}
              />
            ))}
          </Stack>
        </Stack>
        <ButtonStack>
          <Button variant='outlined' color='primary' onClick={closeModal}>Cancel</Button>
          <Button variant='contained' color='primary' type='submit' disabled={!isDirty}>
            {type === 'create' ? 'Add Note' : 'Update'}
          </Button>
        </ButtonStack>
      </Stack >
    </form>
  );
};

const ButtonStack = styled(Stack)`
  gap: 16px;
  justify-content: flex-end;
  flex-direction: row;
  padding: 24px 32px;
`;

export default ServiceProviderNoteForm;