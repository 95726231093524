import React, { FC, createElement, useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import variables from 'styles/variables';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { kebabFormat } from 'utils/helpers';
import { useAtom } from 'jotai';
import { IListItem, NestedListProps, NestedTitleItemProps } from '../../types';
import { sidebarAtom } from 'atoms/sidebarAtom';

const NestedList: FC<NestedListProps> = ({ label, icon, listItems }) => {
  const [sidebarState, setSidebarState] = useAtom(sidebarAtom);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isListActive = listItems.some((el: IListItem) => el.to === pathname);

  const handleClick = () => {
    if (!sidebarState.isOpen) {
      setSidebarState({
        ...sidebarState,
        isOpen: true,
      });
      setOpen(true);
    } else {
      setOpen(!open);
    }
  };

  const handleNavigate = (item: IListItem) => {
    navigate(item.to);
  };

  return (
    <StyledList data-testid={`nestedList-${kebabFormat(label)}`} isListActive={isListActive} isOpen={open} sidebarOpen={sidebarState.isOpen}>
      <StyledTitleItem onClick={handleClick} isListActive={isListActive} sidebarOpen={sidebarState.isOpen} isOpen={open} data-testid={`nestedList-parent-${kebabFormat(label)}`}>
        <StyledListItemIcon>
          {createElement(icon)}
        </StyledListItemIcon>
        {sidebarState.isOpen &&
          <>
            <TitleItemText>
              <Typography variant='subtitle2' color='white' fontWeight='600'>
                {label}
              </Typography>
            </TitleItemText>
            {open ? <ExpandLess htmlColor='white' /> : <ExpandMore htmlColor='white' />}
          </>
        }
      </StyledTitleItem>
      {sidebarState.isOpen &&
        <Collapse in={open} timeout="auto" unmountOnExit sx={{ padding: '0 8px' }}>
          <List component="div" disablePadding data-testid={`nestedList-children-${kebabFormat(label)}`}>
            {listItems.map((item: IListItem, index: number) => {
              const isItemActive = item.to === pathname;
              return (
                <StyledSubItem key={index} isItemActive={isItemActive} onClick={() => handleNavigate(item)}>
                  {isItemActive && <ActiveIndicator data-testid='active-indicator' />}
                  <StyledListItemText>
                    <Typography variant='subtitle2' color='white' fontWeight='400'>
                      {item.label}
                    </Typography>
                  </StyledListItemText>
                </StyledSubItem>
              );
            })}
          </List>
        </Collapse>
      }
    </StyledList>
  );
};

const StyledList = styled(List).withConfig({
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'isListActive' && prop !== 'isOpen' && prop !== 'sidebarOpen',
}) <NestedTitleItemProps>`
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0;
  background-color: ${({ isListActive, isOpen }: { isListActive: boolean, isOpen: boolean }) => (isListActive && isOpen) && variables.colors.primary.main10};
`;

const StyledTitleItem = styled(ListItem).withConfig({
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'isListActive' && prop !== 'isOpen' && prop !== 'sidebarOpen',
}) <NestedTitleItemProps>`
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  user-select: none;
  height: 46px;
  background-color: ${({ isListActive, isOpen, sidebarOpen }: NestedTitleItemProps) => (isListActive && !isOpen || isListActive && !sidebarOpen) && variables.colors.primary.main30};
  &:hover {
    background-color: ${({ isListActive }: { isListActive: boolean }) => !isListActive && variables.colors.primary.main20};
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: unset;
  width: 20px;
  display: block;
  & > svg {
    color: ${variables.colors.secondary.lighter};
    width: 100%;
  }
`;

const TitleItemText = styled(ListItemText)`
  padding: 4px 8px;
  overflow: hidden;
  white-space: nowrap;
`;

export type StyledSubItemProps = {
  isItemActive: boolean;
};

const StyledSubItem = styled(ListItem).withConfig({
  shouldForwardProp: (prop) => prop !== 'isItemActive',
}) <StyledSubItemProps>`
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  padding-left: 20px;
  color: white;
  user-select: none;
  background-color: ${({ isItemActive }: { isItemActive: boolean }) => isItemActive ? variables.colors.primary.main30 : 'transparent'};
  &:hover {
    background-color: ${({ isItemActive }: { isItemActive: boolean }) => !isItemActive && variables.colors.primary.main20};
  }
`;

const StyledListItemText = styled(ListItemText)`
  padding-left: 8px;
  overflow: hidden;
  white-space: nowrap;
`;

const ActiveIndicator = styled.div`
  position: absolute;
  left: 0;
  width: 4px;
  height: 24px;
  background-color: ${variables.colors.secondary.lighter};
  border-radius: 0px 4px 4px 0px;
`;

export default NestedList;