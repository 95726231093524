import React, { forwardRef, useEffect, useRef, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styled from 'styled-components';
import variables from 'styles/variables';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchInput from '../SearchInput';
import { MultiSelectInputProps, DropdownItem } from './types';

const MultiSelectInput = forwardRef<HTMLInputElement, MultiSelectInputProps>(({
  items,
  label,
  id,
  error,
  errorText,
  helperText,
  placeholder,
  onChange,
  isMandatory,
  ...props }, ref) => {
  const [selectedItems, setSelectedItems] = useState<DropdownItem[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<DropdownItem[]>(items);
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const selectFieldRef = useRef<HTMLDivElement>(null);
  const selectDropdown = useRef<HTMLDivElement>(null);

  // This is used to filter the options based on the search value
  useEffect(() => {
    // Filter options based on search value or return all options if searchValue is empty
    const filtered = searchValue ? items.filter((option: DropdownItem) => option.value.toLowerCase().includes(searchValue.toLowerCase())) : items;
    setFilteredOptions(filtered);
  }, [searchValue, items]);

  // This is used to close the filter dropdown when clicking outside of it and outside the filter button
  // The reason we need to check if the filter button is clicked is because the filter button handles it's own click event
  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectFieldRef.current &&
      !selectFieldRef.current.contains(event.target as Node) &&
      selectDropdown.current &&
      !selectDropdown.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleItem = (item: DropdownItem) => {
    setSelectedItems(prevItems => {
      const itemExists = prevItems.find(selected => selected.value === item.value);
      if (itemExists) {
        const newItems = prevItems.filter(selected => selected.value !== item.value);
        onChange(newItems.map(selected => selected.value).join(','));
        return newItems;
      } else {
        const newItems = [...prevItems, item];
        onChange(newItems.map(selected => selected.value).join(','));
        return newItems;
      }
    });
  };

  const removeItem = (value: string) => {
    setSelectedItems(selectedItems.filter(item => item.value !== value));
  };

  const clearAll = (e: React.MouseEvent) => {
    e.stopPropagation();
    onChange('');
    setSelectedItems([]);
  };

  return (
    <OuterBox ref={ref} {...props}>
      {label && (
        <label htmlFor={id}>
          <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }} data-testid='label'>
            {label} {isMandatory && <span style={{ color: 'red' }}>*</span>}
          </Typography>
        </label>
      )}
      <InputBox ref={selectFieldRef} onClick={() => !isOpen && setIsOpen(true)} isOpen={isOpen} id={id} error={error} data-testid='input-box'>
        <Stack direction='row' flex={1} flexWrap='wrap' gap='8px' maxWidth='calc(100% - 42px)'>
          {selectedItems.map((item) => (
            <Stack key={item.value} direction='row' alignItems='center' data-testid={`selected-item-${item.value}`}>
              {item.component ? <item.component /> : item.value}
              <IconButton onClick={() => removeItem(item.value)} size='small'>
                <CloseIcon sx={{ fontSize: '14px', paddingLeft: '0px' }} />
              </IconButton>
            </Stack>
          ))}
          {selectedItems.length === 0 && <Typography variant='body2' color='text.secondary'>{placeholder}</Typography>}
        </Stack>
        <Stack alignSelf='center'>
          <Stack height='fit-content' flexDirection={'row'} alignItems={'center'} justifyContent='center'>
            {selectedItems.length >= 1 && <CancelIcon onClick={clearAll} htmlColor={variables.colors.icon.close} />}
            <KeyboardArrowDownIcon sx={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', height: '20px' }} onClick={() => setIsOpen(!isOpen)} />
          </Stack>
        </Stack>
      </InputBox>
      {isOpen && (
        <Stack position='absolute' width='100%' ref={selectDropdown}>
          <Dropdown data-testid="dropdown">
            <SearchBox>
              <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholderText='Search' data-testid="search-input" />
            </SearchBox>
            <FormGroup sx={{ flexWrap: 'nowrap', overflowY: 'auto', maxHeight: '200px' }}>
              {filteredOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedItems.some((selected: DropdownItem) => selected.value === item.value)}
                        onChange={() => toggleItem(item)}
                      />
                    }
                    label={
                      item.component ? <item.component /> : item.value
                    }
                  />
                </MenuItem>
              ))}
            </FormGroup>
          </Dropdown>
          {/* This is used to add space at the bottom of the dropdown */}
          <Box sx={{ height: '42px' }} />
        </Stack>
      )}
      {(error && errorText) ? <Typography variant='subtitle2' color='red' fontWeight='400'>{errorText}</Typography>
        : helperText && <Typography variant='subtitle2' color={variables.colors.text.secondary} fontWeight='400'>{helperText}</Typography>
      }
    </OuterBox>
  );
});

const OuterBox = styled(Box)`
  position: relative;
  width: 100%;
`;

export interface InputBoxProps {
  error?: boolean;
  isOpen?: boolean;
}

const InputBox = styled(Box).withConfig({
  shouldForwardProp: (prop) => prop !== 'error' && prop !== 'isOpen',
}) <InputBoxProps>`
  border: ${({ error, isOpen }) => error ? '1px solid red' : isOpen ? `1px solid ${variables.colors.primary.darker}` : '1px solid #ccc'};
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 42px;
  background-color: white;
  max-height: 100px;
  height: 100%;
  overflow-y: auto;
`;

const Dropdown = styled(Stack)`
  position: relative;
  top: 100%;
  left: 0;
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 3px 8px -1px #E0E0E0;
  border-radius: 4px;
  z-index: 1000;
`;

const SearchBox = styled.div`
  border-bottom: 1px solid #E0E0E0;
  background-color: white;
  width: 100%;
  & * {
    border: none;
    width: 100%;
  }
  .MuiInputAdornment-positionEnd {
    width: unset;
  }
`;

MultiSelectInput.displayName = 'MultiSelectInput';

export default MultiSelectInput;