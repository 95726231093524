import React, { FC } from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ServiceContract } from 'types/dbSchema/serviceContracts';

export interface ViewIconProps {
  serviceContractToView: ServiceContract;
}

const ViewIcon: FC<ViewIconProps> = ({ serviceContractToView }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <StyledBox onClick={() => navigate(`/participant-centre/participants/${id}/service-contracts/${serviceContractToView.id}`)}>
      <RemoveRedEyeOutlinedIcon />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export default ViewIcon;