import React, { FC } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import TextInput from 'components/common/InputFields/TextInput';
import { ServiceProvider } from 'types/dbSchema/serviceProviders';
import SelectInput from 'components/common/InputFields/SelectInput';
import { Address } from 'types';
import AddressLookupField from 'components/common/InputFields/AddressLookupField';

export interface LocationDetailsProps {
  control: Control<ServiceProvider>;
  errors: FieldErrors<ServiceProvider>;
  setValue: UseFormSetValue<ServiceProvider>,
}

const LocationDetails: FC<LocationDetailsProps> = ({
  control,
  errors,
  setValue,
}) => {

  const handleSetAddress = (values: Address) => {
    setValue('businessAddress', values, { shouldValidate: true });
  };

  return (
    <DetailsStack>
      <Typography variant='h6' fontWeight='600' color='text.primary'>
        Location Details
      </Typography>
      <Row>
        {/* TODO: Change to Lookup field */}
        <Controller
          name="serviceRegion"
          control={control}
          render={({ field }) =>
            <SelectInput
              {...field}
              id='serviceRegion'
              label='Service Region'
              error={errors.serviceRegion ? true : false}
              errorText={errors.serviceRegion?.message}
              options={[
                { value: 'North', label: 'North' },
                { value: 'South', label: 'South' },
                { value: 'East', label: 'East' },
                { value: 'West', label: 'West' },
              ]}
            />}
        />
      </Row>
      <Controller
        name="businessAddress"
        control={control}
        render={({ field }) =>
          <AddressLookupField
            {...field}
            id='businessAddress'
            placeholder='Search for your address'
            setValue={handleSetAddress}
            label='Address'
            error={false}
          />}
      />
    </DetailsStack>
  );
};

const DetailsStack = styled(Stack)`
  background-color: white;
  padding: 32px;
  gap: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
`;

export interface RowProps {
  numberOfChildren?: number;
}

const Row = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'numberOfChildren'
}) <RowProps>`
  gap: 24px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  & > * {
    flex: 0 0 calc((100% - 48px) / ${({ numberOfChildren }) => numberOfChildren});
    max-width: calc((100% - 48px) / ${({ numberOfChildren }) => numberOfChildren});
  }
`;

export default LocationDetails;