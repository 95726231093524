import axiosInstance from "api/config";

// Get a list of progress notes for a participant
export const listParticipantProgressNotes = async (organisationId: string, participantId: string, page: number, pageSize: number) => {
  try {
    if (!organisationId || !participantId) throw new Error('Organisation ID and Participant Id are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants/${participantId}/progress-notes`, {
      params: {
        page,
        pageSize
      }
    });
    return response.data;
  } catch (error) {
    console.error('There was an error listing progress notes for the participant', error);
    throw error;
  }
};

export const getParticipantProgressNoteById = async (organisationId: string, participantId: string, progressNoteId: string) => {
  try {
    if (!organisationId || !participantId || !progressNoteId) throw new Error('Organisation ID, Participant ID, and Progress Note ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants/${participantId}/progress-notes/${progressNoteId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the progress note', error);
    throw error;
  }
};

export const getAllAttachments = async (organisationId: string, participantId: string, progressNoteId: string) => {
  try {
    if (!organisationId || !participantId || !progressNoteId) throw new Error('Organisation ID, Participant ID, and Progress Note ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants/${participantId}/progress-notes/${progressNoteId}/attachments/zip`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the attachments', error);
    throw error;
  }
};

export const getAttachmentByFilename = async (organisationId: string, participantId: string, progressNoteId: string, filename: string) => {
  try {
    if (!organisationId || !participantId || !progressNoteId || !filename) throw new Error('Organisation ID, Participant ID, Progress Note ID, and Filename are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants/${participantId}/progress-notes/${progressNoteId}/attachments/${filename}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the attachment', error);
    throw error;
  }
};

export const deleteAttachmentByFilename = async (organisationId: string, participantId: string, progressNoteId: string, filename: string) => {
  try {
    if (!organisationId || !participantId || !progressNoteId || !filename) throw new Error('Organisation ID, Participant ID, Progress Note ID, and Filename are required');

    const response = await axiosInstance.delete(`/api/organisations/${organisationId}/participants/${participantId}/progress-notes/${progressNoteId}/attachments/${filename}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the attachment', error);
    throw error;
  }
};

export const linkProgressNoteToGoal = async (organisationId: string, participantId: string, progressNoteId: string, goalId: string) => {
  try {
    if (!organisationId || !participantId || !progressNoteId || !goalId) throw new Error('Organisation ID, Participant ID, Progress Note ID, and Goal ID are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/participants/${participantId}/progress-notes/${progressNoteId}/link-to-goal`, {
      goalId
    });
    return response.data;
  } catch (error) {
    console.error('There was an error linking the progress note to the goal', error);
    throw error;
  }
};

export const unlinkProgressNoteFromGoal = async (organisationId: string, participantId: string, progressNoteId: string, goalId: string) => {
  try {
    if (!organisationId || !participantId || !progressNoteId || !goalId) throw new Error('Organisation ID, Participant ID, Progress Note ID, and Goal ID are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/participants/${participantId}/progress-notes/${progressNoteId}/unlink-from-goal`, {
      goalId
    });
    return response.data;
  } catch (error) {
    console.error('There was an error unlinking the progress note from the goal', error);
    throw error;
  }
};