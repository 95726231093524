import { Box, Stack, Typography } from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import styled from 'styled-components';
import { StatusDropdownProps, TStatus } from './types';

const StatusDropdown: FC<StatusDropdownProps> = ({ statuses, currentStatus, setStatus, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const filterDropdownRef = useRef<HTMLDivElement>(null);
  const [currentStatusObject, setCurrentStatusObject] = useState<TStatus | null>(
    statuses.find((statusObject) => statusObject.value === currentStatus) || statuses[0]
  );

  useEffect(() => {
    const foundStatus = statuses.find((statusObject) => statusObject.value.toLowerCase() === currentStatus.toLowerCase()) || statuses[0];
    setCurrentStatusObject(foundStatus);
  }, [statuses, currentStatus]);

  const handleStatusChange = (status: string) => {
    setCurrentStatusObject(statuses.find((statusObject) => statusObject.value === status) || statuses[0]);
    setStatus && setStatus(status);
    setIsOpen(false);
  };

  useEffect(() => {
    // This is used to close the dropdown when clicking outside of it
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && event.target instanceof Node && filterDropdownRef.current && !filterDropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!currentStatusObject) return null;

  return (
    <Box sx={{ position: 'relative' }} width='fit-content' ref={filterDropdownRef}>
      <Stack sx={{ backgroundColor: currentStatusObject.backgroundColor, padding: '4px 8px', borderRadius: '4px', width: 'fit-content', cursor: `${disabled ? 'initial' : 'pointer'}`, position: 'relative' }} direction='row' alignItems='center' onClick={() => setIsOpen(!isOpen)}>
        <Typography textTransform='uppercase' variant='caption' fontWeight='600' color={currentStatusObject.fontColor}>
          {currentStatusObject.label}
        </Typography>
        {!disabled &&
          <KeyboardArrowDownOutlinedIcon sx={{ fontSize: '1rem', marginLeft: '4px', color: currentStatusObject.fontColor, transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }} />
        }
        {isOpen && !disabled && setStatus && (
          <Dropdown>
            {statuses.map((status: TStatus, key) => (
              <Box padding='8px 16px' key={key} onClick={() => handleStatusChange(status.value)}>
                <Box sx={{ backgroundColor: status.backgroundColor, padding: '2px 4px', borderRadius: '4px', width: 'fit-content', cursor: 'pointer', position: 'relative' }}>
                  <Typography variant='body2' fontWeight='600' color={status.fontColor}>
                    {status.label}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Dropdown>
        )}
      </Stack>
    </Box>
  );
};

const Dropdown = styled(Stack)`
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  min-width: 120px;
  width: fit-content;
  height: fit-content;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 3px 8px -1px #E0E0E0;
  border-radius: 4px;
  z-index: 10000;
`;

export default StatusDropdown;