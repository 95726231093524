import React, { FC } from "react";
import { DetailSectionProps } from "../../types";
import { Stack, Typography } from "@mui/material";
import variables from "styles/variables";
import { Participant } from "types/dbSchema/participants";
import ServiceType from "components/common/ServiceType";
import { ServiceType as TServiceType, ShiftLocation } from 'types/dbSchema/schedulingSettings';
import { Controller, useForm } from "react-hook-form";
import DateTimePickerInput from 'components/common/InputFields/DateTimePickerInput';
import { calculateDuration } from "utils/helpers/fullCalendarTemplates";

const DetailsSection: FC<DetailSectionProps> = ({ currentService }) => {
  return (
    <Stack flexDirection="row" gap="16px" alignItems='flex-start' justifyContent='space-between'>
      <OverviewSection currentService={currentService} />
      <DetailsForm currentService={currentService} />
    </Stack>
  );
};

const OverviewSection: FC<DetailSectionProps> = ({ currentService }) => {
  return (
    <Stack gap='8px'>
      <Stack flexDirection='row' gap='8px' alignItems='center' height='32px'>
        <Typography variant='body2' minWidth='120px' color={variables.colors.text.secondary}>
          Participant
        </Typography>
        <Stack flexDirection='row' gap='8px' alignItems='center' justifyContent='flex-start'>
          <img
            src={(currentService.participant as Participant).profilePicture}
            alt="profile"
            style={{
              width: '32px',
              height: '32px',
              borderRadius: '32px',
              objectFit: 'cover',
              marginRight: '12px',
            }}
          />
          <Typography variant='subtitle1'>
            {(currentService.participant as Participant).fullName.firstName} {(currentService.participant as Participant).fullName.lastName}
          </Typography>
        </Stack>
      </Stack>
      <Stack flexDirection='row' gap='8px' alignItems='center' height='32px'>
        <Typography variant='body2' minWidth='120px' color={variables.colors.text.secondary}>
          Service Types
        </Typography>
        <Stack flexDirection='row' gap='8px' alignItems='center' justifyContent='flex-start'>
          {(currentService.serviceTypes as TServiceType[]).map((serviceType, index) => (
            <ServiceType key={index} serviceType={serviceType} />
          ))}
        </Stack>
      </Stack>
      <Stack flexDirection='row' gap='8px' alignItems='center' height='32px'>
        <Typography variant='body2' minWidth='120px' color={variables.colors.text.secondary}>
          Service Item
        </Typography>
        <Typography variant='body2'>
          {currentService.serviceItem}
        </Typography>
      </Stack>
      <Stack flexDirection='row' gap='8px' alignItems='center' height='32px'>
        <Typography variant='body2' minWidth='120px' color={variables.colors.text.secondary}>
          Location
        </Typography>
        <Typography variant='subtitle2'>
          {`${(currentService.shiftLocation as ShiftLocation).address.streetAddress} 
            ${(currentService.shiftLocation as ShiftLocation).address.suburb} 
            ${(currentService.shiftLocation as ShiftLocation).address.state} 
            ${(currentService.shiftLocation as ShiftLocation).address.postCode}`}
        </Typography>
      </Stack>
    </Stack>
  );
};

const DetailsForm: FC<DetailSectionProps> = ({ currentService }) => {
  const { control, handleSubmit, formState: { errors, isDirty } } = useForm({
    mode: 'onChange',
    defaultValues: currentService,
  });

  return (
    <Stack border={`1px solid ${variables.colors.border.main}`} borderRadius='8px' padding='24px' maxWidth='500px' gap='16px'>
      <Stack flexDirection='row' gap='8px' alignItems='center' height='32px'>
        <Typography variant='body2' minWidth='120px' color={variables.colors.text.secondary}>
          Service Start
        </Typography>
        <Controller
          name="scheduledStart"
          control={control}
          rules={{ required: 'Start Time is required' }}
          render={({ field }) =>
            <DateTimePickerInput
              {...field}
              id='scheduledStart'
              // label='Start Time'
              error={errors.scheduledStart ? true : false}
              errorText={errors?.scheduledStart?.message}
              isMandatory
            />
          }
        />
      </Stack>
      <Stack flexDirection='row' gap='8px' alignItems='center' height='32px'>
        <Typography variant='body2' minWidth='120px' color={variables.colors.text.secondary}>
          Service End
        </Typography>
        <Stack flexDirection='row' gap='8px' alignItems='center' justifyContent='flex-start'>
          <Controller
            name="scheduledEnd"
            control={control}
            rules={{ required: 'End Time is required' }}
            render={({ field }) =>
              <DateTimePickerInput
                {...field}
                id='endTime'
                error={errors.scheduledEnd ? true : false}
                errorText={errors?.scheduledEnd?.message}
                isMandatory
              />
            }
          />
        </Stack>
      </Stack>
      <Stack flexDirection='row' gap='8px' alignItems='center' height='32px'>
        <Typography minWidth='120px' variant='body2' sx={{ color: variables.colors.text.secondary }}>
          Total hours
        </Typography>
        <Typography minWidth='120px' variant='body2' fontWeight='600' sx={{ color: '#01579B' }}>
          {calculateDuration(currentService.scheduledStart, currentService.scheduledEnd)} hours
        </Typography>
      </Stack>
    </Stack>
  );
};

export default DetailsSection;