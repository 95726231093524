import { LinearProgress, Stack } from '@mui/material';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { PaginationModel } from 'components/common/DataTable/types';
import useUserOrganisations from 'hooks/useUserOrganisations';
import React, { useState } from 'react';
import { ServiceTeamLeave } from 'types/dbSchema/serviceTeam';
import { placeholderHistoryLeaveData } from 'utils/helpers/getDBData';
import { LeaveItem } from '../UpcomingLeave';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';

const LeaveHistory = () => {
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({ page: 0, pageSize: 20 });
  const [organisations] = useUserOrganisations();

  const { isPending, isError, data, isFetching, isLoading } = useQuery({
    queryKey: ['service-team-leave-history', paginationModel],
    queryFn: () => placeholderHistoryLeaveData,
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.leaveHistory,
  }) as any;

  if (isPending || isFetching || isLoading) return <LinearProgress />;

  return (
    <Stack sx={{ backgroundColor: 'white' }} gap='24px' padding='24px'>
      {(data && data.length > 0) && data.map((item: ServiceTeamLeave) => (
        <LeaveItem key={item.id} item={item} />
      ))}
    </Stack>
  );
};

export default LeaveHistory;