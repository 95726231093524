import React, { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import variables from 'styles/variables';
import { Controller, useForm } from 'react-hook-form';
import { OrganisationDetailsData, OrganisationDetailsProps } from '../types';
import styled from 'styled-components';
import CurrentStepIndicator from '../CurrentStepIndicator';
import Greeting from '../Greeting';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { organisationExists } from 'api';
import { isValidABN } from 'utils/helpers';
import TextInput from 'components/common/InputFields/TextInput';
import { abnLookup } from 'api';
import AuthenticationButton from 'components/common/AuthenticationButton';

const OrganisationDetails: FC<OrganisationDetailsProps> = ({ currentStep, setCurrentStep, orgFormData, setOrgFormData }) => {
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const { control, handleSubmit, watch, formState: { errors } } = useForm<OrganisationDetailsData>({
    defaultValues: {
      organisationName: orgFormData.organisationDetails.organisationName,
      NDISOrganisationNumber: orgFormData.organisationDetails.NDISOrganisationNumber,
      ABN: orgFormData.organisationDetails.ABN
    },
    mode: 'onChange'
  });

  const watchAllFields = watch();

  // TODO: Abstract this function out to clean up the component
  const onSubmit = async (data: OrganisationDetailsData) => {
    const isABNValidResponse = await abnLookup(watchAllFields.ABN);
    if (!isABNValidResponse) {
      setModalState({
        ...modalState,
        status: 'open',
        component: ModalMessage,
        props: {
          type: 'abnInvalid'
        }
      });
      return;
    }
    let organisationExistsResponse;
    try {
      organisationExistsResponse = await organisationExists(watchAllFields.ABN, watchAllFields.NDISOrganisationNumber);
      // eslint-disable-next-line
    } catch (error: any) {
      console.error('There was an error checking if the user is associated with an organisation', error);
      throw error;
    }
    if (organisationExistsResponse.found) {
      setModalState({
        ...modalState,
        status: 'open',
        component: ModalMessage,
        props: {
          type: 'orgExists'
        }
      });
      return;
    }
    if (isABNValidResponse && !organisationExistsResponse.isFound) {
      setOrgFormData({
        ...orgFormData,
        organisationDetails: {
          organisationName: data.organisationName,
          NDISOrganisationNumber: data.NDISOrganisationNumber,
          ABN: data.ABN,
          orgId: data.orgId
        },
      });
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid='org-details-form'>
      <StyledStack>
        <Greeting type='org' />
        <Stack gap='8px'>
          <CurrentStepIndicator currentStep={currentStep} />
          <Typography variant="h6" >
            Organisation Details
          </Typography>
          <Typography variant="body2" color={variables.colors.darkNeutral.lighter}>
            This information is needed to create your business account in the system.
          </Typography>
          <Stack gap='24px' paddingTop='16px'>
            <Controller
              control={control}
              name='organisationName'
              rules={{
                required: 'Organisation name is required',
                minLength: { value: 2, message: 'Organisation name is too short' },
                maxLength: { value: 30, message: 'Organisation name is too long' }
              }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  id='organisationName'
                  label='Organisation Name'
                  error={errors.organisationName ? true : false}
                  errorText={errors.organisationName?.message}
                  isMandatory
                />
              )}
            />
            <Controller
              control={control}
              name='NDISOrganisationNumber'
              rules={{
                maxLength: { value: 10, message: 'NDIS Organisation Number is too long (maximum of 10 digits)' }
              }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  id='NDISOrganisationNumber'
                  label='NDIS Organisation Number'
                  error={errors.NDISOrganisationNumber ? true : false}
                  errorText={errors.NDISOrganisationNumber?.message}
                />
              )}
            />
            <Controller
              control={control}
              name='ABN'
              rules={{
                required: 'ABN is required',
                minLength: { value: 2, message: 'ABN is too short' },
                maxLength: { value: 30, message: 'ABN is too long' },
                validate: {
                  correctFormat: value => isValidABN(value) || 'ABN is invalid', // Custom validation rule
                }
              }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  id='ABN'
                  label='ABN'
                  error={errors.ABN ? true : false}
                  errorText={errors.ABN?.message}
                  isMandatory
                />
              )}
            />
          </Stack>
        </Stack>
        <ButtonStack>
          <AuthenticationButton type='login logout' prompt='login' buttonText='Exit' />
          <Button
            type='submit'
            variant='contained'
            aria-label='Continue'
            data-testid='orgDetailsContinueButton'
          >
            Continue
          </Button>
        </ButtonStack>
      </StyledStack>
    </form>
  );
};

export interface ModalMessageProps {
  type: 'orgExists' | 'abnInvalid';
}

export const ModalMessage: FC<ModalMessageProps> = ({ type }) => {
  return (
    <Stack gap='16px' justifyContent='center' alignItems='center' padding='32px'>
      <Typography variant='h6'>{type === 'orgExists' ? 'Organisation already exists' : 'ABN is invalid'}</Typography>
      <Typography variant='body1'>{type === 'orgExists' ? 'The organisation you are trying to register already exists in the system. Request access from the administrator.' : 'We could not find an ABN in the ABN directory that matches. Please enter a valid ABN.'}</Typography>
    </Stack>
  );
};

const StyledStack = styled(Stack)`
  width: 100%;
  height: fit-content;
  max-width: 430px;
  gap: 32px;
  margin: 0 auto;
  @media (max-width: 462px) {
    max-width: unset;
    width: calc(100% - 32px);
    padding: 0 16px;
  }
`;

const ButtonStack = styled(Stack)`
  flex-direction: row;
  width: 100%;
  max-width: 430px;
  gap: 24px;
  padding-bottom: 24px;
  justify-content: flex-end;
  @media (max-width: 462px) {
    flex-direction: column;
    max-width: unset;
  }
`;

export default OrganisationDetails;