import React, { FC } from 'react';
import TitleWithClose from 'components/common/TitleWithClose';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Box, Button, Stack, Typography } from '@mui/material';
import variables from 'styles/variables';
import styled from 'styled-components';
import { useAtom } from 'jotai';
import { userMessageAtom } from 'atoms';
import { GridRowId } from '@mui/x-data-grid';
import { closeModalAtom } from 'atoms/modalAtom';
import { bulkUserInvite } from 'api';
import useErrorMessage from 'hooks/useErrorMessage';

export interface SendInviteRoleProps {
  selectedRowIds: GridRowId[];
}

const SendInvite: FC<SendInviteRoleProps> = ({ selectedRowIds }) => {
  const [, setUserMessage] = useAtom(userMessageAtom);
  const [, closeModal] = useAtom(closeModalAtom);
  const showError = useErrorMessage();

  const handleSendInvite = async () => {
    try {
      const bulkInvite = await bulkUserInvite(selectedRowIds as string[]);
      closeModal();
      setUserMessage({
        title: `${selectedRowIds.length} User${selectedRowIds.length > 1 ? 's' : ''} Invited.`,
        message: 'They will receive a sign up email shortly.',
        variant: 'success',
        open: true,
        autoHideDuration: 6000,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      });
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <Stack alignItems="center" width='500px'>
      <Stack flexDirection="row" alignItems="center" gap='24px' sx={{ padding: '32px' }}>
        <Box sx={{ borderRadius: '100%', background: '#F6EAFA', padding: '8px', height: '40px', width: '40px', display: 'flex', alignSelf: 'flex-start', boxSizing: 'border-box' }}>
          <EmailOutlinedIcon color='primary' />
        </Box>
        <Stack gap='8px'>
          <TitleWithClose title="Send sign up invite" handleClose={closeModal} />
          <Typography variant='body2' color={variables.colors.text.secondary}>
            You are about to send invitation to {selectedRowIds.length} users. Please confirm this action.
          </Typography>
        </Stack>
      </Stack>
      <ButtonStack>
        <Button variant='outlined' onClick={closeModal}>Cancel</Button>
        <Button variant='contained' onClick={handleSendInvite}>Confirm</Button>
      </ButtonStack>
    </Stack>
  );
};

const ButtonStack = styled(Stack)`
  flex-direction: row;
  width: 100%;
  padding: 16px 32px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px 0px 12px 12px;
  background: #F9FAFB;
  box-sizing: border-box;
`;

export default SendInvite;