import React, { FC } from 'react';
import { MenuItem, Select, Stack, Typography, } from '@mui/material';
import { CalendarEvent } from 'types/fullCalendar';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import { addDays, format, isBefore, parseISO, startOfWeek } from 'date-fns';
import { useAtom } from 'jotai';
import { selectedTemplatedIdAtom, userMessageAtom } from 'atoms';
import variables from 'styles/variables';
import { v4 as uuidv4 } from 'uuid';
import { daysMap, daysOfWeek } from 'utils/helpers/fullCalendarTemplates';
import TimePickerInput from 'components/common/InputFields/TimePickerInput';

export interface IndividualTimeProps {
  event: CalendarEvent;
  setEvents: (value: any) => void;
}

// NOTE: Templates are used to create a week of availability. Because it is a template it uses ambiguous days like "Monday" instead of a specific date like "2022-01-01".
// This allows the template to be used for any week when the user imports it into the service team availability screen.
// This causes issues for time pickers which use a full date and time. To get around this we calculate the date based on the day of the week, relevant to the current week.
const IndividualTime: FC<IndividualTimeProps> = ({ event, setEvents }) => {
  const [selectedEventId, setSelectedEventId] = useAtom(selectedTemplatedIdAtom);
  const [, setUserMessage] = useAtom(userMessageAtom);

  const handleEndTimeBeforeStartTime = () => {
    setUserMessage({
      message: 'End time cannot be before start time.',
      title: "Warning",
      variant: "warning",
      open: true,
      autoHideDuration: 5000,
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    });
  };

  const setDay = (dayValue: string) => {
    // Get the start of the current week (Sunday by default)
    const weekStart = startOfWeek(new Date(), { weekStartsOn: 0 });

    // Calculate the new date based on the selected day
    const newDayIndex = daysMap[dayValue];
    const newDate = addDays(weekStart, newDayIndex);

    // Extract the time parts from the current start and end times
    const currentStartTime = event.start.split('T')[1];
    const currentEndTime = event.end.split('T')[1];

    // Format the new start and end times with the new date
    const newStartTime = `${format(newDate, 'yyyy-MM-dd')}T${currentStartTime}`;
    const newEndTime = `${format(newDate, 'yyyy-MM-dd')}T${currentEndTime}`;

    // Update the event with the new day, start time, and end time
    setEvents((prevEvents: CalendarEvent[]) =>
      prevEvents.map((evt: CalendarEvent) =>
        evt.id === event.id
          ? {
            ...evt,
            day: dayValue,       // Update the day key
            start: newStartTime, // Update the start time with the new date
            end: newEndTime,     // Update the end time with the new date
          }
          : evt
      )
    );
  };

  const handleDuplicate = () => {
    setEvents((prevEvents: any) => [
      ...prevEvents,
      { ...event, id: uuidv4() },
    ]);
  };

  const handleDelete = () => {
    setEvents((prevEvents: any) => prevEvents.filter((evt: any) => evt.id !== event.id));
    setSelectedEventId(null);
  };

  const handleTimeChange = (newValue: string, field: 'start' | 'end') => {
    setEvents((prevEvents: CalendarEvent[]) =>
      prevEvents.map((evt) => {
        if (evt.id !== event.id) {
          return evt;
        }

        // Get the start of the current week (Sunday by default)
        const weekStart = startOfWeek(new Date(), { weekStartsOn: 0 });

        // Calculate the new date based on the event's day
        const dayIndex = daysMap[evt.day];
        const newDate = addDays(weekStart, dayIndex);

        // Extract the time part from the new value
        const newTime = newValue.split('T')[1];

        // Construct the new datetime string with the calculated date and updated time
        const newDateTime = `${format(newDate, 'yyyy-MM-dd')}T${newTime}`;

        // Use the newDateTime for the corresponding start or end field
        const startTime = field === 'start' ? newDateTime : evt.start;
        const endTime = field === 'end' ? newDateTime : evt.end;

        // Prevent the update if the end time is before the start time
        if (isBefore(parseISO(endTime), parseISO(startTime))) {
          handleEndTimeBeforeStartTime();
          return evt; // Return the event unchanged
        }

        return {
          ...evt,
          [field]: newDateTime, // Update start or end time
        };
      })
    );

    setSelectedEventId(event.id!);
  };

  return (
    <Stack
      borderRadius="8px"
      onClick={() => setSelectedEventId(event.id!)}
      sx={{
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: '8px',
          border: selectedEventId === event.id ? `2px solid ${variables.colors.primary.main}` : '1px solid #ccc',
          pointerEvents: 'none',
        },
      }}
    >
      <Stack
        borderRadius="8px"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        padding="8px 16px"
        gap="8px"
        sx={{ backgroundColor: '#F6F7F9' }}
      >
        <Stack flexDirection="row" gap="8px" alignItems="center" width="100%">
          <Typography variant="subtitle2">Day</Typography>
          <Select
            displayEmpty
            value={event.day || ''}
            onChange={(e) => setDay(e.target.value as string)}
            sx={{ width: '100%', height: '36px', backgroundColor: 'white' }}
          >
            <MenuItem value="" disabled>
              <Typography variant="subtitle2" fontWeight="600" sx={{ color: '#9CA3AF' }}>
                Select Day
              </Typography>
            </MenuItem>
            {daysOfWeek.map((dayOption, index) => (
              <MenuItem key={index} value={dayOption.value}>
                {dayOption.label}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <CopyAllOutlinedIcon sx={{ cursor: 'pointer' }} onClick={handleDuplicate} />
        <DeleteOutlinedIcon sx={{ cursor: 'pointer' }} onClick={handleDelete} />
      </Stack>
      <Stack padding="16px" gap="8px" flexDirection="row">
        <TimePickerInput
          ampm={false}
          id='startTime'
          error={false}
          value={event.start}
          clearable={false}
          onChange={(e: any) => {
            handleTimeChange(e, 'start');
          }}
        />
        <TimePickerInput
          ampm={false}
          id='endTime'
          error={false}
          value={event.end}
          clearable={false}
          onChange={(e: any) => {
            handleTimeChange(e, 'end');
          }}
        />
      </Stack>
    </Stack>
  );
};

export default IndividualTime;