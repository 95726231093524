import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import DataTableControls from './Controls';
import DataTable from 'components/common/DataTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { GridRowId } from '@mui/x-data-grid';
import { Columns } from './Controls/Columns';
import { dummyDataSchedulingSupports } from 'utils/helpers/getDBData';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { PaginationModel } from 'components/common/DataTable/types';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import SchedulingSupportsForm from 'components/features/Forms/SchedulingSupportsForm';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';

const SchedulingSupportsDataTable: FC = () => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({ page: 0, pageSize: 20 });
  const [modalState, setModalState] = useAtom(modalStateAtom);

  const handleModalOpen = () => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: SchedulingSupportsForm,
      props: {
        dataTableName: 'scheduling-supports',
        type: 'create',
      },
    }));
  };

  // TODO: Replace dummyDataParticipants with getParticipants when API is ready
  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: ['scheduling-supports', paginationModel, selectedFilters],
    queryFn: () => dummyDataSchedulingSupports,
    placeholderData: keepPreviousData,
    // enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.schedulingSupports,
  });

  return (
    <Stack height='100%' width='100%' sx={{ backgroundColor: 'white' }}>
      <DataTableControls
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        searchText={searchText}
        setSearchText={setSearchText}
        selectedRowIds={selectedRowIds}
      />
      <DataTable
        data={{ items: data || [], totalCount: 2, pageNumber: paginationModel.page, pageSize: paginationModel.pageSize }}
        columns={Columns}
        setSelectedRowIds={setSelectedRowIds}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isPending={isPending || isFetching}
        isError={isError}
        title='Scheduling Supports'
        dataTableName='scheduling-supports'
        checkboxSelection={false}
        paginationMode='server'
        onNoData={handleModalOpen}
      />
    </Stack>
  );
};

export default SchedulingSupportsDataTable;