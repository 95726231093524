import React, { FC } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { TopbarProps } from '../../types';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate } from 'react-router-dom';

const Navbar: FC<TopbarProps> = ({ currentStep, setCurrentStep }) => {
  const navigate = useNavigate();

  return (
    <NavigationStack data-testid='org-registration-nav'>
      <Box sx={{ width: '220px' }}>
        {currentStep > 0 ?
          <Button
            variant="text"
            color='inherit'
            size='medium'
            onClick={() => setCurrentStep(currentStep - 1)}
            startIcon={<ArrowBackOutlinedIcon />}
            aria-label="Back"
          >
            Back
          </Button> :
          <Box />
        }
      </Box>
      <ImageBox>
        <img src="/assets/images/skymo-web-logo-white.png" alt="White Skymo Logo" />
      </ImageBox>
      <Box sx={{ width: '220px' }}/> {/* Placeholder for Contact us button */}
    </NavigationStack>
  );
};

const NavigationStack = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  margin: 8px 0;
`;

const ImageBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  img {
    width: auto;
    height: 100%;
    object-fit: cover;
  }
`;

const NeedHelpTypography = styled(Typography)`
  @media (max-width: 768px) {
    display: none;
  }
`;

export default Navbar;