import React, { FC } from 'react';
import { ViewServiceProviderProps } from '../types';
import ServiceProviderContactDataTable from 'components/features/DataTables/ServiceProviderContactDataTable';

const ContactsTab: FC<ViewServiceProviderProps> = ({ serviceProvider }) => {
  return (
    <ServiceProviderContactDataTable />
  );
};

export default ContactsTab;