import React, { FC } from 'react';
import { useMsal } from '@azure/msal-react';
import { Button, Stack, Typography } from '@mui/material';
import { offload } from 'api';
import useUserOrganisations from 'hooks/useUserOrganisations';

// Placeholder for now - waiting on style guide
const MyUserProfilePage: FC = () => {
  const { accounts } = useMsal();
  const [organisations] = useUserOrganisations();

  const handleClick = async () => {
    const response = confirm('ALERT ALERT do not remove your org (or click this if you do)');
    if (response) {
      const response2 = confirm('Are you really sure? Unless your name is Stevie, Mads, or Michael, you probably shouldn\'t click this.');
      if (response2) {
        await offload();
      }
    }
  };

  return (
    <div>
      <Stack padding='32px' flexDirection='row' gap='16px' justifyContent='center' alignItems='center' width='100%'>
        <Typography variant='h6'>!!WARNING!!</Typography>
        <Button variant='contained' color='primary' onClick={handleClick}>Kill Switch</Button>
        <Typography variant='h6'>!!WARNING!!</Typography>
      </Stack>
    </div>
  );
};

export default MyUserProfilePage;