import React, { FC } from 'react';
import { ColorDisplayBoxProps } from './types';
import { Box } from '@mui/material';

const ColorDisplayBox: FC<ColorDisplayBoxProps> = ({ color }) => {
  if (typeof color === 'object') {
    return (
      <Box
        data-testid='color-display-box'
        sx={{
          width: '50px',
          height: '30px',
          borderRadius: '6px',
          backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
        }}
      />
    );
  }

  return (
    <Box
      data-testid='color-display-box'
      sx={{
        width: '50px',
        height: '30px',
        borderRadius: '6px',
        backgroundColor: `${color}`,
      }}
    />
  );
};

export default ColorDisplayBox;