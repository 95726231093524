import { CheckOutlined } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { closeModalAtom } from 'atoms/modalAtom';
import SelectInput from 'components/common/InputFields/SelectInput';
import { useAtom } from 'jotai';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import variables from 'styles/variables';
import { dummyUserProfileDataNEW } from 'utils/helpers/getDBData';

const TransferOwnership = () => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange',
    defaultValues: {
      userProfile: '',
    },
  });

  const handleTransferOwnership = () => {
    console.log('Transfer Ownership');
    setIsSuccessful(true);
  };

  if (isSuccessful) return (
    <Stack alignItems="center" width='500px'>
      <Stack flexDirection="row" alignItems="center" gap='24px' width='100%' sx={{ padding: '32px' }}>
        <Box sx={{ borderRadius: '100%', background: variables.colors.success.subtle, padding: '8px', height: '40px', width: '40px', display: 'flex', alignSelf: 'flex-start', boxSizing: 'border-box' }}>
          <CheckOutlined color='success' />
        </Box>
        <Typography variant='h6'>
          Ownership transferred successfully.
        </Typography>
      </Stack>
      <ButtonStack>
        <Button variant='outlined' onClick={closeModal}>Cancel</Button>
      </ButtonStack>
    </Stack>
  );

  return (
    <form style={{ width: '500px' }} onSubmit={handleSubmit(handleTransferOwnership)}>
      <Stack height='fit-content' padding='32px' gap='24px'>
        <Typography variant='h6' fontWeight='600'>
          Transfer Ownership
        </Typography>
        <Typography variant='caption' color={variables.colors.text.secondary}>
          Please ensure all payment and business contact information has been updated
          if required before transferring ownership. As you will no longer be able to access
          Organisation Settings.
        </Typography>
        <Controller
          name="userProfile"
          control={control}
          rules={{ required: 'User Profile is required' }}
          render={({ field }) =>
            <SelectInput
              {...field}
              id='userProfile'
              label="User Profile"
              error={errors.userProfile ? true : false}
              errorText={errors.userProfile?.message}
              placeholder='Select'
              isMandatory
              options={dummyUserProfileDataNEW.map((data) => ({
                value: data.id,
                label: data.fullName.firstName + ' ' + data.fullName.lastName,
              }))}
            />}
        />
      </Stack>
      <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' padding='16px 32px' boxSizing='border-box' sx={{ backgroundColor: variables.colors.lightNeutral.subtle, borderRadius: '0 0 12px 12px' }} >
        <Button variant='outlined' color='primary' onClick={closeModal}>
          Cancel
        </Button>
        <Button type='submit' variant='contained' color='primary'>
          Transfer
        </Button>
      </Stack>
    </form>
  );
};

const ButtonStack = styled(Stack)`
  flex-direction: row;
  width: 100%;
  padding: 16px 32px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px 0px 12px 12px;
  background: #F9FAFB;
  box-sizing: border-box;
`;

export default TransferOwnership;