import React, { FC } from 'react';
import { DetailSectionProps } from '../../types';
import Tabs from 'components/common/Tabs';

const ShiftApprovalTabs: FC<DetailSectionProps> = ({ currentService }) => {

  return (
    <Tabs
      tabs={[
        {
          label: 'Allocated Shifts',
          content: <p>Allocated Shifts</p>,
          requiredPermissions: [],
        },
        {
          label: 'Activity History',
          content: <p>Activity History</p>,
          requiredPermissions: [],
        },
      ]}
    />
  );
};

export default ShiftApprovalTabs;