import React, { FC } from 'react';
import FullScreenContainer from 'components/common/FullScreenContainer';
import OrganisationRegistration from 'components/features/OrganisationRegistration';

const OrganisationRegistrationPage: FC = () => {

  return (
    <FullScreenContainer>
      <OrganisationRegistration />
    </FullScreenContainer>
  );
};

export default OrganisationRegistrationPage;