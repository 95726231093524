import { Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Participant } from 'types/dbSchema/participants';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { GridRowId } from '@mui/x-data-grid';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { PaginationModel } from 'components/common/DataTable/types';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useParams } from 'react-router-dom';
import useInvalidateData from 'hooks/useInvalidateData';
import { dismissAlertById, dismissAllAlerts, listNotDismissedAlerts } from 'api/organisations/participants/alerts';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { ParticipantAlert } from 'types/dbSchema/alerts';
import useErrorMessage from 'hooks/useErrorMessage';
import { useAtom } from 'jotai';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';

export interface ParticipantAlertBoxProps {
  participant: Participant;
}

const ParticipantAlertBox: React.FC<ParticipantAlertBoxProps> = ({ participant }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({ page: 0, pageSize: 20 });
  const [organisations] = useUserOrganisations();
  const { id } = useParams();
  useInvalidateData('participant-alerts-not-dismissed');
  const showError = useErrorMessage();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);

  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: ['participant-alerts-not-dismissed', paginationModel, selectedFilters],
    queryFn: () =>
      listNotDismissedAlerts(
        organisations[0].organisation.globalId,
        id as string,
      ) || [],
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    enabled: organisations.length > 0,
    staleTime: cachingInvalidation.participantAlertsBox,
  });

  const handleDismissAllAlerts = async () => {
    try {
      await dismissAllAlerts(organisations[0].organisation.globalId, id as string);
      setInvalidateData((prev) => ({ ...prev, 'participant-alerts-not-dismissed': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <Container
      sx={{
        bottom: isOpen ? '0' : '-476px',
      }}
    >
      <Stack height='100%' width='100%' sx={{ backgroundColor: 'white', overflowY: 'auto' }}>
        <Stack
          flexDirection='row'
          padding='12px 16px 12px 24px'
          height='54px'
          justifyContent='space-between'
          alignItems='center'
          sx={{
            backgroundColor: '#FB8C00'
          }}
        >
          <Stack flexDirection='row' alignItems='center' gap='10px'>
            <WarningOutlinedIcon sx={{ color: 'white' }} />
            <Stack flexDirection='row' alignItems='center' gap='10px'>
              <Typography variant='body1' sx={{ color: 'white' }} fontSize='18px' fontWeight='600'>
                Alerts
              </Typography>
              <Stack sx={{ backgroundColor: '#B71C1C', height: '24px', width: '24px' }} alignItems='center' justifyContent='center' borderRadius='50%'>
                <Typography variant='subtitle1' sx={{ color: 'white' }} fontSize='14px'>
                  {data ? data.length : 0}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <KeyboardArrowDownOutlinedIcon
            sx={{
              color: 'white',
              cursor: 'pointer',
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
            onClick={() => setIsOpen(!isOpen)}
          />
        </Stack>
        <Stack>
          {(data && data.length > 0) && (
            data.map((alert: ParticipantAlert) => (
              <Alert key={alert.id} alert={alert} />
            ))
          )}
        </Stack>
      </Stack>
      <Stack
        padding='12px 16px'
        borderTop='1px solid #E0E0E0'
        sx={{
          backgroundColor: 'white',
        }}>
        <Typography
          variant='body2'
          fontWeight='600'
          sx={{
            color: '#FB8C00',
            cursor: 'pointer',
          }}
          onClick={handleDismissAllAlerts}
        >
          Dismiss all
        </Typography>
      </Stack>
    </Container>
  );
};

const Alert = ({ alert }: { alert: ParticipantAlert }) => {
  const [organisations] = useUserOrganisations();
  const { id } = useParams();
  useInvalidateData('participant-alerts-not-dismissed');
  const showError = useErrorMessage();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);

  const Hours48 = 172800000;
  const isNew = new Date(alert.createdAt).getTime() > new Date().getTime() - Hours48;

  const handleDismissAlert = async (alertId: string) => {
    try {
      await dismissAlertById(organisations[0].organisation.globalId, id as string, alertId);
      setInvalidateData((prev) => ({ ...prev, 'participant-alerts-not-dismissed': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <Stack
      key={alert.id}
      padding='12px 16px'
      borderBottom='1px solid #E0E0E0'
      sx={{
        backgroundColor: isNew ? '#F57C0014' : 'white',
      }}
    >
      <Typography variant='body1' fontWeight={isNew ? '600' : '400'}>
        {alert.content}
      </Typography>
      <Stack flexDirection='row' justifyContent='space-between' marginTop='8px' >
        {isNew && (
          <Stack
            alignContent='center'
            justifyContent='center'
            sx={{
              backgroundColor: '#FB8C00',
              color: 'white',
              padding: '2px 8px',
              borderRadius: '4px',
            }}
          >
            <Typography fontSize='10px' fontWeight='600'>
              NEW
            </Typography>
          </Stack>
        )}
        <Typography
          variant='body2'
          fontWeight='600'
          sx={{
            color: '#FB8C00',
            cursor: 'pointer',
          }}
          onClick={() => handleDismissAlert(alert.id)}
        >
          Dismiss
        </Typography>
      </Stack>
    </Stack >
  );
};


const Container = styled(Stack)`
  position: fixed;
  right: 20px;
  transition: bottom 0.3s;
  width: 320px;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  height: 530px;
  background-color: white;
  box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
  justify-content: space-between;
`;

export default ParticipantAlertBox;