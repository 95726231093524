import React, { FC, useEffect } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import variables from 'styles/variables';
import { Controller, useForm } from 'react-hook-form';
import { UserDetailsData, UserDetailsProps } from '../types';
import styled from 'styled-components';
import CurrentStepIndicator from '../CurrentStepIndicator';
import Greeting from '../Greeting';
import TextInput from 'components/common/InputFields/TextInput';
import AuthenticationButton from 'components/common/AuthenticationButton';

const UserDetails: FC<UserDetailsProps> = ({ currentStep, setCurrentStep, orgFormData, setOrgFormData }) => {
  const { control, handleSubmit, formState: { errors } } = useForm<UserDetailsData>({
    defaultValues: {
      firstName: orgFormData.userDetails.firstName,
      lastName: orgFormData.userDetails.lastName,
      mobile: orgFormData.userDetails.mobile,
    },
    mode: 'onChange'
  });

  const onSubmit = (data: UserDetailsData) => {
    setOrgFormData({
      ...orgFormData,
      userDetails: {
        firstName: data.firstName,
        lastName: data.lastName,
        mobile: data.mobile,
      },
    });
    setCurrentStep(currentStep + 1);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid='user-details-form'>
      <StyledStack>
        <Greeting type='user' />
        <Stack gap='8px'>
          <CurrentStepIndicator currentStep={currentStep} />
          <Typography variant="h6" >
            User Details
          </Typography>
          <Typography variant="body2" color={variables.colors.darkNeutral.lighter}>
            This information is needed to create your User Profile in the system.
          </Typography>
          <Stack paddingTop='16px' gap='24px'>
            <Controller
              control={control}
              name='firstName'
              rules={{
                required: 'First name is required',
                minLength: { value: 2, message: 'First name is too short' },
                maxLength: { value: 30, message: 'First name is too long' }
              }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  id='firstName'
                  label='First Name'
                  error={errors.firstName ? true : false}
                  errorText={errors.firstName?.message}
                  isMandatory
                />
              )}
            />
            <Controller
              control={control}
              name='lastName'
              rules={{
                required: 'Last name is required',
                minLength: { value: 2, message: 'Last name is too short' },
                maxLength: { value: 30, message: 'Last name is too long' }
              }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  id='lastName'
                  label='Last Name'
                  error={errors.lastName ? true : false}
                  errorText={errors.lastName?.message}
                  isMandatory
                />
              )}
            />
            <Controller
              control={control}
              name='mobile'
              rules={{
                required: 'Mobile number is required',
                minLength: { value: 10, message: 'Mobile number is too short, must be 10 digits.' },
                maxLength: { value: 10, message: 'Mobile number is too long, must be 10 digits.' },
                pattern: {
                  value: /^(04)[0-9]*$/,
                  message: 'Mobile number is invalid, only numbers are allowed and must start with 04'
                }
              }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  id='mobile'
                  label='Mobile Number'
                  error={errors.mobile ? true : false}
                  errorText={errors.mobile?.message}
                  isMandatory
                />
              )}
            />
          </Stack>
        </Stack>
        <ButtonStack>
          <AuthenticationButton type='login logout' prompt='login' buttonText='Exit' />
          <Button type='submit' variant='contained' aria-label='Continue'>
            Continue
          </Button>
        </ButtonStack>
      </StyledStack>
    </form>
  );
};


const StyledStack = styled(Stack)`
  width: 100%;
  height: fit-content;
  max-width: 430px;
  gap: 32px;
  margin: 0 auto;
  @media (max-width: 462px) {
    max-width: unset;
    width: calc(100% - 32px);
    padding: 0 16px;
  }
`;

const ButtonStack = styled(Stack)`
  flex-direction: row;
  width: 100%;
  max-width: 430px;
  gap: 24px;
  padding-bottom: 24px;
  justify-content: flex-end;
  @media (max-width: 462px) {
    flex-direction: column;
    max-width: unset;
  }
`;


export default UserDetails;