import axiosInstance from "api/config";
import { ParticipantStatus, StatusesList } from "types/dbSchema/participantSettings";

// Returns a list of Participant Statuses for the given organisation ID
export const listParticipantStatus = async (organisationId: string, page?: number, pageSize?: number): Promise<StatusesList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID, page, and page size are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/participants/statuses`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing Participant Statuses for the organisation', error);
    throw error;
  }
};

// Returns a participant status by ID
export const getParticipantStatusByID = async (organisationId: string, participantStatusID: string): Promise<ParticipantStatus> => {
  try {
    if (!participantStatusID || !organisationId) throw new Error('Organisation ID and ParticipantStatus ID are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/participants/statuses/${participantStatusID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the participant status', error);
    throw error;
  }
};

// Create a new participant status
export const createParticipantStatus = async (organisationId: string, data: ParticipantStatus) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/${organisationId}/settings/participants/statuses`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the participant status', error);
    throw error;
  }
};

// Update a participant status
export const updateParticipantStatus = async (organisationId: string, data: ParticipantStatus) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.put(`/api/${organisationId}/settings/participants/statuses/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the participant status', error);
    throw error;
  }
};

// Delete a participant status
export const deleteParticipantStatus = async (organisationId: string, StatusesID: string) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.delete(`/api/${organisationId}/settings/participants/statuses/${StatusesID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the participant status', error);
    throw error;
  }
};