import React, { FC } from 'react';
import { AppBar, Box, Button } from '@mui/material';
import styled from 'styled-components';
import variables from 'styles/variables';
import Navbar from './Navbar';
import { TopbarProps } from '../types';
import LinearProgress from './LinearProgress';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

// Topbar component for the Organisation Registration page
const OrgRegistrationTopbar: FC<TopbarProps> = ({ currentStep, setCurrentStep }) => {
  return (
    <>
      <StyledAppBar data-testid='org-registration-topbar'>
        <Navbar currentStep={currentStep} setCurrentStep={setCurrentStep} />
        <LinearProgress currentStep={currentStep} />
      </StyledAppBar>
      {currentStep > 0 && (<MobileBack>
        <Button
          variant="text"
          color='secondary'
          size='medium'
          onClick={() => setCurrentStep(currentStep - 1)}
          startIcon={<ArrowBackOutlinedIcon />}
          aria-label="Back"
        >
          Back
        </Button>
      </MobileBack>
      )}
    </>

  );
};

const StyledAppBar = styled(AppBar)`
  height: ${variables.heights.orgRegistrationTopbar};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  position: relative;
  @media (max-width: 620px) {
    display: none;
  }
`;

const MobileBack = styled(Box)`
  display: none;
  @media (max-width: 620px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 60px;
    padding: 6px 0;
    max-width: 430px;
    width: calc(100% - 32px);
    margin: 0 auto;
    & * {
      padding-left: 0;
    }
  }
`;

export default OrgRegistrationTopbar;