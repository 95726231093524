import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { AddOutlined, CloseOutlined } from '@mui/icons-material';
import variables from 'styles/variables';
import { useNavigate, useParams } from 'react-router-dom';
import { Control, Controller, FieldErrors, useForm } from 'react-hook-form';
import RelationshipTypeLookupField from 'components/common/LookupInputField/RelationshipTypeLookupField';
import CheckboxInput from 'components/common/InputFields/CheckboxInput';
import { linkParticipantToExternalContact } from 'api/organisations/participants/externalcontacts';
import useCurrentUser from 'hooks/useCurrentUser';
import useUserOrganisations from 'hooks/useUserOrganisations';
import ParticipantLookupField from 'components/common/LookupInputField/ParticipantLookupField';
import useErrorMessage from 'hooks/useErrorMessage';
import { useAtom } from 'jotai';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import { Participant } from 'types/dbSchema/participants';
import { getParticipantById } from 'api/organisations/participants';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';

export interface NewLinkParticipantToContact {
  relationshipType: string;
  isPrimaryContact: boolean;
  isEmergencyContact: boolean;
  selectedParticipantId: string;
  createdBy: string;
}

const LinkParticipantToContactForm: FC = () => {
  const { id: contactId } = useParams();
  const [organisations] = useUserOrganisations();
  const [currentUser] = useCurrentUser();
  const navigate = useNavigate();
  const showError = useErrorMessage();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const { control, handleSubmit, formState: { errors, isDirty }, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      relationshipType: '',
      isPrimaryContact: false,
      isEmergencyContact: false,
      selectedParticipantId: '',
      createdBy: currentUser?.id || ''
    }
  });

  const handleCancel = () => navigate(-1);

  const onSubmit = async (data: NewLinkParticipantToContact) => {
    try {
      if (!contactId || contactId === 'undefined') return null;
      const { selectedParticipantId, ...rest } = data;
      await linkParticipantToExternalContact(
        organisations[0].organisation.globalId,
        contactId,
        data.selectedParticipantId,
        data
      );
      setInvalidateData((prev) => ({ ...prev, 'external-contacts': true, 'participant-contacts': true }));
      navigate(-1);
    } catch (error) {
      showError((error as Error).message);
    }
  };

  if (!contactId || contactId === 'undefined') return null;

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Banner>
        <MaxWidthContainer>
          <Stack flexDirection='row' gap={1} alignItems='center' minWidth='fit-content'>
            <AddOutlined sx={{ borderRadius: '100%', border: ' 2px dashed #81D4FA', color: '#81D4FA', padding: '4px', height: '28px', width: '28px', boxSizing: 'border-box' }} />
            <Typography variant='h5'>
              Link Participant
            </Typography>
          </Stack>
          <CloseOutlined sx={{ cursor: 'pointer' }} onClick={handleCancel} />
        </MaxWidthContainer>
      </Banner>
      <Stack padding='32px' width='100%' height='fit-content' justifyContent='center' alignItems='center' boxSizing='border-box' gap='32px' overflow='auto'>
        <LinkParticipantDetails control={control} errors={errors} selectedParticipantId={watch().selectedParticipantId} />
        <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' boxSizing='border-box'>
          <Button variant='text' onClick={handleCancel}>Cancel</Button>
          <Button variant='contained' type='submit'>Link Participant</Button>
        </Stack>
      </Stack>
    </StyledForm>
  );
};

export interface LinkParticipantDetailsProps {
  control: Control<NewLinkParticipantToContact>;
  errors: FieldErrors<NewLinkParticipantToContact>;
  selectedParticipantId: string | null;
}

export const LinkParticipantDetails: FC<LinkParticipantDetailsProps> = ({ control, errors, selectedParticipantId }) => {
  const [selectedParticipant, setSelectedParticipant] = useState<Participant | null>(null);
  const [organisations] = useUserOrganisations();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!selectedParticipantId) return;
    const fetchParticipant = async () => {
      setIsLoading(true);
      await getParticipantById(organisations[0].organisation.globalId, selectedParticipantId)
        .then((response) => setSelectedParticipant(response))
        .finally(() => setIsLoading(false));
    };
    fetchParticipant();
  }, [selectedParticipantId]);

  return (
    <DetailsStack>
      <Stack padding='24px' sx={{ backgroundColor: '#F9FAFB' }}>
        <Stack gap='8px'>
          <Typography variant='h6' fontWeight='600'>
            Search a Participant
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Search by adding name and/or email.
          </Typography>
          <Controller
            name="selectedParticipantId"
            control={control}
            render={({ field }) =>
              <ParticipantLookupField
                {...field}
                id='selectedParticipantId'
                label=''
                validationError={errors.selectedParticipantId ? true : false}
                errorText={errors.selectedParticipantId?.message}
                placeholder='Select'
                value={field.value}
                isMandatory

              />
            }
          />
        </Stack>
      </Stack>
      <Stack gap='32px'>
        <Typography variant='h6' fontWeight='600'>
          Participant Details
        </Typography>
        <Stack flexDirection='row' gap='32px' width='100%'>
          {selectedParticipant ? (
            <Stack justifyContent='center' border={`1px dashed ${variables.colors.primary.lighter}`} borderRadius='8px' padding='16px' width='100%' sx={{ boxSizing: 'border-box' }}>
              <Stack height='fit-content' flexDirection='row' gap='16px'>
                <Box
                  sx={{
                    borderRadius: '100%', background: 'rgba(75, 85, 99, 0.12)', padding: '8px', height: '40px', width: '40px', display: 'flex', alignSelf: 'flex-start', boxSizing: 'border-box'
                  }}
                >
                  <AccountBoxOutlinedIcon sx={{ color: variables.colors.icon.standard }} />
                </Box>
                <Stack>
                  <Typography fontSize='18px' fontWeight='600'>
                    {selectedParticipant.fullName.firstName} {selectedParticipant.fullName.lastName}
                  </Typography>
                  <Stack flexDirection='row' gap='8px'>
                    <Typography variant='body2' color={variables.colors.text.secondary}>
                      Email
                    </Typography>
                    <Typography variant='body2'>
                      {selectedParticipant.email}
                    </Typography>
                  </Stack>
                  <Stack flexDirection='row' gap='8px'>
                    <Typography variant='body2' color={variables.colors.text.secondary}>
                      Mobile
                    </Typography>
                    <Typography variant='body2'>
                      {selectedParticipant.mobile}
                    </Typography>
                  </Stack>
                  <Stack flexDirection='row' gap='8px'>
                    <Typography variant='body2' color={variables.colors.text.secondary}>
                      Phone
                    </Typography>
                    <Typography variant='body2'>
                      {selectedParticipant.phone}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <Stack justifyContent='flex-start' border={`1px dashed ${variables.colors.primary.lighter}`} height='200px' borderRadius='8px' padding='16px' gap='30px' width='100%'>
              <Typography variant='body2' color={variables.colors.text.secondary}>
                Your selected participant will be displayed here.
              </Typography>
              <Stack height='fit-content' flexDirection='row' gap='16px'>
                <Skeleton animation={isLoading ? 'wave' : false} variant="circular" width={48} height={48} />
                <Stack>
                  <Skeleton animation={isLoading ? 'wave' : false} width={126} height={20} />
                  <Skeleton animation={isLoading ? 'wave' : false} width={214} height={16} />
                  <Skeleton animation={isLoading ? 'wave' : false} width={202} height={16} />
                  <Skeleton animation={isLoading ? 'wave' : false} width={137} height={16} />
                </Stack>
              </Stack>
            </Stack>
          )}
          <Stack width='100%' gap='24px'>
            <Controller
              name="relationshipType"
              control={control}
              rules={{
                required: 'Relationship is required',
              }}
              render={({ field }) =>
                <RelationshipTypeLookupField
                  {...field}
                  id='relationshipType'
                  label='Relationship'
                  placeholder='Select'
                  validationError={errors.relationshipType ? true : false}
                  errorText={errors.relationshipType?.message}
                  isMandatory
                />
              }
            />
            <Stack>
              <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
                Contact Tags
              </Typography>
              <Controller
                name="isPrimaryContact"
                control={control}
                render={({ field }) =>
                  <CheckboxInput
                    {...field}
                    id='isPrimaryContact'
                    label="Primary Contact"
                    error={errors.isPrimaryContact ? true : false}
                    checked={field.value ? true : false}
                  />}
              />
              <Controller
                name="isEmergencyContact"
                control={control}
                render={({ field }) =>
                  <CheckboxInput
                    {...field}
                    id='isEmergencyContact'
                    label="Emergency Contact"
                    error={errors.isEmergencyContact ? true : false}
                    checked={field.value ? true : false}
                  />}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </DetailsStack >
  );
};

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  background-color: ${variables.colors.primary.darker};
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  color: white;
  position: fixed;
  padding: 16px 32px;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 70px;
`;

const MaxWidthContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  max-width: 960px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  height: fit-content;
  background-color: #F3F4F6;
  margin-top: 70px;
  min-height: calc(100vh - 70px);
`;

const DetailsStack = styled(Stack)`
  background-color: white;
  padding: 32px;
  gap: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
`;

export default LinkParticipantToContactForm;