import React, { FC } from 'react';
import Tabs from 'components/common/Tabs';
import CustomBreadcrumbs from 'components/common/Breadcrumbs';
import { Box, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { DefaultUserRolesProps } from './types';

const DefaultUserRoles: FC<DefaultUserRolesProps> = ({ tabs }) => {
  return (
    <StyledBox>
      <MaxWidthStack>
        <Box padding='32px 32px 0 32px'>
          <CustomBreadcrumbs />
          <Typography variant='h4'>User Roles</Typography>
        </Box>
        <Tabs tabs={tabs} />
      </MaxWidthStack>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  min-height: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  overflow-y: auto;
`;

const MaxWidthStack = styled(Stack)`
  width: 100%;
  min-height: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 8px
`;

export default DefaultUserRoles;