import React, { FC, useEffect, useState } from 'react';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { StripeCheckoutProps } from '../types';
import { useAtom } from 'jotai';
import { organisationsAtom } from 'atoms';
import { createStripeSession } from 'api';
import AuthenticationButton from 'components/common/AuthenticationButton';
import { Box, Stack } from '@mui/material';
import styled from 'styled-components';
import useUserOrganisations from 'hooks/useUserOrganisations';

const StripeCheckout: FC<StripeCheckoutProps> = ({ currentStep, setCurrentStep, planId }) => {
  const [clientSecret, setClientSecret] = useState('');
  const [organisations] = useUserOrganisations();
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [hasBeenInitialized, setHasBeenInitialized] = useState(false);

  useEffect(() => {
    // Load the Stripe instance
    const initializeStripe = async () => {
      const stripeInstance = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');
      setStripe(stripeInstance);
    };

    // If stripe is not initialized, initialize it
    if (!stripe) initializeStripe();

    // Call the API to create a Stripe session
    const initializeSession = async () => {
      if (organisations.length > 0 && stripe) {
        try {
          setHasBeenInitialized(true);
          const stripeResponse = await createStripeSession(planId, organisations[0].organisation.globalId);
          setClientSecret(stripeResponse.data.clientSecret);
        } catch (error) {
          console.error('There was an error during onboarding', error);
          setCurrentStep(currentStep - 1);
        }
      }
    };

    // If the client secret is not set, initialize the session
    if (stripe && clientSecret === '' && !hasBeenInitialized) initializeSession();
  }, [clientSecret, organisations, stripe]);

  // Render the Stripe checkout component if the client secret is set
  if (clientSecret) return (
    <div id='checkout'>
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripe}
          options={{ clientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
      <Stack width='100%' justifyContent='center' alignItems='center'>
        <StyledStack>
          <AuthenticationButton type='login logout' prompt='login' buttonText='Exit' />
        </StyledStack>
      </Stack>
    </div>
  );

  // Otherwise, return an empty fragment
  return <></>;
};

const StyledStack = styled(Stack)`
  max-width: calc(888px + 64px);
  width: 100%;
  height: fit-content;
  justify-content: center;
  align-items: flex-end;
  padding: 32px;
  @media (max-width: 991px) {
    align-items: center;
    max-width: 412px;
    padding: 16px 0;
    & button {
      width: 100%;
    }
  }
  @media (max-width: 448px) {
    padding: 16px;
  }
`;

export default StripeCheckout;