import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import variables from 'styles/variables';
import { AccordionTitleProps } from '../types';

const AccordionTitle: FC<AccordionTitleProps> = ({ text, description, statusArray }) => {
  return (
    <Stack width='100%' padding='0'>
      <Stack width='100%' flexDirection='row' justifyContent='space-between'>
        <Typography variant='h6'>{text}</Typography>
        <Stack flexDirection='row' gap='8px'>
          {statusArray.map((status: { text: string }, index: number) => (
            <AdditionalText key={index}>
              <Typography fontSize='12px' lineHeight='18px' variant='subtitle2' textTransform='uppercase'>{status.text}</Typography>
            </AdditionalText>
          ))}
        </Stack>
      </Stack>
      <Typography color={variables.colors.text.secondary} variant='body2' marginTop='16px'>{description}</Typography>
    </Stack>
  );
};

const AdditionalText = styled(Box)`
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: rgba(33, 33, 33, 0.04);
`;

export default AccordionTitle;