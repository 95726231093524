import { atom } from 'jotai';
import { ModalAtomState } from 'atoms/types';

export const modalStateAtom = atom<ModalAtomState>({
  status: 'closed',
  position: 'right',
  component: '',
  disableClickAway: false,
  props: {},
});

// Atom to close the modal
export const closeModalAtom = atom(
  null, // no read function
  (get, set) => {
    // Use 'get' to access the current state if needed
    const currentModalState = get(modalStateAtom);
    // Reset the modal state
    set(modalStateAtom, {
      ...currentModalState,
      status: 'closed',
      position: 'right',
      component: '',
      disableClickAway: false,
      props: {}
    });
  }
);