import React, { FC } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import ParticipantAlertForm from 'components/features/Forms/ParticipantAlertForm';

const Actions: FC = () => {
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const { id } = useParams();

  const handleModalOpen = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: ParticipantAlertForm,
      props: {
        dataTableName: 'general-notes',
        type: 'create',
        participantId: id,
      }
    });
  };

  return (
    <Button color='primary' variant='contained' onClick={() => handleModalOpen()} startIcon={<AddOutlinedIcon />} sx={{ minWidth: 'fit-content', height: '100%' }}>
      Add New
    </Button>
  );
};

export default Actions;