import IsActiveDropdown from 'components/common/IsActiveDropdown';
import React, { useState } from 'react';
import { ServiceRegion } from 'types/dbSchema/serviceRegions';

// TODO: When API is ready, replace the console.log with the setStatus function
const EditStatus = ({ serviceRegion }: { serviceRegion: ServiceRegion }) => {
  const [currentStatus, setCurrentStatus] = useState(serviceRegion.isActive);

  return (
    <IsActiveDropdown
      isActive={currentStatus}
      setIsActive={setCurrentStatus}
    />
  );
};

export default EditStatus;