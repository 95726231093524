import React, { FC, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import variables from 'styles/variables';
import CustomBreadcrumbs from 'components/common/Breadcrumbs';
import styled from 'styled-components';
import SearchInput from 'components/common/InputFields/SearchInput';
import Actions from './Actions';
import { systemParticipantStatuses } from 'types/dbSchema/participants';
import FilterWithDropdown from 'components/common/FilterWithDropdown';
import { IFilter } from 'components/common/FilterWithDropdown/types';
import { ControlsProps } from '../../types';
import ParticipantFilter from 'components/common/FilterLookup/ParticipantFilter';

const Controls: FC<ControlsProps> = ({
  selectedFilters,
  setSelectedFilters,
  searchText,
  setSearchText,
  selectedRowIds,
}) => {
  const [filterOpen, setFilterOpen] = useState('');

  return (
    <StyledStack>
      <CustomBreadcrumbs />
      <Stack justifyContent='space-between' height='100%' gap='16px'>
        <ActionStack>
          <Typography variant='h4' minWidth='fit-content'>
            Contacts Console
          </Typography>
          <Stack flexDirection='row' gap='16px' >
            <SearchInput searchValue={searchText} setSearchValue={setSearchText} placeholderText='Search' />
            <Actions />
          </Stack>
        </ActionStack>
        <Filters>
          <ParticipantFilter
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            filterOpen={filterOpen}
            setFilterOpen={setFilterOpen}
          />
          {selectedFilters.length > 0 &&
            <ResetFiltersBox onClick={() => setSelectedFilters([])}>
              <Typography variant='body2' fontWeight='600' color='secondary' >
                Reset filters
              </Typography>
            </ResetFiltersBox>
          }
        </Filters>
      </Stack>
    </StyledStack>
  );
};

const Filters = styled(Stack)`
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;

const StyledStack = styled(Stack)`
  height: fit-content;
  padding: 16px 24px 32px 24px;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
`;

const ActionStack = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

const ResetFiltersBox = styled(Stack)`
  cursor: pointer;
  padding: 8px;
  border-left: 1px solid ${variables.colors.secondary.outlineBorder};
  min-width: fit-content;
`;

export default Controls;