import React, { FC } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Button } from '@mui/material';
import { AuthenticationButtonProps } from './types';

// A button that logs the user in or out, depending on their current state
const AuthenticationButton: FC<AuthenticationButtonProps> = ({ variant = 'contained', fullwidth, isValid, type, prompt, buttonText, startIcon }) => {
  const { instance: msalInstance } = useMsal();
  const { instance } = useMsal();
  const msalRequest = {
    scopes: ['user.read', 'openid', 'profile', 'email'],
    prompt: prompt,
  };

  function handleLogin() {
    if (isValid) {
      msalInstance.loginRedirect(msalRequest);
    }
  }

  function handleLogout() {
    instance.logout();
    localStorage.removeItem('user');
  }

  return (
    <>
      <AuthenticatedTemplate>
        <Button
          variant='contained'
          color='secondary'
          onClick={handleLogout}
          aria-label='Logout'
          data-testid='authenticationLogoutButton'
          fullWidth={fullwidth}
          startIcon={startIcon}
        >
          {buttonText ? buttonText : 'Logout'}
        </Button>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {
          type === 'login logout' ?
            <Button
              type='submit'
              fullWidth={fullwidth}
              variant={variant}
              color='primary'
              onClick={handleLogin}
              aria-label='Login'
              startIcon={startIcon}
              data-testid='authenticationLoginButton'
            >
              {buttonText ? buttonText : 'Login'}
            </Button> :
            type === 'sign up' &&
            <Button
              type='submit'
              fullWidth={fullwidth}
              variant={variant}
              color='primary'
              onClick={handleLogin}
              aria-label='Sign Up'
              data-testid='authenticationSignupButton'
            >
              {buttonText ? buttonText : 'Sign Up'}
            </Button>
        }
      </UnauthenticatedTemplate>
    </>

  );
};

export default AuthenticationButton;