import React, { FC } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import { NavigationLinkProps } from './type';

// A link to a page in the app using react-router-dom
const NavigationLink: FC<NavigationLinkProps> = ({ to, label, sx, target }) => {
  return (
    <MuiLink component={ReactRouterLink} to={to} sx={sx} target={target} data-testid={`${label}-navigationLink`}>{label}</MuiLink>
  );
};

export default NavigationLink;