import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { sidebarAtom } from 'atoms';

const useCalendarResize = (calendarRef: any) => {
  const [sidebarState] = useAtom(sidebarAtom);

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();

      // Schedule the render call in a microtask to avoid React lifecycle conflicts
      setTimeout(() => {
        calendarApi.updateSize();  // Forces the calendar to resize
      }, 301);
    }
  }, [sidebarState.isOpen, calendarRef]); // Watch for changes in sidebar state
};

export default useCalendarResize;