import React, { FC } from 'react';
import styled from 'styled-components';
import { Stack, Typography } from '@mui/material';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import variables from 'styles/variables';
import { DownloadAttachmentsIconProps } from './types';
import { downloadAttachment, downloadMultipleAttachments } from 'utils/helpers';
import { DBAttachment } from 'types';

const DownloadAttachmentsIcon: FC<DownloadAttachmentsIconProps> = ({ attachments }) => {

  if (!attachments || attachments?.length === 0) return (
    <StyledStack>
      <AttachFileOutlinedIcon sx={{ color: variables.colors.text.secondary, opacity: 0.4 }} />
    </StyledStack>
  );
  const downloadAllFiles = () => {
    downloadMultipleAttachments(attachments);
  };

  return (
    <StyledStack onClick={downloadAllFiles}>
      <AttachFileOutlinedIcon sx={{ color: variables.colors.text.secondary }} />
      <Typography variant='body1' color={variables.colors.text.secondary}>
        ({attachments.length})
      </Typography>
    </StyledStack>
  );
};

const StyledStack = styled(Stack)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
  flex-direction: row;
  gap: 6px;
`;

export default DownloadAttachmentsIcon;