import React, { forwardRef, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import variables from 'styles/variables';
import { ColorPickerInputProps } from './types';
import { RgbaColorPicker, RgbaColor } from 'react-colorful';
import { hexToRgba, rgbaToHex } from 'utils/helpers';

const ColorPickerInput = forwardRef<HTMLDivElement, ColorPickerInputProps>((
  {
    label,
    id,
    error,
    errorText,
    helperText,
    value,
    onChange,
  }, ref) => {

  const [rgbaValue, setRgbaValue] = useState<RgbaColor>(hexToRgba(value || '#000000'));

  useEffect(() => {
    if (value) {
      setRgbaValue(hexToRgba(value));
    }
  }, [value]);

  const handleChange = (newColor: RgbaColor) => {
    const hexColor = rgbaToHex(newColor);
    onChange(hexColor);
    setRgbaValue(newColor);
  };

  return (
    <div style={{ margin: '0', position: 'relative', width: '100%' }} ref={ref}>
      {label && (
        <label htmlFor={id}>
          <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
            {label}
          </Typography>
        </label>
      )}
      <RgbaColorPicker
        id={id}
        color={rgbaValue}
        onChange={handleChange}
      />
      {(error && errorText) ? <Typography variant='subtitle2' color='red' fontWeight='400'>{errorText}</Typography>
        : helperText && <Typography variant='subtitle2' color={variables.colors.text.secondary} fontWeight='400'>{helperText}</Typography>
      }
    </div>
  );
});

ColorPickerInput.displayName = 'ColorPickerInput';

export default ColorPickerInput;