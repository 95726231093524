import React, { forwardRef } from 'react';
import { Typography } from '@mui/material';
import variables from 'styles/variables';
import styled from 'styled-components';
import { TextareaInputProps, StyledTextareaProps } from './types';

const TextareaInput = forwardRef<HTMLTextAreaElement, TextareaInputProps>(({
  label,
  id,
  error,
  errorText,
  helperText,
  placeholder,
  value,
  onChange,
  isMandatory,
  ...props }, ref) => {

  return (
    <div style={{ margin: '0', position: 'relative', width: '100%' }}>
      {label && (
        <label htmlFor={id}>
          <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
            {label} {isMandatory && <span style={{ color: 'red' }}>*</span>}
          </Typography>
        </label>
      )}
      <StyledTextarea
        id={id}
        error={error}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        value={value ?? ''} // Fallback to an empty string if value is undefined
        ref={ref}
        draggable={false}
        {...props}
      />
      {(error && errorText) ? <Typography variant='subtitle2' color='red' fontWeight='400'>{errorText}</Typography>
        : helperText && <Typography variant='subtitle2' color={variables.colors.text.secondary} fontWeight='400'>{helperText}</Typography>
      }
    </div >
  );
});

const StyledTextarea = styled.textarea.withConfig({
  shouldForwardProp: (prop) => prop !== 'error',
}) <StyledTextareaProps>`
  border-radius: 4px;
  border: ${({ error }) => error ? '1px solid red' : '1px solid #ccc'};
  padding: 10px;
  width: 100%;
  min-height: 68px;
  box-sizing: border-box;
  outline: none !important;
  font-family: ${variables.fonts.family.primary};
  resize: none;
  font-size: 14px;
  &:focus-visible {
    padding: 9px;
    border: 2px solid #5C068C !important;
  }
`;

TextareaInput.displayName = 'TextareaInput';

export default TextareaInput;