import React, { forwardRef } from 'react';
import { Typography } from '@mui/material';
import variables from 'styles/variables';
import { TimePicker } from '@mui/x-date-pickers';
import { TimePickerInputProps } from './types';
import styled from 'styled-components';
import { format } from 'date-fns';

const TimePickerInput = forwardRef<HTMLDivElement, TimePickerInputProps>(({
  id,
  label,
  error,
  errorText,
  helperText,
  onChange,
  value,
  isMandatory,
  disabled,
  ampm,
  clearable,
  ...props
}, ref) => {
  return (
    <div style={{ margin: '0', position: 'relative', width: '100%' }}>
      {label && (
        <label htmlFor={id}>
          <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
            {label} {isMandatory && <span style={{ color: 'red' }}>*</span>}
          </Typography>
        </label>
      )}
      <StyledTimePicker
        ampm={ampm}
        onChange={(newValue) => {
          if (newValue) {
            const localTime = format(newValue, "HH:mm:ss"); // Format to 'HH:mm:ss'
            onChange(`${format(newValue, 'yyyy-MM-dd')}T${localTime}`); // Construct the full datetime string
          } else {
            onChange(''); // Handle case where newValue is null or undefined
          }
        }}
        value={value ? new Date(value) : null}
        ref={ref}
        disabled={disabled}
        slotProps={{
          field: { clearable: clearable === false ? false : true },
          popper: {
            disablePortal: true,
          },
        }}
        {...props}
      />
      {(error && errorText) ? <Typography variant='subtitle2' color='red' fontWeight='400'>{errorText}</Typography>
        : helperText && <Typography variant='subtitle2' color={variables.colors.text.secondary} fontWeight='400'>{helperText}</Typography>
      }
    </div>
  );
});


const StyledTimePicker = styled(TimePicker)`
  .MuiInputBase-root {
    height: 42px;
  }
  width: 100%;
  font-family: ${variables.fonts.family.primary};
  * {
    font-size: 14px;
  }
`;

TimePickerInput.displayName = 'TimePickerInput';

export default TimePickerInput;