import React, { FC } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Action } from 'components/common/ActionsDropdown/types';
import ActionsDropdown from 'components/common/ActionsDropdown';
import { ActionsProps } from 'types';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { handleExport } from 'utils/helpers';
import ImportModal from 'components/features/ImportModal';
import ButtonWithDropdown from 'components/common/ButtonWithDropdown';
import { closeModalAtom } from 'atoms/modalAtom';
import SchedulingSupportsForm from 'components/features/Forms/SchedulingSupportsForm';

const Actions: FC<ActionsProps> = ({ selectedRowIds, dataTableName }) => {
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const [, closeModal] = useAtom(closeModalAtom);

  const handleImportModalOpen = () => {
    if (modalState.status === 'closed') {
      setModalState({
        ...modalState,
        status: 'open',
        position: 'right',
        component: ImportModal,
        props: {
          dataTableName: dataTableName,
        },
      });
    } else {
      closeModal();
    }
  };

  const handleNewSchedulingSupport = () => {
    if (modalState.status === 'closed') {
      setModalState(prevState => ({
        ...prevState,
        status: 'open',
        position: 'center',
        component: SchedulingSupportsForm,
        props: {
          dataTableName: dataTableName,
          type: 'create',
        },
      }));
    } else {
      closeModal();
    }
  };

  const actions: Action[] = [
    {
      icon: AddOutlinedIcon,
      label: 'Add New Scheduling Supports',
      function: () => {
        handleNewSchedulingSupport();
      },
    },
    {
      icon: PostAddOutlinedIcon,
      label: 'Import from CSV',
      function: () => {
        handleImportModalOpen();
      },
    },
    {
      icon: FileUploadOutlinedIcon,
      label: `Export ${selectedRowIds.length > 0 ? 'Selected' : 'All'}`,
      function: () => {
        handleExport(selectedRowIds, dataTableName);
      },
    }
  ];

  return (
    <ButtonWithDropdown
      color='primary'
      label='Actions'
      isDisabled={false}
      dropdownContents={
        <ActionsDropdown selectedRowIds={selectedRowIds} dataTableName={dataTableName} actions={actions} />
      }
    />
  );
};

export default Actions;