import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import ViewIcon from './ViewIcon';
import { Typography } from '@mui/material';
import ParticipantGoalStatusDropdown from './ParticipantGoalStatusDropdown';
import { camelCaseToTitleCase, formatISODate } from 'utils/helpers';
import Score from 'components/common/Score';

export const Columns: GridColDef[] = [
  {
    field: 'view',
    headerName: '',
    width: 50,
    sortable: false,
    renderCell: (params) => (
      <ViewIcon goal={params.row} />
    ),
  },
  {
    field: 'goal',
    headerName: 'Goal',
    flex: 1,
    minWidth: 300,
    renderCell: (params) => (
      <Typography variant='body2' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {params.value}
      </Typography>
    )
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: false,
    width: 150,
    renderCell: (params) => (
      <ParticipantGoalStatusDropdown goal={params.row} />
    ),
  },
  {
    field: 'reviewDate',
    headerName: 'Review Date',
    sortable: false,
    width: 150,
    renderCell: (params) => (
      <Typography>
        {formatISODate(params.value)}
      </Typography>
    ),
  },
  {
    field: 'goalType',
    headerName: 'Goal Type',
    width: 250,
    renderCell: (params) => (
      <Typography>
        {camelCaseToTitleCase(params.value)}
      </Typography>
    ),
  },
  {
    field: 'score',
    headerName: 'Score',
    width: 150,
    renderCell: (params) => (
      <Score score={params.value} />
    ),
  }
];