import { Box, Button, Stack } from '@mui/material';
import useHomePageColor, { homePageColors } from 'hooks/useHomePageColor';
import React, { useState, useEffect, useRef } from 'react';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import styled from 'styled-components';

const CustomiseDropdown: React.FC = () => {
  const [homePageColor, setHomePageColor] = useHomePageColor();
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // This is used to close the dropdown when clicking outside of it and outside the button
  // The reason we need to check if the button is clicked is because the button handles it's own click event
  const handleClickOutside = (event: MouseEvent) => {
    if (
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Stack position='relative'>
      <StyledButton
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        variant='outlined'
        startIcon={<PaletteOutlinedIcon />}
        endIcon={<KeyboardArrowDownOutlinedIcon sx={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }} />}
      >
        Customise
      </StyledButton>
      {isOpen && (
        <Dropdown ref={dropdownRef}>
          {homePageColors.map((color, index) => (
            <ColorBox
              onClick={() => setHomePageColor(color)}
              key={index}
              sx={{
                backgroundColor: color.bgColor,
                boxShadow: homePageColor.bgColor === color.bgColor ? '0px 0px 0px 2px #AC4FC6' : '',
                border: color.bgColor === '#FFF' ? '1px solid var(--outlineBorder-2, #D1D5DB)' : 'none',
              }}
            />
          ))}
        </Dropdown>
      )}
    </Stack >
  );
};

const StyledButton = styled(Button)`
  color: black;
  background-color: white;
  width: fit-content;
  border-radius: 4px;
  border: 1px solid #E5E7EB;
  &:hover {
    background-color: #F9FAFB;
    border: 1px solid #E5E7EB;
  }
`;

const Dropdown = styled(Box)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  width: fit-content;
  position: absolute;
  top: 40px;
  right: 0;
  border-radius: 8px;
  border: 1px solid  #E5E7EB;
  box-shadow: 0px 0px 1px 0px rgba(55, 65, 81, 0.20), 0px 4px 12px 0px rgba(55, 65, 81, 0.15);
  z-index: 1;
`;

const ColorBox = styled(Box)`
  cursor: pointer;
  border-radius: 4px;
  width: 48px;
  height: 48px;
`;

export default CustomiseDropdown;