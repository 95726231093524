import React, { useEffect, useState } from 'react';
import Topbar from './Topbar';
import FullScreenContainer from 'components/common/FullScreenContainer';
import { Box } from '@mui/material';
import OrganisationDetails from './OrganisationDetails';
import SubscriptionDetails from './SubscriptionDetails';
import styled from 'styled-components';
import { OrganisationRegistrationFormData } from './types';
import StripeCheckout from './StripeCheckout';
import UserDetails from './UserDetails';
import useUserOrganisations from 'hooks/useUserOrganisations';

const OrganisationRegistration = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);

  const [orgFormData, setOrgFormData] = useState<OrganisationRegistrationFormData>({
    userDetails: {
      firstName: '',
      lastName: '',
      mobile: '',
    },
    organisationDetails: {
      organisationName: '',
      NDISOrganisationNumber: '',
      ABN: '',
      orgId: ''
    },
    plan: 'price_1OyTTJKBLLkUkmkdspd138IT'
  });

  return (
    <FullScreenContainer>
      <Topbar currentStep={currentStep} setCurrentStep={setCurrentStep} />
      <StepOuterContainer>
        <StepContainer>
          {currentStep === 0 ? (
            <UserDetails currentStep={currentStep} setCurrentStep={setCurrentStep} orgFormData={orgFormData} setOrgFormData={setOrgFormData} />
          ) : currentStep === 1 ? (
            <OrganisationDetails currentStep={currentStep} setCurrentStep={setCurrentStep} orgFormData={orgFormData} setOrgFormData={setOrgFormData} />
          ) : currentStep === 2 ? (
            <SubscriptionDetails currentStep={currentStep} setCurrentStep={setCurrentStep} orgFormData={orgFormData} setOrgFormData={setOrgFormData} />
          ) : currentStep === 3 && (
            <StripeCheckout currentStep={currentStep} setCurrentStep={setCurrentStep} planId={orgFormData.plan} />
          )}
        </StepContainer>
      </StepOuterContainer>
    </FullScreenContainer>
  );
};

const StepOuterContainer = styled(Box)`
  justify-content: flex-start;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: fit-content;
  padding: 48px 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  @media (max-width: 620px) {
    padding: 24px 0;
  }
`;

const StepContainer = styled(Box)`
  height: fit-content;
  width: 100%;
`;

export default OrganisationRegistration;