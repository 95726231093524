import React from 'react';
import SettingsTabs from 'components/common/SettingsTabs';
import CancellationCodes from './CancellationCodes';
// import DeclinedReasons from './DeclinedReasons'; // Not in the scope of MVP1
import ServiceTypes from './ServiceTypes';
import ShiftLocations from './ShiftLocations';

const SchedulingSettings = () => {
  return (
    <SettingsTabs
      title='Scheduling Settings'
      tabs={[
        {
          label: 'Service Types',
          content: <ServiceTypes />,
          requiredPermissions: [],
        },
        {
          label: 'Cancellation Codes',
          content: <CancellationCodes />,
          requiredPermissions: [],
        },
        // This component is partially completed (except for the APIs), it is not in the scope of MVP1
        // {
        //   label: 'Declined Reasons',
        //   content: <DeclinedReasons />
        // },
        {
          label: 'Shift Locations',
          content: <ShiftLocations />,
          requiredPermissions: [],
        },
      ]}
    />
  );
};

export default SchedulingSettings;