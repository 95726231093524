import { homePageColorAtom } from 'atoms';
import { useAtom } from 'jotai';
import { useEffect } from 'react';

export interface HomePageColor {
  bgColor: string;
  color: string;
}

export const homePageColors = [
  {
    bgColor: '#C079D5',
    color: '#FFF',
  },
  {
    bgColor: '#7A47A5',
    color: '#FFF',
  },
  {
    bgColor: '#03A9F4',
    color: '#FFF',
  },
  {
    bgColor: '#1565C0',
    color: '#FFF',
  },
  {
    bgColor: '#00ACC1',
    color: '#FFF',
  },
  {
    bgColor: '#006064',
    color: '#FFF',
  },
  {
    bgColor: '#81C784',
    color: '#000',
  },
  {
    bgColor: '#FFB74D',
    color: '#000',
  },
  {
    bgColor: '#9CA3AF',
    color: '#FFF',
  },
  {
    bgColor: '#F3F4F6',
    color: '#000',
  },
  {
    bgColor: '#F5F2FA',
    color: '#000',
  },
  {
    bgColor: '#FFF',
    color: '#000',
  },
];

const useHomePageColor = (): [HomePageColor, (colorObj: { bgColor: string, color: string }) => void] => {
  const [homePageColor, setHomePageColor] = useAtom(homePageColorAtom);

  // Load the color object from localStorage when the hook is first used
  useEffect(() => {
    const savedColor = localStorage.getItem('homePageColor');
    if (savedColor) {
      setHomePageColor(JSON.parse(savedColor));
    }
  }, []);

  // Function to update the color object and save it to localStorage
  const updateHomePageColor = (colorObj: { bgColor: string, color: string }) => {
    setHomePageColor(colorObj);
    localStorage.setItem('homePageColor', JSON.stringify(colorObj));
  };

  return [homePageColor, updateHomePageColor];
};

export default useHomePageColor;