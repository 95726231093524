import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { Typography } from '@mui/material';
import variables from 'styles/variables';
import { EditIcon } from './EditIcon';

export const Columns: GridColDef[] = [
  {
    field: 'edit',
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <EditIcon notificationSetting={params.row} />
    ),
    sortable: false,
  },
  {
    field: 'notificationType',
    headerName: 'Notification Type',
    width: 300,
    renderCell: (params) => (
      <Typography variant='subtitle2'>
        {params.row.notificationType}
      </Typography>
    ),
    sortable: false,
  },
  {
    field: 'notificationAction',
    headerName: 'Notification Action',
    flex: 1,
    minWidth: 300,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary} sx={{ whiteSpace: 'wrap' }}>
        {params.row.notificationAction}
      </Typography>
    ),
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => (
      <IsActiveDropdown
        isActive={params.row.isActive}
        disabled
      />
    ),
  },
];