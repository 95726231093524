import React, { FC } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

const Actions: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const handleNew = () => {
    navigate(`/participant-centre/participants/view/${id}/goals/create`);
  };

  return (
    <Button color='primary' variant='contained' onClick={() => handleNew()} startIcon={<AddOutlinedIcon />} sx={{ minWidth: 'fit-content', height: '100%' }}>
      Add
    </Button>
  );
};

export default Actions;