import { DateSelectArg } from "@fullcalendar/core";
import { RepeatFrequency } from "components/features/Scheduling/ServiceTeamSchedule/ServiceTeamAvailability/types";
import { addDays, addYears, differenceInMinutes, format, isBefore, isSameDay, parseISO, startOfWeek } from "date-fns";
import { CalendarEvent, RRule, TemplateEvent } from "types/fullCalendar";
import { v4 as uuidv4 } from 'uuid';

export const daysOfWeek = [
  { label: 'Sunday', value: 'Sunday' },
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
  { label: 'Saturday', value: 'Saturday' },
];

export const calculateDuration = (start: string, end: string): string => {
  // Parse the start and end times
  const startISO = parseISO(start);
  const endISO = parseISO(end);

  // Calculate duration in minutes
  const durationInMinutes = differenceInMinutes(endISO, startISO);

  // Convert minutes to HH:mm format
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

export const daysMap = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
} as { [key: string]: number };

export const getDayTwoLetters = (day: string | number): string => {
  if (typeof day === 'number') {
    // Find the day string from the number
    const dayName = Object.keys(daysMap).find(key => daysMap[key] === day);
    if (dayName) {
      return dayName.substring(0, 2).toLowerCase();
    }
  } else if (typeof day === 'string') {
    // Directly get the first two letters from the string
    return day.substring(0, 2).toLowerCase();
  }
  throw new Error('Invalid day input');
};

export const mapTemplateEventToCalendarEvent = (templateEvent: TemplateEvent): CalendarEvent => {

  // Get the start of the current week (Sunday by default)
  const weekStart = startOfWeek(new Date(), { weekStartsOn: 0 });

  // Calculate the actual date for the event based on the day of the week
  const dayIndex = daysMap[templateEvent.day];
  const eventDate = addDays(weekStart, dayIndex);

  // Combine the eventDate with start and end times
  const start = `${format(eventDate, 'yyyy-MM-dd')}T${templateEvent.startTime}`;
  const end = `${format(eventDate, 'yyyy-MM-dd')}T${templateEvent.endTime}`;

  // Calculate the duration
  const duration = calculateDuration(start, end);

  // Create a CalendarEvent object
  return {
    id: templateEvent.id,
    recurringSeriesId: templateEvent.id,
    title: templateEvent.title,
    start,
    end,
    day: templateEvent.day,
    duration,
    backgroundColor: templateEvent.backgroundColor,
    rrule: {
      freq: 'weekly',
      byweekday: [getDayTwoLetters(templateEvent.day)],
      dtstart: start,
      interval: 1,
    },
  };
};

// Converts a TemplateEvent object to a CalendarEvent object for previewing
// TemplateEvent objects are ambiguous and don't have a specific date, so we need to do some calculations
export const convertTemplateEventToCalendarEvent = (templateEvent: TemplateEvent, isTemplatePreview: boolean): CalendarEvent => {
  // Get the start of the current week (Sunday by default)
  const weekStart = startOfWeek(new Date(), { weekStartsOn: 0 });

  // Calculate the actual date for the event based on the day of the week
  const dayTwoLetters = getDayTwoLetters(templateEvent.day);
  const dayIndex = daysMap[templateEvent.day];
  const eventDate = addDays(weekStart, dayIndex);

  // // Combine the eventDate with start and end times
  const start = `${format(eventDate, 'yyyy-MM-dd')}T${templateEvent.startTime}`;
  const end = `${format(eventDate, 'yyyy-MM-dd')}T${templateEvent.endTime}`;

  // Calculate the duration
  const duration = calculateDuration(start, end);

  // Calculate the 'until' date (1 year after the start date)
  const until = format(addYears(parseISO(start), 1), "yyyy-MM-dd'T'HH:mm:ss");

  const id = uuidv4();
  // Create a CalendarEvent object
  return {
    id,
    recurringSeriesId: id,
    title: templateEvent.title,
    start: start,
    end: end,
    day: templateEvent.day,
    backgroundColor: isTemplatePreview ? 'rgba(76, 175, 80, 0.6)' : '#4CAF50',
    isTemplatePreview,
    duration,
    rrule: {
      freq: 'weekly',
      byweekday: [dayTwoLetters],
      dtstart: start,
      interval: 1,
      until,
    }
  };
};

// Maps over an array of TemplateEvent objects and converts them to CalendarEvent objects
export const mapConvertTemplateToCalendarEvents = (templateEvents: TemplateEvent[], isTemplatePreview: boolean): CalendarEvent[] => {
  return templateEvents.map(templateEvent => convertTemplateEventToCalendarEvent(templateEvent, isTemplatePreview));
};

// Maps over an array of CalendarEvent objects and converts any template previews to normal events
// by updating the backgroundColor and isTemplatePreview properties
export const convertPreviewEventsToCalendarEvents = (events: CalendarEvent[]): CalendarEvent[] => {
  return events.map(event => {
    if (event.isTemplatePreview) {
      // Update the event with isTemplatePreview set to true
      return {
        ...event,
        backgroundColor: '#4CAF50',
        isTemplatePreview: false, // Convert it to a normal event
      };
    } else {
      // Return the event as is if it's not a template preview
      return event;
    }
  });
};

// Clear all template preview events from the events array
export const clearTemplatePreviewEvents = (events: CalendarEvent[]): CalendarEvent[] => {
  return events.filter(event => !event.isTemplatePreview);
};

export const handleTodayClick = (ref: any) => {
  const calendarApi = ref.current.getApi();
  calendarApi.today();
};

export const handlePrevClick = (ref: any) => {
  const calendarApi = ref.current.getApi();
  calendarApi.prev();  // Move to the previous date range
};

export const handleNextClick = (ref: any) => {
  const calendarApi = ref.current.getApi();
  calendarApi.next();  // Move to the next date range
};

export const handleEventChange = (eventChangeInfo: any, setEvents: React.Dispatch<React.SetStateAction<any>>) => {
  const { event } = eventChangeInfo;

  // Get the day of the week as a string (e.g., "Monday")
  const newDay = format(event.start, 'EEEE');

  // Extract the start and end times in 'HH:mm:ss' format
  const newStartTime = event.start ? event.start.toTimeString().split(' ')[0] : '00:00:00';
  const newEndTime = event.end ? event.end.toTimeString().split(' ')[0] : '01:00:00';

  // Create a new start date string with the updated day
  const newStartDate = `${format(event.start, 'yyyy-MM-dd')}T${newStartTime}`;
  const newEndDate = `${format(event.end, 'yyyy-MM-dd')}T${newEndTime}`;

  // Update the event in the state
  setEvents((prevEvents: CalendarEvent[]) =>
    prevEvents.map((evt: CalendarEvent) => {
      if (evt.id === event.id) {
        return {
          ...evt,
          day: newDay,           // Update the day with the new day
          start: newStartDate,   // Update the start date with the new start time
          end: newEndDate,       // Update the end date with the new end time
          duration: calculateDuration(newStartDate, newEndDate), // Update the duration
          rrule: evt.rrule
            ? {
              ...evt.rrule,
              byweekday: [getDayTwoLetters(newDay)], // Update the day of the week in the rrule
              dtstart: newStartDate, // Update the start date in the rrule
            }
            : undefined, // Leave rrule undefined if it doesn't exist
        };
      }
      return evt;
    })
  );
};

// Used when creating a new event by selecting a date range in the calendar
export const handleDateSelect = (selectInfo: DateSelectArg, setEvents: React.Dispatch<React.SetStateAction<CalendarEvent[]>>, createWithRecurrence: boolean) => {
  const { start, end, allDay } = selectInfo;

  // Calculate the day of the week from the start date
  const day = format(start, 'EEEE');

  // Format the start and end times to 'HH:mm:ss'
  const startTime = format(start, 'HH:mm:ss');
  const endTime = format(end, 'HH:mm:ss');

  // Calculate the duration
  const duration = calculateDuration(start.toISOString(), end.toISOString());

  // Calculate the 'until' date (1 year after the start date)
  const until = format(addYears(start, 1), "yyyy-MM-dd'T'HH:mm:ss");

  const id = uuidv4();
  // Construct the new event
  const newEvent: CalendarEvent = {
    id,
    recurringSeriesId: createWithRecurrence ? id : undefined,
    title: 'Available',
    day, // Set the day of the week
    start: `${format(start, 'yyyy-MM-dd')}T${startTime}`, // Full start date and time
    end: `${format(end, 'yyyy-MM-dd')}T${endTime}`, // Full end date and time
    backgroundColor: '#4CAF50',
    duration,
    allDay,
    rrule: createWithRecurrence ? {
      freq: 'weekly',
      byweekday: [getDayTwoLetters(day)],
      dtstart: `${format(start, 'yyyy-MM-dd')}T${startTime}`,
      interval: 1,
      until,
    } : undefined,
  };

  // Add the new event to the events state
  setEvents((prevEvents: CalendarEvent[]) => [...prevEvents, newEvent]);

  // Clear the selection on the calendar
  selectInfo.view.calendar.unselect();
  return newEvent;
};

export const checkEventsIsPreview = (events: CalendarEvent[]): boolean => {
  return events.some(event => event.isTemplatePreview === true);
};

export const validateCalendarEvents = (events: CalendarEvent[]): boolean => {
  return events.every((event) => {
    const hasValidStart = event.start && !isNaN(new Date(event.start).getTime());
    const hasValidEnd = event.end && !isNaN(new Date(event.end).getTime());

    if (!hasValidStart || !hasValidEnd) return false;
    return true;
  });
};

export const validateEventTimes = (events: CalendarEvent[]): boolean => {
  return events.every((event) => {
    const startTime = event.start;
    const endTime = event.end;

    // Check if the end time is before
    return isBefore(parseISO(endTime), parseISO(startTime));
  });
};

export const checkForEventOverlaps = (events: CalendarEvent[]): boolean => {
  // Group events by day
  const eventsByDay: { [key: string]: CalendarEvent[] } = {};

  events.forEach(event => {
    const day = event.start.split('T')[0]; // Extract the day portion (yyyy-MM-dd)
    if (!eventsByDay[day]) {
      eventsByDay[day] = [];
    }
    eventsByDay[day].push(event);
  });

  // Check for overlaps within each day
  for (const day in eventsByDay) {
    const eventsOnDay = eventsByDay[day];

    for (let i = 0; i < eventsOnDay.length; i++) {
      for (let j = i + 1; j < eventsOnDay.length; j++) {
        const eventA = eventsOnDay[i];
        const eventB = eventsOnDay[j];

        // Check if eventA and eventB overlap
        if (
          eventA.start < eventB.end &&
          eventA.end > eventB.start
        ) {
          return true; // Overlap found
        }
      }
    }
  }

  return false; // No overlaps found
};

export const updateRRuleFrequency = (selectedFrequency: RepeatFrequency, eventToBeEdited: CalendarEvent): CalendarEvent => {
  let updatedRRule;
  const day = getDayTwoLetters(eventToBeEdited.day);

  switch (selectedFrequency) {
    case 'never':
      updatedRRule = undefined; // No recurrence
      break;
    case 'weekly':
      updatedRRule = {
        dtstart: eventToBeEdited.start,
        byweekday: [day],
        freq: 'weekly',
        interval: 1, // Weekly recurrence
      };
      break;
    case 'fortnightly':
      updatedRRule = {
        dtstart: eventToBeEdited.start,
        byweekday: [day],
        freq: 'weekly',
        interval: 2, // Every 2 weeks
      };
      break;
    default:
      updatedRRule = eventToBeEdited.rrule; // Default to the current value
  }

  return {
    ...eventToBeEdited,
    recurringSeriesId: selectedFrequency === 'never' ? undefined : eventToBeEdited.id, // Set recurringSeriesId if it's not 'never'
    rrule: updatedRRule as RRule,
  };
};

export const updateRRuleUntil = (selectedDate: string | undefined, editedEvent: CalendarEvent, isNever?: boolean): CalendarEvent => {
  if (isNever) {
    return {
      ...editedEvent,
      rrule: {
        ...editedEvent.rrule,
        until: undefined, // Remove the until date
      } as RRule,
    };
  } else {
    return {
      ...editedEvent,
      rrule: {
        ...editedEvent.rrule,
        until: selectedDate, // Set until to the selected date
      } as RRule,
    };
  }
};

export const getRepeatFrequency = (rrule: RRule | undefined): string => {
  if (!rrule) return 'never'; // No recurrence

  if (rrule.freq === 'weekly' && rrule.interval === 1) {
    return 'weekly';
  }

  if (rrule.freq === 'weekly' && rrule.interval === 2) {
    return 'fortnightly';
  }

  if (rrule.freq === 'monthly') {
    return 'monthly';
  }

  return 'never'; // Default to 'never' if it's not found
};

export const getEventSeriesPosition = (selectedEvent: any, parentEvent: CalendarEvent): 'standalone' | 'firstInSeries' | 'indexInSeries' => {
  if (!selectedEvent.extendedProps.recurringSeriesId || !parentEvent?.rrule?.dtstart) return 'standalone';

  const eventStart = format(new Date(selectedEvent.start), "yyyy-MM-dd'T'HH:mm:ss"); // The start date of the selected event
  const rruleStart = parentEvent.rrule.dtstart; // The start date of the recurring rule

  // If the event start date matches the rrule start date, it's the first in the series
  if (isSameDay(eventStart, rruleStart)) {
    return 'firstInSeries';
  }

  // If the event start date doesn't match, it's an index other than the first
  return 'indexInSeries';
};

export const recurrenceIsEdited = (editedEvent: CalendarEvent, parentEvent: CalendarEvent): boolean => {
  if (!parentEvent.rrule && !editedEvent.rrule) return false; // If both don't have an rrule, return false
  if (!parentEvent.rrule || !editedEvent.rrule) return true; // If one has an rrule and the other doesn't, return true

  // Compare specific rrule properties
  const propertiesToCompare = ['freq', 'until', 'interval', 'dtstart'] as const;

  for (const prop of propertiesToCompare) {
    if (parentEvent.rrule[prop] !== editedEvent.rrule[prop]) {
      return true;
    }
  }

  // Compare array byweekday
  if (JSON.stringify(parentEvent.rrule.byweekday) !== JSON.stringify(editedEvent.rrule.byweekday)) {
    return true;
  }

  return false;
};

// Edit a standalone event - Update the event with the new values and setEvents
export const editStandAloneEvent = (editedEvent: CalendarEvent, events: CalendarEvent[], setEvents: React.Dispatch<React.SetStateAction<CalendarEvent[]>>) => {
  const updatedEvents = events.map((event) => {
    if (event.id === editedEvent.id) {
      return { ...event, ...editedEvent };
    }
    return event;
  });
  setEvents(updatedEvents);
  return;
};

// No historical data is considered for the first event in the series
// if single: Create a new event with the updated values and update the series with an exception of the first event and setEvents
// if thisAndFollowing: Update the rrule, this will update all events in the series and setEvents
export const editFirstInSeriesEvent = (
  editedEvent: CalendarEvent, // The edited event with the new values
  selectedEvent: CalendarEvent, // The event clicked on to open the modal
  setEvents: React.Dispatch<React.SetStateAction<CalendarEvent[]>>, // The setEvents function to update the events array
  parentEvent: CalendarEvent, // The parent event of the series - holds the rrule and other series data
  target: 'thisAndFollowing' | 'single' // The target of the edit
) => {
  if (target === 'single') {
    // Create a new event for this specific occurrence
    const newEvent = {
      ...editedEvent,
      id: uuidv4(), // Generate a new id for the new event
      recurringSeriesId: parentEvent.recurringSeriesId, // Maintain the link to the series
      rrule: undefined // This will make it a standalone event
    };

    const parentExdate = parentEvent?.exdate || [];
    // Add exception for this date in the original recurring event
    const updatedParentEvent = {
      ...parentEvent,
      exdate: [...parentExdate, parentEvent.start], // Add the exception date
    };

    // Update the events array
    setEvents((prevEvents: CalendarEvent[]) =>
      prevEvents.map(evt =>
        evt.id === parentEvent.id ? updatedParentEvent : evt
      ).concat(newEvent) // Add the new standalone event
    );

  } else if (target === 'thisAndFollowing') {
    // Update the rrule for all future events in the series
    const updatedParentEvent = {
      ...parentEvent,
      ...editedEvent, // Apply the edits to the parent event
      rrule: {
        ...parentEvent.rrule,
        ...editedEvent.rrule // Update the rrule with the new values
      }
    };

    // Update the events array with the new parent event
    setEvents((prevEvents: any[]) =>
      prevEvents.map(evt =>
        evt.id === parentEvent.id ? updatedParentEvent : evt
      )
    );
  }
};

// Historical data is maintained when editing an event in the series (not the first event)
// if single: Create a new event with the updated values and update the series with an exception of the selected event and setEvents
// if thisAndFollowing: update the rrule of the parent event to prior to the selected event, create a new event with the new rrule and setEvents
export const editIndexInSeriesEvent = (
  editedEvent: CalendarEvent, // The edited event with the new values
  selectedEvent: CalendarEvent, // The event clicked on to open the modal
  setEvents: React.Dispatch<React.SetStateAction<CalendarEvent[]>>, // The setEvents function to update the events array
  parentEvent: CalendarEvent, // The parent event of the series - holds the rrule and other series data
  target: 'thisAndFollowing' | 'single' // The target of the edit - thisAndFollowing or single
) => {
  if (target === 'single') {
    // Create a new event for this specific occurrence
    const newEvent = {
      ...editedEvent,
      id: uuidv4(), // Generate a new id for the new event
      recurringSeriesId: parentEvent.recurringSeriesId, // Maintain the link to the series
      rrule: undefined // This will make it a standalone event
    };

    // Add exception for this date in the original recurring event
    const parentExdate = parentEvent?.exdate || [];
    const updatedParentEvent = {
      ...parentEvent,
      exdate: [
        ...parentExdate,
        format(new Date(selectedEvent.start), "yyyy-MM-dd'T'HH:mm:ss")
      ],
    };

    // Update the events array
    setEvents((prevEvents: CalendarEvent[]) =>
      prevEvents.map(evt =>
        evt.id === parentEvent.id ? updatedParentEvent : evt
      ).concat(newEvent) // Add the new standalone event
    );

  } else if (target === 'thisAndFollowing') {
    // Update parent rrule to stop before the selected event
    const newUntilDate = new Date(selectedEvent.start);
    newUntilDate.setDate(newUntilDate.getDate() - 1); // The day before the selected event

    const updatedParentEvent = {
      ...parentEvent,
      rrule: {
        ...parentEvent.rrule,
        until: format(newUntilDate, "yyyy-MM-dd"), // Stop recurrence just before the selected date
      } as RRule
    };
    //  Create a new event starting from the selected event with the updated rrule
    const newEvent = {
      ...editedEvent,
      id: uuidv4(),
      recurringSeriesId: parentEvent.recurringSeriesId, // Maintain the link to the series
      rrule: {
        ...editedEvent.rrule,
        byweekday: [getDayTwoLetters(editedEvent.day)], // Update the day of the week
        dtstart: format(new Date(editedEvent.start), "yyyy-MM-dd'T'HH:mm:ss"), // Start the new series from this date
        until: editedEvent.rrule?.until || parentEvent.rrule?.until // Use the same "until" from the original series or add a new one
      } as RRule
    };

    // Update the events array with both the updated parent event and the new series event
    setEvents((prevEvents: CalendarEvent[]) => {
      // Remove the old parent event and add the updated one
      const updatedEvents = prevEvents.map(evt => evt.id === parentEvent.id ? updatedParentEvent : evt);

      // Add the new event to the updated events array
      return [...updatedEvents, newEvent];
    });
  }
};

// Delete a standalone event - Remove the event from the events array and setEvents
export const deleteStandAloneEvent = (editedEvent: CalendarEvent, events: CalendarEvent[], setEvents: React.Dispatch<React.SetStateAction<CalendarEvent[]>>) => {
  const updatedEvents = events.filter((event) => event.id !== editedEvent.id);
  setEvents(updatedEvents);
};

// No historical data is considered for the first event in the series
// if single: Set an exception for this date in the original recurring event and setEvents
// if thisAndFollowing: Remove the entire series from the events array and setEvents
export const deleteFirstInSeriesEvent = (
  editedEvent: CalendarEvent, // The edited event with the new values
  selectedEvent: CalendarEvent, // The event clicked on to open the modal
  setEvents: React.Dispatch<React.SetStateAction<CalendarEvent[]>>, // The setEvents function to update the events array
  parentEvent: CalendarEvent, // The parent event of the series - holds the rrule and other series data
  target: 'thisAndFollowing' | 'single' // The target of the edit
) => {
  if (target === 'single') {
    const parentExdate = parentEvent?.exdate || [];
    // Add exception for this date in the original recurring event
    const updatedParentEvent = {
      ...parentEvent,
      exdate: [...parentExdate, parentEvent.start], // Add the exception date
    };

    // Update the events array
    setEvents((prevEvents: CalendarEvent[]) =>
      prevEvents.map(evt =>
        evt.id === parentEvent.id ? updatedParentEvent : evt
      )
    );

  } else if (target === 'thisAndFollowing') {
    setEvents((prevEvents: CalendarEvent[]) =>
      prevEvents.filter(evt => evt.recurringSeriesId !== parentEvent.recurringSeriesId)
    );
  }
};

// Historical data is maintained when deleting an event in the series (not the first event)
// if single: Create an exception for this date in the original recurring event and setEvents
// if thisAndFollowing: update the rrule "until" of the parent event to prior to the selected event and, filter out any events after the selected event based on recurringSeriesId and setEvents
export const deleteIndexInSeriesEvent = (
  editedEvent: CalendarEvent, // The edited event with the new values
  selectedEvent: CalendarEvent, // The event clicked on to open the modal
  setEvents: React.Dispatch<React.SetStateAction<CalendarEvent[]>>, // The setEvents function to update the events array
  parentEvent: CalendarEvent, // The parent event of the series - holds the rrule and other series data
  target: 'thisAndFollowing' | 'single' // The target of the edit - thisAndFollowing or single
) => {
  if (target === 'single') {
    const parentExdate = parentEvent?.exdate || [];
    const newExdate = format(new Date(selectedEvent.start), "yyyy-MM-dd'T'HH:mm:ss");
    // Add exception for this date in the original recurring event
    const updatedParentEvent = {
      ...parentEvent,
      exdate: [...parentExdate, newExdate],
    };
    // Update the events array
    setEvents((prevEvents: CalendarEvent[]) =>
      prevEvents.map(evt =>
        evt.id === parentEvent.id ? updatedParentEvent : evt
      )
    );

  } else if (target === 'thisAndFollowing') {
    // Calculate the day before the selected event to stop the recurrence
    const newUntilDate = new Date(selectedEvent.start);
    newUntilDate.setDate(newUntilDate.getDate() - 1); // Stop the recurrence just before the selected event

    // Update the parent event's RRULE to stop before the selected event
    const updatedParentEvent = {
      ...parentEvent,
      rrule: {
        ...parentEvent.rrule,
        until: format(newUntilDate, "yyyy-MM-dd"), // Set the RRULE until date to the day before the selected event
      } as RRule
    };

    // Filter out any events that have the same recurringSeriesId and are after the selected event
    const updatedEvents = (prevEvents: CalendarEvent[]) =>
      prevEvents
        .map(evt => (evt.id === parentEvent.id ? updatedParentEvent : evt)) // Update the parent event
        .filter(evt => {
          const eventStartDate = new Date(evt.start);
          const selectedEventDate = new Date(selectedEvent.start);

          // Only keep events that occur before the selected event or are part of a different series
          return evt.recurringSeriesId !== parentEvent.recurringSeriesId || eventStartDate < selectedEventDate;
        });

    // Update the events array
    setEvents(updatedEvents);
  }
};