import React, { FC } from 'react';
import { Stack } from '@mui/material';
import styled from 'styled-components';
import SearchInput from 'components/common/InputFields/SearchInput';
import { NotificationSettingsControlsProps } from '../../types';

const Controls: FC<NotificationSettingsControlsProps> = ({
  searchText,
  setSearchText,
}) => {

  return (
    <StyledStack>
      {/* <SearchInput searchValue={searchText} setSearchValue={setSearchText} placeholderText='Search' /> */}
    </StyledStack>
  );
};

const StyledStack = styled(Stack)`
  height: fit-content;
  padding: 24px;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
`;

export default Controls;