import React from 'react';
import App from './App';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import Providers from './components/common/Providers';

// Create a root instance to render the application
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Render the application
root.render(
  <Router>
    <Providers >
      <App />
    </Providers>
  </Router>,
);