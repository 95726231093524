import { useCallback, useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { getCurrentUserProfile } from 'api';
import { User } from 'types/dbSchema/userProfiles';
import { roles } from 'types/dbSchema/permissions';
import { currentUserAtom } from 'atoms/currentUserAtom';

export interface UserWithPermissions extends User {
  permissions: string[];
}

let isFetching = false;

const useCurrentUser = (): [UserWithPermissions | null, (user: UserWithPermissions | null) => void, () => void, boolean] => {
  const [currentUser, setCurrentUser] = useAtom(currentUserAtom);
  const [loading, setLoading] = useState(!currentUser);

  // This function allows us to manually fetch the current logged in user
  const fetchUser = useCallback(async () => {
    if (isFetching || currentUser) return; // Prevent multiple fetches

    isFetching = true;
    setLoading(true);

    try {
      const fetchedUser = await getCurrentUserProfile();
      const viewAs = localStorage.getItem('viewAs');
      if (viewAs) fetchedUser.permissions = roles[viewAs as keyof typeof roles].permissions;
      setCurrentUser(fetchedUser);
    } catch (error) {
      console.error('Error fetching user:', error);
    } finally {
      setLoading(false);
      isFetching = false;
    }
  }, [setCurrentUser, currentUser]);

  // Fetch the user when the hook is called
  useEffect(() => {
    if (currentUser == null) {
      fetchUser();
    } else {
      setLoading(false);
    }
  }, [currentUser, fetchUser]);

  return [currentUser, setCurrentUser, fetchUser, loading];
};

export default useCurrentUser;