import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import DataTableControls from './Controls';
import DataTable from 'components/common/DataTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { GridRowId } from '@mui/x-data-grid';
import { Columns } from './Controls/Columns';
import { dummyServiceRegions } from 'utils/helpers/getDBData';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { ServiceRegion } from 'types/dbSchema/serviceRegions';
import { PaginationModel } from 'components/common/DataTable/types';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import ServiceRegionForm from 'components/features/Forms/ServiceRegionForm';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';

const ServiceRegionsDataTable: FC = () => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);
  const [searchText, setSearchText] = useState('');
  const [, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({ page: 0, pageSize: 20 });
  const [modalState, setModalState] = useAtom(modalStateAtom);

  const handleModalOpen = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: ServiceRegionForm,
      props: {
        dataTableName: 'service-region',
        type: 'create',
      }
    });
  };

  const { isError, data } = useQuery({
    queryKey: ['service-regions', paginationModel, selectedFilters],
    queryFn: () => dummyServiceRegions as ServiceRegion[],
    placeholderData: keepPreviousData,
    // enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.serviceRegions,
  });

  return (
    <Stack height='100%' width='100%' sx={{ backgroundColor: 'white' }}>
      <DataTableControls
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <DataTable
        data={{ items: data || [], totalCount: 10, pageNumber: paginationModel.page, pageSize: paginationModel.pageSize }}
        columns={Columns}
        setSelectedRowIds={setSelectedRowIds}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isPending={false}
        isError={isError}
        title='Service Regions'
        dataTableName='service-regions'
        checkboxSelection={false}
        paginationMode='server'
        onNoData={handleModalOpen}
      />
    </Stack>
  );
};

export default ServiceRegionsDataTable;