import React, { FC } from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import styled from 'styled-components';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { useAtom } from 'jotai';
import { sidebarAtom } from 'atoms/sidebarAtom';

const BackToMainMenuButton: FC = () => {
  const [sidebarState, setSidebarState] = useAtom(sidebarAtom);

  return (
    <StyledList data-testid='BackToMainMenuButton'>
      <StyledTitleItem
        onClick={
          () => setSidebarState({
            ...sidebarState,
            type: 'main',
          })
        }>
        <StyledListItemIcon>
          <ArrowCircleLeftOutlinedIcon sx={{ color: 'white', width: '100%' }} />
        </StyledListItemIcon>
        {sidebarState.isOpen &&
          <TitleItemText>
            <Typography variant='body2' color='white'>
              Back to Main Menu
            </Typography>
          </TitleItemText>
        }
      </StyledTitleItem>
      <BoxLine />
    </StyledList>
  );
};

const StyledList = styled(List)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 0 8px 0;
  gap: 8px;
`;

const StyledTitleItem = styled(ListItem)`
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  user-select: none;
  height: 46px;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: unset;
  width: 20px;
  display: flex;
`;

const TitleItemText = styled(ListItemText)`
  padding: 4px 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const BoxLine = styled(Box)`
  display: flex;
  padding: 0px 8px;
  flex-direction: column;
  align-self: center;
  height: 1px;
  width: 100%;
  background-color: #ffffff7f;
  box-sizing: border-box;
`;

export default BackToMainMenuButton;