import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import variables from 'styles/variables';
import { Typography } from '@mui/material';
import ViewIcon from './ViewIcon';
import { formatISODate } from 'utils/helpers';

export const Columns: GridColDef[] = [
  {
    field: 'view',
    headerName: '',
    width: 50,
    sortable: false,
    renderCell: (params) => (
      <ViewIcon serviceContractToView={params.row} />
    ),
  },
  {
    field: 'supportItemNumber',
    headerName: 'Support Item Number',
    width: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2'>
        {params.row.supportItemNumber}
      </Typography>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2'>
        {params.row.status}
      </Typography>
    ),
  },
  {
    field: 'delivered',
    headerName: 'Delivered',
    width: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2'>
        {params.row.delivered}
        {/* {formatISODate(params.row.delivered)} */}
      </Typography>
    ),
  },
  {
    field: 'extracted',
    headerName: 'Extracted',
    width: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2'>
        {params.row.extracted}
        {/* {formatISODate(params.row.extracted)} */}
      </Typography>
    ),
  },
  {
    field: 'qty',
    headerName: 'QTY',
    width: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2'>
        {params.row.qty}
      </Typography>
    ),
  },
  {
    field: 'hrs',
    headerName: 'HRS',
    width: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2'>
        {params.row.hrs}
      </Typography>
    ),
  },
  {
    field: 'rate',
    headerName: 'Rate',
    width: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2'>
        {params.row.rate}
      </Typography>
    ),
  },
  {
    field: 'total',
    headerName: 'Total',
    width: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2'>
        {params.row.total}
      </Typography>
    ),
  },
];