import ParticipantGoalsDataTable from 'components/features/DataTables/ParticipantGoalsDataTable';
import React, { FC } from 'react';
import { Participant } from 'types/dbSchema/participants';

export interface GoalsProps {
  participant: Participant;
}

const Goals: FC<GoalsProps> = ({ participant }) => <ParticipantGoalsDataTable participant={participant} />;

export default Goals;