import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import Tabs from 'components/common/Tabs';
import styled from 'styled-components';
import CustomBreadcrumbs from 'components/common/Breadcrumbs';
import { SettingsTabsProps } from './types';

const SettingsTabs: FC<SettingsTabsProps> = ({ title, tabs }) => {
  return (
    <Stack height='100%'>
      <StyledStack>
        <CustomBreadcrumbs />
        <Typography variant="h4">{title}</Typography>
      </StyledStack>
      <Tabs tabs={tabs} />
    </Stack>
  );
};

const StyledStack = styled(Stack)`
  padding: 24px 24px 16px 24px;
  gap: 8px;
  background-color: white;
`;

export default SettingsTabs;