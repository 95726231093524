import React from "react";
import { Stack } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import variables from "styles/variables";
import { useNavigate } from "react-router-dom";
import { NotificationSettings } from "types/dbSchema/notificationSettings";

export const EditIcon = ({ notificationSetting }: { notificationSetting: NotificationSettings }) => {
  const navigate = useNavigate();

  return (
    <Stack
      justifyContent='center'
      alignItems='center'
      sx={{ cursor: 'pointer' }}
      onClick={() => navigate(`/settings/notification-settings/edit/${notificationSetting.id}`)}
    >
      <EditOutlinedIcon sx={{ color: variables.colors.icon.standard }} />
    </Stack>
  );
};