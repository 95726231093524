import React, { FC } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { ParticipantAlert } from 'types/dbSchema/alerts';
import ParticipantAlertForm from 'components/features/Forms/ParticipantAlertForm';

export interface EditIconProps {
  alertToEdit: ParticipantAlert;
}

// TODO: Implement the EditIcon component
const EditIcon: FC<EditIconProps> = ({ alertToEdit }) => {
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const { id } = useParams();

  const handleModalOpen = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: ParticipantAlertForm,
      props: {
        dataTableName: 'general-notes',
        type: 'edit',
        participantAlert: alertToEdit,
        participantId: id,
      }
    });
  };

  return (
    <StyledBox onClick={handleModalOpen}>
      <EditOutlinedIcon />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export default EditIcon;