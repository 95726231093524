import React, { FC } from 'react';
import { LinearProgress } from '@mui/material';
import ViewParticipantGoal from 'components/features/ViewParticipantGoal';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useParams } from 'react-router-dom';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { getParticipantGoalById } from 'api/organisations/participants/goals';

const ViewParticipantGoalPage: FC = () => {
  const [organisations] = useUserOrganisations();
  const { id, goalId } = useParams();

  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
    getByIDFunc: () => getParticipantGoalById(organisations[0].organisation.globalId, id as string, goalId as string),
  });

  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return <ViewParticipantGoal participantGoal={currentDataPoint} />;
};

export default ViewParticipantGoalPage;