import React from 'react';
import CreateEditAvailabilityTemplate from 'components/features/AvailabilitySettings/CreateEditAvailabilityTemplate';
import { LinearProgress } from '@mui/material';
import { dummyAvailabilitiesTemplates } from 'utils/helpers/getDBData';
import { AvailabilityTemplate } from 'types/dbSchema/availabilitySettings';
import { useParams } from 'react-router-dom';
import useUserOrganisations from 'hooks/useUserOrganisations';

const EditAvailabilityTemplatePage = () => {
  const [organisations] = useUserOrganisations();
  const { id } = useParams();

  // TODO: Replace this with the actual endpoint to fetch data
  // const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
  //   getByIDFunc: () => getParticipantGoalById(organisations[0].organisation.globalId, id as string, goalId as string),
  // });

  const hasError = false;
  const isLoading = false;
  const currentDataPoint = dummyAvailabilitiesTemplates.find((template: AvailabilityTemplate) => template.id === id);


  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return (
    <CreateEditAvailabilityTemplate availabilityTemplate={currentDataPoint} />
  );
};

export default EditAvailabilityTemplatePage;