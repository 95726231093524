import axiosInstance from 'api/config';

export interface AddressModel {
  selectedAddress: string;
}

export const getAddressSuggestions = async (input: string) => {
  try {
    const response = await axiosInstance.get(`/api/address-lookup/auto-complete?input=${input}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const validateAddress = async (selectedAddress: string) => {
  try {
    const response = await axiosInstance.post('/api/address-lookup/validate', { selectedAddress });
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};