import GeneralNotesDataTable from 'components/features/DataTables/GeneralNotesDataTable';
import React, { FC } from 'react';
import { Participant } from 'types/dbSchema/participants';

export interface GeneralNotesProps {
  participant: Participant;
}

const GeneralNotes: FC<GeneralNotesProps> = () => <GeneralNotesDataTable />;

export default GeneralNotes;