import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { Typography } from '@mui/material';
import variables from 'styles/variables';
import { EditIcon } from './EditIcon';
import { formatISODateTime } from 'utils/helpers';
import DeliveryMethod from 'components/features/DeliveryMethod';

export const Columns: GridColDef[] = [
  {
    field: 'edit',
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <EditIcon notificationSetting={params.row} />
    ),
    sortable: false,
  },
  {
    field: 'notificationType',
    headerName: 'Notification Type',
    width: 400,
    renderCell: (params) => (
      <Typography variant='subtitle2' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {params.row.notificationType}
      </Typography>
    ),
    sortable: false,
  },
  {
    field: 'frequency',
    headerName: 'Frequency',
    flex: 1,
    minWidth: 150,
    renderCell: (params) => (
      <Typography variant='subtitle2'>
        {params.row.frequency}
      </Typography>
    ),
    sortable: false,
  },
  {
    field: 'lastRun',
    headerName: 'Last Run',
    flex: 1,
    minWidth: 150,
    renderCell: (params) => (
      <Typography color={variables.colors.text.secondary} variant='body2'>
        {params.row?.lastRun && formatISODateTime(params.row?.lastRun)}
      </Typography>
    ),
    sortable: false,
  },
  {
    field: 'deliveryMethod',
    headerName: 'Delivery Method',
    flex: 1,
    minWidth: 150,
    renderCell: (params) => (
      <DeliveryMethod {...params.row.deliveryMethods} />
    ),
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => (
      <IsActiveDropdown
        isActive={params.row.isActive}
        disabled
      />
    ),
  },
];