import axiosInstance from "api/config";
import { Tag, TagList } from "types/dbSchema/serviceTeamSettings";

// Returns a list of tags for the given organisation ID
export const listTags = async (organisationId: string, page?: number, pageSize?: number): Promise<TagList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID, page, and page size are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/service-team/tags`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing tags for the organisation', error);
    throw error;
  }
};

// Returns a tag by ID
export const getTagByID = async (organisationId: string, tagID: string): Promise<Tag> => {
  try {
    if (!tagID || !organisationId) throw new Error('Organisation ID and Tag ID are required');

    const response = await axiosInstance.get(`/api/${organisationId}/settings/service-team/tags/${tagID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the tag', error);
    throw error;
  }
};

// Create a new tag
export const createTag = async (organisationId: string, data: Tag) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/${organisationId}/settings/service-team/tags`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the tag', error);
    throw error;
  }
};

// Update a tag
export const updateTag = async (organisationId: string, data: Tag) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.put(`/api/${organisationId}/settings/service-team/tags/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the tag', error);
    throw error;
  }
};

// Delete a tag
export const deleteTag = async (organisationId: string, tagID: string) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.delete(`/api/${organisationId}/settings/service-team/tags/${tagID}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the tag', error);
    throw error;
  }
};