import React, { forwardRef } from 'react';
import {
  Checkbox as MUICheckbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormGroup,
  FormLabel,
  Typography,
} from '@mui/material';
import variables from 'styles/variables';
import { CheckboxGroupInputProps } from './types';

const CheckboxGroupInput = forwardRef<HTMLDivElement, CheckboxGroupInputProps>(
  (
    {
      options,
      name,
      value,
      onChange,
      label,
      id,
      error,
      helperText,
      errorText,
      isMandatory,
      ...props
    },
    ref
  ) => {
    // Handler for checkbox state changes
    const handleChange =
      (optionValue: any) =>
        (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
          let newValue = [...value];

          if (checked) {
            newValue.push(optionValue);
          } else {
            newValue = newValue.filter((v) => v !== optionValue);
          }
          onChange(newValue);
        };

    return (
      <FormControl
        error={error}
        ref={ref}
        id={id}
        sx={{ minWidth: 'fit-content' }}
        {...props}
      >
        {label && (
          <Typography sx={{ marginBottom: '8px', color: variables.colors.text.primary }}>
            {label} {isMandatory && <span style={{ color: 'red' }}>*</span>}
          </Typography>
        )}
        <FormGroup>
          {options.map((option) => (
            <FormControlLabel
              key={String(option.value)}
              control={
                <MUICheckbox
                  name={name}
                  checked={value.includes(option.value)}
                  onChange={handleChange(option.value)}
                />
              }
              label={
                <Typography sx={{ fontSize: '14px' }}>
                  {option.label}
                </Typography>
              }
            />
          ))}
        </FormGroup>
        {(error && errorText) ? <FormHelperText sx={{ color: variables.colors.error.main }}>{errorText}</FormHelperText>
          : helperText && <FormHelperText>{helperText}</FormHelperText>
        }
      </FormControl>
    );
  }
);

CheckboxGroupInput.displayName = 'CheckboxGroupInput';

export default CheckboxGroupInput;