import axiosInstance from 'api/config';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { ParticipantGoal, ParticipantGoalList } from 'types/dbSchema/participantGoals';
import { createFilterString } from 'utils/helpers';

// Returns a list of participant goals for the given organisation ID and participant ID
export const listParticipantGoals = async (
  organisationId: string,
  participantId: string,
  filters: SelectedFilter[] | null,
  page: number,
  pageSize: number
): Promise<ParticipantGoalList> => {

  // Create a formatted filter string
  let formattedFilters = '';
  if (filters && filters.length > 0) {
    formattedFilters = createFilterString(filters);
  }

  try {
    if (!organisationId || !participantId) throw new Error('Organisation ID and Participan ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants/${participantId}/goals`, {
      params: {
        filters: formattedFilters,
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing participant goals for the organisation/participant', error);
    throw error;
  }
};


// Retrieves a participant goal by ID
export const getParticipantGoalById = async (organisationId: string, participantId: string, goalId: string): Promise<ParticipantGoal> => {
  try {
    if (!participantId || !organisationId) throw new Error('Organisation ID and Participant ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants/${participantId}/goals/${goalId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the participant goal', error);
    throw error;
  }
};

// Updates a participant goal by ID
export const updateParticipantGoalById = async (organisationId: string, participantId: string, goalId: string, data: Partial<any>) => {
  try {

    const response = await axiosInstance.put(`/api/organisations/${organisationId}/participants/${participantId}/goals/${goalId}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the participant goal', error);
    throw error;
  }
};

// Create new ParticipantGoal
export const createParticipantGoal = async (organisationId: string, participantId: string, data: any) => {
  try {
    if (!organisationId || !participantId || !data) throw new Error('Organisation ID, Participant ID, and data are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/participants/${participantId}/goals`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating a new participant goal', error);
    throw error;
  }
};

// Create new ParticipantGoal
export const createParticipantGoalComment = async (organisationId: string, participantId: string, goalId: string, data: any) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/participants/${participantId}/goals/${goalId}/comments`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating a new participant goal', error);
    throw error;
  }
};

// Delete ParticipantGoal
export const deleteParticipantGoalById = async (organisationId: string, participantId: string, goalId: any) => {
  try {
    if (!organisationId || !participantId || !goalId) throw new Error('Organisation ID, Participant ID, and goalId are required');

    const response = await axiosInstance.delete(`/api/organisations/${organisationId}/participants/${participantId}/goals/${goalId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error creating a new participant goal', error);
    throw error;
  }
};

export const addCommentToGoal = async (organisationId: string, participantId: string, goalId: string, data: any) => {
  try {
    if (!organisationId || !participantId || !goalId || !data) throw new Error('Organisation ID, Participant ID, Goal ID, and data are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/participants/${participantId}/goals/${goalId}/comments`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error adding a comment to the participant goal', error);
    throw error;
  }
};

export const deleteCommentFromGoal = async (organisationId: string, participantId: string, goalId: string, commentId: string) => {
  try {
    if (!organisationId || !participantId || !goalId || !commentId) throw new Error('Organisation ID, Participant ID, Goal ID, and Comment ID are required');

    const response = await axiosInstance.delete(`/api/organisations/${organisationId}/participants/${participantId}/goals/${goalId}/comments/${commentId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting a comment from the participant goal', error);
    throw error;
  }
};