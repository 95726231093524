import axiosInstance from 'api/config';

// Creates a new organisation and user role for the user
export const onboarding = async (
  serviceCodeId: string,
  subscriptionId: string,
  firstName: string,
  lastName: string,
  email: string,
  mobile: string,
  organisationName: string,
  ndisOrganisationId: string,
  abn: string
) => {
  try {
    if (!serviceCodeId || !subscriptionId || !firstName || !lastName || !email || !mobile || !organisationName || !ndisOrganisationId || !abn) {
      throw new Error('All fields are required');
    }

    const response = await axiosInstance.post('/api/on-boarding', {
      organisationName,
      subscriptionLevel: subscriptionId,
      serviceCodeId,
      firstName,
      lastName,
      email,
      mobile,
      ndisOrganisationId,
      abn,
      logoBlobName: 'string' // TODO: Do we need to add a field for Logo during onboarding - or will it be added from the settings page?
    });

    return response.data;
  } catch (error) {
    console.error('There was an error during onboarding', error);
    throw error;
  }
};

// Used to remove all organisations and user roles from the user - for testing purposes
export const offload = async () => {
  try {
    const response = await axiosInstance.delete('/api/on-boarding/offload', {
      params: {
        removeOrganisations: true,
        removeUserRoles: true,
      }
    });
    return response.data;
  } catch (error) {
    console.error('There was an error offloading:', error);
    throw error;
  }
};

// Retrieves all of the organisations linked to the user
export const userOrganisations = async () => {
  try {
    const response = await axiosInstance.get('/api/on-boarding/user-organisations');
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the user\'s organisations:', error);
    throw error;
  }
};

// Retrieves the client secret for the Stripe Checkout Session
export const createStripeSession = async (priceId: string, organisationId: string) => {
  try {
    const response = await axiosInstance.post(`/api/on-boarding/create-stripe-session/${organisationId}`, {
      priceId,
      successUrl: `${process.env.REACT_APP_URL_BASE}/return`,
      // successUrl: 'http://localhost:3000/return',
      mode: 'subscription'
    });
    return response.data;
  } catch (error) {
    console.error('There was an error creating a Stripe Checkout Session:', error);
    throw error;
  }
};

// Activates a pending organisation
export const activateOrganisation = async (organisationId: string) => {
  try {
    const response = await axiosInstance.post(`/api/on-boarding/activate-organisation/${organisationId}`);
    if (response.status === 200) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.error('There was an error activating the organisation', error);
    throw error;
  }
};

// Bulk update a field for a list of users
export const bulkUserInvite = async (userProfileIDs: string[]) => {
  try {
    if (!userProfileIDs || userProfileIDs.length === 0) throw new Error('User Profile IDs are required');

    const response = await axiosInstance.post(`/api/on-boarding/invite-users`, userProfileIDs);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the role templates', error);
    throw error;
  }
};