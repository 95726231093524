import { Box, Stack } from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';
import variables from 'styles/variables';
import styled from 'styled-components';
import NestedList from './NestedList';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import SidebarItem from './SidebarItem';
import { SidebarAsideProps } from '../types';
import { useAtom } from 'jotai';
import BackToMainMenuButton from './BackToMainMenu';
import { mainMenuItems, MenuItem, settingsMenuItems } from 'utils/helpers/menuItems';
import useCurrentUser from 'hooks/useCurrentUser';
import { sidebarAtom } from 'atoms/sidebarAtom';

const Sidebar: FC = () => {
  const [sidebarState, setSidebarState] = useAtom(sidebarAtom);
  const [navItems, setNavItems] = useState<MenuItem[]>([]);
  const toggleSidebar = () => setSidebarState({
    ...sidebarState,
    isOpen: !sidebarState.isOpen,
  });
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [user] = useCurrentUser();

  // Function to filter menu items and their children based on user permissions
  const filterMenuItems = (items: MenuItem[]): MenuItem[] => {
    return items
      .filter((item) => item.requiredPermissions.every((permission) => user?.permissions.includes(permission)))
      .map((item) => ({
        ...item,
        children: item.children ? filterMenuItems(item.children as MenuItem[]) : []
      }));
  };

  // Set the nav items based on the sidebar type
  // Filter out items that the user does not have permission to view
  useEffect(() => {
    if (sidebarState.type === 'settings') {
      setNavItems(filterMenuItems(settingsMenuItems));
    } else {
      setNavItems(filterMenuItems(mainMenuItems));
    }
  }, [sidebarState.type, user]);

  // Auto close the sidebar when clicking outside of it on small screens
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (window.innerWidth <= 1200 && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarState({
          ...sidebarState,
          isOpen: false,
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebarState.isOpen]);

  if (sidebarState.content) return (
    <Stack height={`calc(100vh - ${variables.heights.topBar})`} sx={{ backgroundColor: `${variables.colors.primary.darkest}` }}>
      <SidebarAside as='aside' sidebarOpen={sidebarState.isOpen} ref={sidebarRef}>
        {sidebarState.content}
      </SidebarAside>
      <OpenCloseBox>
        <BoxLine />
        <ArrowBox onClick={toggleSidebar}>
          <StyledArrow sidebarOpen={sidebarState.isOpen} />
        </ArrowBox>
      </OpenCloseBox>
    </Stack>
  );

  return (
    <Stack height={`calc(100vh - ${variables.heights.topBar})`} sx={{ backgroundColor: `${variables.colors.primary.darkest}` }}>
      <SidebarAside as='aside' sidebarOpen={sidebarState.isOpen} ref={sidebarRef}>
        {sidebarState.type === 'settings' &&
          <BackToMainMenuButton />
        }
        {user && navItems.map((item, index) => (
          item.children.length > 0 ?
            <NestedList key={index} label={item.label} icon={item.icon} listItems={item.children} /> :
            <SidebarItem key={index} label={item.label} icon={item.icon} to={item.to} />
        ))}
      </SidebarAside>
      <OpenCloseBox>
        <BoxLine />
        <ArrowBox onClick={toggleSidebar}>
          <StyledArrow sidebarOpen={sidebarState.isOpen} />
        </ArrowBox>
      </OpenCloseBox>
    </Stack>
  );
};

const SidebarAside = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'sidebarOpen',
}) <SidebarAsideProps>`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  position: relative;
  transition: width 0.3s ease;
  width: ${({ sidebarOpen }) => (sidebarOpen ? variables.widths.sidebarOpen : variables.widths.sidebarClosed)};
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${variables.colors.primary.main};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${variables.colors.primary.darkest};
  }
`;

const OpenCloseBox = styled(Box)`
  height: 53px;
  min-height: 53px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  padding: 12px;
  position: relative;
`;

const BoxLine = styled(Box)`
  height: 1px;
  width: calc(100% - 16px);
  background-color: #ffffff7f;
  position: absolute;
  top: 0;
  left: 8px;
`;

const ArrowBox = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  transform: translateY(6px);
`;
const StyledArrow = styled(KeyboardDoubleArrowRightOutlinedIcon).withConfig({
  shouldForwardProp: (prop) => prop !== 'sidebarOpen',
}) <SidebarAsideProps>`
  color: white;
  transform: ${({ sidebarOpen }) => (sidebarOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export default Sidebar;