import React, { FC } from 'react';
import { MsalProvider } from '@azure/msal-react';
import ThemeProvider from '../Providers/ThemeProvider';
import AuthProvider from './AuthProvider';
import 'styles/globals.css';
import { useAtom } from 'jotai';
import { redirectAtom } from 'atoms';
import RedirectScreen from 'components/features/RedirectScreen';
import { QueryProvider } from './QueryProvider';
import publicClientApplication from 'utils/config/msalInstance';
import Modal from 'components/common/Modal';
import UserMessage from 'components/common/UserMessage';
import { ProvidersProps } from './types';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import ViewAsDropdown from 'components/features/ViewAsDropdown';

// This component is used to wrap the entire application with the different providers to keep the code clean and organized
const Providers: FC<ProvidersProps> = ({ children }) => {
  const [shouldRedirect] = useAtom(redirectAtom);

  return (
    <ThemeProvider>
      <MsalProvider instance={publicClientApplication}>
        <AuthProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <QueryProvider>
              {shouldRedirect && <RedirectScreen />}
              {children}
              <Modal />
              <UserMessage />
              <ViewAsDropdown />
            </QueryProvider>
          </LocalizationProvider>
        </AuthProvider>
      </MsalProvider>
    </ThemeProvider>
  );
};

export default Providers;