import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import ListServiceTypes from 'components/features/ListServiceTypes';
import UserProfileCell from 'components/features/UserProfileCell';

export const Columns: GridColDef[] = [
  {
    field: 'user',
    headerName: '',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => (
      <UserProfileCell userProfile={params.row.user} displayOnly />
    )
  },
  {
    field: 'serviceType',
    headerName: 'Service Types',
    width: 200,
    renderCell: (params) => (
      <ListServiceTypes
        serviceTypesDelivered={params.row.shift.serviceTypes}
      />
    ),
  },
];