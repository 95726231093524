import axiosInstance from 'api/config';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { Participant, ParticipantList } from 'types/dbSchema/participants';
import { createFilterString } from 'utils/helpers';

// Returns a list of participants for the given organisation ID
export const listParticipants = async (organisationId: string, filters: SelectedFilter[] | null, page: number, pageSize: number): Promise<ParticipantList> => {

  try {
    if (!organisationId) throw new Error('Organisation ID is required');

    // Create a formatted filter string
    let formattedFilters = '';
    if (filters && filters.length > 0) {
      formattedFilters = createFilterString(filters);
    }

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants`, {
      params: {
        filters: formattedFilters,
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing participants for the organisation', error);
    throw error;
  }
};


// Retrieves a participant by ID
export const getParticipantById = async (organisationId: string, participantId: string): Promise<Participant> => {
  try {
    if (!participantId || !organisationId) throw new Error('Organisation ID and Participant ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants/${participantId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the participant', error);
    throw error;
  }
};

// Updates a participant by ID
export const updateParticipantById = async (organisationId: string, participantId: string, data: Partial<any>) => {
  try {
    if (!participantId || !organisationId || !data) throw new Error('Organisation ID, Participant ID, and data are required');

    const response = await axiosInstance.put(`/api/organisations/${organisationId}/participants/${participantId}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the participant profile', error);
    throw error;
  }
};

// Create new Participant Profile
export const createParticipant = async (organisationId: string, data: any) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/participants`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating a new participant profile', error);
    throw error;
  }
};

// Retrieves a participant by ID
export const deleteParticipantById = async (organisationId: string, participantId: string): Promise<any> => {
  try {
    if (!participantId || !organisationId) throw new Error('Organisation ID and Participant ID are required');

    const response = await axiosInstance.delete(`/api/organisations/${organisationId}/participants/${participantId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the participant', error);
    throw error;
  }
};