import { Box, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import variables from 'styles/variables';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import React, { FC } from 'react';
import { ErrorNoticeProps } from '../types';

const ErrorNotice: FC<ErrorNoticeProps> = ({ errorMessage }) => {
  return (
    <ErrorNoticeBox>
      <ErrorBox>
        <ErrorOutlineOutlinedIcon sx={{ color: variables.colors.error.main }} />
        <Typography variant='subtitle2' color={variables.colors.error.main}>
          File not uploaded
        </Typography>
      </ErrorBox>
      <Stack gap='8px' width='100%'>
        <Typography variant='subtitle2' color={variables.colors.error.main}>
          Error information
        </Typography>
        <Typography variant='subtitle2' fontWeight='400'>
          {errorMessage}
        </Typography>
      </Stack>
    </ErrorNoticeBox>
  );
};

const ErrorNoticeBox = styled(Box)`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #E2E8F0;
  background: #FFF;
`;

const ErrorBox = styled(Box)`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background:  #FEEBEE;
  width: 100%;
  box-sizing: border-box;
`;

export default ErrorNotice;