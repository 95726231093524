// Purpose: Define all variables used in the application, such as colors, fonts, widths, heights, and breakpoints.
// The variables are then used in the global.css file and in the components to ensure consistency across the application.

const colors = {
  primary: {
    main: '#AC4FC6',
    main10: 'rgba(172, 79, 198, 0.1)',
    main20: 'rgba(172, 79, 198, 0.2)',
    main30: 'rgba(172, 79, 198, 0.3)',
    darker: '#5C068C',
    darkest: '#301D44',
    lighter: '#D4A3E3',
    subtle: '#F6EAFA',
  },
  secondary: {
    main: '#03A9F4',
    darker: '#0277BD',
    lighter: '#4FC3F7',
    subtle: '#E1F5FE',
    outlineBorder: 'rgba(3, 169, 244, 0.50)',
  },
  darkNeutral: {
    main: '#424242',
    darker: '#212121',
    lighter: '#757575',
    subtle: '#9E9E9E',
  },
  lightNeutral: {
    main: '#EEEEEE',
    darker: '#E0E0E0',
    lighter: '#F5F5F5',
    subtle: '#FAFAFA',
  },
  error: {
    main: '#F44336',
    darker: '#D32F2F',
    subtle: '#FFCDD2',
  },
  warning: {
    main: '#FFB300',
    darker: '#F57C00',
    subtle: '#FFF9F0',
  },
  info: {
    main: '#039BE5',
    darker: '#0277BD',
    subtle: '#B3E5FC',
  },
  success: {
    lighter: '#69C64F',
    main: '#4F953C',
    darker: '#356328',
    subtle: '#D0EEC7',
  },
  text: {
    main: '#1F2937',
    primary: '#1F2937',
    secondary: '#00000099',
    disabled: '#00000038',
  },
  border: {
    main: '#E5E7EB'
  },
  icon: {
    standard: '#4B5563',
    close: '#4B5563'
  }
};

const fonts = {
  family: {
    primary: 'Inter, sans-serif',
  },
  fontSize: { // All font sizes are in rem based on 16px base - which can be updated in ./styles/global.css
    h1: '6rem',
    h2: '3.75rem',
    h3: '3rem',
    h4: '2.125rem',
    h5: '1.5rem',
    h6: '1.25rem',
    subtitle1: '1rem',
    subtitle2: '0.875rem',
    body1: '1rem',
    body2: '0.875rem',
    caption: '0.75rem',
    overline: '0.75rem',
    button: {
      small: '0.75rem',
      medium: '0.875rem',
      large: '1rem'
    },
  },
  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
};

const widths = {
  maxWidth: '1440px',
  sidebarOpen: '240px',
  sidebarClosed: '52px',
};

const heights = {
  topBar: '58px',
  orgRegistrationTopbar: '180px',
  dataTableControls: '170px',
  dataTableControlsSmallScreen: '215px',
};

const spacing = {
  xs025: '0.25rem',
  xs05: '0.5rem',
  sm: '1rem',
  sm05: '1.5rem',
  md: '2rem',
  md05: '2.5rem',
  lg: '3rem',
  lg05: '3.5rem',
  xl: '4rem',
  xl05: '4.5rem',
  xxl: '5rem',
  xxl05: '5.5rem',
  xxxl: '6rem',
};

const breakpoints = {
  mobile: '320px',
  tablet: '768px',
  desktop: '1024px',
};

const zIndices = {
  above: 1,
  modal: 100,
  filterDropdown: 200,
};

const variables = {
  colors,
  fonts,
  widths,
  heights,
  spacing,
  breakpoints,
  zIndices,
};

export default variables;