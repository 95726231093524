import React from 'react';
import SettingsTabs from 'components/common/SettingsTabs';
import NoteTypes from './NoteTypes';
import PayPeriods from './PayPeriods';
import ShiftCancellationReasons from './ShiftCancellationReasons';
import Tags from './Tags';
import TimeSheetDeclinedReasons from './TimeSheetDeclinedReasons';

const ServiceTeamSettings = () => {
  return (
    <SettingsTabs
      title='Service Team Settings'
      tabs={[
        {
          label: 'Pay Periods',
          content: <PayPeriods />,
          requiredPermissions: [],
        },
        {
          label: 'Tags',
          content: <Tags />,
          requiredPermissions: [],
        },
        {
          label: 'Note Types',
          content: <NoteTypes />,
          requiredPermissions: [],
        },
        {
          label: 'Time Sheet Declined Reasons',
          content: <TimeSheetDeclinedReasons />,
          requiredPermissions: [],
        },
        {
          label: 'Shift Cancellation Reasons',
          content: <ShiftCancellationReasons />,
          requiredPermissions: [],
        },
      ]}
    />
  );
};

export default ServiceTeamSettings;