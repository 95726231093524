import { PaginationBase } from "types";
import { TemplateEvent } from "types/fullCalendar";

export type LeaveTypeList = PaginationBase<LeaveType>;

export interface LeaveType {
  id: string;
  name: string;
  color: string;
  description: string;
  isActive: boolean;
  isEditable: boolean;
}

export const leaveTypesColorList = [
  { value: '#a21545', label: 'Maroon' },
  { value: '#ee5eae', label: 'Pink' },
  { value: '#da3f43', label: 'Red' },
  { value: '#cd6e1e', label: 'Orange' },
  { value: '#0c4c54', label: 'Teal' },
  { value: '#4a9acd', label: 'Blue' },
  { value: '#000080', label: 'Navy' },
  { value: '#634bbd', label: 'Violet' },
  { value: '#51087f', label: 'Dark Purple' },
];

export type AvailabilityTemplateList = PaginationBase<AvailabilityTemplate>;
export interface AvailabilityTemplate {
  id: string;
  name: string;
  description: string;
  isActive: boolean;
  availabilities: TemplateEvent[];
}