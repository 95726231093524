import React, { FC } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { Participant } from 'types/dbSchema/participants';
import { languages } from 'types';
import ServicePreferences from './ServicePreferences';
import CheckboxInput from 'components/common/InputFields/CheckboxInput';
import TextareaInput from 'components/common/InputFields/TextareaInput';
import TextInput from 'components/common/InputFields/TextInput';
import SelectInput from 'components/common/InputFields/SelectInput';
import PrimaryDisabilityLookupField from 'components/common/LookupInputField/PrimaryDisabilityLookupField';

export interface CareNeedsDetailsProps {
  control: Control<Participant>;
  errors: FieldErrors<Participant>;
}

const CareNeedsDetails: FC<CareNeedsDetailsProps> = ({ control, errors }) => {

  return (
    <CareNeedsDetailsStack id='care-preferences'>
      <Typography variant='h6' fontWeight='600' color='text.primary'>
        Care Needs and Preferences
      </Typography>
      <Row>
        <Controller
          name="primaryLanguage"
          control={control}
          render={({ field }) =>
            <SelectInput
              {...field}
              id='primaryLanguage'
              label="Primary Language"
              error={errors.primaryLanguage ? true : false}
              errorText={errors.primaryLanguage?.message}
              placeholder='Select'
              options={languages}
            />}
        />
        <Controller
          name="otherLanguage"
          control={control}
          render={({ field }) =>
            <SelectInput
              {...field}
              id='otherLanguages'
              label="Other Languages"
              error={errors.otherLanguage ? true : false}
              errorText={errors.otherLanguage?.message}
              placeholder='Select'
              options={languages}
            />}
        />
      </Row>
      <Row>
        <Controller
          name="interpreterRequired"
          control={control}
          render={({ field }) =>
            <CheckboxInput
              {...field}
              checked={field?.value || false}
              label="Interpreter Required"
              id='interpreterRequired'
              error={errors.interpreterRequired ? true : false}
              errorText={errors.interpreterRequired?.message}
            />}
        />
      </Row>
      <Row>
        <Controller
          name="primaryDisability"
          control={control}
          rules={{ required: 'Primary Disability is required' }}
          render={({ field }) =>
            <PrimaryDisabilityLookupField
              {...field}
              id='primaryDisability'
              label='Primary Disability'
              validationError={errors.primaryDisability ? true : false}
              errorText={errors.primaryDisability?.message}
              isMandatory
              placeholder='Select'
            />}
        />
        <Controller
          name="otherDisability"
          control={control}
          render={({ field }) =>
            <TextInput
              {...field}
              id='otherDisabilities'
              label="Other Disabilities"
              error={errors.otherDisability ? true : false}
              errorText={errors.otherDisability?.message}
            />}
        />
      </Row>
      <Row>
        <Controller
          name="culturalRequirements"
          control={control}
          render={({ field }) =>
            <TextareaInput
              {...field}
              id='culturalRequirements'
              label="Cultural Requirements"
              error={errors.culturalRequirements ? true : false}
              errorText={errors.culturalRequirements?.message}
            />}
        />
        <Controller
          name="allergies"
          control={control}
          render={({ field }) =>
            <TextareaInput
              {...field}
              id='allergies'
              label="Allergies"
              error={errors.allergies ? true : false}
              errorText={errors.allergies?.message}
            />}
        />
      </Row>
      <Row>
        <Controller
          name="livingArrangements"
          control={control}
          render={({ field }) =>
            <TextareaInput
              {...field}
              id='Living Arrangements'
              label="Living Arrangements"
              error={errors.livingArrangements ? true : false}
              errorText={errors.livingArrangements?.message}
            />}
        />
        <Controller
          name="interests"
          control={control}
          render={({ field }) =>
            <TextareaInput
              {...field}
              id='Interest'
              label="Interests"
              error={errors.interests ? true : false}
              errorText={errors.interests?.message}
            />}
        />
      </Row>
      <ServicePreferences control={control} errors={errors} />
    </CareNeedsDetailsStack>
  );
};

const CareNeedsDetailsStack = styled(Stack)`
  background-color: white;
  padding: 32px;
  gap: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
`;

const Row = styled(Stack)`
  gap: 24px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export default CareNeedsDetails;