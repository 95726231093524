import React, { FC, useEffect, useState } from 'react';
import { Button, Stack, Typography, } from '@mui/material';
import { CalendarEvent, TemplateEvent } from 'types/fullCalendar';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import variables from 'styles/variables';
import { format, startOfToday, addHours } from 'date-fns';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IndividualTime from '../IndividualTime';
import { useAtom } from 'jotai';
import { selectedTemplatedIdAtom } from 'atoms';
import { v4 as uuidv4 } from 'uuid';

export interface AvailableTimesProps {
  events: any[];
  setEvents: React.Dispatch<React.SetStateAction<CalendarEvent[]>>;
}

const AvailableTimes: FC<AvailableTimesProps> = ({ events, setEvents }) => {
  const [, setSelectedEventId] = useAtom(selectedTemplatedIdAtom);

  const handleNewEvent = () => {
    // Get today's date and set a default start and end time
    const today = startOfToday();
    const defaultStartTime = format(addHours(today, 0), 'HH:mm:ss');
    const defaultEndTime = format(addHours(today, 1), 'HH:mm:ss');

    const newEvent: CalendarEvent = {
      id: uuidv4(),
      title: 'Available',
      day: format(today, 'EEEE'), // Set the day of the week as today
      start: `${format(today, 'yyyy-MM-dd')}T${defaultStartTime}`, // Default start time today
      end: `${format(today, 'yyyy-MM-dd')}T${defaultEndTime}`, // Default end time today
      backgroundColor: '#4CAF50',
      duration: '01:00',
      rrule: {
        freq: 'weekly',
        byweekday: [format(today, 'EEEE').substring(0, 2).toUpperCase()],
        dtstart: `${format(today, 'yyyy-MM-dd')}T${defaultStartTime}`,
        interval: 1,
      },
    };

    setEvents((prevEvents) => [...prevEvents, newEvent]);
    setSelectedEventId(newEvent.id!);
  };

  // Initialize an object with all days of the week
  const groupedEvents = {
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
  } as { [key: string]: CalendarEvent[] };

  // Group events by their day
  events.forEach((event) => {
    const day = event.day;
    if (groupedEvents[day]) {
      groupedEvents[day].push(event);
    }
  });

  return (
    <Stack width='100%' height='fit-content' gap='16px'>
      <Stack flexDirection='row' gap='16px' justifyContent='space-between' alignItems='center'>
        <Typography variant='subtitle1'>Available Times</Typography>
        <Button
          variant='contained'
          sx={{ backgroundColor: 'rgba(166, 0, 255, 0.1)', color: variables.colors.primary.darker, ':hover': { backgroundColor: 'rgba(166, 0, 255, 0.4)' } }}
          size='small'
          color='primary'
          onClick={handleNewEvent}
          startIcon={<AddOutlinedIcon />}
        >
          Add
        </Button>
      </Stack>
      {Object.keys(groupedEvents).map((day, index) => (
        <GroupedEvents key={index} groupedEvents={groupedEvents} day={day} setEvents={setEvents} />
      ))}
    </Stack>
  );
};

const GroupedEvents = ({ groupedEvents, day, setEvents }: any) => {
  const [isOpen, setIsOpen] = useState(true);
  const [selectedEventId] = useAtom(selectedTemplatedIdAtom);

  useEffect(() => {
    const hasSelectedEvent = groupedEvents[day].some((event: TemplateEvent) => event.id === selectedEventId);
    if (hasSelectedEvent) {
      setIsOpen(true);
    }
  }, [selectedEventId, groupedEvents, day]);

  if (groupedEvents[day].length === 0) return null;

  return (
    <Stack gap="4px">
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setIsOpen(!isOpen)}
        sx={{ cursor: 'pointer' }}
      >
        <Typography variant="subtitle2">{day}</Typography>
        <KeyboardArrowDownIcon sx={{ cursor: 'pointer', transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }} />
      </Stack>
      {isOpen && (
        <Stack gap="16px">
          {groupedEvents[day].map((event: CalendarEvent, eventIndex: number) => (
            <IndividualTime key={eventIndex} event={event} setEvents={setEvents} />
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default AvailableTimes;