import React, { FC } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import SelectInput from 'components/common/InputFields/SelectInput';
import TextInput from 'components/common/InputFields/TextInput';
import { Input, Stack, TextField, Typography } from '@mui/material';
import styled from 'styled-components';
import { Participant } from 'types/dbSchema/participants';
import RadioInput from 'components/common/InputFields/RadioInput';
import variables from 'styles/variables';

export interface FundingDetailsProps {
  control: Control<Participant>;
  errors: FieldErrors<Participant>;
}

const FundingDetails: FC<FundingDetailsProps> = ({ control, errors }) => {
  return (
    <FundingDetailsStack>
      <Typography variant='h6' fontWeight='600' color='text.primary'>
        Funding Details
      </Typography>
      <Row>
        <Controller
          name="ndisNumber"
          control={control}
          rules={{
            required: 'NDIS Number is required',
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='ndisNumber'
              label="NDIS Number"
              error={errors.ndisNumber ? true : false}
              errorText={errors.ndisNumber?.message}
              isMandatory
            />}
        />
        {/* Temporary */}
        <Stack width='100%'>
          <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
            Funding Program <span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            value={'NDIS'}
            disabled
            variant='outlined'
            sx={{
              height: '42px',
              '& > *': {
                height: '42px',
                backgroundColor: 'rgba(0,0,0, 0.05)'
              }
            }}
          />
        </Stack>

        {/* We have disabled the below field while there is only one option. If multiple options become available than we remove
        the text field and uncomment this field. */}
        {/* <Controller
          name="fundingProgram"
          control={control}
          render={({ field }) =>
            <SelectInput
              {...field}
              id='fundingProgram'
              label="Funding Program"
              error={errors.fundingProgram ? true : false}
              errorText={errors.fundingProgram?.message}
              placeholder='Select'
              options={[
                { value: 'NDIS', label: 'NDIS' },
              ]}
            />}
        /> */}
      </Row>
      <Row>
        <Controller
          name="ndisSystem"
          control={control}
          rules={{
            required: 'NDIS System is required',
          }}
          render={({ field }) =>
            <RadioInput
              {...field}
              id='ndisSystem'
              label="NDIS System"
              error={errors.ndisSystem ? true : false}
              errorText={errors.ndisSystem?.message}
              isMandatory
              value={field.value || ''}
              values={[
                { value: 'proda', label: 'PRODA' },
                { value: 'pace', label: 'PACE' },
              ]}
            />}
        />
      </Row>
    </FundingDetailsStack>
  );
};

const FundingDetailsStack = styled(Stack)`
  background-color: white;
  padding: 32px;
  gap: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
`;

export interface RowProps {
  numberOfChildren?: number;
}

const Row = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'numberOfChildren'
}) <RowProps>`
  gap: 24px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start; /* Changed to flex-start to accommodate varying numbers of children */
  align-items: flex-start;

  & > * {
    flex: 0 0 calc((100% - 48px) / ${({ numberOfChildren }) => numberOfChildren});
    max-width: calc((100% - 48px) / ${({ numberOfChildren }) => numberOfChildren});
  }
`;

export default FundingDetails;