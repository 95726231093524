import ParticipantFunding from 'components/features/Widgets/ParticipantFunding';
import React, { FC } from 'react';

// Placeholder for now - waiting on style guide
const PowerviewPage: FC = () => {
  return (
    <div style={{ width: '100%', padding: '20px' }}>
      <ParticipantFunding />
    </div>
  );
};

export default PowerviewPage;