import React, { FC } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { User } from 'types/dbSchema/userProfiles';
import TextInput from 'components/common/InputFields/TextInput';
import AddressLookupField from 'components/common/InputFields/AddressLookupField';
import { Address, timezoneOptions } from 'types';
import RelationshipTypeLookupField from 'components/common/LookupInputField/RelationshipTypeLookupField';
import SelectInput from 'components/common/InputFields/SelectInput';

export interface ContactDetailsProps {
  control: Control<User>;
  errors: FieldErrors<User>;
  handleSetAddress: (values: Address) => void;
}

const ContactDetails: FC<ContactDetailsProps> = ({ control, errors, handleSetAddress }) => {

  return (
    <ContactDetailsStack>
      <Typography variant='h6' fontWeight='600' color='text.primary'>
        Contact Details
      </Typography>
      <Row>
        <Controller
          name="primaryMobile"
          control={control}
          rules={{
            required: 'Mobile Number is required',
            pattern: { value: /^[0-9]{10}$/, message: 'Invalid mobile number' },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='mobile'
              label="Mobile Number"
              error={errors.primaryMobile ? true : false}
              errorText={errors.primaryMobile?.message}
              isMandatory
            />}
        />
        <Controller
          name="phone"
          control={control}
          rules={{
            pattern: { value: /^[0-9]{10}$/, message: 'Invalid Phone Number' },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='phone'
              label="Phone"
              error={errors.phone ? true : false}
              errorText={errors.phone?.message}
            />}
        />
        <Controller
          name="timezone"
          control={control}
          rules={{
            required: 'Timezone is required',
          }}
          render={({ field }) =>
            <SelectInput
              {...field}
              id='timezone'
              label="Timezone"
              isMandatory
              error={errors.timezone ? true : false}
              errorText={errors.timezone?.message}
              placeholder='Select a timezone'
              options={timezoneOptions}
            />}
        />
      </Row>
      <Row>
        <Controller
          name="workEmail"
          control={control}
          rules={{
            required: 'Work Email is required',
            pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: 'Invalid Email' },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='workEmail'
              label="Work Email"
              error={errors.workEmail ? true : false}
              errorText={errors.workEmail?.message}
              isMandatory
            />}
        />
        <Controller
          name="personalEmail"
          control={control}
          rules={{
            pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: 'Invalid Email' },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='personalEmail'
              label="Personal Email"
              error={errors.personalEmail ? true : false}
              errorText={errors.personalEmail?.message}
            />}
        />
      </Row>
      <Stack gap='4px'>
        <Controller
          name="address"
          control={control}
          rules={{
            required: 'Address is required',
          }}
          render={({ field }) =>
            <AddressLookupField
              {...field}
              id='address'
              placeholder='Search for your address'
              setValue={handleSetAddress}
              label='Address'
              isMandatory
              error={errors.address ? true : false}
              errorText='Address is required'
            />}
        />
      </Stack>
      <Typography variant='subtitle1' fontWeight='600' color='text.primary'>
        Next of Kin
      </Typography>
      <Row>
        <Controller
          name="nextOfKinFullName"
          control={control}
          rules={{
            required: 'Next of Kin Full Name is required',
            pattern: { value: /^[a-zA-Z\s]+$/, message: 'Next of Kin Full Name must only contain letters and spaces' },
            minLength: { value: 2, message: 'Next of Kin Full Name must be at least 2 characters long' },
            maxLength: { value: 50, message: 'Next of Kin Full Name must be at most 50 characters long' }
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='nextOfKinFullName'
              label='Full Name'
              error={errors.nextOfKinFullName ? true : false}
              errorText={errors.nextOfKinFullName?.message}
              isMandatory
            />}
        />
        <Controller
          name="nextOfKinRelationship"
          control={control}
          rules={{
            required: 'Next of Kin Relationship is required',
          }}
          render={({ field }) =>
            <RelationshipTypeLookupField
              {...field}
              id='relationshipType'
              label='Relationship'
              placeholder='Select'
              validationError={errors.nextOfKinRelationship ? true : false}
              errorText={errors.nextOfKinRelationship?.message}
              isMandatory
            />
          }
        />
      </Row>
      <Row>
        <Controller
          name="nextOfKinMobile"
          control={control}
          rules={{
            required: 'Next of Kin Mobile is required',
            pattern: { value: /^[0-9]{10}$/, message: 'Invalid mobile number' },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='nextOfKinMobile'
              label='Mobile Number'
              error={errors.nextOfKinMobile ? true : false}
              errorText={errors.nextOfKinMobile?.message}
              isMandatory
            />}
        />
        <Controller
          name="nextOfKinPhone"
          control={control}
          rules={{
            pattern: { value: /^[0-9]{10}$/, message: 'Invalid Phone Number' },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='nextOfKinPhone'
              label='Phone Number'
              error={errors.nextOfKinPhone ? true : false}
              errorText={errors.nextOfKinPhone?.message}
            />}
        />
      </Row>
    </ContactDetailsStack>
  );
};

const ContactDetailsStack = styled(Stack)`
  background-color: white;
  padding: 32px;
  gap: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
`;

const Row = styled(Stack)`
  gap: 24px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export default ContactDetails;